import { Button, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { TabsProps } from "../../../shared/types/Tabs.type";
import BlackListCustomers from "./BlackListCustomers";
import BlackListPincode from "./BlackListPincode";
import useDrawer from "../../../shared/hooks/useDrawer";
import { LocalStorageHelper } from "../../../shared/utils/localStorageHelper";
import { LocalStorage } from "../../../enums/localStorage.enum";

const BlackList = () => {
  const {
    visible: isBlacklistCustomerForm,
    toggleVisibility: toggleBlacklistCustomerForm,
  } = useDrawer({});
  const {
    visible: isRestrictedPincode,
    toggleVisibility: toggleIsRestrictedPincode,
  } = useDrawer({});
  const [activeTab, setActiveTab] = useState("1");
  const userAccess = LocalStorageHelper.getItem(
    LocalStorage.CURRENT_USER_ACCESS
  );

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  const tabs: TabsProps[] = [
    {
      label: "Customers",
      key: "1",
      component: (
        <BlackListCustomers
          isBlacklistCustomerForm={isBlacklistCustomerForm}
          toggleBlacklistCustomerForm={toggleBlacklistCustomerForm}
          userAccess={userAccess}
        />
      ),
      hash: "#blacklist-customer",
    },
    {
      label: "Pincode",
      key: "2",
      component: (
        <BlackListPincode
          isRestrictedPincode={isRestrictedPincode}
          toggleIsRestrictedPincode={toggleIsRestrictedPincode}
          userAccess={userAccess}
        />
      ),
      hash: "#blacklist-pincode",
    },
  ];
  const getTabExtraContent = () => {
    switch (activeTab) {
      case "1":
        return (
          <div>
            {userAccess?.writeAccess && (
              <Button
                className="loan-disburse-btn ml-5"
                onClick={() => toggleBlacklistCustomerForm(true)}
              >
                Blacklist Customer
              </Button>
            )}
          </div>
        );
      case "2":
        return (
          <div>
            {userAccess?.writeAccess && (
              <Button
                className="loan-disburse-btn ml-5"
                onClick={() => toggleIsRestrictedPincode(true)}
              >
                Restrict Pincode
              </Button>
            )}
          </div>
        );
      default:
        <></>;
    }
  };
  return (
    <div className="card">
      <Tabs
        activeKey={activeTab}
        defaultActiveKey="2"
        onChange={handleTabChange}
        tabBarExtraContent={getTabExtraContent()}
      >
        {tabs?.map(({ label, key, component }) => (
          <Tabs.TabPane tab={label} key={key}>
            {component}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default BlackList;
