import { alias, serializable } from "serializr";

export class ThumbnailDocs {

    @serializable(alias("aadhaar_thumb"))
    aadhaarThumb?: string;
  
    @serializable(alias("agri_document_thumb"))
    agriDocument_thumb?: string;
  
    @serializable(alias("bank_statement_thumb"))
    bankStatementThumb?: string;
  
    @serializable(alias("document_default_thumb"))
    documentDefaultThumb?: string;
  
    @serializable(alias("house_image_thumb"))
    houseImageThumb?: string;
  
    @serializable(alias("income_statement_thumb"))
    incomeStatementThumb?: string;
  
    @serializable(alias("pan_thumb"))
    panThumb?: string;
  
    @serializable(alias("ration_card_thumb"))
    rationCardThumb?: string;

    @serializable(alias("default_profile_thumb"))
    defaultProfileThumb?: string;
  
  }