import "./businessNature.scss";

import { Col, Row } from "antd";
import React, { ReactElement } from "react";

import { BusinessDetail } from "../../../../../models/businessDetail.model";
import { fillEmptyData } from "../../../../../shared/utils/fillEmptyData";
import { removeUnderscore } from "../../../../../shared/utils/removeUnderscore";
import { getLocation } from "../../../../../shared/hooks/getLocation";

interface BusinessNatureProps {
  businessDetail: BusinessDetail;
}

const BusinessNature = (props: BusinessNatureProps) => {
  const { businessDetail } = props;

  return (
    <Row gutter={[16, 16]} className="block business-nature">
      <Col className="text-capitalize block__heading" span="24">
        nature of business
      </Col>
      <Col span={6} className="block__label">
        nature of business
        <div className="block__value text-capitalize">
          {fillEmptyData(
            removeUnderscore(businessDetail?.natureOfBusiness ?? "")
          )}
        </div>
      </Col>

      <Col span={6} className="block__label">
        Year of Establishment
        <div className="block__value text-capitalize">
          {fillEmptyData(businessDetail?.getEstablishmentYear())} (
          {businessDetail?.yearsOfBusiness
            ? `${fillEmptyData(businessDetail?.yearsOfBusiness)} years`
            : "NA"}
          )
        </div>
      </Col>
      <Col span={6} className="block__label">
        enterprise name
        <div className="block__value text-capitalize">
          {fillEmptyData(businessDetail?.enterpriseName)}
        </div>
      </Col>

      <Col span={6} className="block__label">
        business address
        <div className="block__value text-capitalize">
          {fillEmptyData(businessDetail?.address?.getAddress())}
        </div>
      </Col>
      <Col span={6} className="block__label">
        business description
        <div className="block__value text-capitalize">
          {fillEmptyData(businessDetail?.businessDescription)}
        </div>
      </Col>
      <Col span={6}>
        Location Co-ordinates
        <div className="block__value text-capitalize">
          {businessDetail?.address?.latitude &&
          businessDetail?.address?.longitude ? (
            <div>
              <a
                target="_blank"
                href={getLocation(
                  businessDetail?.address?.latitude,
                  businessDetail?.address?.longitude
                )}
                className="mr-5"
              >{`${businessDetail?.address?.latitude} ${businessDetail?.address?.longitude}`}</a>
            </div>
          ) : (
            "NA"
          )}
        </div>
      </Col>
    </Row>
  );
};

export default BusinessNature;
