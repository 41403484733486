import { Col, Row } from "antd";
import React, { ReactElement } from "react";

import { BusinessDetail } from "../../../../../models/businessDetail.model";
import { fillEmptyData } from "../../../../../shared/utils/fillEmptyData";
import { removeUnderscore } from "../../../../../shared/utils/removeUnderscore";

interface AgriAlliedNatureProps {
  AgriAlliedDetail: BusinessDetail;
}

const AgriAlliedNature = (props: AgriAlliedNatureProps) => {
  const { AgriAlliedDetail } = props;

  return (
    <Row gutter={[16, 16]} className="block business-nature">
      <Col className="text-capitalize block__heading" span="24">
        nature of Agriculture
      </Col>
      <Col span={6} className="block__label">
        Nature of Activity
        <div className="block__value text-capitalize">
          {fillEmptyData(
            removeUnderscore(AgriAlliedDetail?.natureOfActivity)
          )}
        </div>
      </Col>

      <Col span={6} className="block__label">
        Enterprise Name
        <div className="block__value text-capitalize">
          {fillEmptyData(AgriAlliedDetail?.enterpriseName)}
        </div>
      </Col>
      <Col span={6}  className="block__label">
          Vintage
          <div className="block__value text-capitalize">
          {fillEmptyData(AgriAlliedDetail?.vintage)}
        </div>
      </Col>
      <Col span={6} className="block__label">
      Details
        <div className="block__value text-capitalize">
          {fillEmptyData(AgriAlliedDetail?.details)}
        </div>
      </Col>
      <Col span={21} className="block__label">
       Address
        <div className="block__value text-capitalize">
          {fillEmptyData(AgriAlliedDetail?.address?.getAddress())}
        </div>
      </Col>
    </Row>
  );
};

export default AgriAlliedNature;
