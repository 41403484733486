
import {serializable, alias, object, list, primitive} from 'serializr';
import { AccountsDetails } from './accountsDetails.model';

export class OverdueAccounts { 

	@serializable(alias('total_od_accounts', primitive()))
	totalOdAccounts?: number;

	@serializable(alias('total_od_amount', primitive()))
	totalOdAmount?: number;

	@serializable(alias('settlement_accounts_details', list(object(AccountsDetails))))
	totalSettlementsAccounts?: AccountsDetails[] = [];

	@serializable(primitive())
	status?: string

}