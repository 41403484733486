import { Col, Row, Space, Spin } from "antd";
import React from "react";
import totalSettlements from "../../../../../Assets/images/Total Settlements.png";
import { ListMappingKeys } from "../../../../../shared/types/optionArray.type";
import { currencyFormatter } from "../../../../../shared/utils/formatter";
import { fillEmptyData } from "../../../../../shared/utils/fillEmptyData";
import { SettledAccounts } from "../../../../../models/settledAccounts.model";
import AppLoader from "../../../../../shared/components/AppLoader";

interface TotalSettlementsProps {
  data?: SettledAccounts
  loading: boolean
}

const TotalSettlements = (props: TotalSettlementsProps) => {

  const { data, loading } = props;

  const titleCardData: ListMappingKeys[] = [
    {
      label: "Total Settlements Accounts",
      value: data?.totalSettlementsAccount,
      offset: 5,
      key: "1"
    },
    {
      label: "Total Settlements Amount",
      value: currencyFormatter({amount: data?.totalSettlementsAmount, hasCurrencySign: true, shouldReturnNA: false}),
      offset: 2,
      key: "2"
    }
  ]

  return (
    <div>
      <AppLoader loading={loading}>
      <Row className="card crif-card" align={"middle"}>
        <Space direction="horizontal" size={12}>
          <Col>
            <img src={totalSettlements} alt="" />
          </Col>
          <Col>Total Settlements</Col>
        </Space>
        {titleCardData?.map((data) => {
          return (
            <Col offset={data.offset} key={data.key} className="flex-column">
              <span className="font-weight__600 font-size__18">{data.value || 0}</span>
              <span>{data?.label}</span>
            </Col>
          )
        })}
        <Col offset={4} className="flex-column">
          <div>
            <span className="font-weight__600 font-size__18">{currencyFormatter({amount: data?.totalSettlementsAmount, hasCurrencySign: true, shouldReturnNA: false})}</span>
          </div>
          <span className={`text__green text-right`}>{fillEmptyData(data?.status)}</span>
        </Col>
      </Row>
      </AppLoader>
    </div>
  );
};

export default TotalSettlements;
