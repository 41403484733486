const getLocationStatus = (hash: string) => {
    const status = {
      isPending: hash.includes("#pending"),
      isReturned: hash.includes("#returned"),
      isToReturn: hash.includes("#to-return"),
      isStored: hash.includes("#stored")
    };
  
    return status;
  };

export default getLocationStatus