
import {serializable, alias, object, list, primitive} from 'serializr';

export class AddressVariations { 

	@serializable(alias('VALUE', primitive()))
	value?: string;

	@serializable(alias('REPORTED-DATE', primitive()))
	reportedDate?: string;

}