import { alias, primitive, serializable } from "serializr";
import { DocumentType } from "../enums/documentType.type";
import { removeUnderscore } from "../shared/utils/removeUnderscore";

export class LoanDocument {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("owner_type", primitive()))
  ownerType?: string;
  
  @serializable(alias("owner_id", primitive()))
  ownerId?: number;

  @serializable(alias("document_type", primitive()))
  documentType?: DocumentType;
  
  @serializable(alias("document_name", primitive()))
  documentName?: string;
  
  @serializable(alias("created_by_id", primitive()))
  createdById?: number;
  
  @serializable(alias("created_by_role_code", primitive()))
  createdByRoleCode?: string;
  
  @serializable(alias("code", primitive()))
  code?: string;

  @serializable(alias("front_side_image_id", primitive()))
  frontSideImageId?: string;

  @serializable(alias("back_side_image_id", primitive()))
  backSideImageId?: string;

  @serializable(alias("front_side_image_url", primitive()))
  frontSideImageUrl?: string;

  @serializable(alias("back_side_image_url", primitive()))
  backSideImageUrl?: string;
  
  getDocumentLabel = () => {
    switch(this.documentType) {
      case DocumentType.EB_BILL:
        return "EB Bill";
      case DocumentType.LOAN_AGREEMENT:
        return "Loan Agreement"
      case DocumentType.PDC:
        return "PDC"
      case DocumentType.PROPERTY_DOCUMENT:
        return "Property Document"
     default:
      return removeUnderscore(this.documentName ? this.documentName : "Other");
    }
  }
}
