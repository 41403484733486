import {serializable, alias, object, list, primitive} from 'serializr';
import { PersonalInfoVariation } from './personalInfoVariation.model';
import { PaymentStatus } from './paymentStatus.model';
import { OverdueAccounts } from './overdueAccounts.model';
import { SettledAccounts } from './settledAccounts.model';
import { CreditAccounts } from './creditAccounts.model';
import { CreditEnquiries } from './creditEnquiries.model';
import { WrittenOff } from './writtenOff.model';
import { MonthlyEmi } from './monthlyEmi.model';

export class CrifReport { 

	@serializable(alias('crif_score', primitive()))
	crifScore?: number;

	@serializable(alias('risk_level', primitive()))
	riskLevel?: string;

	@serializable(alias('personal_info_variation', object(PersonalInfoVariation)))
	personalInfoVariation?: PersonalInfoVariation;

	@serializable(alias('payment_status', object(PaymentStatus)))
	paymentStatus?: PaymentStatus;

	@serializable(alias('overdue_accounts', object(OverdueAccounts)))
	overdueAccounts?: OverdueAccounts;

	@serializable(alias('settled_accounts', object(SettledAccounts)))
	settledAccounts?: SettledAccounts;

	@serializable(alias('credit_accounts', object(CreditAccounts)))
	creditAccounts?: CreditAccounts;

	@serializable(alias('credit_enquiries', list(object(CreditEnquiries))))
	creditEnquiries?: CreditEnquiries[] = [];

	@serializable(alias('written_off', object(WrittenOff)))
	writtenOff?: WrittenOff;

	@serializable(alias('monthly_emi', object(MonthlyEmi)))
	monthlyEmi?: MonthlyEmi;

	@serializable(primitive())
	status?: string;

	@serializable(alias('last_update_on', primitive()))
	lastUpdateOn?: string

	@serializable(primitive())
	message?: string

}

export class CrifReportTableData {
    @serializable(primitive())
    month?: string;

    @serializable(primitive())
    year?: string;
}