//Icons

//Libraries
import { Button } from "antd";
import { Formik, Form } from "formik";
import React from "react";
import { LoanStatus } from "../../../enums/loanStatus.type";
import { Loan } from "../../../models/loan.model";
import SelectInput from "../../../shared/components/SelectInput";

//Styles
import "./changeStatus.scss";
import { Meta } from "../../../models/meta.modal";
import { LoanService } from "../../../services/Loan/loan.service";

interface ChangeStatusProps {
  loan: Loan;
  onClose: () => void;
  onLoanUpdate: (loan: Loan) => void;
}

const ChangeStatus = (props: ChangeStatusProps) => {
  //Service
  const { updateLoan, loading } = LoanService();

  //Context

  //Prop
  const { loan, onClose, onLoanUpdate } = props;

  //State

  const getOptions = (status: LoanStatus): any[] => {
    switch (status) {
      case LoanStatus.CT_VERIFICATION:
        return [
          {
            label: "CT Verification",
            value: LoanStatus.CT_VERIFICATION,
            disabled: true,
          },
          {
            label: "Approved",
            value: LoanStatus.APPROVED,
          },
          {
            label: "Rejected",
            value: LoanStatus.REJECTED,
          },
        ];

      case LoanStatus.APPROVED:
        return [
          {
            label: "Approved",
            value: LoanStatus.APPROVED,
            disabled: true,
          },
          {
            label: "Dispersed",
            value: LoanStatus.DISBURSED,
          },
          {
            label: "Rejected",
            value: LoanStatus.REJECTED,
          },
        ];

      default:
        return [];
    }
  };

  const handleSubmit = async (loan: Loan) => {
    const updatedLoan = await updateLoan(loan?.id ?? 0, {
      status: loan?.status,
    });
    if (updatedLoan) onLoanUpdate(updatedLoan);
    onClose();
  };

  const loanStatus = loan!.status as LoanStatus;

  return (
    <div className="change-status">
      <div className="change-status__current">
        <div className="change-status__current__label text-capitalize">
          current status:
        </div>
        <div className="change-status__current__value text-capitalize">
          {loan?.getLoanStatusLabel()}
        </div>
      </div>
      <Formik initialValues={loan} onSubmit={handleSubmit}>
        {({ values }) => (
          <Form>
            <div className="change-status__update">
              <SelectInput
                label="Update to"
                name="status"
                placeholder=""
                options={getOptions(loanStatus)}
                disabled={
                  !(
                    loanStatus === LoanStatus.CT_VERIFICATION ||
                    loanStatus === LoanStatus.APPROVED
                  )
                }
              />
            </div>
            <div className="modal__footer text-right">
              <Button
                htmlType="reset"
                onClick={onClose}
                className="modal-footer__cancel"
                type="link"
              >
                Cancel
              </Button>
              <Button
                disabled={
                  loan?.status === values?.status
                  // !(
                  //   loan?.status === LoanStatus.CT_VERIFICATION ||
                  //   loan?.status === LoanStatus.APPROVED
                  // )
                }
                className="modal-footer__submit"
                htmlType="submit"
                loading={loading}
              >
                Update
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ChangeStatus;
