import { Employee } from "../../models/employee.model";
import React from "react";
import { TableColumnsType } from "antd";
import { getFullName } from "../../shared/utils/getFullName";

export const columns: TableColumnsType<Employee> = [
  {
    title: "ID",
    dataIndex: "code",
    sorter: true,
  },
  {
    title: "Name",
    dataIndex: ["firstName", "lastName", "profilePicUrl"],
    sorter: true,
    render: (_: string, record: Employee) => getFullName(record),
  },
  {
    title: "Role",
    dataIndex: "roleName",
  },
  {
    title: "Branch",
    dataIndex: "branches",
    sorter: true,
    render: (_, record) => record?.getBranchNames(),
  },
  {
    title: "Region",
    dataIndex: "branches",
    render: (_, record) => record?.getRegionName(),
  },
  {
    title: "BM",
    dataIndex: "branches",
    render: (_, record) =>
      record?.getReportingManagerNames()?.map(({ name, imgSrc }, index) => (
        <>
          <span>{imgSrc && <img src={imgSrc} />}</span>
          <span>{`${name}${
            index < Number(record?.branches?.length) - 1 ? ", " : ""
          }`}</span>
        </>
      )) || "NA",
  },
  {
    title: "Status",
    dataIndex: "isActive",
    sorter: true,

    render: (isActive: boolean) =>
      isActive ? (
        <span className="active-status text-capitalize text-success">
          active
        </span>
      ) : (
        <span className="active-status text-capitalize text-danger">
          inactive
        </span>
      ),
  },
  {
    title: "Offline Status",
    dataIndex: "offlinePermissionEnabled",

    render: (offlinePermissionEnabled: boolean) =>
      offlinePermissionEnabled ? (
        <span className="active-status text-capitalize text-success">
          enabled
        </span>
      ) : (
        <span className="active-status text-capitalize text-danger">
          disabled
        </span>
      ),
  },
];
