//Libraries
import { Button, Tabs } from "antd";
import FiltersListing, {
  FiltersListingProps,
} from "../../../shared/components/FiltersListing";
import { Form, Formik } from "formik";
import React from "react";

import { CentersService } from "../../../services/Centers/centers.service";
import { LoanParams } from "../../../models/loan.model";
import { MetaService } from "../../../services/Meta/meta.service";
import { QueryParams } from "../../../models/query.params.model";
import { BranchesService } from "../../../services/Branches/branches.service";
import { hideTab } from "../../../shared/utils/hideTab";
import { LocalStorageHelper } from "../../../shared/utils/localStorageHelper";
import { LocalStorage } from "../../../enums/localStorage.enum";
import { Employee } from "../../../models/employee.model";
import { RoleName } from "../../../enums/roleName.enum";

const branchAccountantFilters = [
  "customerId",
  "status",
  "centerId",
  "loanTypeId",
  "createdById",
  "branchId",
  "organisations",
];

interface BranchAccountantFiltersProps {
  onFilter: (val: LoanParams) => void;
  onClose: () => void;
}

const BranchAccountantFilters = (props: BranchAccountantFiltersProps) => {
  // Services
  const {
    getLoanTypes,
    loading: metaLoading,
    getPreferredChannel,
  } = MetaService();
  const { getCenters, loading: centersLoading } = CentersService();
  const { getBranches, loading: branchesLoading } = BranchesService();
  const employee = LocalStorageHelper.getItem(
    LocalStorage.EMPLOYEE
  ) as Employee;

  //Prop
  const { onClose, onFilter } = props;

  const fetchLoanTypes = async () => {
    const data = await getLoanTypes();
    return { data };
  };

  const fetchBranches = async (params: QueryParams) => {
    const result = await getBranches(params);
    return {
      data:
        result?.branches?.map((branch) => ({
          label: branch?.name,
          value: branch?.id,
        })) ?? [],
      meta: result?.meta,
    };
  };

  const fetchPreferredChannel = async () => {
    const result = await getPreferredChannel();

    return {
      data: result?.map((val) => ({
        label: val?.label,
        value: val?.value,
      })),
    };
  };

  const fetchCenters = async (params: QueryParams) => {
    const result = await getCenters(params);
    return {
      data:
        result?.centers?.map((center) => ({
          label: center?.name,
          value: center?.id,
        })) ?? [],
      meta: result?.meta,
    };
  };

  const filtersTab: { title: string; tabContent: FiltersListingProps }[] = [
    {
      title: "Center",
      tabContent: {
        fetchData: fetchCenters,
        hasPagination: true,
        loading: centersLoading,
        name: "centerId",
        searchProps: {
          placeholder: "Search Center",
          hasIcon: false,
          onSearch: () => {},
        },
      },
    },
    {
      title: "Branch",
      tabContent: {
        fetchData: fetchBranches,
        hasPagination: true,
        loading: branchesLoading,
        name: "branchId",
        searchProps: {
          placeholder: "Search Branch",
          hasIcon: false,
          onSearch: () => {},
        },
      },
    },
    {
      title: "Loan Type",
      tabContent: {
        fetchData: fetchLoanTypes,
        hasPagination: false,
        loading: metaLoading,
        name: "loanTypeId",
      },
    },
    {
      title: "Preferred Channel",
      tabContent: {
        fetchData: fetchPreferredChannel,
        hasPagination: false,
        loading: metaLoading,
        name: "organisations",
      },
    },
  ];

  const handleSubmit = (val: LoanParams) => {
    const filters = Object.fromEntries(
      Object.entries(val).filter(([key]) => branchAccountantFilters.includes(key))
    );

    onFilter({ ...filters, page: 1 });
    onClose();
  };

  return (
    <div className="loan-filters">
      <Formik initialValues={new LoanParams()} onSubmit={handleSubmit}>
        {({ setFieldValue }) => (
          <Form>
            <Tabs tabPosition="left">
              {filtersTab
                .filter(
                  ({ tabContent: { name } }) =>
                    !hideTab(
                      employee?.roleName === RoleName.BRANCH_MANAGER,
                      name,
                      ["branchId"]
                    )
                )
                .map((item, index) => (
                  <Tabs.TabPane tab={item?.title} key={index}>
                    <FiltersListing {...item.tabContent} />
                  </Tabs.TabPane>
                ))}
            </Tabs>

            <div className="drawer-footer loan-filters__footer">
              <Button
                className="drawer-footer__cancel"
                htmlType="reset"
                onClick={onClose}
                type="link"
              >
                Cancel
              </Button>
              <Button className="drawer-footer__submit" htmlType="submit">
                Assign
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default BranchAccountantFilters;
