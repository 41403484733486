import { alias, custom, object, primitive, serializable } from "serializr";
import { FileAttachment } from "./fileAttachment.model";

export class Comments {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("creator_firstname", primitive()))
  creatorFirstname?: string;

  @serializable(alias("creator_id", primitive()))
  creatorId?: number;
  @serializable(alias("creator_lastname", primitive()))
  creatorLastname?: string;
  @serializable(alias("creator_type", primitive()))
  creatorType?: string;
  @serializable(alias("description", primitive()))
  description?: string;
  @serializable(alias("loan_id", primitive()))
  loanId?: number;
  @serializable(alias("step", primitive()))
  step?: string;
  @serializable(alias("branch_name", primitive()))
  branchName?: string;
  @serializable(alias("created_at", primitive()))
  createdAt?: string;
  @serializable(alias("attachment_id", primitive()))
  attachmentId?: string;
  @serializable(alias("creator_branch", primitive()))
  creatorBranch?: string;

  @serializable(alias("attachment", object(FileAttachment)))
  attachment?: FileAttachment;
  @serializable(
    alias(
      "rejection_reason",
      custom(
        (file) => file,
        (json) => {}
      )
    )
  )
  comment?: File;
}
