
import {serializable, alias, object, list, primitive} from 'serializr';

export class SettledAccounts { 

	@serializable(alias('total_settlements_account', primitive()))
	totalSettlementsAccount?: number;

	@serializable(alias('total_settlements_amount', primitive()))
	totalSettlementsAmount?: number;

	@serializable(primitive())
	status?: string
}