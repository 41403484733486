import React from "react";
import tick from '../../Assets/images/tick.png'
import yellowSquare from '../../Assets/images/yellow-square.png'
import redSquare from '../../Assets/images/red-square.png'
import { PaymentStatuses } from "../../enums/paymentStatus.type";

export const renderPaymentStatus = (status?: string, payments?: number, days?: number) => {
  switch (status) {
    case PaymentStatuses.ONTIME:
      return <span className="active-status--on-time"><img src={tick} width={15} height={15} alt=""/></span>;
    case PaymentStatuses.LATE_PAYMENTS:
      return <span className="active-status--late-payments"><img src={yellowSquare} width={15} height={15} alt=""/></span>;
    case PaymentStatuses.OD_PAYMENTS:
      return <span className="active-status--od-payments"><img src={redSquare} width={15} height={15} alt=""/></span>;
    case PaymentStatuses.NO_INFO:
      return <span className="active-status--no-info">●</span>;
    default:
      return null;
  }
};
