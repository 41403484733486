import { LoanStatus } from "../../enums/loanStatus.type";

const loanStatusOptions = [
  {
    label: "Disbursed",
    value: LoanStatus.DISBURSED,
  },
  {
    label: "Approved",
    value: LoanStatus.APPROVED,
  },
  {
    label: "CT Verification",
    value: LoanStatus.CT_VERIFICATION,
  },
];

export default loanStatusOptions;
