import "./configurations.scss";
import { BranchParams } from "../../models/branch.model";
import Branches from "./Branches";
import { CenterParams } from "../../models/center.model";
import Centers from "./Centers";
import React, { useEffect } from "react";
import TabExtraContent from "../../shared/components/TabExtraContent";
import { Tabs } from "antd";
import { TabsProps } from "../../shared/types/Tabs.type";
import useDrawer from "../../shared/hooks/useDrawer";
import useQueryParams from "../../shared/hooks/useQueryParams";
import { useState } from "react";
import LoanTypes from "./LoanTypes";
import { LoanParams } from "../../models/loan.model";
import UserAcess from "./UserAccess";
import { QueryParams } from "../../models/query.params.model";
import { useLocation } from "react-router-dom";
import BlackList from "./BlackList";
import { User } from "../../enums/user.type";
import { LocalStorageHelper } from "../../shared/utils/localStorageHelper";
import { LocalStorage } from "../../enums/localStorage.enum";
import { UserAccess } from "../../models/userAccess.model";
import { RoleName } from "../../enums/roleName.enum";

const Configuration = () => {
  const location = useLocation();
  const { params: branchParams, handleSearch: handleBranchSearch } =
    useQueryParams<BranchParams>({
      params: new BranchParams(),
    });

  const { params: centerParams, handleSearch: handleCenterSearch } =
    useQueryParams<CenterParams>({
      params: new CenterParams(),
    });

  const { params: loanTypeParams, handleSearch: handleLoanTypeSearch } =
    useQueryParams<LoanParams>({
      params: new LoanParams(),
    });
  const { updateParams } = useQueryParams<QueryParams>({
    params: new QueryParams(),
  });
  const userAccess = LocalStorageHelper.getItem(
    LocalStorage.CURRENT_USER_ACCESS
  ) as UserAccess;
  /* branch */

  const {
    visible: branchAddFormVisible,
    toggleVisibility: toggleBranchAddForm,
  } = useDrawer({});

  const {
    visible: branchFiltersVisible,
    toggleVisibility: toggleBranchFiltersVisibility,
  } = useDrawer({});

  const {
    visible: branchTargetFormVisible,
    toggleVisibility: toggleBranchTargetForm,
  } = useDrawer({});

  const {
    visible: branchSettingsVisible,
    toggleVisibility: toggleBranchSettings,
  } = useDrawer({});

  /* Center */

  const {
    visible: centerAddFormVisible,
    toggleVisibility: toggleCenterAddForm,
  } = useDrawer({});

  const {
    visible: centerFiltersVisible,
    toggleVisibility: toggleCenterFiltersVisibility,
  } = useDrawer({});

  const {
    visible: centerSettingsVisible,
    toggleVisibility: toggleCenterSettingsVisibility,
  } = useDrawer({});

  /* LoanType */
  const {
    visible: loanFiltersVisible,
    toggleVisibility: toggleLoanFiltersVisibility,
  } = useDrawer({});

  const {
    visible: loanAddFormVisible,
    toggleVisibility: toggleLoanTypesAddForm,
  } = useDrawer({});

  const {
    visible: loanSettingsVisible,
    toggleVisibility: toggleLoanSettingsVisibility,
  } = useDrawer({});

  const [activeTab, setActiveTab] = useState("1");

  useEffect(() => {
    const defaultTab = tabs.find(({ condition }) => condition !== false);

    setActiveTab(
      String(
        tabs?.find((tab) => location?.hash === tab?.hash)?.key ??
          defaultTab?.key
      )
    );
  }, []);

  const tabs: TabsProps[] = [
    {
      label: "User Access",
      key: "1",
      component: <UserAcess />,

      hash: "#user-access",
      condition: userAccess?.name === RoleName.SUPER_ADMIN,
    },
    {
      label: "Branches",
      key: "2",
      component: (
        <Branches
          addFormVisibility={branchAddFormVisible}
          filtersVisibility={branchFiltersVisible}
          targetFormVisibility={branchTargetFormVisible}
          settingsVisibility={branchSettingsVisible}
          toggleAddForm={toggleBranchAddForm}
          toggleFilters={toggleBranchFiltersVisibility}
          toggleTargetForm={toggleBranchTargetForm}
          toggleSettings={toggleBranchSettings}
        />
      ),
      hash: "#branches",
    },
    {
      label: "Center",
      key: "3",
      component: (
        <Centers
          addFormVisible={centerAddFormVisible}
          filtersVisible={centerFiltersVisible}
          settingsVisible={centerSettingsVisible}
          toggleAddForm={toggleCenterAddForm}
          toggleFiltersVisibility={toggleCenterFiltersVisibility}
          toggleSettingsVisibility={toggleCenterSettingsVisibility}
        />
      ),
      hash: "#center",
    },
    {
      label: "Loan Types",
      key: "4",
      component: (
        <LoanTypes
          addFormVisible={loanAddFormVisible}
          settingsVisible={loanSettingsVisible}
          toggleAddForm={toggleLoanTypesAddForm}
          params={loanTypeParams}
          filtersVisible={loanFiltersVisible}
          toggleFiltersVisibility={toggleLoanFiltersVisibility}
          toggleSettingsVisibility={toggleLoanSettingsVisibility}
        />
      ),
      hash: "#loan-types",
    },
    {
      label: "Blacklist",
      key: "5",
      component: (
        <>
          <BlackList />
        </>
      ),
      hash: "#black-list",
    },
  ];

  const handleTabChange = (tabNumber: string) => {
    const hash = tabs?.find((tab, index) => tab?.key === tabNumber)?.hash;
    updateParams({ page: 1 }, hash);
    setActiveTab(tabNumber);
  };

  const getTabExtraContent = () => {
    switch (activeTab) {
      case "2":
        return (
          <TabExtraContent
            addClick={userAccess?.writeAccess ? toggleBranchAddForm : null}
            filterClick={toggleBranchFiltersVisibility}
            settingsClick={toggleBranchSettings}
          />
        );

      case "3":
        return (
          <TabExtraContent
            addClick={userAccess?.writeAccess ? toggleCenterAddForm : null}
            filterClick={toggleCenterFiltersVisibility}
            settingsClick={toggleCenterSettingsVisibility}
          />
        );
      case "4":
        return (
          <TabExtraContent
            addClick={userAccess?.writeAccess ? toggleLoanTypesAddForm : null}
            settingsClick={toggleLoanSettingsVisibility}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div>
      <Tabs
        activeKey={activeTab}
        defaultActiveKey="2"
        onChange={handleTabChange}
        tabBarExtraContent={{
          right: getTabExtraContent(),
        }}
      >
        {tabs
          ?.filter(({ condition }) => condition !== false)
          .map(({ label, key, component }) => (
            <Tabs.TabPane tab={label} key={key}>
              {component}
            </Tabs.TabPane>
          ))}
      </Tabs>
    </div>
  );
};
export default Configuration;
