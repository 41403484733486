import { Button, Col, Drawer, Popover, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LoanTypeConditionModel } from "../../../../../models/loanType.model";
import { LoanService } from "../../../../../services/Loan/loan.service";
import useDrawer from "../../../../../shared/hooks/useDrawer";
import LoanTypeConditionForm from "../../LoanTypeConditionForm";
import "./loanTypeCondition.scss";
import LoanTypeConditionIndividualForm from "./LoanTypeConditionIndividualForm";
import { LocalStorageHelper } from "../../../../../shared/utils/localStorageHelper";
import { LocalStorage } from "../../../../../enums/localStorage.enum";

const LoanTypeCondition = () => {
  const { getLoanTypeCondition, loading, deleteLoanTypeCondition } =
    LoanService();
  const [loanTypeConditon, setLoanTypeCondition] = useState<
    LoanTypeConditionModel[]
  >([]);
  const [loanTypeConditionDetail, setLoanTypeConditionDetail] =
    useState<LoanTypeConditionModel>();
  const userAccess = LocalStorageHelper.getItem(
    LocalStorage.CURRENT_USER_ACCESS
  );

  const { loanTypeId: loanTypeId } = useParams();
  const [open, setOpen] = useState(false);
  const handlePopupVisibility = () => setOpen(!open);
  const {
    visible: isLoanTypeConditionDetailForm,
    toggleVisibility: toogleLoanConditionDetailForm,
  } = useDrawer({});
  const {
    visible: isLoanTypeConditionForm,
    toggleVisibility: toogleLoanConditionForm,
  } = useDrawer({});

  const handleFetchLoanTypeCondition = async () => {
    const loanCondtions = await getLoanTypeCondition(String(loanTypeId));
    loanCondtions && setLoanTypeCondition(loanCondtions);
  };

  const handleDeleteLoanCondition = async (id: string) => {
    const result = await deleteLoanTypeCondition(String(loanTypeId), id);
    if (result) {
      const updatedValue = loanTypeConditon?.filter(
        (loan) => Number(id) !== Number(loan?.id)
      );

      setLoanTypeCondition(updatedValue);
    }
  };
  useEffect(() => {
    handleFetchLoanTypeCondition();
  }, []);
  const handleLoanConditionDetailUpdate = (
    updatedLoanType: LoanTypeConditionModel
  ) => {
    const updatedValue = loanTypeConditon?.map((loan) =>
      loan?.id === updatedLoanType?.id ? updatedLoanType : loan
    );
    setLoanTypeCondition(updatedValue);
  };
  const handleLoanConditionUpdate = (
    updatedLoanType: LoanTypeConditionModel
  ) => {
    setLoanTypeCondition([...loanTypeConditon, updatedLoanType]);
  };

  return (
    <div className="card loan-type-condition">
      <Row className="mb-5 mr-5" justify="end">
        {userAccess?.writeAccess && (
          <Button
            className="text-right"
            onClick={() => toogleLoanConditionForm(true)}
          >
            ADD
          </Button>
        )}
      </Row>
      {loading ? (
        <div className="text-center">
          <Spin />
        </div>
      ) : (
        <Row gutter={[0, 30]} justify="space-between">
          {loanTypeConditon?.map((loan, index) => {
            return (
              <Col span={11} className="content-box mr-5">
                {userAccess?.writeAccess && (
                  <Col span={24} className="text-right">
                    <Popover
                      key={loan?.id}
                      placement="bottomLeft"
                      content={
                        <Row className="personal-details__dot-details">
                          <Col
                            span={24}
                            onClickCapture={handlePopupVisibility}
                            className="mb-3"
                            onClick={() => {
                              setLoanTypeConditionDetail(loan);
                              toogleLoanConditionDetailForm();
                            }}
                          >
                            Edit
                          </Col>
                          {loanTypeConditon?.length !== 1 && (
                            <Col
                              span={24}
                              onClickCapture={handlePopupVisibility}
                              className="mb-3"
                              onClick={() =>
                                handleDeleteLoanCondition(String(loan?.id))
                              }
                            >
                              Delete
                            </Col>
                          )}
                        </Row>
                      }
                    >
                      <a className="personal-details__dot" key={loan?.id}>
                        . . .
                      </a>
                    </Popover>
                  </Col>
                )}
                <Row
                  justify="space-between"
                  className="loan-type-condition__wrapper"
                >
                  <Col className="branch-summary__label mb-5" span={12}>
                    Min-Max amount
                    <Col className="branch-summary__value" span={24}>
                      {`${loan?.minimumAmount} - ${loan?.maximumAmount} `}
                    </Col>
                  </Col>
                  <Col className="branch-summary__label mb-5" span={12}>
                    Interest
                    <Col className="branch-summary__value" span={24}>
                      {`${loan?.interest}%`}
                    </Col>
                  </Col>
                  <Col className="branch-summary__label mb-5" span={12}>
                    Min - Max Tenure
                    <Col className="branch-summary__value" span={24}>
                      {loan?.minimumTenure &&
                        `${loan?.minimumTenure} - ${loan?.maximumTenure} Month`}
                    </Col>
                  </Col>
                  <Col className="branch-summary__label mb-5" span={12}>
                    Branch
                    <Col className="branch-summary__value" span={24}>
                      {loan?.branches?.map(
                        (branch, index) =>
                          `${branch?.label}${
                            index < Number(loan?.branches?.length) - 1
                              ? ", "
                              : ""
                          }`
                      )}
                    </Col>
                  </Col>
                  {loan?.regions?.length ? (
                    <Col className="branch-summary__label mb-5" span={10}>
                      Region
                      <Col className="branch-summary__value" span={24}>
                        {loan?.regions?.map(
                          (region, index) =>
                            `${region?.label}${
                              index < Number(loan?.regions?.length) - 1
                                ? ", "
                                : ""
                            }`
                        )}
                      </Col>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
              </Col>
            );
          })}
          <Drawer
            placement="right"
            onClose={() => toogleLoanConditionForm?.(false)}
            visible={isLoanTypeConditionForm}
            width="1000"
            title="Add Loan Type"
            destroyOnClose
          >
            <LoanTypeConditionForm
              onSuccess={handleLoanConditionUpdate}
              toogleLoanConditionForm={toogleLoanConditionForm}
              formValues={loanTypeConditon}
              isAdd={true}
              loanTypeId={loanTypeId}
            />
          </Drawer>
          <Drawer
            placement="right"
            onClose={() => toogleLoanConditionDetailForm?.(false)}
            visible={isLoanTypeConditionDetailForm}
            width="600"
            title="Edit Loan Type Condition"
            destroyOnClose
          >
            <LoanTypeConditionIndividualForm
              toogleLoanConditionForm={toogleLoanConditionDetailForm}
              onSuccess={handleLoanConditionDetailUpdate}
              formValues={
                loanTypeConditionDetail ?? new LoanTypeConditionModel()
              }
              loanTypeId={String(loanTypeId)}
            />
          </Drawer>
        </Row>
      )}
    </div>
  );
};

export default LoanTypeCondition;
