import React from "react";
import { ColumnProps } from "antd/lib/table";
import { DateFormat } from "../../../enums/dateFormat.type";
import { Deposits } from "../../../models/deposits.model";
import { FileAttachment } from "../../../models/fileAttachment.model";
import { dateTimeFormatter } from "../../../shared/utils/formatter";

export const depositColumn: ColumnProps<Deposits>[] = [
  {
    title: "User",
    dataIndex: "user",
  },
  {
    title: "Role",
    dataIndex: "role",
  },
  {
    title: "Branch",
    dataIndex: "branch",
  },
  {
    title: "Deposited Date",
    dataIndex: "createdAt",
    render: (date: string) => dateTimeFormatter(date, DateFormat.DATE),
    sorter: true
  },
  {
    title: "Time",
    dataIndex: "createdAt",
    render: (date: string) => dateTimeFormatter(date, DateFormat.TIME),
  },
  {
    title: "Collected Amount",
    dataIndex: "collectedAmount",
  },
  {
    title: "Deposited Amount",
    dataIndex: "depositedAmount",
  },
  {
    title: "Attachment",
    dataIndex: "receipt",
    render: (receipt, deposit) =>
      receipt ? (
        <a target="_blank" href={receipt ?? ""}>
          View Receipt
        </a>
      ) : (
        "-"
      ),
  },
  {
    title: "Cash on Hand",
    dataIndex: "cashOnHand",
  },
];
