import React, { useState } from "react";
import Loans from "../../Customers/CustomerDetail/Loans";
import { useLocation } from "react-router-dom";

const CTVerificationLoan = () => {
  const location = useLocation();
  const aadhaarNumber = location?.state?.aadhaarNumber;

  return (
    <div>
      <Loans
        isCustomerLoan={true}
        isCTVerificationLoan={true}
        aadhaarNumber={aadhaarNumber}
      />
    </div>
  );
};

export default CTVerificationLoan;
