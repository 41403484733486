import {
  alias,
  list,
  object,
  optional,
  primitive,
  serializable,
} from "serializr";
import { Customer } from "./customer.model";
import { ModifiedBy } from "./modifiedBy.model";
import { QueryParams } from "./query.params.model";

export class LoanWalletParams extends QueryParams {
  @serializable(alias("status", optional(list(primitive()))))
  status?: string[] = [];
}

export class WalletTransactions {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("datetime", primitive()))
  dateTime?: string;
  @serializable(alias("amount", primitive()))
  amount?: number;
  @serializable(alias("status", primitive()))
  status?: string;
}

class Transactions {
  @serializable(alias("payment_transactions", list(object(WalletTransactions))))
  paymentTransactions?: WalletTransactions[];
}

export class LoanWallet {
  @serializable(alias("total_amount", primitive()))
  totalAmount?: number;
  @serializable(alias("transactions", object(Transactions)))
  transactions = new Transactions();
}
