import React from "react";
import Icon from "../Icon";
import SearchInput, { SearchInputProps } from "../SearchInput";
import filterIcon from "../../../Assets/images/filter.png";
import settingsIcon from "../../../Assets/images/settings.png";
import exportIcon from "../../../Assets/images/Export.png";
import addIcon from "../../../Assets/images/addnew.png";
import "./tabExtraContent.scss";

interface TabExtraContentProps {
  searchProps?: SearchInputProps;
  filterClick?: () => void;
  settingsClick?: () => void;
  exportClick?: () => void;
  addClick?: (() => void) | null;
}

const TabExtraContent = (props: TabExtraContentProps) => {
  const { searchProps, filterClick, settingsClick, exportClick, addClick } =
    props;
  const { value, onSearch, placeholder, isActive = true } = searchProps || {};

  return (
    <div className="tab-extra-content">
      {isActive && searchProps && (
        <SearchInput
          value={value}
          placeholder={placeholder}
          onSearch={onSearch}
        />
      )}
      {filterClick && (
        <Icon hasIconContainer url={filterIcon} onClick={filterClick} />
      )}
      {settingsClick && (
        <Icon hasIconContainer url={settingsIcon} onClick={settingsClick} />
      )}
      {exportClick && (
        <Icon hasIconContainer url={exportIcon} onClick={exportClick} />
      )}
      {addClick && <Icon hasIconContainer url={addIcon} onClick={addClick} />}
    </div>
  );
};

export default TabExtraContent;
