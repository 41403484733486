import { ColumnProps } from "antd/lib/table";
import { CashOnHand } from "../../../models/cashOnHand.model";
import React from "react";
import { Col, Popover, Row } from "antd";
import "./cashOnHand.scss";
import avatar from "../../../Assets/images/userAvatarPlaceholder.jpeg";

const handleDetails = (cashDetails: CashOnHand, type: string) => {
  const detailType =
    type === "cash" ? "cashOnHandDetails" : "pendingAcknowledgementDetails";

  const cashDetail = cashDetails?.[detailType]?.employees?.map(
    ({ user, amount }) => (
      <Row className="cash-on-hand__popover">
        <Col span={2} className="mr-5">
          <img src={avatar} />
        </Col>
        <Col span={15}>{user}</Col>
        <Col span={5}>{amount}</Col>
      </Row>
    )
  );

  return (
    <Popover content={cashDetail} placement="right" trigger="click">
      {cashDetails?.[detailType]?.employees?.length && (
        <a type="primary">{"View Details  >"}</a>
      )}
    </Popover>
  );
};

export const cashOnHandColumn: ColumnProps<CashOnHand>[] = [
  {
    title: "User",
    dataIndex: "user",
    sorter: true,
  },
  {
    title: "Role",
    dataIndex: "role",
  },
  {
    title: "Branch",
    dataIndex: "branch",
    sorter: true,
  },
  {
    title: "Cash on Hand",
    dataIndex: "cashOnHand",
  },
  {
    title: "Details",
    dataIndex: "cashOnHandDetail",
    render: (_, cashDetails) => handleDetails(cashDetails, "cash"),
    width: "15%",
  },
  {
    title: "Pending Acknowledgment",
    dataIndex: "pendingAcknowledgement",
  },
  {
    title: "Details",
    dataIndex: "pendingAcknowledgementDetail",
    render: (_, ackDetails) => handleDetails(ackDetails, "acknowledgement"),
    width: "15%",
  },
  {
    title: "Total Amount",
    dataIndex: "totalAmount",
  },
];
