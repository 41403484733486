import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Employee, EmployeeParams } from "../../../models/employee.model";
import InputField from "../../../shared/components/InputField";
import { InputType } from "../../../enums/input.type";
import SelectInput from "../../../shared/components/SelectInput";
import { Button } from "antd";
import useBranch from "../../../shared/hooks/useBranch";
import { Meta } from "../../../models/meta.modal";
import { MetaService } from "../../../services/Meta/meta.service";
import { EmployeeService } from "../../../services/Employee/employee.service";

interface OfflineFormProps {
  onSuccess: (
    values: Employee[],
    params: EmployeeParams,
    totalPage: number
  ) => void;
  onClose: () => void;
}

const OfflineLoanForm = (props: OfflineFormProps) => {
  const { onSuccess, onClose } = props;
  const {
    loading: metaLoading,
    getRoles,

    getPreferredChannel,
  } = MetaService();
  const {
    loading: branchesLoading,
    branches,
    handleBranchScroll,
    handleBranchSearch,
  } = useBranch({});

  const [organizations, setOrganizations] = useState<Meta[]>([]);
  const [roles, setRoles] = useState<Meta[]>([]);
  const { getEmployees, addEmployee, loading } = EmployeeService();

  useEffect(() => {
    initializeMeta();
  }, []);

  const initializeMeta = async () => {
    const [roles, organizations] = await Promise.all([
      getRoles(),
      getPreferredChannel(),
    ]);
    if (roles) setRoles(roles);
    if (organizations) setOrganizations(organizations);
  };

  const handleSubmit = async (values: EmployeeParams) => {
    const employeesData = await getEmployees(values);
    if (employeesData?.employees && employeesData?.meta) {
      onSuccess(
        employeesData?.employees,
        values,
        Number(employeesData?.meta?.totalCount)
      );
    }
  };
  return (
    <div>
      <div className="disburse-form">
        <Formik initialValues={new EmployeeParams()} onSubmit={handleSubmit}>
          {({ dirty, isValid }) => (
            <Form className="ant-form-vertical disburse-form">
              <SelectInput
                name="roleIds"
                mode="multiple"
                options={roles}
                placeholder="Select Role"
                label="Role"
              />
              <SelectInput
                name="branchesId"
                options={branches}
                showSearch
                handleSearch={handleBranchSearch}
                placeholder="Select Branch"
                label="Branch"
                mode="multiple"
                onScroll={handleBranchScroll}
                loading={branchesLoading}
              />
              <SelectInput
                name="organisations"
                mode="multiple"
                options={organizations}
                placeholder="Select Preferred Channels"
                label="Preferred Channel"
              />

              <div className="drawer-footer">
                <Button
                  htmlType="reset"
                  onClick={onClose}
                  type="link"
                  className="drawer-footer__cancel"
                >
                  Cancel
                </Button>
                <Button
                  loading={loading}
                  htmlType="submit"
                  className="drawer-footer__submit"
                >
                  Apply
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default OfflineLoanForm;
