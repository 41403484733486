import ActionCable from "actioncable";
import Notification from "../components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { Report } from "../../models/report.model";

interface ChannelHandlers<T> {
  onConnected?: () => void;
  onReceived?: (data: Report) => void;
  onRejected?: () => void;
  onError?: (error: Error) => void;
  onDisconnected?: () => void;
}

export async function connectToChannel<T>(
  url: string,
  channelName: string,
  handlers: ChannelHandlers<T> = {},
  reportData: Report | null,
  updateReportData: (data: Report | null) => void
): Promise<ActionCable.Channel> {
  const {
    onConnected = () => {},
    onReceived = () => {},
    onRejected = () => {},
    onError = () => {},
    onDisconnected = () => {},
  } = handlers;

  try {
    const consumer = ActionCable.createConsumer(url);
    const channel = consumer.subscriptions.create(channelName, {
      connected: () => {
        updateReportData({ connected: true });
        console.log("Connected to channel:", channelName);
        onConnected();
      },
      received: (data) => {
        updateReportData({ ...data, connected: true });
        if (data?.url) {
          console.log({ data });
          const link = document.createElement("a");
          link.style.display = "none";
          link.href = data.url;
          link.target = "_blank";

          if (data.url.endsWith(".pdf")) {
            link.download = "document.pdf"; // Specify the desired file name for PDFs
          } else if (data.url.endsWith(".xlsx")) {
            link.download = "document.xlsx"; // Specify the desired file name for Excel files
          }

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          channel.unsubscribe();
          updateReportData(null);
        } else if (data?.error) {
          Notification({
            message: "Failed to generate and download report",
            type: NotificationTypes.ERROR,
          });

          channel.unsubscribe();
          updateReportData(null);
        }
        onReceived(data);
      },
      rejected: onRejected,
      disconnected: onDisconnected,
    });
    return channel;
  } catch (error) {
    onError(error as Error);
    throw error;
  }
}
