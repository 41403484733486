import React from "react";
import { Col, Row } from "antd";
import { OwnedEntity } from "../../../../../models/ownedEntity.model";
import "./appliances.scss";

interface AppliancesProps {
  appliances?: OwnedEntity[];
}

const Appliances = (props: AppliancesProps) => {
  const { appliances } = props;

  return (
    <div className="appliances">
      {appliances?.length ? (
        <Row gutter={[0, 16]} className="block">
          <Col span={24} className="block__heading">
            Appliances
          </Col>
          <div className="appliances__wrapper">
            {appliances?.map((appliance) => (
              <span>{appliance?.entityType}</span>
            ))}
          </div>
        </Row>
      ) : (
        ""
      )}
    </div>
  );
};

export default Appliances;
