import { Layout } from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../../shared/components/Sidebar";
import Navbar from "../../shared/components/NavBar";

import "./appLayout.scss";
import useBreakPoint from "../../shared/hooks/useBreakPoint";

interface AppLayoutProps {}

const AppLayout = (props: AppLayoutProps) => {
  const {} = props;
  const { mdScreen, xsScreen, smScreen } = useBreakPoint()
  const isMobile = ((xsScreen || smScreen) && !mdScreen)

  return (
    <div className="app-layout">
      <Layout hasSider>
        <Sidebar />
        <Layout>
          <Header>
            <Navbar />
          </Header>
          <Content>
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default AppLayout;
