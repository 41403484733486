//Icons

//Libraries
import debounce from "lodash.debounce";
import React, { useEffect, useState } from "react";
import { Branch } from "../../models/branch.model";
import { Meta } from "../../models/meta.modal";
import { PaginationMeta } from "../../models/pagination.model";
import { QueryParams } from "../../models/query.params.model";
import { BranchesService } from "../../services/Branches/branches.service";

interface useBranchProps {
  allBranch?: boolean;
}

const useBranch = (props: useBranchProps) => {
  const { getBranches, loading } = BranchesService();
  //Prop
  const { allBranch } = props;

  //State
  const [branches, setBranches] = useState<Meta[]>([]);
  // const [allBranches, setAllBranches] = useState<Meta[]>([]);
  const [totalPage, setTotalPage] = useState(1);
  const [queryParams, setQueryParams] = useState(new QueryParams());

  const handleBranchUpdate = (branches?: Branch[]) => {
    if (branches?.length)
      setBranches((existingData) => [
        ...existingData,
        ...branches
          ?.map((val) => ({
            value: val?.id ?? 0,
            code: val?.code ?? "",
            label: val?.name ?? "",
          }))
          .filter(
            (val) => !existingData.some((data) => data.value === val.value)
          ),
      ]);
  };

  const handleBranchReset = () => {
    setBranches([]);
    setQueryParams(new QueryParams());
    setTotalPage(1);
  };

  const handleBranchSearch = debounce((value) => {
    setBranches([]);
    setQueryParams((params) => ({ ...params, page: 1, searchText: value }));
  }, 500);

  const handleBranchScroll: React.UIEventHandler<HTMLDivElement> = (event) => {
    const { scrollTop, offsetHeight, scrollHeight } = event.currentTarget;
    if (
      scrollTop + offsetHeight === scrollHeight &&
      queryParams?.page &&
      Number(queryParams?.page) <= totalPage
    ) {
      setQueryParams((params) => {
        if (Number(params?.page) < totalPage)
          return { ...params, page: Number(params?.page) + 1 };

        return params;
      });
    }
  };
  const populateAllBranches = async (queryParams?: QueryParams) => {
    const response = await getBranches(queryParams);

    handleBranchUpdate(response?.branches);

    if (response?.meta?.nextPage)
      populateAllBranches({ page: response?.meta?.nextPage });
  };

  useEffect(() => {
    const populateBranches = async () => {
      const response = await getBranches(queryParams);
      handleBranchUpdate(response?.branches);
      if (response?.meta)
        setTotalPage((response?.meta as PaginationMeta).totalPages ?? 1);
    };
    allBranch ? populateAllBranches() : populateBranches();
  }, [queryParams]);

  return {
    branches,
    loading,
    handleBranchSearch,
    handleBranchScroll,
    handleBranchReset,
    handleBranchUpdate,
  };
};

export default useBranch;
