import { Form, Formik, FormikHelpers } from "formik";
import React, { useEffect, useState } from "react";
import CustomModal from "../../../../shared/components/CustomModal";
import { Button, Col, Row } from "antd";
import SelectInput from "../../../../shared/components/SelectInput";
import "../../loans.scss";
import InputField from "../../../../shared/components/InputField";
import { InputType } from "../../../../enums/input.type";
import { MetaService } from "../../../../services/Meta/meta.service";
import { Meta } from "../../../../models/meta.modal";
import { LoanService } from "../../../../services/Loan/loan.service";
import { HypothecatorLoans } from "../../../../models/bookDebt.model";
import { bookDebtModalValidation } from "./bookDebtModalValidationSchema";

interface BookDebtModalProps {
  bookDebtModalVisible: boolean;
  selectedLoans: number[];
  toggleBookDebtModal: () => void;
  fetchLoans: () => void;
  setSelectedLoans: React.Dispatch<React.SetStateAction<number[]>>;
}

const BookDebtModal = (props: BookDebtModalProps) => {

  const { getHypothecators } = MetaService();
  const { addHypothecator, updateLoansWithHypothecator } = LoanService();
  const { bookDebtModalVisible, toggleBookDebtModal, selectedLoans, fetchLoans, setSelectedLoans } = props;
  const [newHypothecator, setNewHypothecator] = useState(false);
  const [hypothecators, setHypothecators] = useState<Meta[]>([]);
  
  useEffect(() => {
    if (bookDebtModalVisible) getAllHypothecators();
  }, [bookDebtModalVisible]);

  const closeBookDebtModal = () => {
    toggleBookDebtModal();
    setNewHypothecator(false);
    setSelectedLoans([])
  };

  const getAllHypothecators = async () => {
    const hypothecators = await getHypothecators();
    hypothecators && setHypothecators(hypothecators);
  };

  const handleSubmit = async (values: HypothecatorLoans, { resetForm }: FormikHelpers<HypothecatorLoans>) => {
    const { hypothecatorName, ...restValues } = values;
    const updatedValues = { ...restValues, loanIds: selectedLoans };
    const result = await updateLoansWithHypothecator(updatedValues);
    if (result) {
      setNewHypothecator(false)
      resetForm()
      closeBookDebtModal()
      setSelectedLoans([])
      fetchLoans()
    }
  };

  return (
    <div className="book-debt-modal">
      <Formik
        initialValues={new HypothecatorLoans()}
        onSubmit={handleSubmit}
        validationSchema={bookDebtModalValidation}
      >
        {({
          values,
          isValid,
          dirty,
          setFieldValue,
        }) => {
          const toggleHypothecator = async (hypothecatorName?: string) => {
            setFieldValue("hypothecatorId", "")
            if (!newHypothecator) {
              setNewHypothecator(true);
              setFieldValue("newHypothecator", true);
            } else if (newHypothecator) {
              const result = await addHypothecator({ name: hypothecatorName });
              if (result) { 
              getAllHypothecators();
              setNewHypothecator(false);
              setFieldValue("hypothecatorName", "")
              }
            }
          };
          return (
            <CustomModal
              isCancelButton={false}
              isOkButton={false}
              className="book-debt__modal"
              title="Book Debt"
              onCancel={toggleBookDebtModal}
              visible={bookDebtModalVisible}
              okText="Confirm"
            >
              <Form className="ant-form-vertical">
                <Row>
                  <Col span={24}>
                    <Row gutter={[40, 10]} align={"middle"}>
                      <Col span={16}>
                        <SelectInput
                          label="Choose Hypothecator"
                          options={hypothecators}
                          name="hypothecatorId"
                        />
                      </Col>
                      <Col>
                        <Button
                          disabled={(!values?.hypothecatorName && newHypothecator)}
                          onClick={() =>
                            toggleHypothecator(values?.hypothecatorName)
                          }
                          className="book-debt-modal__add-button ml-2 mr-2"
                        >
                          Add New
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  {newHypothecator && (
                    <Col span={24}>
                      <InputField
                        label="Enter Hypothecator Name"
                        name="hypothecatorName"
                        placeholder="Enter Hypothecator"
                        type={InputType.TEXT}
                        disabled={!!values?.hypothecatorId}
                      />
                    </Col>
                  )}
                </Row>
                <div className="drawer-footer">
                  <Button
                    className="drawer-footer__cancel"
                    htmlType="reset"
                    onClick={closeBookDebtModal}
                    type="link"
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!isValid || !dirty}
                    className="drawer-footer__submit"
                    htmlType="submit"
                  >
                    Confirm
                  </Button>
                </div>
              </Form>
            </CustomModal>
          );
        }}
      </Formik>
    </div>
  );
};

export default BookDebtModal;
