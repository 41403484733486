import React from "react";
import { ColumnProps } from "antd/lib/table";
import { DateFormat } from "../../../enums/dateFormat.type";
import { dateTimeFormatter } from "../../../shared/utils/formatter";
import { Payments } from "../../../models/payments.model";
import { removeUnderscore } from "../../../shared/utils/removeUnderscore";
import { fillEmptyData } from "../../../shared/utils/fillEmptyData";

export const paymentsColumns: ColumnProps<Payments>[] = [
  {
    title: "Loan ID",
    dataIndex: "loanCode",
    render: (loanCode: string) => fillEmptyData(loanCode),
    sorter: true
  },
   {
    title: "Partner ID",
    dataIndex: "laf",
    render: (laf: string) => fillEmptyData(laf?.toUpperCase()),
  },
  {
    title: "Receipt No",
    dataIndex: "receiptNo",
    sorter: true
  },
  {
    title: "Transaction ID",
    dataIndex: "transactionId",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    sorter: true
  },
  {
    title: "Payment Type",
    dataIndex: "transactionType",
    render: (transactionType: string) => removeUnderscore(transactionType),
  },
  {
    title: "Date & Time",
    dataIndex: "createdAt",
    render: (date) => dateTimeFormatter(date, DateFormat.DATE_TIME),
    sorter: true
  },
];
