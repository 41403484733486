import { SortOrder } from "antd/lib/table/interface";
import { SortDirection } from "../../enums/sortDirection.type";

export const getSortDirection = (sortDirection?: SortOrder) => {
  switch (sortDirection) {
    case "descend":
      return SortDirection.DESCEND;
    case "ascend":
      return SortDirection.ASCEND;
    default:
      return undefined;
  }
};
