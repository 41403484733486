import React, { useEffect, useState } from "react";
import { Form, Select } from "antd";
import "./selectInput.scss";
import { Field, useField, useFormikContext } from "formik";
import Error from "../Error";
import { Meta } from "../../../models/meta.modal";

interface SelectInputProps {
  disabled?: boolean;
  defaultValue?: string;
  options: Meta[];
  placeholder?: string;
  name: string;
  showSearch?: boolean;
  label?: string;
  onChange?: (name: string, options: Meta | Meta[]) => void;
  onBlur?: () => void;
  handleSearch?: (value: string) => void;
  mode?: "multiple";
  loading?: boolean;
  onScroll?: React.UIEventHandler<HTMLDivElement>;
  onSelect?: (value: string) => void;
  IsSelectAll?: boolean;
  value?: any;
  children?: React.ReactNode;
  onSearch?: (value: string) => void;
  onPopupScroll?: React.UIEventHandler<HTMLDivElement>;
}

const SelectInput = (props: SelectInputProps) => {
  const {
    options,
    handleSearch,
    showSearch,
    placeholder,
    name,
    onChange,
    mode,
    value,
    label,
    defaultValue,
    disabled,
    loading,
    onScroll,
    onSelect,
    IsSelectAll,
    onBlur
  } = props;
  const [field, meta] = useField(name);
  const { setFieldValue, setFieldTouched } = useFormikContext<any>();
  const [valueOptions, setValueOptions] = useState<Meta[]>([]);

  useEffect(() => {
    IsSelectAll
      ? setValueOptions([{ label: "Select All", value: "all" }, ...options])
      : setValueOptions(options);
  }, [options]);

  const handleSelectAllOptions = (value: any) => {
    if (value.includes("all")) {
      const ids = valueOptions
        .map((val) => val.value)
        .filter((val, i) => val !== "all");
      return ids;
    }
  };

  const handleOnBlur = () => {
    if (onBlur) onBlur()
    else setFieldTouched(name)
  }

  const handleChange = (value: string, options: Meta | Meta[]) => {
    if (IsSelectAll) {
      const option = options as Meta[];
      const result = option?.map((opt) => opt.value);
      const allOptions = handleSelectAllOptions(result);
      setFieldValue(name, allOptions ? allOptions : result);
    } else setFieldValue(name, value);
    if (onChange) onChange(name, options);
  };  
  return (
    <Field name={name}>
      {() => (
        <>
          <Form.Item label={label}>
            <Select
              disabled={disabled}
              defaultValue={defaultValue}
              onBlur={handleOnBlur}
              showSearch={showSearch}
              showArrow
              allowClear
              onChange={handleChange}
              onSearch={handleSearch || undefined}
              placeholder={placeholder}
              className="select"
              mode={mode}
              value={value || field.value || undefined}
              filterOption={false}
              options={valueOptions}
              loading={loading}
              onPopupScroll={onScroll}
              onSelect={onSelect}
            />
            {meta?.error && meta?.touched ? (
              <Error message={String(meta?.error)} />
            ) : (
              <></>
            )}
          </Form.Item>
        </>
      )}
    </Field>
  );
};

export default SelectInput;
