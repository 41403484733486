import { alias, list, object, primitive, serializable } from "serializr";
import { Customer } from "./customer.model";

export class DashboardGraph {
  @serializable(alias("month", primitive()))
  month?: string;
  @serializable(alias("sum", primitive()))
  sum?: number;
  @serializable(alias("count", primitive()))
  count?: number;
}
