import { alias, list, object, primitive, serializable } from "serializr";

export class Verification {
  @serializable(alias("aadhaar_number", primitive()))
  aadhaarNumber?: string;
  @serializable(alias("pan_number", primitive()))
  panNumber?: string;
  @serializable(alias("bank_account", primitive()))
  bankAccount?: string;
}
