import {serializable, alias, object, list, primitive} from 'serializr';
import { AccountsDetails } from './accountsDetails.model';

export class CreditAccounts { 

	@serializable(alias('total_credit_accounts', primitive()))
	totalCreditAccounts?: number;

	@serializable(alias('credit_years', primitive()))
	creditYears?: number;

	@serializable(alias('credit_months', primitive()))
	creditMonths?: number;

	@serializable(alias('total_active_accounts', primitive()))
	totalActiveAccounts?: number;

	@serializable(alias('total_closed_accounts', primitive()))
	totalClosedAccounts?: number;

	@serializable(alias('accounts_details', list(object(AccountsDetails))))
	accountsDetails?: AccountsDetails[] = [];

	@serializable(alias('credit_status', primitive()))
	creditStatus?: string
}

