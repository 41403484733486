import { gstDeductions } from "../../enums/gstDeduction.type";
import { LoanDeductionType } from "../../enums/loanDeduction.type";

export const loanDeductionOptions = [
  {
    label: "Flat Rate",
    value: LoanDeductionType.FLAT_RATE,
  },
  {
    label: "Percentage",
    value: LoanDeductionType.PERCENTAGE,
  },
  {
    label: "Both",
    value: LoanDeductionType.BOTH,
  },
];

export const gstDeductionTypes = [
  {
    label: 'Loan Based',
    value: gstDeductions.LOAN_BASED
  },
  {
    label: 'Monthly Based',
    value: gstDeductions.MONTHLY_BASED
  }
]
