import { alias, object, primitive, serializable } from "serializr";
import { QueryParams } from "./query.params.model";

export class WaiveRequestModelParams extends QueryParams {}

export class WaiveRequestModel {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("loan_type", primitive()))
  loanType?: string;
  @serializable(alias("customer_code", primitive()))
  customerCode?: string;
  @serializable(alias("loan_code", primitive()))
  loanCode?: string;
  @serializable(alias("loan_id", primitive()))
  loanId?: number;

  @serializable(alias("customer_name", primitive()))
  customerName?: string;
  @serializable(alias("cro_name", primitive()))
  croName?: string;

  @serializable(alias("penalty_amt", primitive()))
  penaltyAmt?: number;
  @serializable(alias("status", primitive()))
  status?: string;
  @serializable(alias("updated_at", primitive()))
  updatedAt?: string;
}
