import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { CustomersService } from "../../../services/Customers/customers.service";
import { ExistingLoan } from "../../../models/existingLoan.model";
import AppLoader from "../../../shared/components/AppLoader";
import { fillEmptyData } from "../../../shared/utils/fillEmptyData";

interface ExistingLoanSummaryProps {
  hasCard?: boolean;
  aadhaarNumber?: string;
}

const ExistingLoanSummary = (props: ExistingLoanSummaryProps) => {
  const [existingLoan, setExistingLoan] = useState(new ExistingLoan());
  const { getCustomerExistingLoan, loading } = CustomersService();
  const { hasCard, aadhaarNumber } = props;

  useEffect(() => {
    aadhaarNumber && handleGetLoanSummary();
  }, [aadhaarNumber]);

  const handleGetLoanSummary = async () => {
    const data = await getCustomerExistingLoan({ aadhaarNumber });
    if (data) setExistingLoan(data);
  };

  const loanSummary = [
    {
      label: "Borrowe Name",
      value: fillEmptyData(existingLoan?.borrowerName),
      colValue: 4,
    },
    {
      label: "Borrower Role",
      value: fillEmptyData(existingLoan?.borrowerRole),
    },
    {
      label: "Aadhar",
      value: fillEmptyData(existingLoan?.aadhaarNumber),
    },
    {
      label: "PAN",
      value: fillEmptyData(existingLoan?.pan),
    },
    {
      label: "Total Loans",
      value: fillEmptyData(existingLoan?.total),
    },
    {
      label: "Active/Closed Loans",
      value: `${existingLoan?.active ?? 0}/${existingLoan?.closed ?? 0}`,
      colValue: 5,
    },
    {
      label: "Remarks",
      value: fillEmptyData(existingLoan?.remarks),
      colValue: 2,
    },
  ];

  return loading ? (
    <AppLoader loading={loading} />
  ) : (
    <Row
      className={`${hasCard ? "card" : "block"} mb-5 mt-3`}
      gutter={[16, 16]}
    >
      <Col span={24} className="block__heading">
        Existing loan Summary
      </Col>
      {loanSummary?.map((data, index) => (
        <Col key={index} span={data?.colValue ?? 3} className="block__label">
          {data?.label}
          <div className="block__value">{data?.value}</div>
        </Col>
      ))}
    </Row>
  );
};

export default ExistingLoanSummary;
