
import { EmailVariations } from './emailVariations.model';

import { PhoneNumberVariations } from './phoneNumberVariations.model';

import { DateOfBirthVariations } from './dateOfBirthVariations.model';

import { PanVariations} from './panVariations.model';

import { AddressVariations } from './addressVariations.model';

import { NameVariations } from './nameVariations.model';

import {serializable, alias, object, list, primitive} from 'serializr';
import { CkycVariations } from './ckycVariations.model';
import { DrivingLicenseVariations } from './drivingLicenseVariations.model';
import { NregaVariations } from './nregaVariations.model';
import { PassportVariations } from './passportVariations.model';
import { RationCardVariations } from './rationCardVariations.model';

export class PersonalInfoVariation { 

	@serializable(alias('NAME-VARIATIONS', list(object(NameVariations))))
	nameVariations?: NameVariations[] = [];

	@serializable(alias('ADDRESS-VARIATIONS', list(object(AddressVariations))))
	addressVariations?: AddressVariations[] = [];

	@serializable(alias('PAN-VARIATIONS', list(object(PanVariations))))
	panVariations?: PanVariations[] = [];

	@serializable(alias('DATE-OF-BIRTH-VARIATIONS', list(object(DateOfBirthVariations))))
	dateOfBirthVariations?: DateOfBirthVariations[] = [];

	@serializable(alias('PHONE-NUMBER-VARIATIONS', list(object(PhoneNumberVariations))))
	phoneNumberVariations?: PhoneNumberVariations[] = [];

	@serializable(alias('EMAIL-VARIATIONS', list(object(EmailVariations))))
	emailVariations?: EmailVariations[] = [];

	@serializable(alias('CKYC-VARIATIONS', list(object(CkycVariations))))
	ckycVariations?: CkycVariations[] = [];

	@serializable(alias('DRIVING-LICENSE-VARIATIONS', list(object(DrivingLicenseVariations))))
	drivingLicenseVariations?: DrivingLicenseVariations[] = [];

	@serializable(alias('NREGA-VARIATIONS', list(object(NregaVariations))))
	nRegaVariations?: NregaVariations[] = [];

	@serializable(alias('PASSPORT-VARIATIONS', list(object(PassportVariations))))
	passPortVariations?: PassportVariations[] = []

	@serializable(alias('RATION-CARD-VARIATIONS', list(object(RationCardVariations))))
	rationCardVariations?: RationCardVariations[] = []

}