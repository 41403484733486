import * as Yup from "yup";

export const loanTypeDetailFormValidation = Yup.object().shape({
  name: Yup.string().required().label("Loan Type "),
  minimumAmount: Yup.string().required().label("Minimum Amount "),
  maximumAmount: Yup.string().required().label("Maximum Amount "),
  minimumTenure: Yup.string().required().label("Minimum Tenure "),
  maximumTenure: Yup.string().required().label("Maximum Tenure "),
  minimumInterest: Yup.string().required().label("Minimum Interest "),
  maximumInterest: Yup.string().required().label("Maximum Interest "),
  regionIds: Yup.string().required().label("Region Name "),
  branchIds: Yup.string().required().label("Branch Name "),
});
