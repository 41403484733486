import { Form, Formik, FormikHelpers } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "antd";
import {
  LoanTypeConditionModel,
  LoanTypeDetailsModel,
} from "../../../../models/loanType.model";
import InputField from "../../../../shared/components/InputField";
import { InputType } from "../../../../enums/input.type";
import { Meta } from "../../../../models/meta.modal";
import { MetaService } from "../../../../services/Meta/meta.service";
import SelectInput from "../../../../shared/components/SelectInput";
import { BranchesService } from "../../../../services/Branches/branches.service";
import useBranch from "../../../../shared/hooks/useBranch";

import { StepStatus } from "../../../../enums/stepStatus.enum";
import "./loanTypeConditionForm.scss";
import { loanTypeConditionFormValidation } from "./loanTypeConditionFormValidation";
import { LoanService } from "../../../../services/Loan/loan.service";

interface LoanTypeConditionFormProps {
  toggleStep?: (status: string) => void;
  currentStep?: number;
  loanTypeId?: string;
  formValues?: LoanTypeConditionModel[];
  onSuccess?: (loanConfig: LoanTypeConditionModel) => void;
  toogleLoanConditionForm?: () => void;
  isAdd?: boolean;
}

function LoanTypeConditionForm(props: LoanTypeConditionFormProps) {
  const {
    toggleStep,
    currentStep,
    loanTypeId,
    isAdd,
    toogleLoanConditionForm,
    onSuccess,
  } = props;
  const { loading: metaLoading, getRegions } = MetaService();
  const { createLoanTypeCondition, getLoanTypeCondition, loading } =
    LoanService();
  const [formValues, setFormValues] = useState<LoanTypeConditionModel>(
    new LoanTypeConditionModel()
  );

  const [regions, setRegions] = useState<Meta[]>([]);
  const [loanTypeCondition, setLoanTypeCondition] = useState<
    LoanTypeConditionModel[]
  >([]);

  const { branches } = useBranch({ allBranch: true });

  useEffect(() => {
    if (currentStep === 2 || isAdd) {
      const populateMeta = async () => {
        const regions = await getRegions();

        if (regions) setRegions(regions);
      };

      populateMeta();
      handleFetchLoanTypeCondition();
    }
  }, [currentStep, isAdd]);
  const handleFetchLoanTypeCondition = async () => {
    const loanCondtions = await getLoanTypeCondition(String(loanTypeId));
    loanCondtions && setLoanTypeCondition(loanCondtions);
  };

  const handleSubmit = async (
    values: LoanTypeConditionModel,
    helpers: FormikHelpers<any>
  ) => {
    const loan = await createLoanTypeCondition(values, String(loanTypeId));

    if (loan) {
      helpers.resetForm();

      handleFetchLoanTypeCondition();
      if (isAdd) {
        onSuccess && onSuccess(loan);
        toogleLoanConditionForm && toogleLoanConditionForm();
      }
    }
  };

  return (
    <div>
      <Formik
        initialValues={formValues}
        validationSchema={loanTypeConditionFormValidation}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <>
            <div className="loan-type-condition-form">
              <div className="loan-type-condition-form__header">
                {!isAdd && (
                  <div className="loan-type-condition-form__header__process-count">
                    2
                  </div>
                )}
                <div className="loan-type-condition-form__header__process-title">
                  Loan Type condition
                </div>
              </div>
              <div className="branch-form__content">
                <Form className="ant-form-vertical">
                  <InputField
                    type={InputType.NUMBER}
                    name="minimumAmount"
                    placeholder="Enter Minimum Amount"
                    label="Minimum Amount"
                  />
                  <InputField
                    type={InputType.NUMBER}
                    name="maximumAmount"
                    placeholder="Enter Maximum Amount"
                    label="Maximum Amount"
                  />
                  <InputField
                    type={InputType.NUMBER}
                    name="minimumTenure"
                    placeholder="Enter Minimum Tenure"
                    label="Minimum Tenure"
                  />
                  <InputField
                    type={InputType.NUMBER}
                    name="maximumTenure"
                    placeholder="Enter Maxiumum Tenure"
                    label="Maxiumum Tenure"
                  />
                  <InputField
                    type={InputType.NUMBER}
                    name="interest"
                    placeholder="Enter Interest"
                    label="Interest"
                  />

                  <SelectInput
                    name="branchIds"
                    options={branches}
                    mode="multiple"
                    placeholder="Select Branch"
                    label="Branch"
                    IsSelectAll={true}
                  />
                  <SelectInput
                    name="regionIds"
                    options={regions}
                    mode="multiple"
                    placeholder="Select  Region"
                    label="Region"
                  />

                  <Button
                    className="drawer-footer__add mt-5"
                    htmlType="submit"
                    loading={loading}
                  >
                    Add
                  </Button>

                  {!isAdd &&
                    loanTypeCondition?.map((val) => {
                      return (
                        <div className="loan-type-condition-form__container">
                          <Row justify="space-between">
                            <Col span={14} className="text-bold">
                              Min-Max Amount
                              <div className="text-normal">
                                {" "}
                                {`${val?.minimumAmount}- ${val?.maximumAmount}`}
                              </div>
                            </Col>
                            <Col span={10} className="text-bold">
                              Interest
                              <div className="text-normal">
                                {val.interest && `${val.interest}%`}
                              </div>
                            </Col>
                            <Col span={14} className="text-bold">
                              Min-Max Tenure
                              <div className="text-normal">
                                {" "}
                                {val?.minimumAmount &&
                                  `${val?.minimumAmount}- ${val?.maximumAmount} Months`}
                              </div>
                            </Col>
                            {/* <Col span={10} className="text-bold">
                              Branch
                              <div className="text-normal">
                                {val.branches && ` ${val.branches.join(", ")}`}
                              </div>
                            </Col>
                            <Col span={14} className="text-bold">
                              Region
                              <div className="text-normal">
                                {" "}
                                {val.regions && ` ${val.regions.join(", ")}`}
                              </div>
                            </Col> */}
                          </Row>
                        </div>
                      );
                    })}
                  <div className="loan-type-condition-form__footer drawer-footer">
                    <Button
                      className="drawer-footer__cancel"
                      onClick={() =>
                        isAdd
                          ? toogleLoanConditionForm && toogleLoanConditionForm()
                          : toggleStep && toggleStep(StepStatus.PREV)
                      }
                      type="link"
                      icon={!isAdd && "<  "}
                    >
                      {isAdd ? "Cancel" : "Prev"}
                    </Button>
                    {!isAdd && (
                      <Button
                        disabled={!loanTypeCondition?.length}
                        className="drawer-footer__submit"
                        onClick={() =>
                          toggleStep && toggleStep(StepStatus.NEXT)
                        }
                      >
                        Next
                      </Button>
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
}
export default LoanTypeConditionForm;
