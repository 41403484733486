import React, { useState } from "react";
import { Form, Formik } from "formik";
import { LoanDeductionForm as LoanDeductionFormModel } from "../../../../../models/loanDeduction.model";
import InputField from "../../../../../shared/components/InputField";
import { InputType } from "../../../../../enums/input.type";
import { Button, Col, Popover, Row, Space } from "antd";
import RadioField from "../../../../../shared/components/RadioField";
import { gstDeductionTypes, loanDeductionOptions } from "../../../../../shared/Constant/loanDeductionOptions";
import "./loanDeductionForm.scss";
import { LoanService } from "../../../../../services/Loan/loan.service";
import { useParams } from "react-router-dom";
import { loanDeductionFormValidation } from "./loanDeductionFormValidation";
import iIcon from "../../../../../Assets/images/iIcon.png";
import { LoanDeductionType } from "../../../../../enums/loanDeduction.type";
import SwitchComponent from "../../../../../shared/components/Switch";

interface LoanDeductionFormProps {
  deductionFormValue?: LoanDeductionFormModel;
  onSuccess: () => void;
  toggleHandler: () => void;
}

const LoanDeductionForm = (props: LoanDeductionFormProps) => {
  const { loanTypeId: loanTypeId } = useParams();
  const { addLoanDeduction, loading, updateLoanDeduction } = LoanService();
  const { toggleHandler, onSuccess, deductionFormValue } = props;
  const [isGstIncluded, setIsGstIncluded] = useState(false);

  const handleSubmit = async (values: LoanDeductionFormModel) => {
    const result = values?.id
      ? await updateLoanDeduction(
        { ...values, id: undefined },
        String(values?.id)
      )
      : await addLoanDeduction({
        ...values,
        loanTypeId: Number(loanTypeId),
      });
    result && onSuccess();
  };

  return (
    <div>
      <Formik
        initialValues={{
          ...Object.assign(new LoanDeductionFormModel(), deductionFormValue),
          gstEnabled: deductionFormValue?.gstEnabled || false,
        }}
        onSubmit={handleSubmit}
        validationSchema={loanDeductionFormValidation}
        enableReinitialize
      >
        {({ values, setFieldValue, isValid, dirty, errors }) => {
            const handleSwitchChange = (name: string, checked: boolean) => {
              setFieldValue(name, checked);
            if (!checked) {
              setFieldValue('gst', 0);
            }
          };

          const handleModeChange = (name: string, value: string) => {
              if(value === LoanDeductionType.FLAT_RATE)
              {
                setFieldValue(LoanDeductionType.PERCENTAGE, 0)
              } else if (value === LoanDeductionType.PERCENTAGE) {
                setFieldValue('fixedRate', 0)
              }
          }

          return (
            <Form className="loan-deduction-form ant-form-vertical">
              <div className="content-box mt-5">
                <Row gutter={[20, 0]}>
                  <Col span={24}>
                    <InputField
                      type={InputType.TEXT}
                      name="name"
                      placeholder="Enter Deduction Name"
                      label="Deduction Name"
                    />
                  </Col>
                  <Col span={12} className="mt-5">
                    <span className="text-bold">
                      Deduction Type
                    </span>
                    <RadioField
                      name="deductionType"
                      className="mt-5"
                      defaultValue='loan_based'
                      options={gstDeductionTypes}
                      value={values?.deductionType}
                      setFieldValue={setFieldValue}
                    />
                  </Col>
                  <Col span={12} className="mt-5">
                    <span className="text-bold mb-5">
                      Deduction Fee Structure
                      <Popover
                        content={
                          <div>
                            Percentage of loan amount and fixed amount whichever
                            is greater for a Loan
                          </div>
                        }
                      >
                        <img
                          className="ml-5 cursor-pointer"
                          src={iIcon}
                          width={15}
                          height={15}
                        />
                      </Popover>
                    </span>

                    <RadioField
                      name="mode"
                      className="mt-5"
                      options={loanDeductionOptions}
                      value={values?.mode}
                      setFieldValue={setFieldValue}
                      onChange={handleModeChange}
                    />
                  </Col>
                  {(values?.mode === LoanDeductionType.FLAT_RATE ||
                    values?.mode === LoanDeductionType.BOTH) && (
                      <Col span={12} className="mt-2">
                        <InputField
                          type={InputType.NUMBER}
                          name="fixedRate"
                          placeholder="Enter Amount"
                          label="Fixed Rate"
                        />
                      </Col>
                    )}
                  {(values?.mode === LoanDeductionType.PERCENTAGE ||
                    values?.mode === LoanDeductionType.BOTH) && (
                      <Col span={12} className="mt-2">
                        <InputField
                          type={InputType.NUMBER}
                          name="percentage"
                          placeholder="Enter Percentage"
                          label="Percentage"
                        />
                      </Col>
                    )}
                  <Col span={24} className="mt-5">
                    <Space direction="horizontal" size={20}>
                      <span className="text-bold ">Include GST in Deductions</span>
                      <SwitchComponent name="gstEnabled" onChange={handleSwitchChange} />
                    </Space>
                  </Col>
                  {values.gstEnabled === true && (
                    <Col span={12} className="mt-5">
                      <InputField
                        type={InputType.NUMBER}
                        name="gst"
                        placeholder="Enter GST Rate"
                        label="GST Rate (%)"
                      />
                    </Col>
                  )}
                </Row>
              </div>

              <div className="drawer-footer">
                <Button
                  htmlType="reset"
                  onClick={toggleHandler}
                  type="link"
                  className="drawer-footer__cancel"
                >
                  Cancel
                </Button>
                <Button
                  loading={loading}
                  htmlType="submit"
                  className="drawer-footer__submit"
                  disabled={!dirty || !isValid}
                >
                  Save
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  );
};

export default LoanDeductionForm;
