import { Grid } from "antd";

const useBreakPoint = () => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const xsScreen = screens.xs;
  const lgScreen = screens.lg;
  const mdScreen = screens.md;
  const xlScreen = screens.xl;
  const xxlScreen = screens.xxl;
  const smScreen = screens.sm;
  const isMobile = ((xsScreen) && !smScreen)
  const isSmallScreen = ((smScreen) && !mdScreen)
  const isMediumScreen = ((mdScreen) && !lgScreen)
  const isLargeScreen = ((lgScreen) && !xlScreen)

  const getDrawerWidth = () => {
    const width = window.innerWidth;
    if (width >= 1200) {
      return '916px';
    } else if (width >= 768) {
      return '70%';
    } else if (width >= 568) {
      return '60%';
    } else {
      return '100%';
    }
  };

  return {
    xlScreen,
    lgScreen,
    mdScreen,
    xxlScreen,
    smScreen,
    xsScreen,
    isMobile,
    isLargeScreen,
    isSmallScreen,
    isMediumScreen,
    getDrawerWidth
  };
};

export default useBreakPoint;
