import "./customers.scss";

import React, { useEffect, useState } from "react";

import { CustomerParams } from "../../models/customer.model";
import Individual from "./Individual";
import TabExtraContent from "../../shared/components/TabExtraContent";
import { Tabs } from "antd";
import { TabsProps } from "../../shared/types/Tabs.type";
import useDrawer from "../../shared/hooks/useDrawer";
import useQueryParams from "../../shared/hooks/useQueryParams";
import CustomRangePicker from "../../shared/components/CustomRangePicker";
import { DateRange } from "../../shared/types/date.type";

interface CustomersProps {}

const Customers = (props: CustomersProps) => {
  const [dateRange, setDateRange] = useState<DateRange>();
  const {
    params: customerParams,
    handleSearch: handleCustomerSearch,
    updateParams,
    getParams,
  } = useQueryParams({
    params: new CustomerParams(),
  });
  const {
    visible: customerFilterVisible,
    toggleVisibility: toggleCustomerFilterVisibility,
  } = useDrawer({});

  const {
    visible: customerSettingsVisible,
    toggleVisibility: toggleCustomerSettingsVisibility,
  } = useDrawer({});

  const [activeTab, setActiveTab] = useState("1");

  const isCustomerTab = () => activeTab === "1";

  useEffect(() => {
    const { toDate, fromDate } = getParams();
    setDateRange({ toDate, fromDate });
  }, []);

  const handleDateChange = (date: DateRange) => {
    setDateRange(date);
    updateParams({ ...customerParams, ...date });
  };

  const handleTabChange = (activeKey: string) => setActiveTab(activeKey);

  const tabs: TabsProps[] = [
    {
      label: "Individual",
      key: "1",
      component: (
        <Individual
          dateRange={dateRange}
          params={customerParams}
          filterVisible={customerFilterVisible}
          onFilter={toggleCustomerFilterVisibility}
          settingsVisible={customerSettingsVisible}
          onSettings={toggleCustomerSettingsVisibility}
        />
      ),
    },
  ];

  const GroupTabLeftContent = (
    <TabExtraContent
      searchProps={{
        onSearch: () => {},
        placeholder: "Search Group",
      }}
      // filterClick={toggleGroupFilter}
      settingsClick={toggleCustomerSettingsVisibility}
      // exportClick={toggleGroupExport}
      // addClick={toggleGroupAdd}
    />
  );

  return (
    <>
      <div className="customers">
        <Tabs
          defaultActiveKey="1"
          onChange={handleTabChange}
        >
        </Tabs>
        <Individual
          dateRange={dateRange}
          params={customerParams}
          filterVisible={customerFilterVisible}
          onFilter={toggleCustomerFilterVisibility}
          settingsVisible={customerSettingsVisible}
          onSettings={toggleCustomerSettingsVisibility}
        />
      </div>
    </>
  );
};

export default Customers;
