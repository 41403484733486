import React, { useEffect, useState } from "react";
import { StepStatus } from "../../../../enums/stepStatus.enum";
import { Branch } from "../../../../models/branch.model";
import BranchCollectionDueForm from "../BranchCollectionDueForm";
import BranchDetailForm from "../BranchDetailForm";
import BranchPinLocationForm from "../BranchPinLocationForm";

interface BranchStepWrapperProps {
  formValues?: Branch;
  onSubmit?: (branch: Branch) => void;
  onClose: () => void;
}

const BranchStepWrapper = (props: BranchStepWrapperProps) => {
  const { formValues, onSubmit, onClose } = props;
  const [values, setValues] = useState(formValues);
  const [currentStep, setCurrentStep] = useState(1);

  const toggleSteps = (status: string) =>
    setCurrentStep(
      StepStatus.NEXT === status ? currentStep + 1 : currentStep - 1
    );

  const handleOnSumit = (val: Branch) => setValues(val);

  const handleClose = () => {
    setCurrentStep(1);
  };

  useEffect(() => {
    setValues(formValues);
  }, [formValues]);

  const steps = [
    {
      step: 1,
      component: (
        <BranchDetailForm
          formValues={values}
          toggleStep={toggleSteps}
          currentStep={currentStep}
          onSubmit={handleOnSumit}
          onClose={onClose}
        />
      ),
    },
    {
      step: 2,
      component: (
        <BranchCollectionDueForm
          formValues={values}
          toggleStep={toggleSteps}
          currentStep={currentStep}
          onSubmit={handleOnSumit}
          onClose={onClose}
        />
      ),
    },
    {
      step: 3,
      component: (
        <BranchPinLocationForm
          formValues={values}
          toggleStep={toggleSteps}
          currentStep={currentStep}
          onSubmit={(branch: Branch) => onSubmit && onSubmit(branch)}
          onClose={onClose}
        />
      ),
    },
  ];
  return <div>{steps?.[currentStep - 1]?.component} </div>;
};

export default BranchStepWrapper;
