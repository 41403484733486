import { useState } from "react";
import { deserialize, serialize } from "serializr";
import { NotificationTypes } from "../../enums/notificationTypes";
import axiosInstance from "../../interceptor/axiosInstance";
import { PaginationMeta } from "../../models/pagination.model";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import Notification from "../../shared/components/Notification";
import { Customer, CustomerParams } from "../../models/customer.model";
import { generatePath } from "react-router-dom";
import { CustomerDetail } from "../../models/customerDetail.model";
import { ExistingLoan } from "../../models/existingLoan.model";

export const CustomersService = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  const getCustomers = async (params?: CustomerParams) => {
    setLoading(true);
    try {
      const queryParams = params ? serialize(CustomerParams, params) : {};
      const { data } = await axiosInstance.get(ApiRoutes.CUSTOMERS, {
        params: queryParams,
      });
      const deserializedCustomers = deserialize(
        Customer,
        data?.customers as any[]
      );
      const deserializedCustomersMeta = deserialize(PaginationMeta, data?.meta);

      return {
        customers: deserializedCustomers,
        meta: deserializedCustomersMeta,
      };
    } catch (ex) {
      Notification({
        message: "Unable to Get Customers",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Get Customers details",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const getCreatedCustomers = async (
    employeeId: string,
    params?: CustomerParams
  ) => {
    setLoading(true);
    try {
      const queryParams = params ? serialize(CustomerParams, params) : {};
      const endpoint = generatePath(ApiRoutes.CREATED_CUSTOMERS, {
        employeeId,
      });
      const { data } = await axiosInstance.get(endpoint, {
        params: queryParams,
      });
      const deserializedCustomers = deserialize(
        Customer,
        data?.customers as any[]
      );
      const deserializedCustomersMeta = deserialize(PaginationMeta, data?.meta);

      return {
        customers: deserializedCustomers,
        meta: deserializedCustomersMeta,
      };
    } catch (ex) {
      Notification({
        message: "Unable to get created customers",
        description: (ex as Error)?.message,
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const getCustomer = async (customerId: string) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.CUSTOMER_DETAIL, {
        customerId,
      });
      const { data } = await axiosInstance.get(endpoint);
      const deserializedCustomers = deserialize(
        CustomerDetail,
        data?.["customer_v2"]
      );

      return deserializedCustomers;
    } catch (ex) {
      Notification({
        message: "Unable to Get Customer",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Get Customer details",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };
  const getCustomerByCode = async (code: string) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.CUSTOMER_DETAIL_CODE);
      const { data } = await axiosInstance.get(endpoint, {
        params: { code: code },
      });
      const deserializedCustomers = deserialize(Customer, data?.["customer"]);

      return deserializedCustomers;
    } catch (ex) {
      Notification({
        message: "Unable to Get Customer",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Get Customer details",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const updateCustomer = async (customerId: string, values: Customer) => {
    setLoading(true);
    const params = serialize(Customer, values);
    try {
      const endpoint = generatePath(ApiRoutes.CUSTOMER_DETAIL, {
        customerId,
      });

      const { data } = await axiosInstance.put(endpoint, {
        customer: params,
      });
      const deserializedCustomers = deserialize(Customer, data?.["customer"]);

      return {
        customer: deserializedCustomers,
      };
    } catch (ex) {
      Notification({
        message: "Unable to Update Customer",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Update Customer details",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const getCustomerExistingLoan = async (params?: CustomerParams) => {
    setLoading(true);
    try {
      const queryParams = params ? serialize(CustomerParams, params) : {};
      const { data } = await axiosInstance.get(
        ApiRoutes.CUSTOMER_EXISTING_LOAN,
        {
          params: queryParams,
        }
      );
      const deserializedCustomers = deserialize(
        ExistingLoan,
        data["customer_details"]
      );

      return deserializedCustomers;
    } catch (ex) {
      Notification({
        message: "Unable to get customer loan",
        description: (ex as Error)?.message,
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    getCustomers,
    getCreatedCustomers,
    getCustomer,
    updateCustomer,
    getCustomerByCode,
    getCustomerExistingLoan,
    loading,
    error,
  };
};
