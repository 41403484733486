import { User } from "../../enums/user.type";

const userOptions = [
  {
    label: "Super Admin",
    value: User.SUPER_ADMIN,
  },
  {
    label: "Admin",
    value: User.ADMIN,
  },
  {
    label: "Head Office Member",
    value: User.HO,
  },
];

export default userOptions;
