import { alias, list, object, primitive, serializable } from "serializr";

import { LoanType } from "../enums/loanType.type";
import { LoanSummary } from "./loanSummary";

class Loans {
  @serializable(alias("loans", list(object(LoanSummary))))
  loans?: LoanSummary[];
}
export class ExistingLoan {
  @serializable(alias("amount", primitive()))
  amount?: number;

  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("institution_name", primitive()))
  institutionName?: string;

  @serializable(alias("loan_type", primitive()))
  loanType?: LoanType;

  @serializable(alias("monthly_emi", primitive()))
  monthlyEmi?: number;
  @serializable(alias("borrower_name", primitive()))
  borrowerName?: string;
  @serializable(alias("borrower_role", primitive()))
  borrowerRole?: string;
  @serializable(alias("aadhaar_number", primitive()))
  aadhaarNumber?: string;
  @serializable(alias("pan", primitive()))
  pan?: string;
  @serializable(alias("total", primitive()))
  total?: number;
  @serializable(alias("active", primitive()))
  active?: number;
  @serializable(alias("remarks", primitive()))
  remarks?: string;
  @serializable(alias("closed", primitive()))
  closed?: number;
  @serializable(alias("loans", object(Loans)))
  loans?: Loans;

  getLoanType = () => {
    switch (this.loanType) {
      case LoanType.BUSINESS:
        return "Business Loan";
      case LoanType.HOUSING:
        return "Housing Loan";
    }
  };
}
