import { MODStatus } from "../../enums/modStatusTags.type";

export const getMODStatusLabel = (status?: string) => {
    if(!status) return
    switch(status) {
        case MODStatus.COMPLETED:
            return "Completed";
        case MODStatus.PENDING_ACKNOWLEDGEMENT:
            return "Pending Acknowledgement";
        case MODStatus.UPLOAD_MOD:
            return "Upload MOD";
        
        default:
      return status;
    }
}