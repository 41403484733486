import React, { ReactNode, useEffect, useState } from "react";
import "./attachmentUpload.scss";
import { ErrorMessage, Field } from "formik";
import Error from "../Error";
import { Row, Col } from "antd";

import { FileAttachment } from "../../../models/fileAttachment.model";
import AppLoader from "../AppLoader";

import uploadIcon from "../../../Assets/images/upload.png";
import excelIcon from "../../../Assets/images/excelIcon.png";
import { FileService } from "../../../services/FileService/file.service";

interface AttachmentUploadProps {
  name: string;
  title?: string;
  placeholder: string | ReactNode;
  multiple?: boolean;
  accept: string;
  setFieldValue: Function;
  value?: FileAttachment;
  onChange?: Function;
  required?: boolean;
  isLoading?: (value: boolean) => void;
  customUploadFunction?: (file: File) => {};
  customUploadIcon?: string
  fileNameRequired?: boolean
}

function AttachmentUpload(props: AttachmentUploadProps) {
  const { uploadFile } = FileService();
  let inputRef: any;

  const {
    name,
    title,
    required,
    placeholder,
    multiple,
    setFieldValue,
    accept,
    value,
    onChange,
    isLoading,
    customUploadFunction,
    customUploadIcon,
    fileNameRequired = false
  } = props;

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [fileExtension, setFileExtension] = useState<string>();
  const excelFile = ["xls", "xlsx", "csv"];

  const handleClick = () => {
    inputRef?.click();
  };

  const handleChange = async (e: any) => {
    if (multiple) {
      /*      const files: File[] = newAttachments.concat(Array.from(e.target.files));
      setFieldValue(name, files);*/
    } else {
      const fileName = e.target.value
      const file = e.target.files?.[0];
      e.persist()
      setFileExtension(file?.name?.split(".").pop()?.toLowerCase());
      setLoading(true);
      isLoading?.(true);

      const attachment = customUploadFunction
        ? await customUploadFunction(file)
        : await uploadFile(file);
  
      if (attachment && attachment.id)
        if (!fileNameRequired)
          (onChange || setFieldValue)(name, attachment.id);
        else if (fileNameRequired)
          (onChange || setFieldValue)(name, { id: attachment.id, fileName });
  
      setImageUrl(attachment.attachmentUrl);
      e.target.value = ''
      setLoading(false);
      isLoading?.(false);
    }
  };

  return (
    <Field name={name}>
      {({ field, form: { touched, errors }, meta }: any) => (
        <div className="attachment-upload">
          {title && <div className="attachment-upload__title">{title}</div>}
          {required && <sup className="text-danger">*</sup>}
          <Row className="attachment-upload__attachment-list" gutter={[15, 15]}>
            <Col span={24} className="attachment-upload__img-wrapper">
              <div className="attachment-upload__input" onClick={handleClick}>
                {loading ? (
                  <AppLoader loading={loading} />
                ) : (
                  <React.Fragment>
                    <div className="attachment-upload__content">
                      {" "}
                      <img src={customUploadIcon ?? uploadIcon} alt="" className="" />
                      <p> {placeholder}</p>
                    </div>
                  </React.Fragment>
                )}
              </div>
              <input
                required={required}
                ref={(ref) => (inputRef = ref)}
                type="file"
                multiple={multiple}
                onChange={handleChange}
                accept={accept}
                style={{ display: "none" }}
              />
            </Col>
            {imageUrl || excelFile?.indexOf(fileExtension || "") > -1 ? (
              <div className="attachment-upload__attachment-wrapper">
                {imageUrl ? (
                  excelFile?.indexOf(fileExtension || "") > -1 ? (
                    <div className="text-primary ml-2">
                      <img className="attachment-icon" src={excelIcon} />
                      <div className="mt-5">File Uploaded</div>
                    </div>
                  ) : imageUrl?.includes("pdf") ? (
                    <iframe src={imageUrl} className="attachment-upload__img" />
                  ) : (
                    <img
                      src={imageUrl}
                      alt="Attachment"
                      className="attachment-upload__img"
                    />
                  )
                ) : (
                  ""
                )}
              </div>
            ) : value ? (
              <div className="attachment-upload__attachment-wrapper">
                {value?.attachmentUrl?.includes("pdf") ? (
                  <iframe
                    src={value.attachmentUrl}
                    className="attachment-upload__img"
                  />
                ) : (
                  <img
                    src={value.attachmentUrl}
                    alt="Attachment"
                    className="attachment-upload__img"
                  />
                )}
              </div>
            ) : null}
          </Row>
          <ErrorMessage name={name}>
            {(message: string) => <Error message={message} />}
          </ErrorMessage>
        </div>
      )}
    </Field>
  );
}

export default AttachmentUpload;
