
import {serializable, alias, object, list, primitive} from 'serializr';

export class PaymentStatus { 

	@serializable(alias('total_payments', primitive()))
	totalPayments?: number;

	@serializable(alias('on_time_payments', primitive()))
	onTimePayments?: number;

	@serializable(alias('late_payments', primitive()))
	latePayments?: number;

	@serializable(alias('no_payment_information', primitive()))
	noPaymentInformation?: number;

	@serializable(alias('payment_status', primitive()))
	paymentStatus?: string

	@serializable(primitive())
	status?: string

}