import { alias, list, object, primitive, serializable } from "serializr";
import { Meta } from "./meta.modal";

export class LoanTypeDetailsModel {
  @serializable(alias("loan_type_id", primitive()))
  loanTypeId?: string;
  @serializable(alias("id", primitive()))
  id?: string;

  @serializable(alias("minimum_amount", primitive()))
  minimumAmount?: string;

  @serializable(alias("maximum_amount", primitive()))
  maximumAmount?: number;

  @serializable(alias("minimum_tenure", primitive()))
  minimumTenure?: number;

  @serializable(alias("maximum_tenure", primitive()))
  maximumTenure?: number;

  @serializable(alias("minimum_interest", primitive()))
  minimumInterest?: number;

  @serializable(alias("maximum_interest", primitive()))
  maximumInterest?: number;
  @serializable(alias("is_active", primitive()))
  isActive?: boolean;

  @serializable(alias("region_ids", list(primitive())))
  regionIds?: number[];
  @serializable(alias("branch_ids", list(primitive())))
  branchIds?: number[];
  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("regions", list(object(Meta))))
  regions?: Meta[];
  @serializable(alias("branches", list(object(Meta))))
  branches?: Meta[];
}

export class LoanTypeModel extends LoanTypeDetailsModel {
  @serializable(alias("id", primitive()))
  value?: number | string;

  @serializable(alias("code", primitive()))
  code?: string;

  @serializable(alias("name", primitive()))
  label?: string;
}
export class LoanTypeConditionModel extends LoanTypeModel {
  @serializable(alias("interest", primitive()))
  interest?: number;
}

class LoanSteps {
  @serializable(alias("name", primitive()))
  name?: string;
}

export class LoanTypeStepsMaps {
  @serializable(alias("loan_section_input_id", primitive()))
  loanSectionInputId?: string;
  @serializable(alias("id", primitive()))
  id?: string;
  @serializable(alias("name", primitive()))
  name?: number;
  @serializable(alias("loan_step_id", primitive()))
  loanStepId?: number;
  @serializable(alias("is_required", primitive()))
  isRequired?: boolean;
  @serializable(alias("loan_step", object(LoanSteps)))
  loanSteps?: LoanSteps;
}
class LoanSectionInput {
  @serializable(alias("loan_step_id", primitive()))
  loanStepId?: string;
  @serializable(alias("name", primitive()))
  name?: string;
}
export class LoanSectionInputMaps {
  @serializable(alias("id", primitive()))
  id?: string;
  @serializable(alias("loan_section_input_id", primitive()))
  loanSectionInputId?: string;
  @serializable(alias("is_required", primitive()))
  isRequired?: boolean;
  @serializable(alias("loan_section_input", object(LoanSectionInput)))
  loanSectionInput?: LoanSectionInput;
}

export class LoanTypeConfigurationModel {
  @serializable(alias("id", primitive()))
  id?: string;
  @serializable(alias("loan_type_step_maps", list(object(LoanTypeStepsMaps))))
  loanTypeSteps?: LoanTypeStepsMaps[];
  @serializable(
    alias("loan_section_input_maps", list(object(LoanSectionInputMaps)))
  )
  loanSectionInputMaps?: LoanSectionInputMaps[];
}
