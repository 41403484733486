import "./branchDetails.scss";

import Back from "../../../shared/components/Back";
import BranchSummary from "./BranchSummary";
import React, { useState } from "react";
import { Button, Tabs } from "antd";
import { TabsProps } from "../../../shared/types/Tabs.type";
import useDrawer from "../../../shared/hooks/useDrawer";
import editIcon from "../../../Assets/images/edit.png";
import { LocalStorageHelper } from "../../../shared/utils/localStorageHelper";
import { LocalStorage } from "../../../enums/localStorage.enum";

interface BranchDetailsProps {}

const BranchDetails = (props: BranchDetailsProps) => {
  const {
    visible: editBranchVisibility,
    toggleVisibility: toggleEditBranchVisibility,
  } = useDrawer({});
  const {} = props;

  const [activeTab, setActiveTab] = useState("0");
  const userAccess = LocalStorageHelper.getItem(
    LocalStorage.CURRENT_USER_ACCESS
  );

  const tabs: TabsProps[] = [
    {
      label: "Branch Details",
      component: (
        <BranchSummary
          editBranch={editBranchVisibility}
          toggleEditBranch={toggleEditBranchVisibility}
        />
      ),
    },
    // {
    //   label: "Centers",
    //   component: <></>,
    // },
    // {
    //   label: "CRO",
    //   component: <></>,
    // },
    // {
    //   label: "Credit Officer",
    //   component: <></>,
    // },
  ];

  const tabExtraContent = (activeTabKey = activeTab) => {
    switch (activeTabKey) {
      case "0":
        return (
          userAccess?.writeAccess && (
            <Button
              className="btn--outline "
              onClick={() => toggleEditBranchVisibility()}
            >
              <img
                alt=""
                className="mr-2"
                src={editIcon}
                width={15}
                height={15}
              />
              <span>Edit</span>
            </Button>
          )
        );
      default:
        <></>;
    }
  };

  const handleTabChange = (tabKey: string) => setActiveTab(tabKey);

  return (
    <div className="branch-details">
      <Back />
      <Tabs
        tabBarExtraContent={tabExtraContent(activeTab)}
        onChange={handleTabChange}
      >
        {tabs.map(({ component, label }, index) => (
          <Tabs.TabPane className="mt-5" tab={label} key={String(index)}>
            {component}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default BranchDetails;
