import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import "./repaymentChart.scss";
import { Col, Row } from "antd";
import { formatNumber } from "../../../../shared/utils/formatNumber";
import { currencyFormatter } from "../../../../shared/utils/formatter";
import { RepaymentChart as RepaymentChartModel } from "../../../../models/repaymentChart.model";

interface RepaymentChartProps {
  data: RepaymentChartModel;
}

const chartColor = ["#5ED5A0", "#A5CC7F"];

const RepaymentChart: React.FC<RepaymentChartProps> = ({ data }) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const [dataValue, setDataValue] = useState<number[]>([]);

  useEffect(() => {
    const values = [data?.collected?.total, data?.yetToCollect?.total];

    if (chartRef.current) {
      const chart = new Chart(chartRef.current, {
        type: "doughnut",

        options: {
          cutout: 115,
        },
        data: {
          datasets: [
            {
              data: values.length ? values : [1],
              backgroundColor: chartColor,
            },
          ],
        },
      });

      return () => {
        chart.destroy();
      };
    }
  }, [data]);

  const footerData = [
    {
      label: "Collected",
      value: data?.collected?.total,
      interest: data?.collected?.interest,
      principal: data?.collected?.principal,
      color: chartColor[0],
    },
    {
      label: "Yet to Collect",
      value: data?.yetToCollect?.total,
      interest: data?.yetToCollect?.interest,
      principal: data?.collected?.principal,
      color: chartColor[1],
    },
  ];

  return (
    <div className="repayment-chart">
      <canvas ref={chartRef} />
      <div className="repayment-chart__content">
        <p>Total Repayments</p>
        <h1>
          {currencyFormatter({
            amount: data?.total,
            hasCurrencySign: true,
          })}
        </h1>
        <Row>
          <Col span={24}>
            Principal{" "}
            <div className="repayment-chart__content-value">
              {" "}
              {currencyFormatter({
                amount: Math.round(Number(data?.principal)),
                hasCurrencySign: true,
              })}
            </div>
          </Col>
          </Row>
          <Row>
          <Col span={24} className="mt-5">
          Interest{" "}
            <div className="repayment-chart__content-value">
              {" "}
              {currencyFormatter({
                amount: Math.round(Number(data?.interest)),
                hasCurrencySign: true,
              })}
            </div>
          </Col>
        </Row>
      </div>
      <Row className="mt-5 ml-5">
        {footerData?.map((data) => {
          return (
            <Col span={12}>
              <div
                className="circle mr-2"
                style={{ background: data?.color }}
              ></div>{" "}
              {data?.label}
              <h2
                className="repayment-chart__content-value"
                style={{ color: data?.color }}
              >
                {currencyFormatter({
                  amount: data?.value,
                  hasCurrencySign: true,
                })}
              </h2>
              <Row justify={"space-between"}>
                <Col span={12}>
                  Principal{" "}
                  <div className="repayment-chart__content-value">
                    {" "}
                    {currencyFormatter({
                      amount: data?.principal,
                      hasCurrencySign: true,
                    })}
                  </div>
                </Col>
                <Col span={12}>
                  Interest
                  <div className="repayment-chart__content-value">
                    {" "}
                    {currencyFormatter({
                      amount: data?.interest,
                      hasCurrencySign: true,
                    })}
                  </div>
                </Col>
              </Row>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default RepaymentChart;
