import React, { useState } from "react";
import { DatePicker } from "antd";
import moment, { Moment } from "moment";
import { DateRange } from "../../types/date.type";

interface RangePickerProps {
  onChange: (dateRange: DateRange) => void;
  className?: string;
  values?: DateRange;
  submittedDate?: boolean;
  approvedDate?: boolean;
  disabledDateRequired?: boolean;
  maxMonths?: number;
  ctVerification?: boolean;
}

const CustomRangePicker: React.FC<RangePickerProps> = ({
  onChange,
  className,
  values,
  submittedDate,
  approvedDate,
  disabledDateRequired = false,
  maxMonths = 11,
  ctVerification
}) => {
  const [selectedFromDate, setSelectedFromDate] = useState<Moment | null>(null);
  const [, setSelectedToDate] = useState<Moment | null>(null);

  const handleRangeChange = (_: unknown, dateStrings: [string, string]) => {
    if (submittedDate) {
      onChange({ submittedFromDate: dateStrings[0], submittedToDate: dateStrings[1] });
    } else if(approvedDate) {
      onChange({ approvedFromDate: dateStrings[0], approvedToDate: dateStrings[1]})
    } else if (ctVerification) {
      onChange({ ctVerifiedFromDate: dateStrings[0], ctVerifiedToDate: dateStrings[1] })
    } else {
      onChange({ fromDate: dateStrings[0], toDate: dateStrings[1] });
    }
  };

  const value: [Moment, Moment] | undefined =
   
      values?.fromDate && values?.toDate
      ? [
          moment(values.fromDate, "DD/MM/YYYY"),
          moment(values.toDate, "DD/MM/YYYY"),
        ]

      : undefined;

      const handleDisabledDate = (current: Moment) => {
        if (!selectedFromDate) return false;
        const fromDate = selectedFromDate;
        const toDate = fromDate.clone().add(maxMonths, 'months').endOf('month');
      
        return current.isBefore(fromDate, 'day') || current.isAfter(toDate, 'day');
      };
      

  const handleCalenderChange = (dates: (Moment | null)[] | null) => {
    if(!dates) {
      setSelectedFromDate(null)
      setSelectedToDate(null);
      return 
    };
    setSelectedFromDate(dates[0]);
    setSelectedToDate(dates[1]);
  }

  return (
    <DatePicker.RangePicker
      className={className}
      format={"DD/MM/YYYY"}
      placeholder={["dd/mm/yy", "dd/mm/yy"]}
      onChange={handleRangeChange}
      value={value}
      {...(disabledDateRequired && { 
        disabledDate: handleDisabledDate, 
      })}
      onCalendarChange={handleCalenderChange}
    />
  );
};

export default CustomRangePicker;
