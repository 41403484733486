import { Col, Row } from "antd";
import React, { ReactElement } from "react";

import { OwnedEntity } from "../../../../../models/ownedEntity.model";
import { fillEmptyData } from "../../../../../shared/utils/fillEmptyData";
import { currencyFormatter } from "../../../../../shared/utils/formatter";

interface VehicleListProps {
  vehicles?: OwnedEntity[];
}

const VehicleList = (props: VehicleListProps) => {
  const { vehicles } = props;

  const data = [
    {
      label: "Vehicle Type",
      value: (vehicle: OwnedEntity) => fillEmptyData(vehicle?.entityType),
    },
    {
      label: "Number",
      value: (vehicle: OwnedEntity) => fillEmptyData(vehicle?.code),
    },
  ];

  return (
    <>
      {vehicles?.length ? (
        <>
          <div className="block__heading mb-5">Vehicle</div>
          <div>
            <table className="custom-table">
              <thead>
                <tr>
                  {data.map((field) => (
                    <th key={field.label}>{field.label}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {vehicles?.map((val, index) => (
                  <tr key={index}>
                    {data.map((field) => (
                      <td className="text-capitalize" key={field.label}>
                        {val ? field.value(val) : "NA"}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default VehicleList;
