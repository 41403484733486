import { Col, Row } from "antd";
import React from "react";
import VehicleList from "../VehicleList";
import { Loan } from "../../../../../models/loan.model";
import CattleList from "../CattleList";
import PropertyList from "../PropertyList";
import InvestmentList from "../InvestmentList";
import { OwnedEntity } from "../../../../../models/ownedEntity.model";

interface LoanPersonalWrapperProps {
  vehicles?: OwnedEntity[];
  cattles?: OwnedEntity[];
  properties?: OwnedEntity[];
  investments?: OwnedEntity[];
}

const LoanPersonalWrapper = (props: LoanPersonalWrapperProps) => {
  const { vehicles, cattles, properties, investments } = props;
  const items = [
    { component: <VehicleList vehicles={vehicles} />, values: vehicles },
    { component: <CattleList cattles={cattles} />, values: cattles },
    {
      component: <PropertyList properties={properties} />,
      values: properties,
    },
    {
      component: <InvestmentList investments={investments} />,
      values: investments,
    },
  ].filter((item) => item.values?.length);

  return (
    <div className="block">
      <Row gutter={[30, 0]}>
        {items?.map((data) => {
          return <Col span={6}>{data?.component}</Col>;
        })}
      </Row>
    </div>
  );
};

export default LoanPersonalWrapper;
