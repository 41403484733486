import React from "react";
import { AuthProvider } from "./context/AuthContext";
import "antd/dist/antd.css";
import "./styles/_main.scss";
import AppRouter from "./routes";
import AppProviders from "./context/AppProvider";

const App = () => {
  return (
    <AuthProvider>
      <AppProviders>
        <AppRouter />
      </AppProviders>
    </AuthProvider>
  );
};

export default App;
