import React, { useState } from "react";
import { Button, Col, Row, Select, Spin } from "antd";
import { Employee } from "../../../models/employee.model";
import { currencyFormatter } from "../../../shared/utils/formatter";
import "./dashStats.scss";
import { RoleName } from "../../../enums/roleName.enum";
import { DashboardParams } from "../../../models/dashboard.model";
import userOptions from "../../../shared/Constant/userOptions";
import { CardProps } from "../../../shared/types/card.type";
import CustomRangePicker from "../../../shared/components/CustomRangePicker";
import { User } from "../../../enums/user.type";
import { DateRange } from "../../../shared/types/date.type";
import { Skeleton } from "antd";

interface DashStatsProps {
  employee?: Employee;
  card: CardProps[];
  onFilter: (params: DashboardParams) => void;
  onDateChange?: (date: DateRange) => void;
  role?: string;
}

const DashStats = (props: DashStatsProps) => {
  const [selectedRole, setSelectedRole] = useState<string>();

  //Props
  const { card, employee, onFilter, role, onDateChange } = props;

  const handleSelectedRole = (selectedOption: string) =>
    setSelectedRole(selectedOption);

  const handleDateChange = (date: DateRange) =>
    onDateChange && onDateChange(date);

  return (
    <div className="card dash-stats">
      <Row gutter={[16, 16]}>
        <Col
          span={24}
          className="text-bold text-capitalize dash-stats__heading mb-5"
        >
          Hello, User {employee?.firstName} {employee?.lastName}
        </Col>
      </Row>

      <Row gutter={[16, 16]} className="mt-5">
        {employee?.roleName === RoleName.SUPER_ADMIN && (
          <>
            <Col span={24} className="text-white">
              Select Role
            </Col>
            <Col xxl={19} sm={18} lg={20} xs={24} md={19} xl={17} className="text-white dash-stats__select">
              <Select
                placeholder="Select Role"
                options={userOptions}
                value={selectedRole ?? role ?? User.SUPER_ADMIN}
                onChange={handleSelectedRole}
              />
            </Col>
            <Col xxl={2} xl={7} md={5} sm={6} xs={24} lg={4}>
              {" "}
              <Button
                className="dash-stats__apply-btn"
                onClick={() => onFilter({ user: selectedRole })}
              >
                APPLY
              </Button>
            </Col>
          </>
        )}

        {(employee?.roleName === RoleName.HEAD_OFFICER || role === User.HO) && (
          <>
            <Col span={24} className="dash-stats__picker">
              <CustomRangePicker onChange={handleDateChange} />
            </Col>
          </>
        )}

        {card?.map((card) => (
          <Col lg={12} sm={12} xs={24}>
            <div className="card dash-stats__stat text-primary">
              <span>{card?.label}</span>
              <div>
                {card?.loading ? (
                  <Skeleton active />
                ) : (
                  <h1 className="text-primary dash-stats__box-heading">
                    {Math.round(card?.count ?? 0)}
                  </h1>
                )}
                <p>
                  {card?.key === "tat"
                    ? "Days"
                    : card?.value &&
                      currencyFormatter({
                        amount: card?.value ?? 0,
                        hasCurrencySign: true,
                      })}
                </p>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default DashStats;
