// Libraries
import React, { useState } from "react";
import { Button, Col, Drawer, Image, Modal, Row } from "antd";
import { AuthContext } from "../../context/AuthContext";
import { Employee } from "../../models/employee.model";
import { fillEmptyData } from "../../shared/utils/fillEmptyData";
// Styles
import "./profile.scss";
import previewAvatarImage from "../../shared/Constant/previewAvatarImage";
import UserOtpVerify from "./UserOtpVerify";
import ChangePassword from "./ChangePassword";

const Profile = () => {
  // Context
  const user: Employee | undefined = AuthContext().user?.employee;
  const [isOtpVisible, setIsOtpVisible] = useState(false);
  const [isPasswordChangevisible, setIsPasswordChangeVisible] = useState(false);

  const handleOtpVisibility = () => setIsOtpVisible(!isOtpVisible);
  const handlePasswordChangeVisibility = () =>
    setIsPasswordChangeVisible(!isPasswordChangevisible);

  return (
    <div className="card profile">
      <Row gutter={[32, 16]}>
        <Col span={6}>
          <Image
            src={user?.profilePicUrl ?? ""}
            alt=""
            className="profile__pic"
            preview={false}
            fallback={previewAvatarImage}
          />
        </Col>
        <Col span={18} className="text-capitalize">
          <h1 className="profile__name">
            {user?.firstName} {user?.lastName}
          </h1>
          <h3 className="profile__role">{user?.roleName}</h3>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={3}>
          <p className="profile__attr__name">Gender</p>
          <p className="profile__attr__name">DOB</p>
          <p className="profile__attr__name">Age</p>
          <p className="profile__attr__name">Phone number</p>
          <p className="profile__attr__name">E-mail ID</p>
        </Col>
        <Col span={6}>
          <p className="profile__attr__value">{fillEmptyData(user?.gender)}</p>
          {/* <p className="profile__attr__value">{fillEmptyData(user?.getDob())}</p> */}
          <p className="profile__attr__value">22 Jan 2020</p>
          {/* <p className="profile__attr__value">{fillEmptyData(user?.getAge())}</p> */}
          <p className="profile__attr__value">30 years</p>
          {/* <p className="profile__attr__value">{user?.getPhoneNumber() ?? "N/A"}</p> */}
          <p className="profile__attr__value">
            {fillEmptyData(user?.phoneNumber)}
          </p>
          <p className="profile__attr__value">{fillEmptyData(user?.email)}</p>
        </Col>
      </Row>
      <a
        onClick={handleOtpVisibility}
        className="text-capitalize text-underline text-right profile__pass"
      >
        change password
      </a>
      <Drawer
        placement="right"
        onClose={handlePasswordChangeVisibility}
        visible={isPasswordChangevisible}
        width="500"
        title="Change Password"
        destroyOnClose
      >
        <ChangePassword onClose={handlePasswordChangeVisibility} />
      </Drawer>
      <Modal
        className="profile__modal"
        open={isOtpVisible}
        onCancel={handleOtpVisibility}
        destroyOnClose
        centered
        footer={false}
      >
        <UserOtpVerify
          onClose={handleOtpVisibility}
          onSuccess={handlePasswordChangeVisibility}
        />
      </Modal>
    </div>
  );
};

export default Profile;
