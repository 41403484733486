import { useState } from "react";
import { Accountant, AccountantParams } from "../../models/accountant.model";
import { generatePath } from "react-router-dom";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { deserialize, serialize } from "serializr";
import { PaginationMeta } from "../../models/pagination.model";
import { ChangeRegularisationStatus } from "../../models/regularisation.model";

export const AccountantService = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error>();

    const getRepaymentRegularisations = async (queryParams?: AccountantParams) => {
        setLoading(true);
        try {
          const params = queryParams ? serialize(AccountantParams, queryParams) : {};
          const endpoint = generatePath(ApiRoutes.GET_REPAYMENT_REGULARISATIONS);
          const { data } = await axiosInstance.get(endpoint, {
            params
          });
          const deserializedLoan = deserialize(Accountant, data["repayment_regularisations"]  as any[]);
          const deserializedLoanMeta = deserialize(PaginationMeta, data?.meta);

          return {
            loans: deserializedLoan,
            meta: deserializedLoanMeta,
          };
        } catch (ex) {
          Notification({
            message: "Unable to Get Repayment Regularisations",
            description: (ex as Error)?.message
              ? (ex as Error)?.message
              : "Unable to Get Repayment Regularisations",
            type: NotificationTypes.ERROR,
          });
          setError(ex as Error);
        } finally {
          setLoading(false);
        }
    }

    const changeRegularisationStatus = async (updateData: ChangeRegularisationStatus) => {
    setLoading(true)
      try {
        const params = updateData ? serialize(ChangeRegularisationStatus, updateData) : {};
        const endpoint = generatePath(ApiRoutes.UPDATE_REGULARISATION_STATUS);
        const { data } = await axiosInstance.put(endpoint, {
          repayment_regularise: {
            regularise_ids: params.regularise_ids,
            status: params.status,
            rejected_reason: params.rejected_reason,
          }})
        Notification({
          message: data.message,
          description: "",
          type: NotificationTypes.SUCCESS,
        });
        return true;
      } catch (ex) {
        Notification({
          message: (ex as Error)?.message,
          description: "",
          type: NotificationTypes.ERROR,
        });
        setError(ex as Error);
      } finally {
        setLoading(false);
      }
    }
    
    return {
        loading, error, getRepaymentRegularisations, changeRegularisationStatus
    }
}