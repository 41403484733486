export const getPaymentLinkFromURL = () => {
  const paymentLink = new URLSearchParams(window.location.search).get(
    "paymentLink"
  );
  return paymentLink ? decodeURIComponent(paymentLink) : null;
};

export function generatePaymentRedirectionURL(
  appRoutes: string,
  paymentLink: string
) {
  return `${appRoutes}?paymentLink=${encodeURIComponent(paymentLink)}`;
}
