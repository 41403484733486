import { alias, list, object, primitive, serializable } from "serializr";
import { UserRole } from "../enums/userRoles";

export class AddressAttachment {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("attachment_id", primitive()))
  attachmentId?: number;

  @serializable(alias("attachment_url", primitive()))
  attachmentUrl?: string;

  @serializable(alias("employee_id", primitive()))
  employeeId?: number;

  @serializable(alias("employee_role_code", primitive()))
  employeeRoleCode?: string;
  @serializable(alias("created_at", primitive()))
  createdAt?: string;
}
export class Address {
  @serializable(alias("id", primitive()))
  id?: string;

  @serializable(alias("address_attachments", list(object(AddressAttachment))))
  addressAttachment?: AddressAttachment[];

  @serializable(alias("addressable_type", primitive()))
  addressableType?: string;

  @serializable(alias("addressable_id", primitive()))
  addressableId?: string;

  @serializable(alias("address_line_1", primitive()))
  addressLine1?: string;

  @serializable(alias("address_line_2", primitive()))
  addressLine2?: string;

  @serializable(alias("city_id", primitive()))
  cityId?: string;

  @serializable(alias("city", primitive()))
  city?: string

  @serializable(alias("state", primitive()))
  state?: string

  @serializable(alias("city_name", primitive()))
  cityName?: string;

  @serializable(alias("state_id", primitive()))
  stateId?: string;

  @serializable(alias("state_name", primitive()))
  stateName?: string;

  @serializable(alias("pincode", primitive()))
  pincode?: string;

  @serializable(alias("landmark", primitive()))
  landmark?: string;

  @serializable(alias("image_id", primitive()))
  imageId?: string;

  @serializable(alias("image_url", primitive()))
  imageUrl?: string;
  @serializable(alias("latitude", primitive()))
  latitude?: number;
  @serializable(alias("longitude", primitive()))
  longitude?: number;

  getAddress = () => {
    const { addressLine1, addressLine2, cityName, stateName, pincode } = this;
    const addressArr = [
      addressLine1,
      addressLine2,
      cityName,
      stateName,
      pincode
    ].filter(Boolean);

    return addressArr.length > 0 ? `${addressArr.join(", ")}` : "NA";
  };

  getAddressImageByRole = (role: UserRole) =>
    this.addressAttachment?.find(
      (attachment) => attachment.employeeRoleCode === role
    );
}
