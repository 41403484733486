import React, { useState } from "react";
import { Button } from "antd";
import { Formik, Form } from "formik";
import AuthService from "../../../services/AuthService/auth.service";
import { User } from "../../../models/user.model";
import InputField from "../../../shared/components/InputField";
import { InputType } from "../../../enums/input.type";
import { passwordValidation } from "../../Auth/CreatePassword/createPasswordValidation";
import "./changePassword.scss";
import { Employee } from "../../../models/employee.model";
import { AuthContext } from "../../../context/AuthContext";
import { changePasswordValidation } from "./changePasswordValidation";

interface ChangePasswordProps {
  onClose: () => void;
}

function ChangePassword(props: ChangePasswordProps) {
  const user: Employee | undefined = AuthContext().user?.employee;
  const { onClose } = props;
  const { loading, resetNewPassword } = AuthService();
  const onSubmit = async (values: User) => {
    const result = await resetNewPassword({
      email: user?.email,
      newPassword: values.newPassword,
      oldPassword: values?.oldPassword,
    });
    result && onClose();
  };

  return (
    <div className="change-password">
      <Formik
        initialValues={new User()}
        onSubmit={onSubmit}
        validationSchema={changePasswordValidation}
      >
        {({ dirty, isValid }) => (
          <Form className="ant-form-vertical disburse-form">
            <InputField
              label="Old Password"
              type={InputType.PASSWORD}
              name="oldPassword"
              placeholder="Enter Old Password"
            />
            <InputField
              label="New Password"
              type={InputType.PASSWORD}
              name="newPassword"
              placeholder="Enter New Password"
            />

            <InputField
              label="Confirm Password"
              type={InputType.PASSWORD}
              name="confirmPassword"
              placeholder="Re Enter Password"
            />

            <div className="drawer-footer">
              <Button
                htmlType="reset"
                onClick={onClose}
                type="link"
                className="drawer-footer__cancel"
              >
                Cancel
              </Button>
              <Button
                loading={loading}
                disabled={!dirty || !isValid}
                htmlType="submit"
                className="drawer-footer__submit"
              >
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ChangePassword;
