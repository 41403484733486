import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";

import { Branch } from "../../../../models/branch.model";
import { Button, Select } from "antd";
import InputField from "../../../../shared/components/InputField";
import { InputType } from "../../../../enums/input.type";
import { Meta } from "../../../../models/meta.modal";
import { MetaService } from "../../../../services/Meta/meta.service";
import SelectInput from "../../../../shared/components/SelectInput";

import { StepStatus } from "../../../../enums/stepStatus.enum";
import DatePickerField from "../../../../shared/components/DatePickerField";
import { branchCollectionDueValidation } from "./branchCollectionDueVaidation";
import { DatePicker, Space } from "antd";
import "../branchForm.scss";

const { RangePicker } = DatePicker;

interface BranchCollectionDueFormProps {
  formValues?: Branch;
  currentStep?: number;
  toggleStep?: (status: string) => void;
  onSubmit: (branch: Branch) => void;
  onClose: () => void;
}

function BranchCollectionDueForm({
  formValues,
  onClose,
  toggleStep,
  onSubmit,

  currentStep,
}: BranchCollectionDueFormProps) {
  const [selected, setSelected] = useState<number[]>([]);
  const handleSubmit = (values: Branch) => {
    toggleStep && toggleStep(StepStatus.NEXT);
    onSubmit(values);
  };
  const options: Meta[] = Array.from({ length: 31 }, (_, i) => {
    return {
      label: `${i + 1}${
        i + 1 === 1 ? "st" : i + 1 === 2 ? "nd" : i + 1 === 3 ? "rd" : "th"
      } of every month`,
      value: i + 1,
    };
  });

  const handleChange = (_: string, value: Meta[]) => {};

  return (
    <div>
      <Formik
        initialValues={formValues ?? new Branch()}
        validationSchema={branchCollectionDueValidation}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting, setFieldValue, values, dirty, isValid }) => {
          return (
            <>
              <div className="branch-collection-form">
                <div className="branch-collection-form__header">
                  <div className="branch-collection-form__header__process-count">
                    2
                  </div>
                  <div className="branch-collection-form__header__process-title">
                    Due collection dates and Limits
                  </div>
                </div>

                <div className="branch-collection-form__content">
                  <Form className="ant-form-vertical">
                    <InputField
                      type={InputType.NUMBER}
                      name="maxDueCollectionPerDay"
                      placeholder="Max Due"
                      label="Max Dues Collection per day"
                    />

                    <SelectInput
                      name="dueCollectionDates"
                      options={options}
                      value={values?.dueCollectionDates}
                      onChange={(_, value) => {
                        let result = value as Meta[];
                        let data = result.map(({ value }) => value) as [];
                        if (
                          values.dueCollectionDates &&
                          values?.dueCollectionDates.length > 9
                        )
                          setFieldValue(
                            "dueCollectionDates",
                            data.slice(0, 10)
                          );
                        else setFieldValue("dueCollectionDates", data);
                      }}
                      mode="multiple"
                      placeholder="Select Due Dates"
                      label="Due Collection Dates"
                    />

                    <div className="branch-form__content__footer drawer-footer">
                      <Button
                        className="drawer-footer__cancel"
                        htmlType="reset"
                        onClick={() =>
                          toggleStep && toggleStep(StepStatus.PREV)
                        }
                        type="link"
                      >
                        Prev
                      </Button>
                      <Button
                        disabled={!isValid}
                        className="drawer-footer__submit"
                        htmlType="submit"
                      >
                        Next
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
}
export default BranchCollectionDueForm;
