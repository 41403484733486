import { Center, CenterParams } from "../../models/center.model";
import { deserialize, serialize } from "serializr";

import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { PaginationMeta } from "../../models/pagination.model";
import axiosInstance from "../../interceptor/axiosInstance";
import { useState } from "react";
import {
  BlacklistsParams,
  Blacklists,
  BlacklistForm,
} from "../../models/blacklists";
import { generatePath } from "react-router-dom";

export const BlacklistService = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  const getBlacklistDetails = async (params: BlacklistsParams) => {
    setLoading(true);
    const queryParams = serialize(BlacklistsParams, params || {});

    try {
      const { data } = await axiosInstance.get(ApiRoutes.BLACKLIST, {
        params: queryParams,
      });
      const deserializedValue = deserialize(
        Blacklists,
        data?.["blacklists"] as Blacklists[]
      );
      const deserializedMeta = deserialize(PaginationMeta, data?.["meta"]);
      return { blacklist: deserializedValue, meta: deserializedMeta };
    } catch (ex) {
      Notification({
        message: "Unable to Get Blacklist Details",
        description: (ex as Error)?.message,
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };
  const addBlacklistCustomer = async (params: BlacklistForm) => {
    setLoading(true);
    const queryParams = serialize(BlacklistForm, params || {});

    const endpoint = generatePath(ApiRoutes.BLACKLIST);
    try {
      const { data } = await axiosInstance.post(endpoint, {
        blacklist: queryParams,
      });
      const deserializedValue = deserialize(
        Blacklists,
        data?.["blacklist"] as Blacklists
      );
      const deserializedMeta = deserialize(PaginationMeta, data?.["meta"]);

      Notification({
        message: "Added to Blacklist",

        type: NotificationTypes.SUCCESS,
      });
      return { blacklist: deserializedValue, meta: deserializedMeta };
    } catch (ex) {
      Notification({
        message: "Unable to Blacklist",
        description: (ex as Error).message,
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };
  const restrictPincode = async (params: Blacklists) => {
    const queryParams = serialize(Blacklists, params || {});
    setLoading(true);

    const endpoint = generatePath(ApiRoutes.BLACKLIST);
    try {
      const { data } = await axiosInstance.post(endpoint, {
        blacklist: queryParams,
      });
      const deserializedValue = deserialize(
        Blacklists,
        data?.["blacklist"] as Blacklists
      );

      Notification({
        message: "Pincode Restricted",

        type: NotificationTypes.SUCCESS,
      });
      return deserializedValue;
    } catch (ex) {
      Notification({
        message: "Unable to Restrict pincode",
        description: (ex as Error).message,
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };
  const unblockCustomerPincode = async (id: string) => {
    setLoading(true);

    const endpoint = generatePath(ApiRoutes.BLACKLIST + "/" + id);
    try {
      const { data } = await axiosInstance.delete(endpoint);

      Notification({
        message: "Unblocked",

        type: NotificationTypes.SUCCESS,
      });
      return true;
    } catch (ex) {
      Notification({
        message: "Unable to Unblock",
        description: (ex as Error).message,
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    getBlacklistDetails,
    addBlacklistCustomer,
    unblockCustomerPincode,
    restrictPincode,
    loading,
  };
};
