import React, { FC } from "react";
import { Field, useField } from "formik";
import { Form, Input } from "antd";
import Error from "../Error";
import { InputType } from "../../../enums/input.type";
import viewPassword from "../../../Assets/images/view.png";
import hidePassword from "../../../Assets/images/hide-password.png";
import Icon from "../Icon";

interface InputFieldProps {
  type: InputType;
  name: string;
  placeholder: string;
  label?: string;
  disabled?: boolean;
  values?: string | number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const InputField: FC<InputFieldProps> = (props) => {
  const { name, label, type } = props;
  const [, meta] = useField(name);
  const passwordProps =
    props?.type === InputType.PASSWORD
      ? {
          iconRender: (visible: boolean) =>
            visible ? <Icon url={viewPassword} /> : <Icon url={hidePassword} />,
        }
      : {};

  return (
    <div>
      <Form.Item label={label}>
        <Field
          as={
            type === InputType.PASSWORD
              ? Input.Password
              : type === InputType.TEXT_AREA
              ? Input.TextArea
              : Input
          }
          {...props}
          {...passwordProps}
        />
         {meta?.error && meta?.touched && <Error message={meta?.error} />}
      </Form.Item>
    </div>
  );
};

export default InputField;
