import Sider from "antd/lib/layout/Sider";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import logoutIcon from "../../../Assets/images/logout.png";
import vigroLogo from "../../../Assets/images/vigrologo.png";
import mobileVgroLogo from '../../../Assets/images/vgro_mobile-logo.png'
import "./sidebar.scss";
import AuthService from "../../../services/AuthService/auth.service";
import { AuthContext } from "../../../context/AuthContext";
import SidebarComponents from "../SidebarComponents";
import { Skeleton } from "antd";
import useBreakPoint from "../../hooks/useBreakPoint";

interface SidebarProps {}

const Sidebar = (props: SidebarProps) => {
  const { logout } = AuthService();
  const { authenticated } = AuthContext();
  const { pathname } = useLocation();
  const { loading, sidebar } = SidebarComponents();
  const [collapsed, setCollapsed] = useState(false);
  const {} = props;

  const { mdScreen, xsScreen, smScreen } = useBreakPoint()
  const isMobile = ((xsScreen || smScreen) && !mdScreen)
 
  const getClassName = (route: string) => {
    const baseClassName = "sider__item";
    const additionalClassName = pathname.includes(route) ? "sider--active" : "";

    return baseClassName + " " + additionalClassName;
  };

  return authenticated ? (
    <Sider collapsedWidth={80} trigger={null} collapsible collapsed={isMobile} className="sider pr-0">
      {!collapsed &&
        <span className={`sider__logo ${!collapsed && 'mobile'}`}>
          <img src={isMobile ? mobileVgroLogo : vigroLogo} alt="" />
        </span>
      } 
      {loading ? (
        <Skeleton />
      ) : (
        sidebar?.map((item, index) =>
          item.condition === undefined || item.condition ? (
            <div key={index} className={getClassName(item.route)}>
              <Link to={item.route}>
                <img src={item.image} className="sider__item__icon" alt="" />
                <span className="text-white">{item.text}</span>
              </Link>
            </div>
          ) : null
        )
      )}
      <div className="sider__item">
        <Link onClick={logout} to={AppRoutes.LOGIN}>
          <img src={logoutIcon} className="sider__item__icon" alt="" />
          <span className="text-white">logout</span>
        </Link>
      </div>
    </Sider>
  ) : (
    <></>
  );
};

export default Sidebar;
