//Icons

//Libraries
import { SorterResult } from "antd/lib/table/interface";
import { useState } from "react";
import { SortData } from "../types/sortData.type";
import { getSortDirection } from "../utils/getSortDirection";

//Styles

interface useSortingProps {
  sortBy: object;
}

const useSorting = <T extends object>(props: useSortingProps) => {
  //Prop
  const { sortBy } = props;

  //State
  const [sortData, setSortData] = useState(new SortData());

  const updateSortData = (props: SorterResult<T>) => {
    const { field, order } = props;
    const key = (
      Array.isArray(field) ? field[0] : field
    ) as keyof typeof sortBy;

    setSortData((data) => ({
      ...data,
      sortBy: sortBy[key],
      sortDirection: getSortDirection(order),
    }));

    return { sortBy: sortBy[key], sortDirection: getSortDirection(order) };
  };

  return {
    sortData,
    updateSortData,
  };
};

export default useSorting;
