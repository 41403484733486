import { LoanParams } from "./loan.model";
import { alias, list, object, primitive, serializable } from "serializr";
import { RepaymentChart } from "./repaymentChart.model";

export class RepaymentsParams extends LoanParams {
  @serializable(alias("branch_ids", list(primitive())))
  branchIds?: number[] = [];
  @serializable(alias("collection_dates", list(primitive())))
  collectionDates?: string[] = [];
  @serializable(alias("loan_type_ids", list(primitive())))
  loanTypeIds?: number[] = [];
  @serializable(alias("status", list(primitive())))
  installmentStatus?: string[] = [];

  @serializable(alias("paid_date", primitive()))
  paidDate?: string;
  @serializable(alias("branch", primitive()))
  branch?: number;
  @serializable(alias("type", primitive()))
  type?: string;
  @serializable(alias("graph_branch", primitive()))
  graphBranch?: number;
  @serializable(alias("date", primitive()))
  date?: string;
  @serializable(alias("start_date", primitive()))
  startDate?: string;
  @serializable(alias("end_date", primitive()))
  endDate?: string;
}

class paymentTransactions {
  @serializable(alias("id", primitive()))
  id?: string;
  @serializable(alias("activity", primitive()))
  activity?: string;
  @serializable(alias("amount", primitive()))
  amount?: string;
  @serializable(alias("created_at", primitive()))
  createdAt?: string;
  @serializable(alias("receipt_no", primitive()))
  receiptNo?: string;
}

export class RepaymentDashboard {
  @serializable(alias("total_repayment", primitive()))
  totalRepayment?: number;
  @serializable(alias("total_repayment_count", primitive()))
  totalRepaymentCount?: number;
  @serializable(alias("own_repayment", primitive()))
  ownRepayment?: number;
  @serializable(alias("own_repayment_count", primitive()))
  ownRepaymentCount?: number;
  @serializable(alias("managed_repayment", primitive()))
  managedRepayment?: number;
  @serializable(alias("managed_repayment_count", primitive()))
  managedRepaymentCount?: number;
  @serializable(alias("overdue", primitive()))
  overdue?: number;
  @serializable(alias("overdue_count", primitive()))
  overdueCount?: number;
  @serializable(alias("pre_close_repayment", primitive()))
  precloseRepayment?: number;
  @serializable(alias("pre_close_count", primitive()))
  preCloseCount?: number;
  @serializable(alias("advance_repayment", primitive()))
  advanceRepayment?: number;
  @serializable(alias("advance_repayment_count", primitive()))
  advanceRepaymentCount?: number;
  @serializable(alias("new_overdue_customers", primitive()))
  newOverdueCustomer?: number;
  @serializable(alias("new_overdue_customers_count", primitive()))
  newOverdueCustomersCount?: number;
  @serializable(alias("overdue_percentage", primitive()))
  overduePercentage?: number;
  @serializable(alias("overdue_percentage_count", primitive()))
  overduePercentageCount?: number;
  @serializable(alias("overdue_customer_percentage", primitive()))
  overdueCustomerPercentage?: number;
  @serializable(alias("new_overdue_percentage", primitive()))
  newOverduePercentage?: number;
  @serializable(alias("new_overdue_percentage_count", primitive()))
  newOverduePercentageCount?: number;
  @serializable(alias("new_overdue_customers_percentage", primitive()))
  newOverdueCustomersPercentage?: number;
}
export class Repayments {
  @serializable(alias("customer_name", primitive()))
  customerName?: string;
  @serializable(alias("code", primitive()))
  code?: string;
  @serializable(alias("laf", primitive()))
  laf?: string;

  @serializable(alias("branch", primitive()))
  branch?: string;
  @serializable(alias("loan_type", primitive()))
  loanType?: string;
  @serializable(alias("repayment_date", primitive()))
  repaymentDate?: string;
  @serializable(alias("pricipal", primitive()))
  principal?: number;
  @serializable(alias("interest", primitive()))
  interest?: number;
  @serializable(alias("total_amount", primitive()))
  totalAmount?: number;
  @serializable(alias("pending_amount", primitive()))
  pendingAmount?: number;
  @serializable(alias("wallet_balance", primitive()))
  walletBalance?: number;
  @serializable(alias("paid_date", primitive()))
  paidDate?: string;
  @serializable(alias("receipt_no", primitive()))
  receiptNo?: string;
  @serializable(alias("repayment_remarks", primitive()))
  repaymentRemarks?: string;
  @serializable(alias("status", primitive()))
  status?: string;
  @serializable(alias("payment_mode", primitive()))
  paymentMode?: string;

  @serializable(
    alias("payment_transactions", list(object(paymentTransactions)))
  )
  paymentTransactions?: paymentTransactions[];
}
