// Icons
import dashboard from "../../../Assets/images/dashboard.png";
import ctVerification from "../../../Assets/images/ct-verification.png";
import users from "../../../Assets/images/User.png";
import configurations from "../../../Assets/images/configurations.png";
import customers from "../../../Assets/images/customers.png";
import reportsImage from "../../../Assets/images/reports.png";
import loans from "../../../Assets/images/loans.png";
import pipeline from "../../../Assets/images/pipeline.png";
import userAvatar from "../../../Assets/images/userAvatarPlaceholder.jpeg";
import crif from '../../../Assets/images/crif_icon.svg'
import claims from '../../../Assets/images/claims.svg'

// Libraries
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";

// Styles
import "./navbar.scss";
import { RoleName } from "../../../enums/roleName.enum";

function Navbar() {
  const { pathname } = useLocation();

  const { authenticated, user } = AuthContext();

  const [navLabel, setNavLabel] = useState("");

  const [imageChange, setImageChange] = useState("");

  useEffect(() => {
    fetchHeaderLabel();
  }, [pathname]);
  const fetchHeaderLabel = () => {
    if (pathname.includes("users")) {
      setNavLabel("Users Management");
      setImageChange(users);
    } else if (pathname.includes("profile")) {
      setNavLabel("My Profile");
      setImageChange(customers);
    } else if (pathname.includes("dashboard")) {
      if(user?.employee.roleName === RoleName.ACCOUNTANT) {
        setNavLabel("Home");
      } else {
        setNavLabel("Dashboard")
      }
      setImageChange(dashboard);
    } else if (pathname.includes("customers")) {
      setNavLabel("Customers");
      setImageChange(customers);
    } else if (pathname.includes("disbursement")) {
      setNavLabel("Disbursement");
      setImageChange(customers);
    } else if (pathname.includes("pipeline")) {
      setNavLabel("Pipeline");
      setImageChange(pipeline);
    } else if (pathname.endsWith("credit-report")) {
      setNavLabel("CRIF Score")
      setImageChange(crif)
     } else if (pathname.includes("loans")) {
      setNavLabel("Loans");
      setImageChange(loans);
    } else if (pathname.includes("repayments")) {
      setNavLabel("Repayments");
      setImageChange(loans);
    } else if (pathname.includes("configuration")) {
      setNavLabel("Configurations");
      setImageChange(configurations);
    } else if (pathname.includes("reports")) {
      setNavLabel("Reports");
      setImageChange(reportsImage);
    } else if (pathname.includes("ct-verification")) {
      setNavLabel("CT Verification");
      setImageChange(ctVerification);
     } else if (pathname.includes("branch-accountant")){
      setNavLabel("BC Loans")
      setImageChange(loans)
     } else if (pathname.includes("accountant")) {
      setNavLabel("Home")
      setImageChange(dashboard)
     } else if (pathname.includes("loan-claims")) {
      setNavLabel("Claims")
      setImageChange(claims)
     } else if (pathname.includes("mod-documents")) {
      setNavLabel("Documents")
      setImageChange(reportsImage)
     }
  };

  return authenticated ? (
    <div className="navbar">
      <span className="navbar__page-info">
        <img alt="sidebar-img" className="navbar__page-info__image" src={imageChange} />
        <span className="navbar__page-info__title">{navLabel}</span>
      </span>
      <span className="navbar__profile-container">
        <Link to={AppRoutes.PROFILE}>
          <span className="navbar__profile" onClick={() => {}}>
            <span className="navbar__profile__details">
              <span className="navbar__profile__details__name">
                {user?.employee?.firstName} {user?.employee?.lastName}
              </span>
              <span className="navbar__profile__details__role">
                {user?.employee?.roleName}
              </span>
            </span>
            <span className="navbar__profile">
              <img
                width={42}
                height={42}
                src={user?.employee?.profilePicUrl ?? userAvatar}
                alt=""
              />
            </span>
          </span>
        </Link>
      </span>
    </div>
  ) : (
    <></>
  );
}
export default Navbar;
