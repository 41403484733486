import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Image,
  Row,
  Select,
  Spin,
  Table,
  TableColumnsType,
  TableProps,
  Tabs,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Meta } from "../../../models/meta.modal";
import { QueryParams } from "../../../models/query.params.model";
import { BranchesService } from "../../../services/Branches/branches.service";
import "./repaymentDetails.scss";
import useBranch from "../../../shared/hooks/useBranch";
import { repaymentColumn } from "./repaymentDetailsColumn";
import { Repayments, RepaymentsParams } from "../../../models/repayments.model";
import { Loan, LoanParams } from "../../../models/loan.model";
import { ToggleDrawer } from "../../../shared/types/toggleDrawer";
import useQueryParams from "../../../shared/hooks/useQueryParams";
import LoanFilters from "../../Loans/LoanFilters";
import ColumnOptions from "../../../shared/components/ColumnOptions";
import { RepaymentService } from "../../../services/Repayment/repayment.service";
import { update } from "lodash";
import useSorting from "../../../shared/hooks/useSorting";
import { SorterResult } from "antd/lib/table/interface";
import useTableConfig from "../../../shared/hooks/useTableConfig";
import { TableConfig } from "../../../enums/tableConfig.type";
import RepaymentFilters from "../RepaymentFilters";
import FilterButtons from "../../../shared/components/FilterButtons";
import { Filters } from "../../../models/filters.model";
import CustomModal from "../../../shared/components/CustomModal";
import { TabsProps } from "../../../shared/types/Tabs.type";
import { NachType } from "../../../enums/nachType";
import { CardProps } from "../../../shared/types/card.type";
import { Formik, FormikProps, FormikValues } from "formik";
import AttachmentUpload from "../../../shared/components/AttachmentUpload";
import { LoanService } from "../../../services/Loan/loan.service";
import { NachReport } from "../../../models/nachReport";
import { nachReportValidation } from "./nachReportValidation";
import { CloseCircleOutlined } from "@ant-design/icons";
import downloadIcon from "../../../Assets/images/downloadBlue.png";
import { LocalStorageHelper } from "../../../shared/utils/localStorageHelper";
import { LocalStorage } from "../../../enums/localStorage.enum";

interface RepaymentDetailsProps {
  params: RepaymentsParams;
  filterVisible?: boolean;
  onFilter?: ToggleDrawer;
  settingsVisible?: boolean;
  onSettings?: ToggleDrawer;
}

const sortBy = {
  code: "code",
  loanType: "loan_type",
  customerName: "customer_name",
  status: "status",
  repaymentDate: "repayment_date",
  paidDate: "paid_date",
};

const RepaymentDetails = (props: RepaymentDetailsProps) => {
  const [totalPage, setTotalPage] = useState(1);
  const [isNachVisible, setIsNachVisible] = useState(false);
  const [repayments, setRepayments] = useState<Repayments[]>([]);
  const [defaultColumns, setDefaultColumns] =
    useState<TableColumnsType<Repayments>>(repaymentColumn);
  const { columnConfig, setColumnConfig } = useTableConfig();
  const [filters, setFilters] = useState<Filters>(new Filters());
  const [activeTab, setActiveTab] = useState<NachType>(NachType.REGISTRATION);
  const [isLoading, setIsLoading] = useState(false);

  const [filteredColumns, setFilteredColumns] = useState<string[]>(
    columnConfig?.repayments ?? []
  );
  const { params, updateParams, getParams } = useQueryParams({
    params: props?.params,
  });
  const userAccess = LocalStorageHelper.getItem(
    LocalStorage.CURRENT_USER_ACCESS
  );
  const { updateSortData } = useSorting<Repayments>({ sortBy });
  const {
    nachImport,
    loading: nachLoading,
    downloadNachTemplate,
    templateLoading,
  } = LoanService();

  const {
    params: loanParams,
    filterVisible,
    onFilter,
    onSettings,
    settingsVisible,
  } = props;
  const {
    loading: branchesLoading,
    branches,
    handleBranchScroll,
  } = useBranch({ allBranch: true });

  const { getBranches } = BranchesService();
  const { getRepayments, loading } = RepaymentService();
  const [branch, setBranch] = useState<Meta[]>([]);

  useEffect(() => {
    const updateParams = loanParams?.searchText
      ? { ...params,
        searchText: loanParams?.searchText }
      : params;
    handleFetchPayments(updateParams);
  }, [params, loanParams?.searchText]);

  useEffect(() => {
    setBranch([{ label: "Select All", value: "all" }, ...branches]);
  }, [branches]);
  const [selectedValue, setSelectedValue] = useState('Select All')

  const handleBranch = (option: string) => {
    if (option === "all") {
      handleFetchPayments({ ...params, branchIds: [] });
      setSelectedValue(option)
      return;
    }
    setSelectedValue(option)
    updateParams({
      ...params,
      branchIds: [Number(option)],
    });
    return option;
  };

  const handleFetchPayments = async (params: RepaymentsParams) => {
    const collectionDates = params?.collectionDates
      ? [String(params?.collectionDates)]
      : [];

    const result = await getRepayments({ ...params, collectionDates });

    if (result) setRepayments(result?.repayments);
    if (result?.meta) {
      setTotalPage(result?.meta?.totalCount ?? 1);
      result?.meta?.filters && setFilters(result?.meta?.filters);
    }
  };

  const handleChange: TableProps<Repayments>["onChange"] = (
   pagination,
    _,
    sorter
  ) => {
    const { sortBy, sortDirection } = updateSortData(
      sorter as SorterResult<Repayments>
    );
    updateParams({ ...params, page: pagination?.current, sortBy, sortDirection });
  };
  useEffect(() => {
    setColumnConfig(TableConfig.REPAYMENTS, filteredColumns);
  }, [filteredColumns]);

  const handleUpdateParams = () => updateParams(getParams());
  const handleNachVisibility = () => setIsNachVisible(!isNachVisible);

  const tabs: CardProps[] = [
    {
      label: "Registration",
      key: NachType.REGISTRATION,
    },
    {
      label: "Repayment",
      key: NachType.REPAYMENT,
    },
  ];

  const handleTabChange = (key: string) => {
    setActiveTab(key as NachType);
  };
  const handleNachReport = async (values: NachReport) => {
    const attachment = await nachImport(values);
    if (attachment) {
      handleCloseNach();
      return true;
    }
  };

  const handleDownloadTemplate = async () =>
    await downloadNachTemplate(activeTab);

  const handleCloseNach = () => {
    setIsNachVisible(false);
  };
  const handleFilter = () => {
    handleUpdateParams()
    setSelectedValue('Select All')
  }

  return (
    <div className="repayment-details">
      <div className="repayment-details__filter">
        <Row gutter={[40, 10]} className="mb-5">
          <Col span={5} className="d-flex ml-5">
            <span className="mr-5 block__name"> Branch</span>
            <Select
              value={selectedValue}
              placeholder="Select Branch"
              options={branch}
              onPopupScroll={handleBranchScroll}
              loading={branchesLoading}
              onChange={handleBranch}
            />
          </Col>
          <Col span={8}>
            <span className="mr-5 block__name"> Collection Dates</span>
            <DatePicker
              format={"DD/MM/YYYY"}
              onChange={(_, value) => {
                updateParams({
                  ...params,
                  collectionDates: value.length ? [value] : [],
                });
              }}
            />
          </Col>
          <Col span={8}>
            <span className="mr-5 block__name">Paid Date</span>
            <DatePicker
              format={"DD/MM/YYYY"}
              onChange={(_, value) => {
                updateParams({
                  ...params,
                  paidDate: value,
                });
              }}
            />
          </Col>
          {userAccess?.writeAccess && (
            <Col span={2}>
              <Button
                className="repayment-details__nach-btn"
                onClick={handleNachVisibility}
              >
                NACH
              </Button>
            </Col>
          )}
        </Row>
      </div>
      {!loading && (
        <FilterButtons filters={filters} onFilter={handleFilter} />
      )}
      <div className="card">
        <Table
          loading={loading}
          onChange={handleChange}
          columns={defaultColumns.filter(
            (col) => !filteredColumns.includes(col.title as string)
          )}
          className=" custom-scrollbar"
          dataSource={repayments}
          scroll={{ x: true }}
          pagination={{
            hideOnSinglePage: true,
            current: params?.page,
            total: totalPage,
          }}
        />
      </div>
      {filterVisible && onFilter && (
        <Drawer
          placement="right"
          onClose={() => onFilter(false)}
          visible={filterVisible}
          width="916"
          title="Filters"
          closable
          destroyOnClose
        >
          <RepaymentFilters onFilter={updateParams} onClose={onFilter} />
        </Drawer>
      )}
      {settingsVisible && onSettings && (
        <Drawer
          placement="right"
          onClose={() => onSettings(false)}
          visible={settingsVisible}
          width={500}
          title="Column Options"
          destroyOnClose
        >
          <ColumnOptions
            defaultColumns={defaultColumns}
            filteredColumns={filteredColumns}
            setFilteredColumns={setFilteredColumns}
            onClose={() => onSettings(false)}
            setDefaultColumns={setDefaultColumns}
          />
        </Drawer>
      )}
      <Formik
        validationSchema={nachReportValidation}
        initialValues={new NachReport()}
        onSubmit={() => {}}
      >
        {({ setFieldValue, isValid, dirty, values, resetForm }) => (
          <div>
            <CustomModal
              title="NACH"
              isOkButton={false}
              isCancelButton={false}
              visible={isNachVisible}
              onCancel={() => {
                handleCloseNach();
                resetForm();
              }}
            >
              <div>
                <div className="repayment-details__nach-tab">
                  <Tabs activeKey={activeTab} onChange={handleTabChange}>
                    {tabs?.map(({ label, key }) => (
                      <Tabs.TabPane tab={label} key={key}>
                        <div className="mt-5">
                          <Image
                            className="mr-5"
                            height={12}
                            width={12}
                            src={downloadIcon}
                            preview={false}
                          />
                          <a onClick={handleDownloadTemplate} className="mr-5">
                            <span> Download template</span>
                          </a>
                          {templateLoading && <Spin />}
                          <Form className="ant-form-vertical mt-5">
                            <Row gutter={[0, 30]}>
                              <Col span={24}>
                                <AttachmentUpload
                                  accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                  name="attachmentId"
                                  placeholder="Upload File"
                                  setFieldValue={setFieldValue}
                                  isLoading={(value) => setIsLoading(value)}
                                />
                                {values.attachmentId && !isLoading && (
                                  <Col className="mt-5" span={8}>
                                    <span className="text-primary mr-5">
                                      File Uploaded
                                    </span>
                                    <CloseCircleOutlined
                                      onClick={() =>
                                        setFieldValue("attachmentId", undefined)
                                      }
                                    />
                                  </Col>
                                )}
                              </Col>
                              <Col span={24}>
                                <div className="drawer-footer text-right">
                                  <Button
                                    htmlType="submit"
                                    disabled={!dirty || !isValid || isLoading}
                                    loading={nachLoading}
                                    type="link"
                                    onClick={async () => {
                                      const success = await handleNachReport({
                                        ...values,
                                        importType: activeTab,
                                      });
                                      if (success) resetForm();
                                    }}
                                  >
                                    Continue
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </Tabs.TabPane>
                    ))}
                  </Tabs>
                </div>
              </div>
            </CustomModal>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default RepaymentDetails;
