import { User } from "../../enums/user.type";
import { UserStatus } from "../../enums/userStatus";

const userStatusOption = [
  {
    label: "Active",
    value: UserStatus.ACTIVE,
  },
  {
    label: "Inactive",
    value: UserStatus.INACTIVE,
  },
];

export default userStatusOption;
