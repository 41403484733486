export const objectToIndexedQueryString = function (
  obj: { [key: string]: any },
  prefix?: string
): string {
  var str = [],
    p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix : p,
        v = obj[p];
      str.push(
        v !== null && typeof v === "object"
          ? objectToIndexedQueryString(v, k)
          : k + "=" + v
      );
    }
  }
  return str.join("&");
};
