//Icons

//Styles
import "./customerDetail.scss";

import React, { useEffect, useState } from "react";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";

import Back from "../../../shared/components/Back";
import { CustomerDetail as CustomerDetailsModel } from "../../../models/customerDetail.model";
import { CustomersService } from "../../../services/Customers/customers.service";
import Loans from "./Loans";
//Libraries
import { Button, Col, Modal, Popover, Row, Select, Switch, Tabs } from "antd";
import { TabsProps } from "../../../shared/types/Tabs.type";
import debounce from "lodash.debounce";
import { useParams } from "react-router-dom";
import { LoanService } from "../../../services/Loan/loan.service";
import { useLocation, useNavigate } from "react-router";
import { generatePath } from "react-router";
import { hasAccessOf } from "../../../shared/utils/hasAccess";
import { RoleName } from "../../../enums/roleName.enum";
import Comment from "./Comments";
import { Loan } from "../../../models/loan.model";
import { LoanStatus } from "../../../enums/loanStatus.type";
import { Comments } from "../../../models/comments.model";
import { MetaService } from "../../../services/Meta/meta.service";
import { Meta } from "../../../models/meta.modal";
import useQueryParams from "../../../shared/hooks/useQueryParams";
import { loanRejectionOptions } from "../../../shared/Constant/loanRejectionOptions";
import LoanTimelines from "../../Loans/LoanIndividualDetails/LoanTimeline";
import CustomerDocument from "./CustomerDocument";
import CustomerPersonalDetails from "./PersonalDetails/CustomerPersonalDetails";
import LoanSummary from "../../Loans/LoanIndividualDetails/LoanSummary";
import { LocalStorageHelper } from "../../../shared/utils/localStorageHelper";
import { LocalStorage } from "../../../enums/localStorage.enum";
import { Form, Formik } from "formik";
import InputField from "../../../shared/components/InputField";
import { InputType } from "../../../enums/input.type";
import { organisationNames } from "../../../enums/organisationNames";
import SelectInput from "../../../shared/components/SelectInput";
import { BCStatusTags } from "../../../enums/currentStatusTags.type";
import { creditScoreValidation } from "./creditScoreValidation";
import { CreditScore } from "../../../models/creditScore.model";
import SpecialApprovalReason from "../../../shared/Constant/specialApprovalOptions";
import { Employee } from "../../../models/employee.model";
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";

interface CustomerDetailProps {}

const CustomerDetail = (props: CustomerDetailProps) => {
  const navigate = useNavigate();
  const userAccess = LocalStorageHelper.getItem(
    LocalStorage.CURRENT_USER_ACCESS
  );

  const [status, setStatusVisibility] = useState(false);
  const [loan, setLoan] = useState<Loan>();
  const [comment, setComment] = useState<Comments>();
  const [isChannelChange, setIsChannelChange] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState<number | null>();
  const [selectedReason, setSelectedReason] = useState<string>();
  const location = useLocation();
  const [loanId, setLoanId] = useState<string>(location?.state?.loanId);
  const path = location.pathname.slice(0, location.pathname.lastIndexOf("/"));
  const isCustomer = path.includes(AppRoutes.CUSTOMERS);

  const HOAccess =
    hasAccessOf(RoleName.HEAD_OFFICER) || hasAccessOf(RoleName.SUPER_ADMIN) || hasAccessOf(RoleName.BRANCH_ACCOUNTANT)

  const isBranchManager = hasAccessOf(RoleName.BRANCH_ACCOUNTANT)

  //State
  const [open, setOpen] = useState(false);
  const handlePopupVisibility = () => setOpen(!open);
  const [loanLoading, setLoanLoading] = useState(false);
  const [organizations, setOrganizations] = useState<Meta[]>([]);
  const [isSwitchCheckedBorrowerSA, setisSwitchCheckedBorrowerSA] = useState(false);
  const [isSwitchCheckedBorrowerNTC, setisSwitchCheckedBorrowerNTC] = useState(false);
  const [creditScoreBorrower, setCreditScoreBorrower] = useState<string>();

  const handleCreditScoreChangeBorrower = (e: { target: { value: string; }; }) => {
    const value = e.target.value;
    setCreditScoreBorrower(value);
  };
  
  const handleCreditScoreChange = () => {
    if (creditScoreBorrower && parseInt(creditScoreBorrower) < 600) {
      setisSwitchCheckedBorrowerSA(true);
    } else {
      setisSwitchCheckedBorrowerSA(false);
    }

    if (isSwitchCheckedBorrowerNTC) {
      setisSwitchCheckedBorrowerNTC(true);
      setCreditScoreBorrower('0');
    } else {
      setisSwitchCheckedBorrowerNTC(false);
    }
  };

  useEffect(() => { handleCreditScoreChange(); }, [creditScoreBorrower]);

  const handleSwitchChangeBorrowerSA = (checked: boolean, setFieldValue: (field: string, value: boolean | null, shouldValidate?: boolean | undefined) => void) => {
    setisSwitchCheckedBorrowerSA(checked);
    setFieldValue("specialApproval", checked);
    setFieldValue("specialApprovalReason", null)
    if (checked) {
      setisSwitchCheckedBorrowerNTC(!checked);
      setFieldValue("isNTC", !checked)
      setFieldValue("specialApprovalReason", null)
    } 
  };

  const handleSwitchChangeBorrowerNTC = (checked: boolean, setFieldValue: (field: string, value: boolean | number | null, shouldValidate?: boolean | undefined) => void) => {
    setisSwitchCheckedBorrowerNTC(checked);
    setFieldValue("isNTC", checked);
    setFieldValue("specialApprovalReason", null)
    if (checked) {
      setisSwitchCheckedBorrowerSA(!checked);
      setFieldValue("specialApproval", !checked);
      setFieldValue("specialApprovalReason", null)
      setFieldValue("creditScore", -1)
      setFieldValue("dpdDays", 0)
      setFieldValue("dpdAmount", 0)
      setFieldValue("activeLoansOutstanding", 0)
    }
  };

  //Service
  const { updateParams } = useQueryParams({
    params: {},
  });
  const { getCustomer, loading } = CustomersService();
  const {
    getPreferredChannel,
  } = MetaService();

  const {
    getLoans,
    getLoan,
    updateLoan,
    approveLoan,
    loading: loanLoad,
    rejectLoan,
    verifyDocuments
  } = LoanService();
  //Context
  const { customerId } = useParams<{ customerId: string }>();

  //Prop

  //State
  const [customer, setCustomer] = useState<CustomerDetailsModel>(
    new CustomerDetailsModel()
  );
  const [activeTab, setActiveTab] = useState(HOAccess ? "2" : "1");
  const [documentSearch, setDocumentSearch] = useState("");
  const [loanSearch, setLoanSearch] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    initializeLoans();
    setActiveTab(
      String(
        tabs?.find((tab) => location?.hash === tab?.hash)?.key ?? activeTab
      )
    );
  }, []);

  const initializeCustomer = async () => {
    const customerDetails = await getCustomer(customerId ?? "");

    setCustomer(customerDetails ?? new CustomerDetailsModel());
  };
  
  useEffect(() => {
    initializeCustomer();
  }, []);

  const initializeLoans = async () => {
    const loanParams = {
      ...(customerId && { customerId: [Number(customerId)] }),
    };
    const loans = await getLoans(loanParams);

    if (loans?.loans.length) {
      const loan = await getLoan(
        loanId ? Number(loanId) : Number(loans?.loans[0]?.id)
      );
      loan && setLoan(loan);
    }
  };

  const initializePreferredChannel = async () => {
    const organizations = await getPreferredChannel();
    if (organizations) setOrganizations(organizations);
  };

  const toggleStatusVisibility = () => {
    setStatusVisibility((visible) => !visible);
    setIsChannelChange(false);
    setSelectedChannel(null)
    setSelectedReason('')
    setisSwitchCheckedBorrowerNTC(false)
    setisSwitchCheckedBorrowerSA(false)
  };

  const tabs: TabsProps[] = [
    {
      label: "Personal Details",
      key: "1",
      component: (
        <CustomerPersonalDetails
          hasCard
          customer={customer}
          loading={loading}
          loan={loan}
        />
      ),
      hash: "#personal-details",
    },
    {
      label: isBranchManager ? "Loan Details" : "Loans",
      key: "2",
      component:
        path === AppRoutes.CT_VERIFICATION ? (
          <LoanSummary
            initializeLoan={initializeLoans}
            initializeCustomer={initializeCustomer}
            loan={loan ?? new Loan()}
            loanLoad={loading}
            onSuccess={initializeLoans}
          />
        ) : (
          <Loans
            searchLoan={loanSearch}
            isCustomerLoan={true}
            aadhaarNumber={customer?.aadhaarNumber}
          />
        ),
      hash: "#loans",
    },

    {
      label: "Documents",
      key: "3",
      component: (
        <CustomerDocument
          customer={customer}
          loan={loan}
          initializeLoans={initializeLoans}
          initializeCustomer={initializeCustomer}
          loanType={loan?.loanTypeCode}
          additionalDetails={loan?.businessDetail}
          coBorrower={loan?.customer?.getCoBorrower()}
          nominee={loan?.customer?.getNominee()}
        />

      ),
    },

    {
      label: "Comments",
      key: "4",
      component: (
        <Comment
          isCustomer={isCustomer}
          id={Number(customer?.id)}
          onSuccess={(values) => setComment(values)}
          commentLoanId={loanId}
        />
      ),
      hash: "#comment",
    },
    {
      label: "Timeline",
      key: "5",
      component: <LoanTimelines loan={loan} />,
      hash: "#timeline",
    },
  ];

  const handleTabChange = (activeKey: string) => {
    const hash = tabs?.find((tab, index) => tab?.key === activeKey)?.hash;
    updateParams({ page: 1 }, hash);
    setActiveTab(activeKey);
  };

  const handleDocumentSearch = (searchVal: string) =>
    setDocumentSearch(searchVal);

  const handleLoanSearch = debounce(
    (searchVal: string) => setLoanSearch(searchVal),
    500
  );

  const employee = LocalStorageHelper.getItem(
    LocalStorage.EMPLOYEE
  ) as Employee;
  const isSuperAdmin = employee.roleName === RoleName.SUPER_ADMIN

  const isMISLoan =
    loan?.organizationName === organisationNames.MASS_FINANCIAL_SERVICES;
  const handlePreferredChannel = async () => {
    const updatedLoan = await updateLoan(loan?.id ?? 0, {
      ...loan,
      status: loan?.status,
      organizationId: Number(selectedChannel),
    });
    if (updatedLoan) {
      setLoan(loan);
      initializeLoans();
      toggleStatusVisibility();
    }
  };

  const handleLoanStatus = async (values?: CreditScore) => {
    setLoanLoading(true);
    const updatedValues = {...values,  masApiCall: loan?.currentStatusTag === BCStatusTags.LOAN_AGREEMENT_UPLOADED ? false : true,}
    if (!isSuperAdmin) {
    const result =
      currentStatus === LoanStatus.APPROVED
        ? await approveLoan(String(loan?.id), updatedValues ? updatedValues : {})
        : await rejectLoan(String(loan?.id), selectedReason ?? "");
    if (result) navigate(generatePath(AppRoutes.CT_VERIFICATION));
    setLoanLoading(false);
    } else {
      Notification({
        message: 'Super Admins do not have access to Approve Loans',
        type: NotificationTypes.ERROR
      })
      setLoanLoading(false);
    }
  };

  const handleExistingLoan = () =>
    navigate(
      generatePath(AppRoutes.CT_VERIFICATION_LOAN, {
        customerId: customerId,
      }),
      {
        state: {
          aadhaarNumber: customer?.aadhaarNumber,
        },
      }
    );

  const handleVerifyApprove = () => {
    toggleStatusVisibility();
    setCurrentStatus(LoanStatus.APPROVED);
  }

  const handleCreditReportClick = () => {
    navigate(generatePath(AppRoutes.INDIVIDUAL_CUSTOMER_CREDIT_REPORT, {
        loanId: String(loan?.id),
      })
    )
  }

  const isNotMASOrUgro = ((loan?.organizationName === organisationNames.MASS_FINANCIAL_SERVICES) || (loan?.organizationName === organisationNames.UGRO))

  const getTabExtraContent = () => {
    switch (activeTab) {
      default:
        return (
          <>
            {HOAccess && (
              <>
              {HOAccess && !isBranchManager && (
                <a onClick={handleExistingLoan} className="mr-5">
                  {"Existing Loan >"}
                </a>
                )}
                <Button onClick={handleCreditReportClick} disabled={loading} className="credit-report-btn mr-3 loan-disburse-btn">
              Credit Report
            </Button>

                {userAccess?.writeAccess && (
                  <Button
                    className="btn-primary mr-5 verify-btn"
                    onClick={handleVerifyApprove}
                  >
                    VERIFY & APPROVE
                  </Button>
                )}
                {userAccess?.writeAccess && (
                  <Popover
                    placement="bottomLeft"
                    visible={open}
                    onVisibleChange={handlePopupVisibility}
                    content={
                      <Row>
                        <Col
                          span={24}
                          onClickCapture={handlePopupVisibility}
                          className="mb-3 cursor-pointer"
                          onClick={() => {
                            toggleStatusVisibility();
                            setCurrentStatus(LoanStatus.REJECTED);
                          }}
                        >
                          Reject Loan
                        </Col>
                        <Col
                          span={24}
                          onClickCapture={handlePopupVisibility}
                          className="mb-3 cursor-pointer"
                          onClick={() => {
                            initializePreferredChannel();
                            toggleStatusVisibility();
                            setIsChannelChange(true);
                          }}
                        >
                          Change Preferred Channel
                        </Col>
                      </Row>
                    }
                    trigger="click"
                  >
                    <a className="pop-btn" href="#">
                      . . .
                    </a>
                  </Popover>
                )}
              </>
            )}
          </>
        );
    }
  };

  const handleConfirmClick = () => {
    isChannelChange ? handlePreferredChannel() : handleLoanStatus()
  }

  const handleSubmit = (values: CreditScore) => {
    const updatedValues = {
      ...values,
      specialApprovalReason: (!isSwitchCheckedBorrowerNTC && !values.specialApproval) ? null : values.specialApprovalReason,
      ...(isSwitchCheckedBorrowerNTC ? {
        creditScore: -1,
        dpdDays: 0,
        dpdAmount: 0,
        activeLoansOutstanding: 0,
      } : {})
    };
  
    isChannelChange ? handlePreferredChannel() : handleLoanStatus(updatedValues);
  };
  
  const handleSelectPreferredChannel = (channel: number) => {
    setSelectedChannel(channel)
  }

  const handleSelectedReason = (reason: string) => {
    setSelectedReason(reason)
  }

  return (
    <div className='customer-detail'>
      <div className="customer-detail__header">
        <Back />
      </div>
      <div className="customer-detail__content">
        <Tabs
          activeKey={String(activeTab)}
          onChange={handleTabChange}
          tabBarExtraContent={getTabExtraContent()}
        >
          {tabs.map(
            ({ component, label, key }) =>
              !(key == "1" && HOAccess) && (
                <Tabs.TabPane tab={label} key={key}>
                  {component}
                </Tabs.TabPane>
              )
          )}
        </Tabs>
        <Modal
          open={status}
          onCancel={toggleStatusVisibility}
          footer={false}

          destroyOnClose

          className={`customer-detail__modal ${(isMISLoan && !isChannelChange && currentStatus !== LoanStatus.REJECTED) && 'mas-form-modal'}`}
        >
          <Row>
            <Col span={24}>
              <h1>
                {isChannelChange ? "Change Preferred Channel" : "Change Status"}
              </h1>
            </Col>
            {!isChannelChange && (
              <>
                <Col span={24}>
                  Are you sure you want to{" "}
                  {currentStatus === LoanStatus.REJECTED
                    ? "reject"
                    : "Verify & Approve"}{" "}
                  this loan?
                  {isMISLoan && currentStatus !== LoanStatus.REJECTED && (
                    <Col span={24} className="credit-score-form">
                  <Formik
                validateOnChange
                validateOnBlur
                validationSchema={creditScoreValidation}
                initialValues={new CreditScore()}
                onSubmit={handleSubmit}
              >
                {({ values, dirty, isValid, setFieldValue, errors }) => { 
                  return (
                  <Form className="ant-form-vertical credit-score-form">
                    <Row gutter={[15, 0]}>
                      <Col span={10}><h2 className="text-primary">Borrower</h2></Col>
                      <Switch onChange={(isChecked) => {
                        handleSwitchChangeBorrowerNTC(isChecked, setFieldValue)
                      }} checked={isSwitchCheckedBorrowerNTC}></Switch>
                      <Col span={7}>
                        <h4 className="text-toggle-NTC">New To Customer(NTC)</h4></Col>
                      <Switch onChange={(isChecked) => {
                        handleSwitchChangeBorrowerSA(isChecked, setFieldValue)
                      }} checked={isSwitchCheckedBorrowerSA}></Switch>
                      <Col span={4}>
                        <h4 className="text-toggle-SpecialApproval">Special Approval</h4>
                      </Col>
                    </Row>
                    <Row gutter={[15, 0]}>
                      <Col span={12}>
                        <InputField
                          name="creditScore"
                          placeholder="Credit Score"
                          label="Credit Score"
                          type={InputType.NUMBER}
                          onChange={(e) => {
                            setFieldValue("creditScore", e.target.value)
                            setFieldValue("specialApproval", parseInt(e.target.value) < 600);
                            handleCreditScoreChangeBorrower(e)
                          }}
                          disabled={isSwitchCheckedBorrowerNTC}
                          values={isSwitchCheckedBorrowerNTC ? '0' : creditScoreBorrower}
                        />
                      </Col>

                      <Col span={12}>
                        <InputField
                          name="dpdDays"
                          placeholder="Enter  DPD days"
                          label="DPD days"
                          type={InputType.NUMBER}
                          disabled={isSwitchCheckedBorrowerNTC}
                          values={isSwitchCheckedBorrowerNTC ? '0' : creditScoreBorrower}
                        />
                      </Col>
                    </Row>
                    <Row gutter={[15, 0]}>
                      <Col span={12}>
                        <InputField
                          name="dpdAmount"
                          placeholder="Enter DPD amount"
                          type={InputType.NUMBER}
                          label="Total DPD amount"
                          disabled={isSwitchCheckedBorrowerNTC}
                        />
                      </Col>
                      <Col span={12}>
                        <InputField
                          name="activeLoansOutstanding"
                          placeholder="Enter total outstanding of live loans"
                          type={InputType.NUMBER}
                          label="Total outstanding of live loans"
                          disabled={isSwitchCheckedBorrowerNTC}
                        />
                      </Col>
                    </Row>
                    <Row gutter={[15, 0]}>
                      <Col span={12}>
                        <InputField
                          name="enquiryNo"
                          placeholder="Enter Credit Bureau Enquiry Number"
                          type={InputType.TEXT}
                          label=" Credit Bureau Enquiry Number"
                        />
                      </Col>
                      {isSwitchCheckedBorrowerSA ? (
                        <Col span={12}>
                          <SelectInput
                            name="specialApprovalReason"
                            label="Special Approval Reason"
                            placeholder="Select"
                            options={SpecialApprovalReason}
                          />
                        </Col>
                      ) : null}
                    </Row>
                    <Col span={10}><h2 className="text-primary">Co Borrower</h2></Col>
                    <Row gutter={[15, 0]}>
                      <Col span={12}>
                        <InputField
                          name="cbCreditScore"
                          placeholder="Enter Credit Score"
                          label="Credit Score"
                          type={InputType.NUMBER}
                        />
                      </Col>

                      <Col span={12}>
                        <InputField
                          name="cbEnquiryNumber"
                          placeholder="Enter Credit Bureau Enquiry Number"
                          type={InputType.TEXT}
                          label=" Credit Bureau Enquiry Number"
                        />
                      </Col>
                    </Row>
                    <div className="drawer-footer text-right">
                      <Button
                        htmlType="reset"
                        onClick={toggleStatusVisibility}
                        className="modal-footer__cancel mt-5"
                        type="link"
                      >
                        Cancel
                      </Button>
                      <Button
                        loading={loanLoading || loading}
                        className="modal-footer__submit w-10 mt-5"
                        htmlType="submit"
                        disabled={!dirty || !isValid}
                      >
                        Confirm
                      </Button>
                    </div>
                  </Form>
)}}
              </Formik>
                    </Col>
                  )}
                </Col>
              </>
            )}
            {currentStatus === LoanStatus.REJECTED && !isChannelChange && (
              <Col span={24} className="mt-5">
                <p>Reject Reason</p>
                <Select
                  options={loanRejectionOptions}
                  placeholder="Select Reason"
                  showArrow
                  onChange={handleSelectedReason}
                />
              </Col>
            )}
            {isChannelChange && (
              <Col span={24}>
                <Select
                  options={organizations}
                  placeholder="Select Preferred Channel"
                  showArrow
                  onChange={handleSelectPreferredChannel}
                />
              </Col>
            )}
          </Row>
           {(isChannelChange || currentStatus === LoanStatus.REJECTED || !isMISLoan ) && (
            <div className="text-right mt-5">
              <Button
                htmlType="reset"
                onClick={toggleStatusVisibility}
                className="modal-footer__cancel"
                type="link"
              >
                Cancel
              </Button>
              <Button
                loading={loanLoading || loading}
                className="modal-footer__submit w-10"
                onClick={handleConfirmClick}
                disabled={isChannelChange ? !selectedChannel : (currentStatus === LoanStatus.REJECTED ? !selectedReason : false)}
              >
                Confirm
              </Button>
            </div>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default CustomerDetail;