import { alias, primitive, serializable } from "serializr"

export class DocumentCreate {
    @serializable(alias('document_type', primitive()))
    documentType?: string

    @serializable(alias('code', primitive()))
    code?: number

    @serializable(alias('front_side_image_id', primitive()))
    frontSideImageId?: string
}