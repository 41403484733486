import {
  SKIP,
  alias,
  custom,
  list,
  object,
  serializable,
} from "serializr";
import { Meta } from "./meta.modal";

export class Filters {
  @serializable(list(object(Meta)))
  branches?: Meta[];
  @serializable(list(object(Meta)))
  centers?: Meta[];
  @serializable(alias('disbursement_date', custom(() => SKIP, (values) => {
    return values
  })))
  disbursementDate?: [];
  @serializable(alias("is_hypothecator", list(object(Meta))))
  isHypothecator?: Meta[];
  @serializable(alias("maximum_loan_amount", list(object(Meta))))
  maximumLoanAmount?: Meta[];
  @serializable(alias("minimum_loan_amount", list(object(Meta))))
  minimumLoanAmount?: Meta[];
  @serializable(alias("total_amount", list(object(Meta))))
  totalAmount?: Meta[];
  @serializable(list(object(Meta)))
  tenure?: Meta[];
  @serializable(list(object(Meta)))
  states?: Meta[]
  @serializable(list(object(Meta)))
  hypothecators?: Meta[];
  @serializable(list(object(Meta)))
  organisations?: Meta[];

  @serializable(alias("created_by", list(object(Meta))))
  createdBy?: Meta[];
  @serializable(list(object(Meta)))
  customers?: Meta[];
  @serializable(alias("loan_types", list(object(Meta))))
  loanTypes?: Meta[];
  @serializable(alias("is_active", custom(() => SKIP, (values) => { 
    return values
   }
  )))
  isActive?: Meta[];
  @serializable(list(object(Meta)))
  regions?: Meta[];
  @serializable(alias('responsible_employee', list(object(Meta))))
  responsibleEmployee? : Meta[]
  @serializable(alias("customers", list(object(Meta))))
  customerId?: Meta[];
  @serializable(alias('security_types', custom(() => SKIP, (values) => {
    return values
  })))
  securityTypes?: [];

  @serializable(list(object(Meta)))
  roles?: Meta[];
  @serializable(
      custom(
        () => SKIP,
        (values) => {
          return values;
        }
      )
  )
  statuses?: [];
}