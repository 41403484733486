import * as Yup from "yup";

export const disbursementAmountValidation = Yup.object().shape({
  disburseAmount: Yup.string().required().label("Disburse Amount Required"),
  process: Yup.string().required().label("Process is Required"),
  branchIds: Yup.string().required().label("Branch  is Required"),
  loanTypeIds: Yup.string().required().label("Loan Type is Required"),
  maximumLoanAmount: Yup.string()
    .required()
    .label("Maximum Loan Amount is Required"),
});
