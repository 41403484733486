const SpecialApprovalReason = [
  {
    label: "Due to Agri Loan",
    value: "due_to_agri_loan",
  },
  {
    label: "Due to Education Loan",
    value: "due_to_education_loan",
  },
  {
    label: "Due to Gold Loan",
    value: "due_to_gold_loan",
  },
];

export default SpecialApprovalReason;
