import { alias, primitive, serializable } from "serializr";

export class RegenerateRepaymentsModel {
  @serializable(alias("tenure_in_months", primitive()))
  tenureInMonths?: string;
  @serializable(alias("expected_start_date", primitive()))
  expectedStartDate?: string;
  @serializable(alias("requested_loan_amt", primitive()))
  requestedLoanAmt?: number;
  @serializable(alias("tenure", primitive()))
  tenure?: number;

  @serializable(alias("paid_principal", primitive()))
  paidPrincipal?: number;

  @serializable(alias("outstanding_principal_amount", primitive()))
  outstandingPrincipalAmount?: number;
}
