import React, { useEffect, useState } from "react";
import Table from "../../../shared/components/Table";
import { Drawer, TableColumnsType, TableProps } from "antd";
import { Loan, LoanParams } from "../../../models/loan.model";
import useTableConfig from "../../../shared/hooks/useTableConfig";
import { SorterResult } from "antd/lib/table/interface";
import useQueryParams from "../../../shared/hooks/useQueryParams";
import useSorting from "../../../shared/hooks/useSorting";
import useDrawer from "../../../shared/hooks/useDrawer";
import TabExtraContent from "../../../shared/components/TabExtraContent";
import FilterButtons from "../../../shared/components/FilterButtons";
import { Filters } from "../../../models/filters.model";
import { TableConfig } from "../../../enums/tableConfig.type";
import './returnedDocuments.scss'
import ColumnOptions from "../../../shared/components/ColumnOptions";
import AcknowledgeDrawer from "../AcknowledgeDetails";
import { returnedColumns } from "./returnedColumns";
import { modLoansTypes } from "../../../enums/modLoans.type";
import { LoanService } from "../../../services/Loan/loan.service";
import MODFilters from "../MODFilters";

type Props = {};

const sortBy = {
  code: "code",
  security: "security",
  customerId: "customer_id",
  customerName: "customer_name",
  createdByFirstName: "created_by.firstname",
  branch: "branch",
  modDocumentId: "mod_document_id",
  securedType: "secured_type",
  closedDate: "closed_date",
  returnDate: "return_date",
  requestedLoanAmt: "requested_loan_amt",
};

const ReturnedDocuments = (props: Props) => {
  const [defaultColumns, setDefaultColumns] =
    useState<TableColumnsType<Loan>>(returnedColumns);
  const [documents, setDocuments] = useState<Loan[]>([]);
  const [filters, setFilters] = useState<Filters>(new Filters());
  const { columnConfig, setColumnConfig } = useTableConfig();
  const [filteredColumns, setFilteredColumns] = useState<string[]>(
    columnConfig?.modDocuments ?? []
  );
  const [documentsTotalPage, setDocumentsTotalPage] = useState(1);
  const [displayDocument, setDisplayDocument] = useState<Loan>();
  const { loading, getLoans } = LoanService()

  const {
    params: returnedParams,
    updateParams,
    getParams,
    handleSearch: handleReturnedSearch,
  } = useQueryParams({
    params: new LoanParams(),
  });

  const {
    visible: documentModalVisible,
    toggleVisibility: toggleDocumentModalVisibility,
  } = useDrawer({});

  const { updateSortData } = useSorting<Loan>({ sortBy });

  const handleChange: TableProps<Loan>["onChange"] = (
    pagination,
    _,
    sorter
  ) => {
    const sortData = updateSortData(sorter as SorterResult<Loan>);
    updateParams({
      ...returnedParams,
      ...sortData,
      page: pagination?.current,
    });
  };

  const handleUpdateParams = () => updateParams(getParams());

  useEffect(() => {
    setColumnConfig(TableConfig.MOD_OFFICER, filteredColumns);
  }, [filteredColumns]);

  const fetchDocuments = async () => {
    const updatedParams = {
      ...returnedParams,
      searchText: returnedParams.searchText,
      modStatus: modLoansTypes.RETURNED
    };
    const loanData = await getLoans(updatedParams);
    if (loanData?.loans) setDocuments(loanData?.loans);
    if (loanData?.meta) {
      setDocumentsTotalPage(loanData?.meta?.totalCount ?? 1);
      loanData?.meta?.filters && setFilters(loanData?.meta?.filters);
    }
  };

  useEffect(()=> {
    fetchDocuments()
  }, [returnedParams])

  const {
    visible: returnedFilterVisible,
    toggleVisibility: toggleReturnedFilterVisibility,
  } = useDrawer({});

  const {
    visible: returnedSettingsVisible,
    toggleVisibility: toggleReturnedSettingsVisibility,
  } = useDrawer({});

  const handleRowChange = (record: Loan) => ({
    onClick: (e: any) => {
      {
        e.preventDefault();
        e.stopPropagation();
        toggleDocumentModalVisibility();
        setDisplayDocument(record);
      }
    },
  });
  

  return (
    <div className="returned-documents">
      {!loading && (
        <FilterButtons filters={filters} onFilter={handleUpdateParams} />
      )}
      <div className="returned-extra-content">
        <TabExtraContent
          searchProps={{
            onSearch: handleReturnedSearch,
            placeholder: "Search Documents",
            value: returnedParams.searchText,
          }}
          filterClick={toggleReturnedFilterVisibility}
          settingsClick={toggleReturnedSettingsVisibility}
        />
      </div>
      <Table
        className={`returned-documents-table`}
        rowKey="id"
        scroll={{ x: true }}
        columns={defaultColumns.filter(
          (col) => !filteredColumns.includes(col.title as string)
        )}
        dataSource={documents}
        loading={loading}
        onRow={handleRowChange}
        showSorterTooltip={false}
        onChange={handleChange}
        pagination={{
          current: returnedParams?.page,
          total: documentsTotalPage,
          hideOnSinglePage: true,
        }}
      />
      <Drawer
          placement="right"
          onClose={() => toggleReturnedFilterVisibility(false)}
          visible={returnedFilterVisible}
          width="916"
          title="Filters"
          closable
          destroyOnClose
        >
         <MODFilters
            onClose={() => toggleReturnedFilterVisibility(false)}
            onFilter={updateParams}
          />
        </Drawer>
        <Drawer
          placement="right"
          onClose={() => toggleReturnedSettingsVisibility(false)}
          visible={returnedSettingsVisible}
          width={500}
          title="Column Options"
          destroyOnClose
        >
          <ColumnOptions
            defaultColumns={defaultColumns}
            filteredColumns={filteredColumns}
            setFilteredColumns={setFilteredColumns}
            onClose={() => toggleReturnedSettingsVisibility(false)}
            setDefaultColumns={setDefaultColumns}
          />
        </Drawer>
        <Drawer
        placement="right"
        onClose={() => toggleDocumentModalVisibility(false)}
        visible={documentModalVisible}
        width={600}
        destroyOnClose
        title="Returned Document Details"
        >
        <AcknowledgeDrawer fetchDocuments={fetchDocuments} document={displayDocument} onClose={() => toggleDocumentModalVisibility(false)}/>
        </Drawer>
    </div>
  );
};

export default ReturnedDocuments;
