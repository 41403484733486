const rejectionReasons = [
    {
        label: 'Collection proof is missing',
        value: 'Collection proof is missing'
    },
    {
        label: 'Payment not received on this date',
        value: 'Payment not received on this date'
    }
]

export default rejectionReasons