import { Month } from "../../enums/month.type";
import { Meta } from "../../models/meta.modal";

export const monthOptions: Meta[] = [
  {
    label: "January",
    value: Month.JANUARY,
    code: Month.JANUARY,
  },
  {
    label: "February",
    value: Month.FEBRUARY,
    code: Month.FEBRUARY,
  },
  {
    label: "March",
    value: Month.MARCH,
    code: Month.MARCH,
  },
  {
    label: "April",
    value: Month.APRIL,
    code: Month.APRIL,
  },
  {
    label: "May",
    value: Month.MAY,
    code: Month.MAY,
  },
  {
    label: "June",
    value: Month.JUNE,
    code: Month.JUNE,
  },
  {
    label: "July",
    value: Month.JULY,
    code: Month.JULY,
  },
  {
    label: "August",
    value: Month.AUGUST,
    code: Month.AUGUST,
  },
  {
    label: "September",
    value: Month.SEPTEMBER,
    code: Month.SEPTEMBER,
  },
  {
    label: "October",
    value: Month.OCTOBER,
    code: Month.OCTOBER,
  },
  {
    label: "November",
    value: Month.NOVEMBER,
    code: Month.NOVEMBER,
  },
  {
    label: "December",
    value: Month.DECEMBER,
    code: Month.DECEMBER,
  },
];

export const shortMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

