import { alias, primitive, serializable } from "serializr";

export class Role {
  @serializable(alias("name", primitive()))
  name?: string;
  @serializable(alias("code", primitive()))
  code?: string;
  @serializable(alias("id", primitive()))
  id?: number;
}
