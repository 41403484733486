import { deserialize } from "serializr";
import { LocalStorage } from "../../enums/localStorage.enum";
import { User } from "../../models/user.model";
import { Employee } from "../../models/employee.model";

export class LocalStorageHelper {
  static setItem(key: LocalStorage, value: any) {
    const data = JSON.stringify(value);
    localStorage.setItem(key, data);
  }

  static getItem(key: LocalStorage) {
    const value = localStorage.getItem(key);
    const data = value ? JSON.parse(value) : null;
    switch (key) {
      case LocalStorage.USER:
        return deserialize(User, data);
      default:
        return data;
    }
  }
}

export const encryptBase64 = (text: string) => {
  return btoa(text);
};

export const decryptBase64 = (encryptedText: string) => {
  return atob(encryptedText);
};

export const validateLocalStorage = () => {
  const storedRole = localStorage.getItem(LocalStorage.EMPLOYEE_ROLE);
  const decryptedRole = storedRole ? decryptBase64(storedRole) : undefined;
  const employee = localStorage.getItem(LocalStorage.EMPLOYEE)
    ? JSON.parse(localStorage.getItem(LocalStorage.EMPLOYEE)!)
    : new Employee();

  if (decryptedRole !== employee.roleName) {
    localStorage.clear()
    return false;
  }
  return true;
};