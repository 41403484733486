import { Col, Collapse, Row, Space, Spin } from "antd";
import React, { useState } from "react";
import "../../IndividualKeyFactor";
import paymentStatusImg from "../../../../../Assets/images/On-Time Payments.svg";
import "../individualKeyFactor.scss";
import {
  ListMappingKeys,
} from "../../../../../shared/types/optionArray.type";
import { CrifReport } from "../../../../../models/creditReport.model";
import { convertStringForClassName } from "../../../../../shared/utils/creditReportUtil";
import { fillEmptyData } from "../../../../../shared/utils/fillEmptyData";
import { AccountsDetails } from "../../../../../models/accountsDetails.model";
import PaymentHistory from "../../../Total Accounts/PaymentTable";
import AppLoader from "../../../../../shared/components/AppLoader";

interface PaymentStatusFactorProps {
  data?: CrifReport;
  loading: boolean
}

const PaymentStatusFactor = (props: PaymentStatusFactorProps) => {
  const { data, loading } = props;

  const { Panel } = Collapse;

  const [activeCollapseKeys, setActiveCollapseKeys] = useState<string[]>([]);

  const handlePanelChange = (keys: string | string[]) => {
    setActiveCollapseKeys(Array.isArray(keys) ? keys : [keys]);
  };

  const keyFactorData: ListMappingKeys[] = [
    {
      label: "Total Payments",
      value: data?.paymentStatus?.totalPayments,
      offset: 3,
      key: "1",
      className: "text-blue",
    },
    {
      label: "On-Time Payments",
      value: data?.paymentStatus?.onTimePayments,
      className: "text__green",
      offset: 1,
      key: "2",
    },
    {
      label: "Late Payments",
      value: data?.paymentStatus?.latePayments,
      className: "text__red",
      offset: 1,
      key: "3",
    },
    {
      label: "No Payment Information",
      value: data?.paymentStatus?.noPaymentInformation,
      offset: 1,
      key: "4",
    },
  ];

  const renderPanelContent = (account: AccountsDetails) => {
    const panelDetails: ListMappingKeys[] = [
      {
        label: "Account Number",
        value: fillEmptyData(account.accountNumber),
        key: "1",
        offset: 3,
      },
      {
        label: "On-Time Payments",
        value: fillEmptyData(account.onTimePayments),
        key: "2",
      },
      {
        label: "Last Updated",
        value: fillEmptyData(account.reportedOn),
        key: "3",
        offset: 1,
      },
      {
        label: "Status",
        value: fillEmptyData(account.accountStatus),
        key: "4",
        className: `${account.accountStatus}`,
      },
    ];
    return (
      <Row gutter={[16, 16]} className="panel-component-first">
        <Col>
          <img src={paymentStatusImg} alt="Total Accounts Logo" />
        </Col>
        <Col className="flex-column" span={5}>
          <span>{account.lender}</span>
          <span className="block__label text-capitalize">{account.accountType}</span>
        </Col>
        {panelDetails.map((detail) => (
          <Col
            key={detail?.key}
            span={detail.label === "Last Updated" ? 4 : detail.label === "Status" ? 3 : 5}
            className="block__value"
          >
            <span className={`status--${convertStringForClassName(detail.className)}`}>{detail.value}</span>
            <div className={`block__label text-capitalize`}>{detail.label}</div>
          </Col>
        ))}
      </Row>
    );
  };

  return (
    <div className="payment-status">
      <AppLoader loading={loading}>
      <Row className="card crif-card" align={"middle"}>
        <Space direction="horizontal" size={12}>
          <Col>
            <img src={paymentStatusImg} alt="Payment Status" />
          </Col>
          <Col>Payment Status</Col>
        </Space>
        {keyFactorData.map((data) => (
          <Col offset={data?.offset} className="flex-column" key={data.key}>
            <span className="font-weight__600 font-size__18">
              {data.value || 0}
            </span>
            <span className={`${data.className}`}>{data.label}</span>
          </Col>
        ))}
        <Col offset={2} className="flex-column">
          <div>
            <span className="font-weight__600 font-size__18">
            {/* // To be Confirmed from BE */}
              0
              %
            </span>
            <span> On Time</span>
          </div>
          <span className={`text__green text-right`}>
            {fillEmptyData(data?.paymentStatus?.status)}
          </span>
        </Col>
      </Row>
      <Collapse
        className="mt-5 mb-5"
        activeKey={activeCollapseKeys}
        expandIconPosition="end"
        onChange={handlePanelChange}
        bordered={false}
        ghost={true}
      >
        {data?.creditAccounts?.accountsDetails?.map((account, index) => {
          return (
            <Panel
              className="card mt-5 mb-5"
              key={index + 1}
              header={renderPanelContent(account)}
            >
              <PaymentHistory account={account} />
            </Panel>
          );
        })}
      </Collapse>
      </AppLoader>
    </div>
  );
};

export default PaymentStatusFactor;
