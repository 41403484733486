import React, { useEffect, useState } from "react";
import { TabsProps } from "../../../shared/types/Tabs.type";
import PendingDocuments from "../Pending";
import StoredDocuments from "../Stored";
import ToReturnDocuments from "../ToReturn";
import ReturnedDocuments from "../Returned";
import useQueryParams from "../../../shared/hooks/useQueryParams";
import { LoanParams } from "../../../models/loan.model";
import { useLocation } from "react-router-dom";
import { Tabs } from "antd";

type Props = {};

const MODDocuments = (props: Props) => {
  const tabs: TabsProps[] = [
    {
      label: "Pending",
      key: "1",
      component: <PendingDocuments />,
      hash: "#pending-documents",
    },
    {
      label: "Stored",
      key: "2",
      component: <StoredDocuments />,
      hash: "#stored-documents",
    },
    {
      label: "To Return",
      key: "3",
      component: <ToReturnDocuments />,
      hash: "#to-return-documents",
    },
    {
      label: "Returned",
      key: "4",
      component: <ReturnedDocuments />,
      hash: "#returned-documents",
    },
  ];

  const location = useLocation();
  const { updateParams } = useQueryParams({ params: new LoanParams() });
  const [activeTab, setActiveTab] = useState("1");

  useEffect(() => {
    setActiveTab(
      String(
        tabs?.find((tab) => location?.hash === tab?.hash)?.key ?? activeTab
      )
    );
  }, [activeTab]);

  useEffect(()=> {
    if (!location.hash) {
      window.location.hash = tabs?.find((tab) => tab.key === activeTab)?.hash ?? '';
    }
  },[location.hash])

  const handleTabChange = (tabNumber: string) => {
    const hash = tabs?.find((tab, index) => tab?.key === tabNumber)?.hash;
    updateParams({ page: 1 }, hash);
    setActiveTab(tabNumber);
  };

  const tabDetails = tabs?.find((tab) => location?.hash === tab?.hash);
  const tabKey = String(tabDetails?.key);

  return (
    <div className="mod-wrapper">
      <Tabs
        defaultActiveKey="1"
        activeKey={tabKey !== undefined ? tabKey : "1"}
        onChange={handleTabChange}
      >
        {tabs?.map(({ label, key, component }) => (
          <Tabs.TabPane tab={label} key={key}>
            {component}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default MODDocuments;
