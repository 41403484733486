//Icons

//Libraries
import React, { useEffect, useState } from "react";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Loan } from "../../../../models/loan.model";
import * as AppRoutes from "../../../../routes/routeConstants/appRoutes";
import { LoanService } from "../../../../services/Loan/loan.service";
import Table from "../../../../shared/components/Table";
import { loanColumns } from "./loanColumns";

//Styles
import "./loans.scss";
import ExistingLoanSummary from "../../../Loans/ExistingLoanSummary";
import Back from "../../../../shared/components/Back";

interface LoansProps {
  searchLoan?: string;
  isCustomerLoan?: boolean;
  isCTVerificationLoan?: boolean;
  aadhaarNumber?: string;
}

const Loans = (props: LoansProps) => {
  const location = useLocation();
  //Service
  const { getLoans, loading } = LoanService();

  //Context
  const navigate = useNavigate();
  const { customerId } = useParams<{ customerId: string }>();

  //Prop
  const { searchLoan, isCustomerLoan, isCTVerificationLoan, aadhaarNumber } =
    props;

  //State
  const [loans, setLoans] = useState<Loan[]>([]);
  const [loanPage, setLoanPage] = useState(1);
  const [loanTotalPage, setLoanTotalPage] = useState(1);
  const isCTVerification = location?.pathname.includes(
    AppRoutes.CT_VERIFICATION
  );

  const initializeLoans = async (loanPage: number, searchText?: string) => {
    const loanParams = {
      page: loanPage,
      searchText,
      ...(customerId && { customerId: [Number(customerId)] }),
    };
    const loans = await getLoans(loanParams);
    setLoans(loans?.loans ?? []);
    setLoanTotalPage(loans?.meta?.totalCount ?? 1);
  };
  useEffect(() => {
    initializeLoans(loanPage);
  }, [loanPage]);

  useEffect(() => {
    setLoanPage(1);
    initializeLoans(1, searchLoan);
  }, [searchLoan]);

  return (
    <div className="loans">
      {isCTVerificationLoan && <Back />}
      <ExistingLoanSummary hasCard={true} aadhaarNumber={aadhaarNumber} />
      <div className="card">
        <div className="block__heading">Existing loans</div>
        <Table
          columns={loanColumns}
          dataSource={loans}
          loading={loading}
          pagination={{
            hideOnSinglePage: true,
            total: loanTotalPage,
            onChange: (page) => setLoanPage(page),
          }}
          onRow={(record) => ({
            onClick: () =>
              navigate(
                generatePath(
                  isCTVerification
                    ? AppRoutes.CT_VERIFICATION_LOAN_DETAILS
                    : AppRoutes.CUSTOMERS_LOAN_DETAILS,
                  {
                    loanId: record?.id,
                    customerId: customerId,
                  }
                ),
                {
                  state: {
                    isCustomerLoan: isCustomerLoan,
                    disbursedDate: record?.recentLoanStatus?.assignedAt,
                  },
                }
              ),
          })}
        />
      </div>
    </div>
  );
};

export default Loans;
