import { LoanStatus } from "../../enums/loanStatus.type";
import { portfolioType } from "../../enums/portfolioType";

const portfolioOptions = [
  {
    label: "Own",
    value: portfolioType.OWN,
  },
  {
    label: "Managed",
    value: portfolioType.MANAGED,
  },
  {
    label: "Both",
    value: portfolioType.BOTH,
  },
];

export default portfolioOptions;
