import { alias, primitive, serializable } from "serializr";
import { DocumentType } from "../enums/documentType.type";
import { removeUnderscore } from "../shared/utils/removeUnderscore";

export class Document {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("owner_type", primitive()))
  ownerType?: string;

  @serializable(alias("owner_id", primitive()))
  ownerId?: number;

  @serializable(alias("document_type", primitive()))
  documentType?: DocumentType;

  @serializable(alias("code", primitive()))
  code?: string;
  @serializable(alias("document_name", primitive()))
  documentName?: string;

  @serializable(alias("front_side_image_id", primitive()))
  frontSideImageId?: string;

  @serializable(alias("back_side_image_id", primitive()))
  backSideImageId?: string;

  @serializable(alias("front_side_image_url", primitive()))
  frontSideImageUrl?: string;

  @serializable(alias("back_side_image_url", primitive()))
  backSideImageUrl?: string;

  @serializable(alias('created_at', primitive()))
  createdAt?: string;

  getDocumentLabel = () => {
    switch(this.documentType) {
      case DocumentType.EB_BILL:
        return "EB Bill";
      case DocumentType.LOAN_AGREEMENT:
        return "Loan Agreement"
      case DocumentType.PDC:
        return "PDC"
      case DocumentType.PROPERTY_DOCUMENT:
        return "Property Document"
     default:
      return removeUnderscore(this.documentName ? this.documentName : "Other");
    }

    // switch (this.documentType) {
    //   case DocumentType.DRIVING_LICENSE:
    //     return "driving license";
    //   case DocumentType.WATER_BILL:
    //     return "water bill";
    //   case DocumentType.INCOME_CERTIFICATE:
    //     return "income certificate";
    //   case DocumentType.INCOME_TAX_RECEIPT:
    //     return "income tax receipt";
    //   case DocumentType.RATION_CARD:
    //     return "ration card";
    //   case DocumentType.VOTER_ID:
    //     return "voter id";
    //   case DocumentType.BANK_STATEMENT:
    //     return "bank statement";
    //   case DocumentType.MSME_UDHAYAM_CERTIFICATE:
    //     return "msme udhayam certificate";
    //   case DocumentType.BUSINESS_VINTAGE_PROOF:
    //     return "business vintage proof";
    //   case DocumentType.OTHER_BUSINESS_PROOFS:
    //     return "other business proofs";

    //   default:
    //     return removeUnderscore("Other");
    // }
  };
}
