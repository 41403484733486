import { Gender } from "../../enums/gender.type";
import { Meta } from "../../models/meta.modal";

export const genderOptions: Meta[] = [
  {
    label: "Male",
    value: Gender.MALE,
    code: Gender.MALE,
  },
  {
    label: "Female",
    value: Gender.FEMALE,
    code: Gender.FEMALE,
  },
  {
    label: "Does not wish to specify",
    value: Gender.OTHER,
    code: Gender.OTHER,
  },
];
