export enum DateFormat {
  TIME = "time",
  MONTH = "month",
  DATE = "date",
  DATE_MONTH = "date_month",
  ORDINAL_DATE = "ordinal_date",
  DATE_TIME = "date_time",
  ORIGINAL_TIME = "original_time",
  ORIGINAL_DATE = "original_date"
}
