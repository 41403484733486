import React, { useEffect, useState } from "react";
import "./reports.scss";
import { Formik } from "formik";
import { Button, Col, Form, Progress, Row } from "antd";
import SelectInput from "../../shared/components/SelectInput";
import { Meta } from "../../models/meta.modal";
import { reportValidation } from "./reportValidation";
import { LoanReportParams } from "../../models/loan.model";
import { LoanService } from "../../services/Loan/loan.service";
import useBranch from "../../shared/hooks/useBranch";
import { MetaService } from "../../services/Meta/meta.service";
import reportOptions from "../../shared/Constant/reportOptions";
import reportDateOptions from "../../shared/Constant/reportDateOption";
import { connectToChannel } from "../../shared/utils/connectToChannel";
import { ChannelName } from "../../enums/channelName.type";
import { useReportContext } from "../../context/ReportContext";
import { ReportNameType } from "../../enums/reportName.type";
import DatePickerField from "../../shared/components/DatePickerField";
import { ReportDate } from "../../enums/reportDate.type";
import { dateFormats } from "../../enums/dateFormats.type";
import moment from "moment";

function Reports() {
  const { generateReport, loading } = LoanService();
  const [loanTypesOptions, setLoanTypeOptions] = useState<Meta[]>([]);
  const [organizations, setOrganizations] = useState<Meta[]>([]);
  const { reportData, updateReportData } = useReportContext();

  const {
    loading: branchesLoading,
    branches,
    handleBranchScroll,
    handleBranchSearch,
  } = useBranch({});
  const {
    getLoanTypes,
    getPreferredChannel,
    getHypothecators,
    hypothecators
  } = MetaService();

  useEffect(() => {
    initializeMeta();
  }, []);

  const handleSubmit = async (params: LoanReportParams) => {
    await connectToChannel(
      process.env.REACT_APP_REPORT_CHANNEL_URL as string,
      ChannelName.VELICHAM,
      {
        onConnected: async () => await generateReport(params),
      },
      reportData,
      updateReportData
    );
  };

  const multiBranchReportNames = [
    ReportNameType.PAYMENT,
    ReportNameType.REPAYMENT,
    ReportNameType.BOOK_DEBT,
    ReportNameType.DISBURSEMENT,
    ReportNameType.INSURANCE,
    ReportNameType.DEMAND,
    ReportNameType.OUTSTANDING
  ];

  const initializeMeta = async () => {
    const [loanTypesOptions, organizations] = await Promise.all([
      getLoanTypes(),
      getPreferredChannel(),
      getHypothecators()
    ]);
    if (loanTypesOptions) setLoanTypeOptions(loanTypesOptions);
    if (organizations) setOrganizations(organizations);
  };

  return (
    <div className="reports card">
      <Formik
        validationSchema={reportValidation}
        initialValues={new LoanReportParams()}
        onSubmit={() => {}}
      >
        {({ setFieldValue, values, dirty, isValid }) => {

        const handleCustomDateRange = (date: string[]) => {
          if (date) {
            setFieldValue("fromDate", moment(date[0])?.format(dateFormats.DATE_FIRST))
            setFieldValue("toDate", moment(date[1])?.format(dateFormats.DATE_FIRST))
            setFieldValue("customDate", `${moment(date[0])?.format(dateFormats.DATE_FIRST)}-${moment(date[1])?.format(dateFormats.DATE_FIRST)}`)
          } else if (!date) {
            setFieldValue("fromDate", undefined)
            setFieldValue("toDate", undefined)
            setFieldValue("customDate", undefined)
          }
        }

        const handleBranchSelect = (name: string, options: Meta | Meta[]) => {
          const branchValues = Array.isArray(options)
            ? options.map(option => option.value) 
            : [options.value];
        
          setFieldValue(name, branchValues);
        };

          return (
            <Form>
              <Row gutter={[20, 0]}>
                <Col lg={8} xs={24} sm={12}>
                  <SelectInput
                    options={reportOptions}
                    placeholder="Report Name"
                    name="reportName"
                  />
                </Col>
               {values.reportName !== ReportNameType.OUTSTANDING && values?.reportName !== ReportNameType.BOOK_DEBT && ( <Col lg={8} xs={24} sm={12}>
                  <SelectInput
                    options={reportDateOptions}
                    placeholder="Select Date"
                    name="date"
                  />
                </Col> )}
                {(values.reportName === ReportNameType.OUTSTANDING || values?.reportName === ReportNameType.BOOK_DEBT) && (
                  <Col xs={24} sm={12} lg={8}>
                    <DatePickerField name="asOnDate" setFieldValue={setFieldValue} format="DD/MM/YYYY"/>
                    </Col>
                )}
                {values?.date === ReportDate.CUSTOM_DATE &&
                  (!(values?.reportName === ReportNameType.OUTSTANDING || values?.reportName === ReportNameType.BOOK_DEBT)) && (
                    <Col xs={24} sm={12} lg={8}>
                       <DatePickerField type="rangePicker" maxMonths={4} name="customDate" setFieldValue={setFieldValue} format="DD/MM/YYYY" onChange={handleCustomDateRange}/>
                    </Col>
                  )}
                <Col xs={24} sm={12} lg={8}>
                  <SelectInput
                    name="branch"
                    options={branches}
                    showSearch
                    {...(values?.reportName &&
                      multiBranchReportNames.includes(
                        values?.reportName as ReportNameType
                      ) && { mode: "multiple" })}
                    handleSearch={handleBranchSearch}
                    placeholder="Select Branch"
                    onChange={handleBranchSelect}
                    onScroll={handleBranchScroll}
                    loading={branchesLoading}
                  />
                </Col>
                <Col lg={8} xs={24} sm={12}>
                  <SelectInput
                    name="loanType"
                    options={loanTypesOptions}
                    showSearch
                    placeholder="Select Loan Type"
                  />
                </Col>
                <Col lg={8} xs={24} sm={12}>
                  <SelectInput
                    name="organisation"
                    options={organizations}
                    placeholder="Select Preferred Channels"
                  />
                </Col>
                {values?.reportName === ReportNameType.BOOK_DEBT && 
                <Col lg={8} xs={24} sm={12}>
                  <SelectInput
                    name="hypothecator"
                    options={hypothecators}
                    placeholder="Select Hypothecators"
                  />
                </Col>
                }
                <Col lg={8} xs={24} sm={12}>
                  <>
                    {!reportData?.connected ? (
                      <Button
                        className="btn-primary report-btn"
                        htmlType="submit"
                        loading={loading}
                        disabled={!isValid || !dirty || reportData?.connected}
                        onClick={() => handleSubmit(values)}
                      >
                        {`${
                          reportData?.connected
                            ? "Download in Progress"
                            : "  GENERATE & DOWNLOAD REPORT"
                        }`}
                      </Button>
                    ) : (
                      <Progress
                        className="custom-progress mt-5"
                        strokeLinecap="butt"
                        percent={Math.floor(reportData?.percentage ?? 0)}
                      />
                    )}
                  </>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default Reports;
