import { DocumentType } from "../../enums/documentType.type";
import { Document } from "../../models/document.model";
import { LoanDocument } from "../../models/loanDocument";

export const filterModLoanDocuments = (loanDocuments?: LoanDocument[]) => {
    if (!loanDocuments) return []
    return loanDocuments.filter((doc) => {
      const documentType = doc.documentType;
      return (
        documentType &&
        ![
          DocumentType.EC_DOCUMENT,
          DocumentType.MOD_DOCUMENT,
          DocumentType.OTHER_SECURITY,
          DocumentType.RELEASE_LETTER,
          DocumentType.PROPERTY_DOCUMENT,
          DocumentType.LOAN_AGREEMENT,
          DocumentType.PDC
        ].includes(documentType)
      );
    });
  };

  export const getModLoanDocuments = (loanDocuments: Document[]) => {
    return loanDocuments.filter((doc) => {
      const documentType = doc.documentType;
      return (
        documentType &&
        [
          DocumentType.EC_DOCUMENT,
          DocumentType.MOD_DOCUMENT,
          DocumentType.OTHER_SECURITY,
          DocumentType.RELEASE_LETTER,
          DocumentType.PROPERTY_DOCUMENT,
          DocumentType.LOAN_AGREEMENT,
          DocumentType.PDC
        ].includes(documentType)
      );
    });
  };