import { REPAYMENTS } from "./../../routes/routeConstants/appRoutes";
import { ReportNameType } from "../../enums/reportName.type";
import { User } from "../../enums/user.type";

const reportOptions = [
  {
    label: "Demand",
    value: ReportNameType.DEMAND,
  },
  {
    label: "Repayment",
    value: ReportNameType.REPAYMENT,
  },
  {
    label: "Overdue",
    value: ReportNameType.OVERDUE,
  },
  {
    label: "Disbursement",
    value: ReportNameType.DISBURSEMENT,
  },
  {
    label: "Outstanding",
    value: ReportNameType.OUTSTANDING,
  },

  {
    label: "Payment",
    value: ReportNameType.PAYMENT,
  },
  {
    label: "Insurance",
    value: ReportNameType.INSURANCE,
  },
  {
    label: "Book Debt",
    value: ReportNameType.BOOK_DEBT
  }
];

export default reportOptions;
