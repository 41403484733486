import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import InputField from "../../../../../shared/components/InputField";
import { InputType } from "../../../../../enums/input.type";
import { Button } from "antd";
import "../loanRepaymentSchedule.scss";
import { Loan } from "../../../../../models/loan.model";
import { PreCloseLoan as PreCloseLoanModel } from "../../../../../models/preCloseLoan.model";
import { LoanService } from "../../../../../services/Loan/loan.service";
import { preCloseValidation } from "./preCloseValidation";

interface PreCloseProps {
  loan: Loan;
  onClose: () => void;
}

function PreCloseLoan(props: PreCloseProps) {
  const { updatePreCloseLoan } = LoanService();
  const { loan, onClose } = props;

  const [formValues, setFormValues] = useState<PreCloseLoanModel>(
    new PreCloseLoanModel()
  );

  const onSubmit = async (values: PreCloseLoanModel) => {
    const preLoan = await updatePreCloseLoan(
      String(loan?.id),
      values?.amountCollected ?? 0
    );
    if (preLoan) onClose();
  };

  const initializeFormValue = () => {
    setFormValues({
      id: loan?.id,
      customerName: loan?.customer?.getName(),
      outstandingAmount: Number(loan?.preClosedAmount),
      numberOfpendingDues: Number(loan?.numberOfpendingDues),
    });
  };

  useEffect(() => {
    initializeFormValue();
  }, []);
  
  return (
    <div>
      <Formik
        initialValues={formValues}
        validationSchema={preCloseValidation}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ isSubmitting, values, isValid, dirty }) => (
          <>
            <div className="pre-close-loan">
              <div className="pre-close-loan__content">
                <Form className="ant-form-vertical">
                  <InputField
                    type={InputType.NUMBER}
                    name="id"
                    placeholder=""
                    label="Loan ID"
                    disabled
                  />
                  <InputField
                    type={InputType.TEXT}
                    name={`customerName`}
                    placeholder=""
                    label="Customer Name"
                    disabled
                  />
                  <InputField
                    type={InputType.NUMBER}
                    name="outstandingAmount"
                    placeholder=""
                    label="Outstanding Amount"
                    disabled
                  />
                  <InputField
                    type={InputType.NUMBER}
                    name="numberOfpendingDues"
                    placeholder=""
                    label="No of Dues Pending"
                    disabled
                  />
                  <InputField
                    type={InputType.TEXT}
                    name="amountCollected"
                    placeholder="Enter Amount Collected"
                    label="Amount Collected"
                  />
                  <div className="pre-close-loan__content__footer drawer-footer">
                    <Button
                      className="drawer-footer__cancel"
                      htmlType="reset"
                      onClick={onClose}
                      type="link"
                    >
                      Cancel
                    </Button>
                    <Button
                      loading={isSubmitting}
                      className="drawer-footer__submit mr-5"
                      htmlType="submit"
                      disabled={!isValid || !dirty}
                    >
                      SEND RECEIPT
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
}
export default PreCloseLoan;
