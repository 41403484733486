import React, { useEffect, useState } from "react";
import TabExtraContent from "../../../shared/components/TabExtraContent";
import useQueryParams from "../../../shared/hooks/useQueryParams";
import { Loan, LoanParams } from "../../../models/loan.model";
import useDrawer from "../../../shared/hooks/useDrawer";
import './rejectedLoans.scss'
import FilterButtons from "../../../shared/components/FilterButtons";
import { Filters } from "../../../models/filters.model";
import { LoanService } from "../../../services/Loan/loan.service";
import { currencyFormatter } from "../../../shared/utils/formatter";
import { Drawer, Table, TableColumnsType, TableProps } from "antd";
import { pipelineColumns } from "../PipelineWrapper/pipelineColumns";
import useTableConfig from "../../../shared/hooks/useTableConfig";
import useSorting from "../../../shared/hooks/useSorting";
import { SorterResult } from "antd/lib/table/interface";
import { generatePath, useNavigate } from "react-router-dom";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import LoanFilters from "../../Loans/LoanFilters";
import ColumnOptions from "../../../shared/components/ColumnOptions";
import { AllLoanTypes } from "../../../enums/pipelineLoanTypes";
import { TableConfig } from "../../../enums/tableConfig.type";
import LoanAmountDetails from "../../../shared/components/LoanDetails";

type Props = {
  activeTab: string;
};

const sortBy = {
  code: "code",
  name: "customer_name",
  centerName: "center_name",
  createdByFirstName: "created_by.firstname",
  loanTypeName: "loan_type_name",
  status: "status",
  organizationName: "organisation_name",
};

const OfflineLoans = (props: Props) => {
  const { getLoans, loading } = LoanService();
  const handleUpdateParams = () => updateParams(getParams());
  const { activeTab } = props

  const [filters, setFilters] = useState<Filters>(new Filters());
  const [defaultColumns, setDefaultColumns] =
    useState<TableColumnsType<Loan>>(pipelineColumns);
  const [loans, setLoans] = useState<Loan[]>([]);
  const { columnConfig, setColumnConfig } = useTableConfig();
  const [totalLoans, setTotalLoans] = useState(1);
  const [filteredColumns, setFilteredColumns] = useState<string[]>(
    columnConfig?.loans ?? []
  );
  const { updateSortData } = useSorting<Loan>({ sortBy });
  const navigate = useNavigate();

  const {
    params: rejectedParams,
    handleSearch,
    updateParams,
    getParams,
  } = useQueryParams<LoanParams>({
    params: new LoanParams(),
  });

  const {
    visible: rejectedFilterVisible,
    toggleVisibility: toggleRejectedFilterVisibility,
  } = useDrawer({});

  const {
    visible: rejectedSettingsVisible,
    toggleVisibility: toggleRejectedSettingsVisibility,
  } = useDrawer({});

  const handleChange: TableProps<Loan>["onChange"] = (
    pagination,
    _,
    sorter
  ) => {
    const sortData = updateSortData(sorter as SorterResult<Loan>);
    updateParams({
      ...rejectedParams,
      ...sortData,
      page: pagination?.current,
    });
  };

 
  useEffect(() => {
    const fetchLoans = async () => {
      const updatedParams = {
        ...rejectedParams,
        searchText: rejectedParams.searchText,
        rejected: true,
      };
      const loanData = await getLoans(updatedParams);
      if (loanData?.loans) setLoans(loanData?.loans);
      if (loanData?.meta) {
        setTotalLoans(loanData?.meta?.totalCount ?? 1);
        loanData?.meta?.filters && setFilters(loanData?.meta?.filters);
      }
    };
    fetchLoans();
  }, [rejectedParams, rejectedParams.searchText]);


  useEffect(() => {
    setColumnConfig(TableConfig.LOANS, filteredColumns);
  }, [filteredColumns]);

  return (
    <div className="rejected-loans">
      {!loading && (
        <FilterButtons filters={filters} onFilter={handleUpdateParams} />
      )}
      <LoanAmountDetails params={rejectedParams} type={AllLoanTypes.REJECTED}/>
      <Table
        scroll={{ x: true }}
        className="rejected-loans-table"
        columns={defaultColumns.filter(
          (col) => !filteredColumns.includes(col.title as string)
        )}
        dataSource={loans}
        loading={loading}
        onChange={handleChange}
        rowKey={(record: any) => record?.id}
        onRow={(record) => ({
          onClick: () => {
            navigate(
              generatePath(AppRoutes.LOAN_PIPELINE_DETAIL, {
                loanId: record?.id,
              }),
              {
                state: {
                  disbursedDate: record?.recentLoanStatus?.assignedAt,
                },
              }
            );
          },
        })}
        pagination={{
          current: rejectedParams?.page,
          hideOnSinglePage: true,
          total: totalLoans,
        }}
      />
      <div className="rejected-extra-content">
        <TabExtraContent
          searchProps={{
            onSearch: handleSearch,
            placeholder: "Search Loan",
            value: rejectedParams.searchText,
          }}
          filterClick={toggleRejectedFilterVisibility}
          settingsClick={toggleRejectedSettingsVisibility}
        />
      </div>
      {rejectedFilterVisible && (
        <Drawer
          placement="right"
          onClose={() => toggleRejectedFilterVisibility(false)}
          visible={rejectedFilterVisible}
          width={"70vw"}
          title="Filters"
          closable
          destroyOnClose
        >
          <LoanFilters
            onClose={() => toggleRejectedFilterVisibility(false)}
            onFilter={updateParams}
          />
        </Drawer>
      )}

      {rejectedSettingsVisible && (
        <Drawer
          placement="right"
          onClose={() => toggleRejectedSettingsVisibility(false)}
          visible={rejectedSettingsVisible}
          width={500}
          title="Column Options"
          destroyOnClose
        >
          <ColumnOptions
            defaultColumns={defaultColumns}
            filteredColumns={filteredColumns}
            setFilteredColumns={setFilteredColumns}
            onClose={() => toggleRejectedSettingsVisibility()}
            setDefaultColumns={setDefaultColumns}
          />
        </Drawer>
      )}
    </div>
  );
};

export default OfflineLoans;
