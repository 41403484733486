import * as Yup from "yup";

export const loanTypeConditionFormValidation = Yup.object().shape({
  minimumAmount: Yup.string().required().label("Minimum Amount "),
  maximumAmount: Yup.string().required().label("Maximum Amount "),
  minimumTenure: Yup.string().required().label("Minimum Tenure "),
  maximumTenure: Yup.string().required().label("Maximum Tenure "),
  interest: Yup.string().required().label("Interest "),

  regionIds: Yup.string().required().label("Region Name "),
  branchIds: Yup.string().required().label("Branch Name "),
});
