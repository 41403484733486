import { Button, Col, Row } from "antd";
import { Formik, Form } from "formik";
import React from "react";
import { FileAttachment } from "../../../models/fileAttachment.model";

import { User } from "../../../models/user.model";
import { FileService } from "../../../services/FileService/file.service";
import { LoanService } from "../../../services/Loan/loan.service";
import AttachmentUpload from "../../../shared/components/AttachmentUpload";

interface PipelineLoanUploadProps {
  handleModalVisibility: () => void;
}

const PipelineLoanUpload = (props: PipelineLoanUploadProps) => {
  const { handleModalVisibility } = props;
  const { importLoan, loading } = LoanService();
  const handleSubmit = async (values: FileAttachment) => {
    const file = await importLoan(values);
    file && handleModalVisibility();
  };
  return (
    <div>
      <Row>
        <Col span={24}>
          {" "}
          <Formik initialValues={new FileAttachment()} onSubmit={handleSubmit}>
            {({ values, handleSubmit, setFieldValue }) => {
              return (
                <Form className="ant-form-vertical" onSubmit={handleSubmit}>
                  <div className="mt-5">
                    {" "}
                    <AttachmentUpload
                      accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      name="attachmentId"
                      placeholder="Upload File"
                      setFieldValue={setFieldValue}
                    />
                  </div>
                  <div className="text-right mt-5">
                    <Button
                      disabled={!values.attachmentId}
                      className="btn-tertiary"
                      htmlType="submit"
                      loading={loading}
                    >
                      IMPORT
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};

export default PipelineLoanUpload;
