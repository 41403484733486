import { alias, object, primitive, serializable } from "serializr";
import { Role } from "./role.model";

export class ModifiedBy {
  @serializable(alias("firstname", primitive()))
  firstName?: string;
  @serializable(alias("lastname", primitive()))
  lastName?: string;

  @serializable(alias("role", object(Role)))
  role?: Role;
  getName = () => `${this.firstName ?? ""} ${this.lastName ?? ""}`.trim();
}
