import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Drawer,
  Steps,
  Table,
  TableColumnsType,
  TableProps,
  Tabs,
} from "antd";
import { Row, Col, Divider } from "antd";
import "./loanType.scss";
import { Loan, LoanParams } from "../../../models/loan.model";
import { ToggleDrawer } from "../../../shared/types/toggleDrawer";
import { loanTypesColumns } from "./loanTypesColumns";
import { generatePath, Navigate, useNavigate } from "react-router-dom";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import LoanTypeForm from "./LoanTypeDetailsForm";
import { MetaService } from "../../../services/Meta/meta.service";
import { Meta } from "../../../models/meta.modal";
import { LoanTypeModel } from "../../../models/loanType.model";
import { QueryParams } from "../../../models/query.params.model";
import { StepStatus } from "../../../enums/stepStatus.enum";
import LoanTypeDetailForm from "./LoanTypeDetailsForm";
import LoanTypeConditionForm from "./LoanTypeConditionForm";
import LoanTypeConfigurationForm from "./LoanTypeConfigurationForm";
import ColumnOptions from "../../../shared/components/ColumnOptions";
import useTableConfig from "../../../shared/hooks/useTableConfig";
import { TableConfig } from "../../../enums/tableConfig.type";
import TabExtraContent from "../../../shared/components/TabExtraContent";
import useQueryParams from "../../../shared/hooks/useQueryParams";
import './loanType.scss'
import { SorterResult } from "antd/lib/table/interface";
import useSorting from "../../../shared/hooks/useSorting";

interface LoanTypeProps {
  params: LoanParams;
  addFormVisible: boolean;
  filtersVisible: boolean;
  settingsVisible: boolean;
  toggleAddForm: ToggleDrawer;
  toggleFiltersVisibility: ToggleDrawer;
  toggleSettingsVisibility: ToggleDrawer;
}

const sortBy = {}
function LoanTypes(props: LoanTypeProps) {
  const [currentStep, setCurrentStep] = useState(1);
  const [loanTypeId, setLoanTypeId] = useState("");

  const toggleLoanType = (loanId: string) => setLoanTypeId(loanId);

  const {
    addFormVisible,
    filtersVisible,
    settingsVisible,
    params,
    toggleAddForm,
    toggleFiltersVisibility,
    toggleSettingsVisibility,
  } = props;
  const navigate = useNavigate();
  const {
    getLoanTypesListing,
    getLoanStatus,
    loading: metaLoading,
  } = MetaService();
  const [loanTypesOptions, setLoanTypeOptions] = useState<LoanTypeModel[]>([]);

  const [defaultColumns, setDefaultColumns] =
    useState<TableColumnsType<LoanTypeModel>>(loanTypesColumns);
  const { columnConfig, setColumnConfig } = useTableConfig();
  const [filteredColumns, setFilteredColumns] = useState<string[]>(
    columnConfig?.loanTypes ?? []
  );
  const handleRowChange = (record: LoanTypeModel, rowIndex?: number) => ({
    onClick: () =>
      navigate(
        generatePath(AppRoutes.LOAN_TYPE_DETAIL, {
          loanTypeId: String(record?.loanTypeId),
        })
      ),
  });
  const fetchLoanTypes = async (params: QueryParams) => {
    const data = await getLoanTypesListing(params);

    data && setLoanTypeOptions(data);
  };

  const { params: loanTypeParams, updateParams, getParams, handleSearch: handleLoanTypeSearch} = useQueryParams<LoanParams>({
    params: new LoanParams(),
  });

  const toggleSteps = (status: string) =>
    setCurrentStep(
      StepStatus.NEXT === status ? currentStep + 1 : currentStep - 1
    );

    const { updateSortData } = useSorting({ sortBy });

    const handleChange: TableProps<LoanTypeModel>["onChange"] = (
      pagination,
      _,
      sorter
    ) => {
      const { sortBy, sortDirection } = updateSortData(
        sorter as SorterResult<LoanTypeModel>
      );
      updateParams({
        page: pagination?.current,
        sortBy,
        sortDirection,
      });
    };

  useEffect(() => {
    const updateParams = loanTypeParams?.searchText
    ? { page: loanTypeParams.page, searchText: loanTypeParams?.searchText }
      : loanTypeParams;
    fetchLoanTypes(updateParams);
    setLoanTypeId("");
  }, [loanTypeParams, loanTypeParams.searchText, params]);

  useEffect(() => {
    setColumnConfig(TableConfig.LOAN_TYPES, filteredColumns);
  }, [filteredColumns]);

  const steps = [
    {
      step: 1,
      component: (
        <LoanTypeDetailForm
          toggleStep={toggleSteps}
          currentStep={currentStep}
          loanTypeId={loanTypeId}
          toggleAddForm={toggleAddForm}
          toggleLoanType={toggleLoanType}
        />
      ),
    },
    {
      step: 2,
      component: (
        <LoanTypeConditionForm
          loanTypeId={loanTypeId}
          currentStep={currentStep}
          toggleStep={toggleSteps}
        />
      ),
    },
    {
      step: 3,
      component: (
        <LoanTypeConfigurationForm
          currentStep={currentStep}
          loanTypeId={loanTypeId}
          toggleStep={toggleSteps}
          toggleAddForm={() => {
            fetchLoanTypes(params);
            toggleAddForm();
          }}
        />
      ),
    },
  ];

  return (
    <div className="loan-types">
      <div className="loan-types-extra-content">
      <TabExtraContent
      searchProps={{
        onSearch: handleLoanTypeSearch,
        placeholder: "Search Loan Type",
        value: loanTypeParams.searchText
      }}
      />
      </div>
      <Table
        columns={defaultColumns.filter(
          (col) => !filteredColumns.includes(col.title as string)
        )}
        dataSource={loanTypesOptions}
        onRow={handleRowChange}
        loading={metaLoading}
        onChange={handleChange}
      />
      <Drawer
        placement="right"
        onClose={() => {
          toggleAddForm(false);
          setCurrentStep(1);
        }}
        visible={addFormVisible}
        width="1100"
        title="New Loan Type"
        destroyOnClose
      >
        {steps.map((item) => (
          <div
            className={`steps-content ${item.step !== currentStep && "hidden"}`}
          >
            {item.component}
          </div>
        ))}
      </Drawer>
      <Drawer
        placement="right"
        onClose={() => toggleSettingsVisibility(false)}
        visible={settingsVisible}
        width="500"
        title="Column Options"
        destroyOnClose
      >
        <ColumnOptions
          defaultColumns={defaultColumns}
          filteredColumns={filteredColumns}
          setFilteredColumns={setFilteredColumns}
          onClose={() => toggleSettingsVisibility(false)}
          setDefaultColumns={setDefaultColumns}
        />
      </Drawer>
    </div>
  );
}

export default LoanTypes;
