import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toTitleCase } from "../../../../shared/utils/titleCaseConverter";
import Back from "../../../../shared/components/Back";
import { Row } from "antd";
import PaymentStatusFactor from "./PaymentStatus";
import { keyFactorRoutes } from "../../../../enums/keyFactors.type";
import MonthlyEMI from "./MonthlyEMI";
import TotalWriteOff from "./TotalWriteOff";
import OverDue from "./OD";
import CreditAge from "./CreditAge";
import TotalSettlements from "./TotalSettlements";
import AccountSummary from "./AccountSummary";
import { getLastSegment } from "../../../../shared/utils/getLastSegment";
import { removeHyphen } from "../../../../shared/utils/removeUnderscore";
import { CrifReport } from "../../../../models/creditReport.model";
import { LoanService } from "../../../../services/Loan/loan.service";
import { Loan } from "../../../../models/loan.model";

const IndividualKeyFactor = () => {
  const location = useLocation();
  const keyFactor: keyFactorRoutes = getLastSegment(location.pathname) as keyFactorRoutes;
  const [crifData, setCrifData] = useState<CrifReport>();
  const [loanLoaded, setLoanLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const { getCrifReport, getLoan } = LoanService()
  const [loan, setLoan] = useState<Loan>();
  const { loanId } = useParams()

  const getLoanDetails = async () => {
    setLoading(true)
    const details = await getLoan(Number(loanId))
    setLoan(details)
    setLoanLoaded(true);
  }

  useEffect(() => {
      getLoanDetails();
  }, [loanId]);

  useEffect(() => {
    getCrifData()
  }, [loan, loanLoaded]);

  const getCrifData = async () => {
  if (loan && loanLoaded) {
    const crifData = await getCrifReport({ customerId: Number(loan?.customer.id), loanId: Number(loan?.id), initiate: false });
    setCrifData(crifData)
    setLoading(false)
  }
}
  const keyFactorContent = {
    [keyFactorRoutes.PAYMENT_STATUS]: (
      <div>
        <PaymentStatusFactor loading={loading} data={crifData} />
      </div>
    ),
    [keyFactorRoutes.MONTHLY_EMI]: <MonthlyEMI loading={loading} data={crifData?.monthlyEmi}/>,
    [keyFactorRoutes.TOTAL_WRITE_OFF]: <TotalWriteOff loading={loading} data={crifData?.writtenOff}/>,
    [keyFactorRoutes.OD]: <OverDue loading={loading} data={crifData?.overdueAccounts}/>,
    [keyFactorRoutes.CREDIT_AGE]: <CreditAge loading={loading} data={crifData}/>,
    [keyFactorRoutes.TOTAL_SETTLEMENTS]: <TotalSettlements loading={loading} data={crifData?.settledAccounts}/>,
    [keyFactorRoutes.ACCOUNT_SUMMARY]: <AccountSummary loading={loading} data={crifData}/>,
  };

  return (
    <div>
      <Back />
      <Row className="mt-5 mb-5">
        <h3 className="block__value">
          {toTitleCase(removeHyphen(keyFactor))}
        </h3>
      </Row>
      {keyFactorContent[keyFactor]}
    </div>
  );
};

export default IndividualKeyFactor;
