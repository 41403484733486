import { LoanDocument } from './loanDocument';
import { alias, list, object, primitive, serializable } from "serializr";


export class CustomerDocument {
  @serializable(alias("id", primitive()))
  id?: number;
  
  @serializable(alias("code", primitive()))
  code?: string;
  
  @serializable(alias("loan_documents", list(object(LoanDocument))))
  loanDocuments: LoanDocument[] = [];

}
