import { modLoansTypes } from "../../enums/modLoans.type";
import { Meta } from "../../models/meta.modal";

const modStatusOptions:Meta[] = [
    {
      label: "Secured - MOD",
      value: modLoansTypes.MOD_SECURED
    },
    {
      label: "Not - Secured",
      value: modLoansTypes.UNSECURED
    },
    {
      label: "Equitable Mortgage",
      value: modLoansTypes.EQUAITABLE_MORTAGE
    }
  ]

  export default modStatusOptions