import { alias, object, primitive, serializable } from "serializr";

class AmountCollection {
  @serializable(alias("total", primitive()))
  total?: number;
  @serializable(alias("principal", primitive()))
  principal?: number;
  @serializable(alias("interest", primitive()))
  interest?: number;
}

export class RepaymentChart {
  @serializable(alias("total", primitive()))
  total?: number;
  @serializable(alias("principal", primitive()))
  principal?: number;
  @serializable(alias("interest", primitive()))
  interest?: number;
  @serializable(alias("collected", object(AmountCollection)))
  collected?: AmountCollection;
  @serializable(alias("yet_to_collect", object(AmountCollection)))
  yetToCollect?: AmountCollection;
}
