import React, { useEffect, useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { Button } from "antd";
import { LoanTypeConditionModel } from "../../../../../../models/loanType.model";
import InputField from "../../../../../../shared/components/InputField";
import { InputType } from "../../../../../../enums/input.type";
import SelectInput from "../../../../../../shared/components/SelectInput";
import useBranch from "../../../../../../shared/hooks/useBranch";
import { MetaService } from "../../../../../../services/Meta/meta.service";
import { Meta } from "../../../../../../models/meta.modal";
import { LoanService } from "../../../../../../services/Loan/loan.service";
import { QueryParams } from "../../../../../../models/query.params.model";
import { BranchesService } from "../../../../../../services/Branches/branches.service";

interface LoanTypeConditionIndividualFormProps {
  formValues: LoanTypeConditionModel;
  loanTypeId: string;
  toogleLoanConditionForm: () => void;
  onSuccess: (updatedLoan: LoanTypeConditionModel) => void;
}
function LoanTypeConditionIndividualForm(
  props: LoanTypeConditionIndividualFormProps
) {
  const { branches } = useBranch({ allBranch: true });
  const { updateLoanTypeCondition } = LoanService();
  const { formValues, loanTypeId, toogleLoanConditionForm, onSuccess } = props;
  const handleSubmit = async (
    values: LoanTypeConditionModel,
    helpers: FormikHelpers<any>
  ) => {
    const conditionUpdate = await updateLoanTypeCondition(values, loanTypeId);
    if (conditionUpdate) {
      onSuccess(conditionUpdate);
      toogleLoanConditionForm();
    }
  };

  const { loading: metaLoading, getRegions } = MetaService();
  const [regions, setRegions] = useState<Meta[]>([]);

  useEffect(() => {
    const populateMeta = async () => {
      const regions = await getRegions();

      if (regions) setRegions(regions);
    };

    populateMeta();
  }, []);

  return (
    <div className="loan-type-condition-form">
      <Formik initialValues={formValues} onSubmit={handleSubmit}>
        {(props) => (
          <Form className="ant-form-vertical center-form">
            <InputField
              type={InputType.NUMBER}
              name="minimumAmount"
              placeholder="Enter Minimum Amount"
              label="Minimum Amount"
            />
            <InputField
              type={InputType.NUMBER}
              name="maximumAmount"
              placeholder="Enter Maximum Amount"
              label="Maximum Amount"
            />
            <InputField
              type={InputType.NUMBER}
              name="minimumTenure"
              placeholder="Enter Minimum Tenure"
              label="Minimum Tenure"
            />
            <InputField
              type={InputType.NUMBER}
              name="maximumTenure"
              placeholder="Enter Maxiumum Tenure"
              label="Maxiumum Tenure"
            />
            <InputField
              type={InputType.NUMBER}
              name="interest"
              placeholder="Enter Interest"
              label="Interest"
            />

            <SelectInput
              name="branchIds"
              options={branches}
              mode="multiple"
              placeholder="Select Branch"
              label="Branch"
              IsSelectAll={true}
            />
            <SelectInput
              name="regionIds"
              options={regions}
              mode="multiple"
              placeholder="Select  Region"
              label="Region"
            />
            <div className="drawer-footer">
              <Button
                htmlType="reset"
                type="link"
                className="drawer-footer__cancel"
              >
                Cancel
              </Button>
              <Button htmlType="submit" className="drawer-footer__submit">
                Update
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
export default LoanTypeConditionIndividualForm;
