import { alias, primitive, serializable } from "serializr";

export class LoanOwnedEntity{
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("owner_type", primitive()))
  ownerType?: string;

  @serializable(alias("owner_id", primitive()))
  ownerId?: number;

  @serializable(alias("entity_category", primitive()))
  entityCategory?: string;

  @serializable(alias("entity_type", primitive()))
  entityType?: string;

  @serializable(alias("entity_worth", primitive()))
  entityWorth?: number;

  @serializable(alias("number_of_entities", primitive()))
  entityNumber?: number;

  @serializable(alias("code", primitive()))
  code?: string;
}
