import React, { useState, useEffect } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { Spin } from "antd";

interface MapsProps {
  centerValue: CenterProps;
  setFieldValue?: (field: string, value: any) => void;
  latName: string;
  lngName: string;
  height: string;
  width: string;
  disabled?: boolean;
}
interface CenterProps {
  lat: number;
  lng: number;
}

const options = {
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
};

const Maps: React.FC<MapsProps> = (props) => {
  const {
    setFieldValue,
    centerValue,
    latName,
    lngName,
    height,
    width,
    disabled,
  } = props;
  const containerStyle = {
    width,
    height,
  };

  const [center, setCenter] = useState<CenterProps>(centerValue);
  const [markerPosition, setMarkerPosition] = useState(centerValue);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
  });

  useEffect(() => {
    if (!center?.lat && !center?.lng && !disabled) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        setMarkerPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        if (setFieldValue) {
          setFieldValue(latName, position.coords.latitude);
          setFieldValue(lngName, position.coords.longitude);
        }
      });
    }
  }, []);

  const handleDragEnd = (e: any) => {
    setMarkerPosition({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
    if (setFieldValue) {
      setFieldValue(latName, e.latLng.lat());
      setFieldValue(lngName, e.latLng.lng());
    }
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!center?.lat || !center?.lng || !isLoaded) return <Spin />;

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      zoom={15}
      center={center}
      options={options}
    >
      <Marker
        position={markerPosition}
        draggable={!disabled && true}
        onDragEnd={handleDragEnd}
      />
    </GoogleMap>
  );
};

export default Maps;
