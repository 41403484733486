import { alias, list, object, primitive, serializable } from "serializr";

import { Address } from "./address.model";
import { QueryParams } from "./query.params.model";

export class BranchParams extends QueryParams {
  @serializable(alias("responsible_employee_id", list(primitive())))
  responsibleEmployee?: number[] = [];

  @serializable(alias("region_id", list(primitive())))
  regionId?: number[] = [];

  @serializable(alias("is_active", list(primitive())))
  isActive?: string[] = [];
}
export class Branch {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("code", primitive()))
  code?: string;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("organisation_id", primitive()))
  organisationId?: number;

  @serializable(alias("organisation_code", primitive()))
  organizationCode?: string;

  @serializable(alias("organisation_name", primitive()))
  organizationName?: string;

  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("isd_code", primitive()))
  isdCode = "+91";

  @serializable(alias("mobile_number", primitive()))
  mobileNumber?: string;

  @serializable(alias("responsible_employee_id", primitive()))
  responsibleEmployeeId?: number;

  @serializable(alias("responsible_employee_firstname", primitive()))
  responsibleEmployeeFirstName?: string;

  @serializable(alias("responsible_employee_lastname", primitive()))
  responsibleEmployeeLastName?: string;

  @serializable(alias("responsible_employee_profile_pic_url", primitive()))
  responsibleEmployeeProfilePicUrl?: string;

  @serializable(alias("region_id", primitive()))
  regionId?: number;

  @serializable(alias("region_code", primitive()))
  regionCode?: string;

  @serializable(alias("region_name", primitive()))
  regionName?: string;

  @serializable(alias("is_active", primitive()))
  isActive?: boolean;

  @serializable(alias("year", primitive()))
  year?: string;
  @serializable(alias("month", primitive()))
  month?: string;
  @serializable(alias("target", primitive()))
  target?: number;
  @serializable(alias("targetable_type", primitive()))
  targetableType?: string;
  @serializable(alias("max_due_collection_per_day", primitive()))
  maxDueCollectionPerDay?: number;

  @serializable(alias("due_collection_dates", list(primitive())))
  dueCollectionDates?: number[] = [];

  @serializable(alias("address", object(Address)))
  address = new Address();

  getPhoneNumber = () => {
    const isdCode = this.isdCode ?? "";
    const mobileNumber = this.mobileNumber ?? "";

    return isdCode + " " + mobileNumber;
  };
}
