import React from "react";
import "./creditEnquiries.scss";
import { ColumnProps } from "antd/lib/table";
import { currencyFormatter, dateTimeFormatter } from "../../../shared/utils/formatter";
import { DateFormat } from "../../../enums/dateFormat.type";
import Table from "../../../shared/components/Table";
import { CrifReport } from "../../../models/creditReport.model";
import { CreditEnquiries } from "../../../models/creditEnquiries.model";

interface CreditEnquiriesProps {
  data?: CrifReport;
}

const CreditEnquiriesComponent = (props: CreditEnquiriesProps) => {
  const { data } = props;

  const columns: ColumnProps<CreditEnquiries>[] = [
    {
      title: "Bank Name",
      dataIndex: "bankName",
      width: "25%",
      render: (_, record) => {
        return record.memberName;
      },
    },
    {
      title: "Loan Type",
      dataIndex: "purpose",
      className: "loan-type",
      width: "25%",
      render: (_, record) => {
        return record.purpose;
      },
    },
    {
      title: "Enquiry Date",
      dataIndex: "inquiryDate",
      width: "25%",
      className: "enquiry-date",
    },
    {
      title: "Enquiry Amount",
      dataIndex: "amount",
      width: "25%",
      className: "enquiry-amount",
      render: (_, record) => {
        return `₹${record?.amount}`;
      },
    },
  ];
  return (
    <div className="crif-credit-enquiries">
      <div className="card">
        <Table columns={columns} dataSource={data?.creditEnquiries} />
      </div>
    </div>
  );
};

export default CreditEnquiriesComponent;
