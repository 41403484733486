import { alias, primitive, serializable } from "serializr";

export class LoanAmountDeductions {
    @serializable(alias('id', primitive()))
    id?: number

    @serializable(alias('amount', primitive()))
    amount?: number

    @serializable(alias('name', primitive()))
    name?: string
}
