import { ColumnProps } from "antd/lib/table";
import React from "react";
import { DateFormat } from "../../../../enums/dateFormat.type";
import { LoanRepayamentSchedule } from "../../../../models/loanRepaymentSchedule";
import { dateTimeFormatter } from "../../../../shared/utils/formatter";

import { removeUnderscore } from "../../../../shared/utils/removeUnderscore";
import { roundOffValue } from "../../../../shared/utils/roundOffValue";
import { RepaymentRemarksArray } from "../../../../shared/Constant/repaymentRemarks";
import { RepaymentRemarks } from "../../../../enums/repaymentRemarks";

export const LoanRepayamentColumn: ColumnProps<LoanRepayamentSchedule>[] = [
  {
    title: "Repayment No",
    dataIndex: "repaymentNo",
  },
  {
    title: "Due Date",
    dataIndex: "dueDate",
    render: (dueDate) => dateTimeFormatter(dueDate ?? "", DateFormat.DATE),
  },
  {
    title: "Repayment Amount",
    dataIndex: "amount",
  },
  {
    title: "Interest",
    dataIndex: "interest",
    render: (interest) => roundOffValue(interest),
  },
  {
    title: "Principal",
    dataIndex: "principal",
  },
  {
    title: "Closing Principal",
    dataIndex: "closingPrincipal",
  },
  {
    title: "Pending Amount",
    dataIndex: "pendingAmount",
  },
  {
    title: "Paid Date",
    dataIndex: "paidDate",
    render: (paidDate) => dateTimeFormatter(paidDate ?? "", DateFormat.DATE),
  },
  {
    title: "Repayment remarks",
    dataIndex: "remarks",
    render: (remark) => {
      const className = "active-status--" + remark?.split("_")?.join("-");
      const getRepaymentRemark = (remark: string) => {
        if (RepaymentRemarksArray?.includes(remark)) return RepaymentRemarks.PARTIALLY_PAID;
        else return remark
      }
      return (
        <span className={` text-capitalize  ${className}`}>
          {removeUnderscore(getRepaymentRemark(remark))}
        </span>
      );
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    sorter: true,
    render: (_, record) => {
      const className =
        "active-status--" + record.status?.split("_")?.join("-");
      return (
        <span className={` text-capitalize  ${className}`}>
          {removeUnderscore(record?.status ?? "")}
        </span>
      );
    },
  },
];
