import React from "react";
import { ColumnProps } from "antd/lib/table";
import { LoanInstallmentSchedule } from "../../../models/loanInstallmentSchedule.model";
import { dateTimeFormatter } from "../../../shared/utils/formatter";
import { DateFormat } from "../../../enums/dateFormat.type";
import { removeUnderscore } from "../../../shared/utils/removeUnderscore";
import { roundOffValue } from "../../../shared/utils/roundOffValue";
import { LoanRepayamentSchedule } from "../../../models/loanRepaymentSchedule";

export const velichamInstallmentColumn: ColumnProps<LoanRepayamentSchedule>[] =
  [
    {
      title: "Repayment No",
      dataIndex: "repaymentNo",
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      render: (dueDate) => dateTimeFormatter(dueDate ?? "", DateFormat.DATE),
    },
    {
      title: "Repayment Amount",
      dataIndex: "amount",
    },
    {
      title: "Principal",
      dataIndex: "principal",
    },
    {
      title: "Interest",
      dataIndex: "interest",
      render: (interest) => roundOffValue(interest),
    },

    {
      title: "Closing Principal",
      dataIndex: "closingPrincipal",
    },
    {
      title: "Paid Date",
      dataIndex: "paidDate",
      render: (paidDate) => dateTimeFormatter(paidDate ?? "", DateFormat.DATE),
    },

    {
      title: "Repayment remarks",
      dataIndex: "remarks",
      render: (remark) => {
        const className = "active-status--" + remark?.split("_")?.join("-");
        return (
          <span className={` text-capitalize  ${className}`}>
            {removeUnderscore(remark ?? "")}
          </span>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",

      render: (_, record) => {
        const className =
          "active-status--" + record.status?.split("_")?.join("-");
        return (
          <span className={` text-capitalize  ${className}`}>
            {removeUnderscore(record?.status ?? "")}
          </span>
        );
      },
    },
  ];
