import { alias, primitive, serializable } from "serializr";

export class FamilyDetail {
  @serializable(alias("education_expense", primitive()))
  educationExpense?: number;

  @serializable(alias("electricity_expense", primitive()))
  electricityExpense?: number;

  @serializable(alias("house_rent_expense", primitive()))
  houseRentExpense?: number;

  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("total_monthly_income", primitive()))
  totalMonthlyIncome?: number;
  @serializable(alias("total_monthly_expense", primitive()))
  totalMonthlyExpense?: number;
  @serializable(alias("total_monthly_emi", primitive()))
  totalMonthlyEmi?: number;
  @serializable(alias("total_monthly_balance", primitive()))
  totalMonthlyBalance?: number;
  @serializable(alias("other_expenditure", primitive()))
  otherExpenditure?: number;
}
