import { alias, list, object, primitive, serializable } from "serializr";
import { QueryParams } from "./query.params.model";
import { Employee } from "../models/employee.model";
import { LoanParams } from "./loan.model";

export class CashOnHandParams extends LoanParams {
  @serializable(alias("branch_ids", list(primitive())))
  branchIds?: number[] = [];

  @serializable(alias("collection_dates", list(primitive())))
  collectionDates?: string[] = [];
}
class CashOnHandDetails {
  @serializable(alias("employees", list(object(Employee))))
  employees?: Employee[];
}
class PendingAcknowledgementDetails {
  @serializable(alias("employees", list(object(Employee))))
  employees?: Employee[];
}
export class CashOnHand {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("user", primitive()))
  user?: string;
  @serializable(alias("role", primitive()))
  role?: string;
  @serializable(alias("branch", primitive()))
  branch?: string;
  @serializable(alias("profile_pic_id", primitive()))
  profilePicId?: string;
  @serializable(alias("cash_on_hand", primitive()))
  cashOnHand?: number;
  @serializable(alias("cash_on_hand_details", object(CashOnHandDetails)))
  cashOnHandDetails?: CashOnHandDetails;
  @serializable(alias("pending_acknowledgement", primitive()))
  pendingAcknowledgement?: number;
  @serializable(
    alias(
      "pending_acknowledgement_details",
      object(PendingAcknowledgementDetails)
    )
  )
  pendingAcknowledgementDetails?: PendingAcknowledgementDetails;

  @serializable(alias("total_amount", primitive()))
  totalAmount?: number;
}
