import {
  alias,
  custom,
  list,
  object,
  optional,
  primitive,
  serializable,
} from "serializr";

import { Address } from "./address.model";
import { Branch } from "./branch.model";
import { Center } from "./center.model";
import { Gender } from "../enums/gender.type";
import { QueryParams } from "./query.params.model";
import moment from "moment";
import { getFullName } from "../shared/utils/getFullName";
import { preferredChannel } from "./preferredChannel.model";

export class EmployeeParams extends QueryParams {
  @serializable(alias("role_ids", list(primitive())))
  roleIds?: number[] = [];

  @serializable(alias("branches.id", list(primitive())))
  branchesId?: number[] = [];

  @serializable(alias("centers.id", list(primitive())))
  centersId?: number[] = [];

  @serializable(alias("is_active", primitive()))
  isActive?: boolean;
  @serializable(alias("role_id", primitive()))
  role?: string;
  @serializable(alias("current_employee_id", primitive()))
  currentEmployeeId?: string;
  @serializable(alias("organisation_ids", optional(list(primitive()))))
  organisationIds? = [];
  stepNo?: number;
  @serializable(alias("from_date", primitive()))
  fromDate?: string;
  @serializable(alias("to_date", primitive()))
  toDate?: string;
  @serializable(alias("organisation_ids", optional(list(primitive()))))
  organisations? = [];
}

export class Employee {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("code", primitive()))
  code?: string;

  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("firstname", primitive()))
  firstName?: string;

  @serializable(alias("lastname", primitive()))
  lastName?: string;

  @serializable(alias("isd_code", primitive()))
  isdCode? = "+91";

  @serializable(alias("phone_number", primitive()))
  phoneNumber?: string;

  @serializable(alias("profile_pic_id", primitive()))
  profilePicId?: string;

  @serializable(alias("profile_pic_url", primitive()))
  profilePicUrl?: string;

  @serializable(alias("gender", primitive()))
  gender?: Gender;

  @serializable(alias("role_id", primitive()))
  roleId?: string;
  @serializable(alias("offline_permission_enabled", primitive()))
  offlinePermissionEnabled?: boolean;

  @serializable(alias("role_name", primitive()))
  roleName?: string;
  @serializable(alias("role", primitive()))
  role?: string;

  @serializable(alias("is_active", primitive()))
  isActive?: boolean;
  @serializable(alias("user", primitive()))
  user?: string;

  @serializable(alias("amount", primitive()))
  amount?: string;

  @serializable(alias("address", object(Address)))
  address = new Address();

  @serializable(alias("branches", list(object(Branch))))
  branches?: Branch[];

  @serializable(alias("centers", list(object(Center))))
  centers?: Center[];

  @serializable(alias("organisation_ids", list(primitive())))
  organisationIds?: number[];
  @serializable(alias("preferred_channel", list(object(preferredChannel))))
  preferredChannel?: preferredChannel[];

  @serializable(alias("branch_ids", list(primitive())))
  branchIds?: number[];

  @serializable(alias("center_ids", list(primitive())))
  centerIds?: number[];
  @serializable(alias("success", primitive()))
  success?: boolean;

  getName = () =>
    this.firstName ? `${this.firstName} ${this.lastName ?? ""}  ` : "";

  getBranchNames = () =>
    this.branches?.map((branch) => branch?.name).join(", ");

  getRegionName = () =>
    Array.from(new Set(this.branches?.map((branch) => branch.regionName))).join(
      ","
    );

  getReportingManagerNames = () =>
    this.branches?.map((branch) => ({
      name: getFullName({
        firstName: branch?.responsibleEmployeeFirstName,
        lastName: branch?.responsibleEmployeeLastName,
      }),
      imgSrc: branch?.responsibleEmployeeProfilePicUrl,
    }));
}
