import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Row, Switch } from "antd";

import {
  LoanTypeConfigurationModel,
  LoanTypeDetailsModel,
  LoanTypeStepsMaps,
} from "../../../../models/loanType.model";
import { StepStatus } from "../../../../enums/stepStatus.enum";
import "./loanTypeConfigurationForm.scss";
import { LoanService } from "../../../../services/Loan/loan.service";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { LoanStepName } from "../../../../enums/loanStepName";

interface LoanTypeConfigurationFormProps {
  toggleStep: (status: string) => void;
  currentStep: number;
  toggleAddForm: () => void;
  loanTypeId: string;
}

function LoanTypeConfigurationForm(props: LoanTypeConfigurationFormProps) {
  const { toggleStep, currentStep, toggleAddForm, loanTypeId } = props;
  const { getLoanTypeConfiguration, updateLoanTypeConfiguration } =
    LoanService();
  const [isEligibilityVisibility, setIsEligibilityVisibility] = useState(false);
  const [formValues, setFormValues] = useState<LoanTypeConfigurationModel>(
    new LoanTypeConfigurationModel()
  );
  const [loanTypeConfig, setLoanTypeConfig] =
    useState<LoanTypeConfigurationModel>();

  const handleligibilityVisibility = () =>
    setIsEligibilityVisibility(!isEligibilityVisibility);

  const handleSubmit = async () => {
    const stepsMaps = loanTypeConfig?.loanTypeSteps?.map((config) => {
      return {
        loanStepId: config?.loanStepId,
        isRequired: config?.isRequired,
      };
    });

    const loanSectionInputMap = loanTypeConfig?.loanSectionInputMaps?.map(
      (config) => {
        return {
          loanSectionInputId: config?.loanSectionInputId,
          isRequired: config?.isRequired,
        };
      }
    );

    const result = await updateLoanTypeConfiguration(
      { loanSectionInputMaps: loanSectionInputMap, loanTypeSteps: stepsMaps },
      loanTypeId
    );
    result && toggleAddForm();
  };
  const handleFetchLoanTypeConfiguration = async () => {
    const config = await getLoanTypeConfiguration(loanTypeId);

    setLoanTypeConfig(config);
    config && setFormValues(config);
  };

  useEffect(() => {
    if (currentStep === 3) handleFetchLoanTypeConfiguration();
  }, [currentStep]);

  const handleUpdateLoanTypeConfiguration = async (
    requiredValue: boolean,
    config: LoanTypeStepsMaps,
    type: string
  ) => {
    const data = loanTypeConfig?.[
      type === "loanTypeSteps" ? `loanTypeSteps` : `loanSectionInputMaps`
    ]?.map((loan, index) => {
      if (config?.id === loan?.id) {
        return { ...loan, isRequired: requiredValue };
      } else return loan;
    });
    if (type === "loanTypeSteps")
      setLoanTypeConfig({ ...loanTypeConfig, loanTypeSteps: data });
    else setLoanTypeConfig({ ...loanTypeConfig, loanSectionInputMaps: data });
  };
  const checkCustomerInfo = (name: string, bool: boolean) => {
    if (
      name === LoanStepName.FAMILY_DETAILS ||
      name === LoanStepName.ASSERT_DETAILS ||
      name === LoanStepName.BANK_DETAILS ||
      name === LoanStepName.DOCUMENT ||
      name === LoanStepName.INCOME_EXPENSES
    ) {
      const data = loanTypeConfig?.loanTypeSteps?.filter(
        (loan) =>
          loan?.loanSteps?.name === LoanStepName.CUSTOMER_INFORMATION &&
          loan?.isRequired
      );
      if (data?.length) return true;
      else return false;

      // console.log({ isTrue });
      // return isTrue;
    } else return true;
  };

  return (
    <div>
      <Formik initialValues={formValues} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <>
            <div className="loan-type-configuration-form">
              <div className="loan-type-configuration-form__header">
                <div
                  className="loan-type-configuration-form__header__process-count cursor"
                  onClick={() => {
                    if (isEligibilityVisibility) handleligibilityVisibility();
                  }}
                >
                  {!isEligibilityVisibility ? 3 : "<"}
                </div>
                <div className="loan-type-configuration-form__header__process-title">
                  {!isEligibilityVisibility
                    ? " Loan Type configuration"
                    : "Eligibility Check"}
                </div>
              </div>
              <div className="loan-type-configuration-form__content">
                <Form className="ant-form-vertical">
                  <div>
                    {!isEligibilityVisibility && (
                      <Col span={14}>
                        {loanTypeConfig?.loanTypeSteps?.map((config, index) => {
                          return (
                            <>
                              {checkCustomerInfo(
                                config?.loanSteps?.name ?? "",
                                config?.isRequired ? true : false
                              ) && (
                                <div>
                                  <Row key={index}>
                                    <Col span={22}>
                                      <span
                                        className="arrow mr-2 cursor"
                                        onClick={() => {
                                          config?.isRequired
                                            ? handleligibilityVisibility()
                                            : setIsEligibilityVisibility(false);
                                        }}
                                      >
                                        {config?.loanStepId == 1 ? (
                                          isEligibilityVisibility ? (
                                            <DownOutlined />
                                          ) : (
                                            <RightOutlined />
                                          )
                                        ) : (
                                          ""
                                        )}
                                      </span>
                                      <span className="text-primary loan-type-configuration__label">
                                        {config?.loanSteps?.name}
                                      </span>{" "}
                                    </Col>
                                    <Col span={2}>
                                      {" "}
                                      <Switch
                                        checked={config?.isRequired}
                                        onChange={(value) => {
                                          handleUpdateLoanTypeConfiguration(
                                            value,
                                            config,

                                            "loanTypeSteps"
                                          );
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                  <div className="block"></div>
                                </div>
                              )}
                            </>
                          );
                        })}
                      </Col>
                    )}
                    {isEligibilityVisibility ? (
                      <Col
                        span={14}
                        className="loan-type-configuration__eligibility content-box"
                      >
                        {loanTypeConfig?.loanSectionInputMaps?.map(
                          (config, index) => {
                            return (
                              <>
                                <Row>
                                  <Col span={22}>
                                    {" "}
                                    <span className="text-primary loan-type-configuration__label">
                                      {config?.loanSectionInput?.name}
                                    </span>
                                  </Col>
                                  <Col span={2}>
                                    <Switch
                                      checked={config?.isRequired}
                                      onChange={(value) => {
                                        handleUpdateLoanTypeConfiguration(
                                          value,
                                          config,

                                          "loanSectionInputMaps"
                                        );
                                      }}
                                    />
                                  </Col>
                                </Row>
                                <div className="block"></div>
                              </>
                            );
                          }
                        )}
                      </Col>
                    ) : (
                      <Col span={8}></Col>
                    )}
                  </div>
                  <div className="loan-type-configuration-form__content__footer drawer-footer">
                    <Button
                      className="drawer-footer__cancel"
                      onClick={() => toggleStep(StepStatus.PREV)}
                      type="link"
                      icon={"<  "}
                    >
                      Prev
                    </Button>
                    <Button
                      className="drawer-footer__submit"
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                    >
                      Create
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
}
export default LoanTypeConfigurationForm;
