import {
  alias,
  list,
  optional,
  primitive,
  serializable,
} from "serializr";
import { QueryParams } from "./query.params.model";

export class LoanRepayamentScheduleParams extends QueryParams {
  @serializable(alias("status", optional(list(primitive()))))
  status?: string[] = [];
}

export class LoanRepayamentSchedule {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("repayment_no", primitive()))
  repaymentNo?: number;
  @serializable(alias("due_date", primitive()))
  dueDate?: string;
  @serializable(alias("amount", primitive()))
  amount?: number;
  @serializable(alias("pricipal", primitive()))
  principal?: number;
  @serializable(alias("interest", primitive()))
  interest?: number;
  @serializable(alias("closing_principal", primitive()))
  closingPrincipal?: number;
  @serializable(alias("pending_amount", primitive()))
  pendingAmount?: number;
  @serializable(alias("paid_date", primitive()))
  paidDate?: string;
  @serializable(alias("receipt_number", primitive()))
  receiptNumber?: string;
  @serializable(alias("remarks", primitive()))
  remarks?: string;
  @serializable(alias("status", primitive()))
  status?: string;

  @serializable(alias("name", primitive()))
  name?: string;
}
