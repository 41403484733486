//Icons

import React, { useEffect, useState } from "react";

import { CentersService } from "../../services/Centers/centers.service";
import { Meta } from "../../models/meta.modal";
import { PaginationMeta } from "../../models/pagination.model";
//Libraries
import debounce from "lodash.debounce";
import { Center } from "../../models/center.model";

interface useCenterProps {}

const useCenter = (props: useCenterProps) => {
  const { getCenters, loading } = CentersService();
  //Prop
  const {} = props;

  //State
  const [centers, setCenters] = useState<Meta[]>([]);
  const [branchId, setBranchId] = useState("");
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [searchText, setSearchText] = useState("");

  const handleCenterUpdate = (centers?: Center[]) => {
    if (centers?.length) {
      setCenters((existingData) => [
        ...existingData,
        ...centers
          ?.map((center) => ({
            value: center?.id ?? 0,
            code: center?.code ?? "",
            label: center?.name ?? "",
          }))
          .filter(
            (val) => !existingData.some((data) => data.value === val.value)
          ),
      ]);
    }
  };

  const handleCenterReset = () => {
    setCenters([]);
    setBranchId("");
    setPage(1);
    setTotalPage(1);
    setSearchText("");
  };

  const handleSearch = debounce((value) => {
    setPage(1);
    setSearchText(value);
    setCenters([]);
  }, 500);

  const handleDropdownScroll: React.UIEventHandler<HTMLDivElement> = (
    event
  ) => {
    const { scrollTop, offsetHeight, scrollHeight } = event.currentTarget;
    if (scrollTop + offsetHeight === scrollHeight && page <= totalPage) {
      setPage((page) => (page < totalPage ? page + 1 : totalPage));
    }
  };

  useEffect(() => {
    if (!branchId) return handleCenterReset();

    const populateMeta = async () => {
      const response = await getCenters({
        page,
        searchText,
        branchId: [+branchId],
      });
      handleCenterUpdate(response?.centers);
      if (response?.meta)
        setTotalPage((response?.meta as PaginationMeta).totalPages ?? 1);
    };
    populateMeta();
  }, [page, searchText, branchId]);

  return {
    centers,
    loading,
    handleSearch,
    handleDropdownScroll,
    setBranchId,
    handleCenterReset,
    handleCenterUpdate,
  };
};

export default useCenter;
