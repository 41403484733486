import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import "../auth.scss";
import AuthService from "../../../services/AuthService/auth.service";
import { User } from "../../../models/user.model";
import mainLogo from "../../../Assets/images/main-logo.png";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import OtpInput from "react18-input-otp";
import "./verifyOTP.scss";
import { LocalStorageHelper } from "../../../shared/utils/localStorageHelper";
import { LocalStorage } from "../../../enums/localStorage.enum";

function VerifyOTP() {
  const { loading, forgotPassword, verifyOTP, verifyEmailOtp } = AuthService();

  const employee = LocalStorageHelper.getItem(LocalStorage.EMPLOYEE);
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [resendLoading, setResendLoading] = useState(false);
  const [counter, setCounter] = React.useState(20);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);
  const handleChange = (enteredOtp: string) => {
    setOtp(enteredOtp);
  };

  const handleSendOTP = async () => {
    setResendLoading(true);
    const result = await forgotPassword({ email: employee?.email });
    result && setCounter(30);
    setResendLoading(false);
  };

  const onSubmit = async () => {
    const emp = {
      otp: otp,
      email: employee?.email,
    };

    const output = (await verifyEmailOtp(emp)) as User;
    if (output?.resetPasswordToken) {
      LocalStorageHelper.setItem(LocalStorage.EMPLOYEE, {
        ...employee,
        token: output?.resetPasswordToken,
      });

      navigate(AppRoutes.CREATE_PASSWORD);
    }
  };
  return (
    <Row className="verify-otp">
      <Col span={12} className="login__logo-container">
        <img src={mainLogo} alt="logo" />
      </Col>
      <Col span={12} className="content-container">
        <div className="verify-otp__form">
          <Formik initialValues={new User()} onSubmit={onSubmit}>
            {(props) => (
              <Form className="ant-form-vertical" onSubmit={props.handleSubmit}>
                <Row gutter={[0, 30]} justify="space-evenly">
                  <Col span={18}>
                    <h1 className="verify-otp__form__heading">Enter OTP</h1>
                  </Col>
                  <Col span={18} className="mb-5">
                    Please enter the verification code sent to your
                    mobile/email.
                  </Col>
                  <Col span={18} className="mt-5">
                    <OtpInput
                      className="verify-otp__form-content"
                      value={otp}
                      onChange={handleChange}
                      numInputs={4}
                    />
                  </Col>
                  <Col
                    span={16}
                    className="verify-otp__form-resend text-right text-primary"
                  >
                    {counter ? (
                      `RESEND OTP in 00:${counter < 10 ? `0` : ``}${counter}`
                    ) : (
                      <a
                        onClick={resendLoading ? () => {} : handleSendOTP}
                        className="text-primary"
                      >
                        RESEND
                      </a>
                    )}
                  </Col>

                  <Col span={18}>
                    <Button
                      loading={loading}
                      className="mt-5 w-100"
                      htmlType="submit"
                      disabled={otp.length !== 4}
                    >
                      Verify
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      </Col>
    </Row>
  );
}

export default VerifyOTP;
