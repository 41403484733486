import { Col, Row } from "antd";
import React, { ReactElement } from "react";

import { OwnedEntity } from "../../../../../models/ownedEntity.model";
import { currencyFormatter } from "../../../../../shared/utils/formatter";
import { fillEmptyData } from "../../../../../shared/utils/fillEmptyData";

interface CattleListProps {
  cattles?: OwnedEntity[];
}

const CattleList = (props: CattleListProps) => {
  const { cattles } = props;

  const data = [
    {
      label: "Cattle",
      value: (data: OwnedEntity) => fillEmptyData(data?.entityType),
    },
    {
      label: "How Many",
      value: (data: OwnedEntity) => fillEmptyData(data?.entityNumber),
    },
  ];

  return (
    <>
      {cattles?.length ? (
        <div>
          <div className="block__heading mb-5">Cattle Details</div>
          <table className="custom-table">
            <thead>
              <tr>
                {data.map((field) => (
                  <th key={field.label}>{field.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {cattles?.map((val, index) => (
                <tr key={index}>
                  {data.map((field) => (
                    <td className="text-capitalize" key={field.label}>
                      {val ? field.value(val) : "NA"}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default CattleList;
