import * as Yup from "yup";

export const preCloseValidation = Yup.object().shape({
  outstandingAmount: Yup.string()
    .required()
    .label("Outstanding Amount is required"),
  amountCollected: Yup.string()
    .oneOf(
      [Yup.ref("outstandingAmount"), ""],
      "Amount Collected and Outstanding Amount should be same"
    )
    .required("Amount Collected is required"),

  id: Yup.string().required().label("Id is required"),
  numberOfpendingDues: Yup.string().required().label("No of Dues is required"),
  customerName: Yup.string().required().label("Customer Name is required"),
});
