import { alias, custom, list, object, primitive, serializable } from "serializr";


class UgroFile {
    @serializable(primitive()) 
    id?: number;

    @serializable(alias('file_name', primitive()))
    fileName?: string
}
export class UgroLoanDocument {
    @serializable(alias('document_name', primitive()))
    documentName?: string;

    @serializable(alias('front_side_image_id', custom(
        value => value?.id, 
        id => ({ id })
    )))
    frontSideImageId: UgroFile = new UgroFile();

    @serializable(alias('document_type', primitive()))
    documentType?: string;
}

export class UgroLoanDocuments {
    @serializable(alias('documents', list(object(UgroLoanDocument))))
    documents?: UgroLoanDocument[]
}

