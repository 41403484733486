
import {serializable, alias, object, list, primitive} from 'serializr';

export class LoanPaymentHistory {
	 
	@serializable(primitive())
    month?: string;

    @serializable(primitive())
    year?: string;

    @serializable(alias('paid_status', primitive()))
    paidStatus?: string;

}