import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Branch } from "../../../../models/branch.model";
import { Button } from "antd";
import InputField from "../../../../shared/components/InputField";
import { InputType } from "../../../../enums/input.type";
import { Meta } from "../../../../models/meta.modal";
import { MetaService } from "../../../../services/Meta/meta.service";
import SelectInput from "../../../../shared/components/SelectInput";
import "./loanTypeDetailsForm.scss";
import useBranch from "../../../../shared/hooks/useBranch";
import { LoanTypeDetailsModel } from "../../../../models/loanType.model";
import { StepStatus } from "../../../../enums/stepStatus.enum";
import { loanTypeDetailFormValidation } from "./loanTypeDetailFormValidation";
import { LoanService } from "../../../../services/Loan/loan.service";

interface LoanTypeDetailProps {
  toggleStep?: (status: string) => void;
  toggleAddForm?: () => void;
  currentStep?: number;
  toggleLoanType?: (loanId: string) => void;
  loanTypeId: string;
  formValues?: LoanTypeDetailsModel;
  onSuccess?: (loanDetails: LoanTypeDetailsModel) => void;
  toggleLoanDetailsForm?: () => void;
}

function LoanTypeDetailForm(props: LoanTypeDetailProps) {
  const { branches } = useBranch({ allBranch: true });
  const {
    toggleStep,
    toggleAddForm,
    currentStep,
    toggleLoanType,
    loanTypeId,
    onSuccess,
    formValues,
    toggleLoanDetailsForm,
  } = props;

  const { loading: metaLoading, getRegions } = MetaService();
  const [formData, setFormData] = useState<LoanTypeDetailsModel>(
    formValues ?? new LoanTypeDetailsModel()
  );
  const { createLoanTypeDetails, updateLoanTypeDetails, loading } =
    LoanService();

  const [regions, setRegions] = useState<Meta[]>([]);

  useEffect(() => {
    if (currentStep == 1 || formData?.id) {
      const populateMeta = async () => {
        const regions = await getRegions();
        if (regions) setRegions(regions);
      };
      populateMeta();
      const { branches, branchIds, regionIds, regions } = formData;
      setFormData({
        ...formData,
        branchIds: branches?.map((val) => Number(val.value)),
        regionIds: regions?.map((val) => Number(val.value)),
      });
    }
  }, [currentStep, formData?.id]);

  const handleSubmit = async (values: LoanTypeDetailsModel) => {
    delete values?.branches;
    delete values?.regions;

    if (loanTypeId || formData?.id) {
      const details = await updateLoanTypeDetails(
        values,
        loanTypeId ?? String(formData?.id)
      );
      if (details) {
        formData?.id
          ? onSuccess && onSuccess(details)
          : toggleStep && toggleStep(StepStatus.NEXT);
      }
    } else {
      const loanDetails = await createLoanTypeDetails(values);
      if (loanDetails && toggleLoanType) {
        toggleLoanType(String(loanDetails?.id));
        toggleStep && toggleStep(StepStatus.NEXT);
      }
    }
  };

  return (
    <div>
      <Formik
        initialValues={formData}
        validationSchema={loanTypeDetailFormValidation}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting, dirty, isValid, setFieldValue, values }) => {
          return (
            <>
              <div className="loan-type-details-form">
                <div className="loan-type-details-form__header">
                  {!formData?.id && (
                    <div className="loan-type-details-form__header__process-count">
                      1
                    </div>
                  )}
                  <div className="loan-type-details-form__header__process-title">
                    {formData?.id
                      ? "Update Loan Type Details"
                      : "Loan Type Details"}
                  </div>
                </div>
                <div className="branch-form__content">
                  <Form className="ant-form-vertical">
                    <InputField
                      type={InputType.TEXT}
                      name="name"
                      placeholder="Enter Loan Type"
                      label="Loan Type"
                    />
                    <br />

                    <InputField
                      type={InputType.NUMBER}
                      name="minimumAmount"
                      placeholder="Enter Minimum Amount"
                      label="Minimum Amount"
                    />
                    <InputField
                      type={InputType.NUMBER}
                      name="maximumAmount"
                      placeholder="Enter Maximum Amount"
                      label="Maximum Amount"
                    />
                    <InputField
                      type={InputType.NUMBER}
                      name="minimumTenure"
                      placeholder="Enter Minimum Tenure"
                      label="Minimum Tenure"
                    />
                    <InputField
                      type={InputType.NUMBER}
                      name="maximumTenure"
                      placeholder="Enter Maxiumum Tenure"
                      label="Maxiumum Tenure"
                    />
                    <InputField
                      type={InputType.NUMBER}
                      name="minimumInterest"
                      placeholder="Enter Minimum Interest"
                      label="Minimum Interest"
                    />
                    <InputField
                      type={InputType.NUMBER}
                      name="maximumInterest"
                      placeholder="Enter Maximum Interest"
                      label="Maximum Interest"
                    />

                    <SelectInput
                      name="branchIds"
                      options={branches}
                      mode="multiple"
                      showSearch
                      placeholder="Select Branch"
                      label="Branch"
                      IsSelectAll={true}
                    />

                    <SelectInput
                      name="regionIds"
                      options={regions}
                      mode="multiple"
                      placeholder="Select  Region"
                      label="Region"
                    />

                    <div className="loan-type-details-form__content__footer drawer-footer">
                      <Button
                        className="drawer-footer__cancel"
                        htmlType="reset"
                        type="link"
                        onClick={() => {
                          formData?.id
                            ? toggleLoanDetailsForm && toggleLoanDetailsForm()
                            : toggleAddForm && toggleAddForm();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="drawer-footer__submit"
                        htmlType="submit"
                        loading={loading}
                        disabled={!isValid}
                      >
                        {formData?.id ? "Update" : "Next"}
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
}
export default LoanTypeDetailForm;
