import React, { ReactNode } from "react";
import { ReportProvider } from "../ReportContext";

interface AppProvidersProps {
  children: ReactNode;
}

const AppProviders: React.FC<AppProvidersProps> = ({ children }) => {
  return <ReportProvider>{children}</ReportProvider>;
};

export default AppProviders;
