import { Col, Row } from "antd";
import React, { ReactElement } from "react";

import { OwnedEntity } from "../../../../../models/ownedEntity.model";
import { currencyFormatter } from "../../../../../shared/utils/formatter";
import { fillEmptyData } from "../../../../../shared/utils/fillEmptyData";

interface InvestmentListProps {
  investments?: OwnedEntity[];
}

const InvestmentList = (props: InvestmentListProps) => {
  const { investments } = props;

  const data = [
    {
      label: "Investment",
      value: (data: OwnedEntity) => fillEmptyData(data?.entityType),
    },
    {
      label: "Worth",
      value: (data: OwnedEntity) =>
        currencyFormatter({ amount: data?.entityWorth ?? 0 }),
    },
  ];

  return (
    <>
      {investments?.length ? (
        <div>
          <div className="block__heading mb-5">Other Investments</div>
          <table className="custom-table">
            <thead>
              <tr>
                {data.map((field) => (
                  <th key={field.label}>{field.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {investments?.map((val, index) => (
                <tr key={index}>
                  {data.map((field) => (
                    <td className="text-capitalize" key={field.label}>
                      {val ? field.value(val) : "NA"}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default InvestmentList;
