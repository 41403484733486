import React, { useCallback, useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { Button, Col, Input, Row } from "antd";
import InputField from "../../../../shared/components/InputField";
import { InputType } from "../../../../enums/input.type";
import { BlacklistService } from "../../../../services/BlacklistService/blacklist.service";
import { BlacklistForm, Blacklists } from "../../../../models/blacklists";
import { BlacklistType } from "../../../../enums/blacklist.type";
import { CustomersService } from "../../../../services/Customers/customers.service";
import debounce from "lodash.debounce";
import { Customer } from "../../../../models/customer.model";
import { blackListCustomerFormValidation } from "./blackListCustomerFormValidation";
import './blackListCustomerForm.scss'

interface BlacklistCustomerFormProps {
  onSuccess: (branch: Blacklists) => void;
  onClose: () => void;
}

function BlacklistCustomerForm({
  onClose,
  onSuccess,
}: BlacklistCustomerFormProps) {
  const { loading, addBlacklistCustomer } = BlacklistService();
  const { getCustomerByCode } = CustomersService();
  const [formValues, setFormValues] = useState<BlacklistForm>(
    new BlacklistForm()
  );

  const getCustomerInfo = debounce(async (code: string) => {
    const customer = await getCustomerByCode(code);
    if (customer) {
      setFormValues({
        customerCode: customer?.code,
        name: customer?.getName(),
        aadhaarNumber: customer?.aadhaarNumber,
        panNumber: customer?.panNumber,
        phoneNumber: customer?.phoneNumber,
      });
     } else {
      setFormValues({
        customerCode: code,
      })
     }
  }, 600);

  const getCustomerInfoFunction = useCallback(debounce(getCustomerInfo, 2000), []);

  const handleCustomerCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const code = e.target.value;
    setFormValues((prevValues) => ({ ...prevValues, customerCode: code }));
    getCustomerInfoFunction(code);
  };

  const handleSubmit = async (values: BlacklistForm) => {
    const result = await addBlacklistCustomer({
      customerCode: values?.customerCode,
      reason: values?.reason,
      type: BlacklistType.CUSTOMER,
    });
    result && onSuccess(result?.blacklist);
  };

  return (
    <div className="blacklist-customer-form">
      <Formik
        initialValues={formValues}
        onSubmit={handleSubmit}
        validationSchema={blackListCustomerFormValidation}
        enableReinitialize
      >
        {({ isValid, dirty }) => {
          return (
            <div className="blacklist-customer-form__container">
              <Form className="ant-form-vertical">
                <Row gutter={24}>
                  <Col span={12} className="customer-input">
                    <InputField
                      type={InputType.TEXT}
                      label="Customer Code"
                      name="customerCode"
                      placeholder="Enter Customer ID"
                      onChange={handleCustomerCodeChange}
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      label="Name"
                      type={InputType.TEXT}
                      name="name"
                      placeholder="Enter Name"
                      disabled={!!formValues?.customerCode}
                    />
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={12}>
                    <InputField
                      label="Aaadhar"
                      type={InputType.NUMBER}
                      name="aadhaarNumber"
                      placeholder="Enter Aadhar Number"
                      disabled={!!formValues?.customerCode}
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      label="PAN"
                      type={InputType.TEXT}
                      name="panNumber"
                      placeholder="Enter PAN Number"
                      disabled={!!formValues?.customerCode}
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      label="Mobile Number"
                      type={InputType.NUMBER}
                      name="phoneNumber"
                      placeholder="Enter Mobile Number"
                      disabled={!!formValues?.customerCode}
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      label="Reason"
                      type={InputType.TEXT}
                      name="reason"
                      placeholder="Enter Reason"
                    />
                  </Col>
                </Row>

                <div className="drawer-footer">
                  <Button
                    onClick={onClose}
                    type="link"
                    className="drawer-footer__cancel"
                    htmlType="reset"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="drawer-footer__submit"
                    htmlType="submit"
                    loading={loading}
                    disabled={!dirty || !isValid}
                  >
                    Blacklist
                  </Button>
                </div>
              </Form>
            </div>
          );
        }}
      </Formik>
    </div>
  );
}
export default BlacklistCustomerForm;
