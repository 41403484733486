import React from "react";
import { Button, Col, Row } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import "../auth.scss";
import InputField from "../../../shared/components/InputField";
import AuthService from "../../../services/AuthService/auth.service";
import { User } from "../../../models/user.model";
import { InputType } from "../../../enums/input.type";
import mainLogo from "../../../Assets/images/main-logo.png";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import { resetPasswordValidation } from "./resetPasswordValidation";

function ResetPassword() {
  const { loading, resetNewPassword } = AuthService();
  const navigate = useNavigate();

  const onSubmit = async (values: User) => {
    const result = await resetNewPassword(values);
    result && navigate(AppRoutes.LOGIN);
  };
  return (
    <Row className="reset-password">
      <Col span={12} className="login__logo-container">
        <img src={mainLogo} alt="logo" />
      </Col>
      <Col span={12} className="content-container">
        <div className="reset-password__form">
          <Formik
            validationSchema={resetPasswordValidation}
            initialValues={new User()}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, isValid, dirty }) => (
              <Form className="ant-form-vertical" onSubmit={handleSubmit}>
                <h1 className="reset-password__form__heading">
                  Reset Password
                </h1>
                <InputField
                  label="Email ID"
                  type={InputType.EMAIL}
                  name="email"
                  placeholder="Email ID"
                />
                <InputField
                  label="Enter old Password"
                  type={InputType.PASSWORD}
                  name="oldPassword"
                  placeholder="Enter old Password"
                />
                <InputField
                  label="New Password"
                  type={InputType.PASSWORD}
                  name="newPassword"
                  placeholder="Enter new Password"
                />
                <InputField
                  label="Confirm Password"
                  type={InputType.PASSWORD}
                  name="confirmPassword"
                  placeholder="Enter confirm Password"
                />
                <Button
                  htmlType="submit"
                  loading={loading}
                  disabled={!dirty || !isValid}
                >
                  Reset
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </Col>
    </Row>
  );
}

export default ResetPassword;
