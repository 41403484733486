import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { RoleName } from "../../enums/roleName.enum";
import SuperAdminDashboard from "./SuperAdminDashboard";
import HeadOfficerDashboard from "./HeadOfficerDashboard";
import { User } from "../../enums/user.type";
import { switchUserRole } from "../../shared/utils/switchUserRole";
import AdminDashboard from "./AdminDashboard";
import MISDashboard from "./MISDashboard";
import './dashboard.scss'

const Dashboard = () => {
  const { user } = AuthContext();
  const [currentRole, setCurrentRole] = useState<RoleName>();
  const [selectedRole, setSelectedRole] = useState<User>();

  useEffect(() => {
    setCurrentRole(user?.employee?.roleName as RoleName);
  }, []);

  const handleRoleChange = (role: User) => {
    setCurrentRole(switchUserRole(role));
    setSelectedRole(role);
  };

  const dashboardCol = (role: RoleName) => {
    switch (role) {
      case RoleName.SUPER_ADMIN:
        return (
          <SuperAdminDashboard
            selectedRole={selectedRole}
            onRoleChange={handleRoleChange}
          />
        );
      case RoleName.ADMIN:
        return (
          <AdminDashboard
            onRoleChange={handleRoleChange}
            selectedRole={selectedRole}
          />
        );
      case RoleName.HEAD_OFFICER:
        return (
          <HeadOfficerDashboard
            selectedRole={selectedRole}
            onRoleChange={handleRoleChange}
          />
        );
      case RoleName.MIS_MANAGER:
      case RoleName.BRANCH_MIS:
      case RoleName.BRANCH_ACCOUNTANT:
      case RoleName.ACCOUNTANT:
        return (
          <MISDashboard
            selectedRole={selectedRole}
            onRoleChange={handleRoleChange}
          />
        );
      default:
        return <></>;
    }
  };

  return <div>{currentRole && dashboardCol(currentRole)}</div>;
};

export default Dashboard;
