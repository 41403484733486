import React, { useEffect, useState } from "react";
import "../loanClaims.scss";
import useQueryParams from "../../../shared/hooks/useQueryParams";
import { LoanClaimsModel, LoanClaimsParams } from "../../../models/loanClaims.model";
import Table from "../../../shared/components/Table";
import useTableConfig from "../../../shared/hooks/useTableConfig";
import useSorting from "../../../shared/hooks/useSorting";
import useDrawer from "../../../shared/hooks/useDrawer";
import { Drawer, TableColumnsType, TableProps } from "antd";
import { SorterResult } from "antd/lib/table/interface";
import { generatePath, useNavigate } from "react-router-dom";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import TabExtraContent from "../../../shared/components/TabExtraContent";
import ColumnOptions from "../../../shared/components/ColumnOptions";
import { TableConfig } from "../../../enums/tableConfig.type";
import { ColumnProps } from "antd/lib/table";
import { fillEmptyData } from "../../../shared/utils/fillEmptyData";
import { DateFormat } from "../../../enums/dateFormat.type";
import { currencyFormatter, dateTimeFormatter } from "../../../shared/utils/formatter";
import { ClaimsService } from "../../../services/Claims/claims.service";
import { LoansClaimsStatus } from "../../../enums/loanClaimsStatuses.type";
import { toTitleCase } from "../../../shared/utils/titleCaseConverter";
import { removeUnderscore } from "../../../shared/utils/removeUnderscore";
import useBreakPoint from "../../../shared/hooks/useBreakPoint";

const sortBy = {
  loanCode: "loan_code",
  role: "role",
  nameOfExpired: "name_of_expired",
  deathDate: "death_date"
};

const ProcessingClaims = () => {
  const {
    params: processingParams,
    handleSearch,
    updateParams,
  } = useQueryParams<LoanClaimsParams>({
    params: new LoanClaimsParams(),
  });

  const processingColumns: ColumnProps<LoanClaimsModel>[] = [
    {
        title: "Loan ID",
        dataIndex: "loanCode",
        render: (code: string) => fillEmptyData(code?.toUpperCase()),
        sorter: true,
    },
    {
        title: "Name of Expired",
        dataIndex: "nameOfExpired",
        render: (name: string) => fillEmptyData(name),
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      render: (name: string) => fillEmptyData(name),
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (role: string) => fillEmptyData(toTitleCase(removeUnderscore(role))),
      sorter: true,
    },
    {
        title: "Death Date",
        dataIndex: "deathDate",
        render: (date: string) =>
            dateTimeFormatter(date, DateFormat.DATE),
        sorter: true
    },
    {
      title: "Claim Sent On",
      dataIndex: "claimSentOn",
      render: (date: string) => dateTimeFormatter(date, DateFormat.DATE),
    },
    {
      title: "Branch",
      dataIndex: "branch.name",
      render: (text, record) => {
        return fillEmptyData(record?.branch);
      },
    },
    {
      title: "CRO",
      dataIndex: "cro",
      render: (text: string, record: LoanClaimsModel) => {
        return fillEmptyData(toTitleCase(record?.croName ?? ""))
      },
    },
    {
        title: "Total Insured",
        dataIndex: "totalInsured",
        render: (_, record: LoanClaimsModel) => {
            return currencyFormatter({amount: record.totalInsured, hasCurrencySign: true})
        },
    },
    {
        title: "Outstanding",
        dataIndex: "outstanding",
        render: (_, record: LoanClaimsModel) => {
            return currencyFormatter({amount: record.outstanding, hasCurrencySign: true})
        },
    },
    {
      title: "To Customer",
      dataIndex: "toCustomer",
      render: (_, record: LoanClaimsModel) => {
        return currencyFormatter({amount: record?.toCustomer, hasCurrencySign: true})
      },
    },
    {
      title: "To Velicham",
      dataIndex: "toVelicham",
      render:(_, record: LoanClaimsModel) => {
        return currencyFormatter({amount: record?.toVelicham, hasCurrencySign: true})
      }
    },
    {
        title: "Insurer",
        dataIndex: "insurer",
        render: (_, record: LoanClaimsModel) => (fillEmptyData(toTitleCase(removeUnderscore(record.insurer))))
    },
    {
        title: "Nominee Name",
        dataIndex: "nomineeName",
        render: (_, record: LoanClaimsModel) => fillEmptyData(toTitleCase(removeUnderscore(record.nomineeName))),
        sorter: true
    }
]

  const { getClaims, loading } = ClaimsService()
  const [totalClaims, setTotalClaims] = useState(1);
  const { columnConfig, setColumnConfig } = useTableConfig();
  const [filteredColumns, setFilteredColumns] = useState<string[]>(
    columnConfig?.loanClaims ?? []
  );
  const [defaultColumns, setDefaultColumns] =
    useState<TableColumnsType<LoanClaimsModel>>(processingColumns);
  const [claims, setClaims] = useState<LoanClaimsModel[]>([]);
  const { updateSortData } = useSorting<LoanClaimsModel>({ sortBy });
  const { getDrawerWidth } = useBreakPoint()
  useEffect(() => {
    setColumnConfig(TableConfig.LOAN_CLAIMS, filteredColumns);
  }, [filteredColumns]);

  const {
    visible: processingSettingsVisible,
    toggleVisibility: toggleProcessingSettingsVisibility,
  } = useDrawer({});

  const handleChange: TableProps<LoanClaimsModel>["onChange"] = (
    pagination,
    _,
    sorter
  ) => {
    const sortData = updateSortData(sorter as SorterResult<LoanClaimsModel>);
    updateParams({
      ...processingParams,
      ...sortData,
      page: pagination?.current,
    });
  };

  const navigate = useNavigate();

  useEffect(() => {
    fetchClaims();
  }, [processingParams, processingParams.searchText]);

  const fetchClaims = async () => {
    const updatedParams = {
      ...processingParams,
      searchText: processingParams.searchText,
      status: LoansClaimsStatus.PROCESSING
    };
    const claimsData = await getClaims(updatedParams);
    if (claimsData?.claims) setClaims(claimsData?.claims);
    if (claimsData?.meta) {
      setTotalClaims(claimsData?.meta?.totalCount ?? 1);
    }
  };

  return (
    <div className="processing-claims">
      <div className="processing-claims-extra-content">
        <TabExtraContent
          searchProps={{
            onSearch: handleSearch,
            placeholder: "Search Document",
            value: processingParams.searchText,
          }}
          settingsClick={toggleProcessingSettingsVisibility}
        />
      </div>
      <Table
        onChange={handleChange}
        scroll={{ x: true }}
        className="processing-claims-table"
        columns={defaultColumns.filter(
          (col) => !filteredColumns.includes(col.title as string)
        )}
        loading={loading}
        dataSource={claims}
        rowKey={(record: LoanClaimsModel) => record?.loanId as number}
        onRow={(record: LoanClaimsModel) => ({
          onClick: () => {
            navigate(
              generatePath(AppRoutes.INDIVIDUAL_LOAN_CLAIM, {
                loanId: String(record?.loanId),
              })
            );
          },
        })}
        pagination={{
          current: processingParams?.page,
          hideOnSinglePage: true,
          total: totalClaims,
        }}
      />
      
      <Drawer
        placement="right"
        visible={processingSettingsVisible}
        destroyOnClose
        onClose={() => toggleProcessingSettingsVisibility(false)}
        title="Column Options"
        width={getDrawerWidth()}
      >
        <ColumnOptions
          defaultColumns={defaultColumns}
          filteredColumns={filteredColumns}
          setFilteredColumns={setFilteredColumns}
          onClose={() => toggleProcessingSettingsVisibility(false)}
          setDefaultColumns={setDefaultColumns}
        />
      </Drawer>
    </div>
  );
};

export default ProcessingClaims;
