import { Table, TableProps } from "antd";
import React, { useEffect, useState } from "react";
import { Payments as PaymentsModel } from "../../../models/payments.model";
import { paymentsColumns } from "./paymentsColumn";
import { RepaymentService } from "../../../services/Repayment/repayment.service";
import "./payments.scss";
import useQueryParams from "../../../shared/hooks/useQueryParams";
import { QueryParams } from "../../../models/query.params.model";
import useSorting from "../../../shared/hooks/useSorting";
import { SorterResult } from "antd/lib/table/interface";
import TabExtraContent from "../../../shared/components/TabExtraContent";

const Payments = () => {
  const { getPayments, loading } = RepaymentService();
  const { params, updateParams, getParams, handleSearch } = useQueryParams({
    params: new QueryParams(),
  });
  const [totalPage, setTotalPage] = useState(1);
  const [payments, setPayments] = useState<PaymentsModel[]>([]);

  const sortBy = {
    loanCode: 'loan_code',
    receiptNo: 'receipt_no',
    amount: 'amount',
    createdAt: 'date',
  };

  const { updateSortData } = useSorting<PaymentsModel>({ sortBy });

  useEffect(() => {
    handleFetchPayments(getParams());
  }, [params, params.searchText]);

  

  const handleFetchPayments = async (params: QueryParams) => {
    const result = await getPayments(params);
    setPayments(result?.payments ?? []);
    setTotalPage(result?.meta?.totalCount ?? 1);
  };

  const handleChange: TableProps<PaymentsModel>["onChange"] = (
    { current },
    _,
    sorter
  ) => {
    const { sortBy, sortDirection } = updateSortData(
      sorter as SorterResult<PaymentsModel>
    );
    updateParams({ ...params, page: current, sortBy, sortDirection, });
  };

  return (
    <div className="payments">
      <TabExtraContent
                searchProps={{
                  placeholder: "Search Payment",
                  value: params?.searchText,
                  onSearch: handleSearch,
                }}/>
      <div className="card">
        <Table
          columns={paymentsColumns}
          dataSource={payments}
          loading={loading}
          onChange={handleChange}
          pagination={{
            current: params?.page,
            hideOnSinglePage: true,
            total: totalPage,
          }}
        />
      </div>
    </div>
  );
};

export default Payments;
