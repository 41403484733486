import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row, Switch } from "antd";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LoanStepName } from "../../../../../enums/loanStepName";
import {
  LoanTypeConfigurationModel,
  LoanTypeStepsMaps,
} from "../../../../../models/loanType.model";
import { LoanService } from "../../../../../services/Loan/loan.service";
import { checkCustomerInfo } from "../../../../../shared/utils/checkCustomerInfo";
import "./loanTypeConfiguration.scss";
import { LocalStorageHelper } from "../../../../../shared/utils/localStorageHelper";
import { LocalStorage } from "../../../../../enums/localStorage.enum";

const LoanTypeConfiguration = () => {
  const [isEligibilityVisibility, setIsEligibilityVisibility] = useState(false);
  const handleligibilityVisibility = () =>
    setIsEligibilityVisibility(!isEligibilityVisibility);
  const [updateLoading, setUpdateLoading] = useState(false);
  const userAccess = LocalStorageHelper.getItem(
    LocalStorage.CURRENT_USER_ACCESS
  );

  const {
    getLoanTypeConfiguration,

    updateLoanTypeConfiguration,
  } = LoanService();
  const [loanTypeConfig, setLoanTypeConfig] =
    useState<LoanTypeConfigurationModel>();
  const { loanTypeId: loanTypeId } = useParams();
  const [formValues, setFormValues] = useState<LoanTypeConfigurationModel>();

  const handleFetchLoanTypeConfiguration = async () => {
    const config = await getLoanTypeConfiguration(String(loanTypeId));

    const stepsMaps = config?.loanTypeSteps?.map((config) => {
      return {
        loanStepId: config?.loanStepId,
        isRequired: config?.isRequired,
        // loanSteps: config?.loanSteps?.name,
      };
    });

    const loanSectionInputMap = config?.loanSectionInputMaps?.map((config) => {
      return {
        loanSectionInputId: config?.loanSectionInputId,
        isRequired: config?.isRequired,
        loanName: config?.loanSectionInput?.name,
      };
    });

    config && setLoanTypeConfig(config);
    config &&
      setFormValues({
        loanSectionInputMaps: loanSectionInputMap,
        loanTypeSteps: stepsMaps,
      });
  };

  useEffect(() => {
    handleFetchLoanTypeConfiguration();
  }, []);

  const handleSubmit = async () => {
    const stepsMaps = loanTypeConfig?.loanTypeSteps?.map((config) => {
      return {
        loanStepId: config?.loanStepId,
        isRequired: config?.isRequired,
      };
    });

    const loanSectionInputMap = loanTypeConfig?.loanSectionInputMaps?.map(
      (config) => {
        return {
          loanSectionInputId: config?.loanSectionInputId,
          isRequired: config?.isRequired,
        };
      }
    );
    setUpdateLoading(true);
    const result = await updateLoanTypeConfiguration(
      { loanSectionInputMaps: loanSectionInputMap, loanTypeSteps: stepsMaps },
      String(loanTypeId)
    );
    setUpdateLoading(false);
  };

  const handleUpdateLoanTypeConfiguration = async (
    requiredValue: boolean,
    config: LoanTypeStepsMaps,
    type: string
  ) => {
    const data = loanTypeConfig?.[
      type === "loanTypeSteps" ? `loanTypeSteps` : `loanSectionInputMaps`
    ]?.map((loan, index) => {
      if (config?.id === loan?.id) {
        return { ...loan, isRequired: requiredValue };
      } else return loan;
    });
    if (type === "loanTypeSteps")
      setLoanTypeConfig({ ...loanTypeConfig, loanTypeSteps: data });
    else setLoanTypeConfig({ ...loanTypeConfig, loanSectionInputMaps: data });
  };

  return (
    <div className="loan-type-configuration card ">
      <Formik
        initialValues={formValues ?? new LoanTypeConfigurationModel()}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, dirty, isValid, values, isSubmitting }) => {
          return (
            <Form>
              <Row
                className="loan-type-configuration__list mr-5"
                justify="space-around"
              >
                <Col span={8}>
                  {loanTypeConfig?.loanTypeSteps?.map((config, index) => {
                    return (
                      <>
                        {checkCustomerInfo(
                          loanTypeConfig,
                          config?.loanSteps?.name ?? "",
                          config?.isRequired ? true : false
                        ) && (
                          <div>
                            <Row key={index}>
                              <Col span={22}>
                                <span
                                  className="arrow mr-2"
                                  onClick={() => {
                                    config?.isRequired
                                      ? handleligibilityVisibility()
                                      : setIsEligibilityVisibility(false);
                                  }}
                                >
                                  {config?.loanStepId == 1 ? (
                                    isEligibilityVisibility ? (
                                      <DownOutlined />
                                    ) : (
                                      <RightOutlined />
                                    )
                                  ) : (
                                    ""
                                  )}
                                </span>
                                <span className="text-primary loan-type-configuration__label">
                                  {config?.loanSteps?.name}
                                </span>{" "}
                              </Col>
                              <Col span={2}>
                                {" "}
                                <Switch
                                  checked={config?.isRequired}
                                  onChange={(value) => {
                                    userAccess?.writeAccess &&
                                      handleUpdateLoanTypeConfiguration(
                                        value,
                                        config,

                                        "loanTypeSteps"
                                      );
                                  }}
                                />
                              </Col>
                            </Row>

                            <div className="block"></div>
                          </div>
                        )}
                      </>
                    );
                  })}
                </Col>

                {isEligibilityVisibility ? (
                  <Col
                    span={8}
                    className="loan-type-configuration__eligibility content-box"
                  >
                    {loanTypeConfig?.loanSectionInputMaps?.map(
                      (config, index) => {
                        return (
                          <>
                            <Row>
                              <Col span={22}>
                                {" "}
                                <span className="text-primary loan-type-configuration__label">
                                  {config?.loanSectionInput?.name}
                                </span>
                              </Col>
                              <Col span={2}>
                                <Switch
                                  checked={config?.isRequired}
                                  onChange={(value) => {
                                    userAccess?.writeAccess &&
                                      handleUpdateLoanTypeConfiguration(
                                        value,
                                        config,

                                        "loanSectionInputMaps"
                                      );
                                  }}
                                />
                              </Col>
                            </Row>
                            <div className="block"></div>
                          </>
                        );
                      }
                    )}
                  </Col>
                ) : (
                  <Col span={8}></Col>
                )}
                {loanTypeConfig?.loanTypeSteps?.length &&
                userAccess?.writeAccess ? (
                  <Col span={24} className="text-right">
                    {" "}
                    <div>
                      <a
                        className="loan-type-configuration__cancel-btn mr-5"
                        onClick={handleFetchLoanTypeConfiguration}
                        type="link"
                      >
                        Cancel
                      </a>
                      <Button
                        className="loan-type-configuration__submit-btn ml-5"
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                        loading={updateLoading}
                      >
                        Save
                      </Button>
                    </div>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default LoanTypeConfiguration;
