import { Input } from "antd";
import React, { useState } from "react";
import searchIcon from "../../../Assets/images/search.png";
import Icon from "../Icon";
import "./searchInput.scss";

export interface SearchInputProps {
  placeholder?: string;
  value?: string;
  onSearch?: (searchVal: string) => void;
  hasIcon?: boolean;
  isActive?: boolean;
}

const SearchInput = (props: SearchInputProps) => {
  const { placeholder, onSearch, hasIcon = true, isActive = true, value } = props;
  const [searchValue, setSearchValue] = useState<string | undefined>(value);

  const handleSearch = (value: string | undefined) => {
    setSearchValue(value);
    if(onSearch) onSearch(value || '');
  };

  return (
    <div className="search-input">
      {isActive && (
        <Input
          onChange={(e) => handleSearch(e?.currentTarget?.value)}
          prefix={hasIcon && <Icon url={searchIcon} />}
          placeholder={placeholder}
          value={searchValue}
        />
      )}
    </div>
  );
};

export default SearchInput;