import { Badge, Col, Drawer, Popover, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LoanTypeDetailsModel } from "../../../../../models/loanType.model";
import { Meta } from "../../../../../models/meta.modal";
import { LoanService } from "../../../../../services/Loan/loan.service";
import { MetaService } from "../../../../../services/Meta/meta.service";
import useDrawer from "../../../../../shared/hooks/useDrawer";
import { fillEmptyData } from "../../../../../shared/utils/fillEmptyData";
import LoanTypeDetailForm from "../../LoanTypeDetailsForm";
import "./loanTypeDetailValue.scss";
import { LoanDeductionForm } from "../../../../../models/loanDeduction.model";
import { removeUnderscore } from "../../../../../shared/utils/removeUnderscore";
import CustomModal from "../../../../../shared/components/CustomModal";
import { LocalStorageHelper } from "../../../../../shared/utils/localStorageHelper";
import { LocalStorage } from "../../../../../enums/localStorage.enum";
import { toTitleCase } from "../../../../../shared/utils/titleCaseConverter";
import { selectOptions } from "../../../../../enums/selectionOptions.type";

interface LoanTypeDetailValueProps {
  reload: boolean;
  handleDeduction: (deduction: LoanDeductionForm) => void;
}

const LoanTypeDetailValue = (props: LoanTypeDetailValueProps) => {
  const {
    getLoanTypeDetails,
    getLoanDeduction,
    deleteLoanDeduction,
    updateLoanTypeStatus,
    loading,
  } = LoanService();
  const { reload, handleDeduction } = props;
  const [loanDetails, setLoanDetails] = useState<LoanTypeDetailsModel>();
  const [loanDeduction, setLoanDeduction] = useState<LoanDeductionForm[]>([]);
  const { loanTypeId: loanTypeId } = useParams();
  const [open, setOpen] = useState(false);
  const [openPopoverIndex, setOpenPopoverIndex] = useState(-1);
  const [modalVisible, setModalVisible] = useState(false);
  const userAccess = LocalStorageHelper.getItem(
    LocalStorage.CURRENT_USER_ACCESS
  );

  useEffect(() => {
    handleFetchLoanTypes();
    handleFetchLoanDeduction();
  }, [reload]);

  const handleOpenPopover = (index: number) =>
    setOpenPopoverIndex(index === openPopoverIndex ? -1 : index);
  const handlePopupVisibility = () => setOpen(!open);
  const {
    visible: isChangeLoanTypeDetails,
    toggleVisibility: toggleLoanDetailsForm,
  } = useDrawer({});

  const handleModalVisibility = () => setModalVisible(!modalVisible);

  const loanValues = [
    {
      title: "Loan Type Name",
      value: fillEmptyData(loanDetails?.name),
    },
    {
      title: "Minumum Amount",
      value: fillEmptyData(loanDetails?.minimumAmount),
    },
    {
      title: "Maximum Amount",
      value: fillEmptyData(loanDetails?.maximumAmount),
    },
    {
      title: "Minumum Tenure",
      value: fillEmptyData(
        loanDetails?.minimumTenure && `${loanDetails?.minimumTenure}  Months`
      ),
    },
    {
      title: "Maximum Tenure",
      value: fillEmptyData(
        loanDetails?.maximumTenure && `${loanDetails?.maximumTenure}  Months`
      ),
    },
    {
      title: "Minumum Interest",
      value: fillEmptyData(
        loanDetails?.minimumInterest && `${loanDetails?.minimumInterest} %`
      ),
    },
    {
      title: "Maximum Interest",
      value: fillEmptyData(
        loanDetails?.maximumInterest && `${loanDetails?.maximumInterest} %`
      ),
    },
    {
      title: "Region",
      value: loanDetails?.regions?.map(
        (region, index) =>
          `${region?.label}${index < Number(loanDetails?.regions?.length) - 1 ? ", " : ""
          }`
      ),
    },
    {
      title: "Branch",
      value: loanDetails?.branches?.map(
        (branch, index) =>
          `${branch?.label}${index < Number(loanDetails?.branches?.length) - 1 ? ", " : ""
          }`
      ),
    },
  ];

  const handleDeleteDeduction = async (id: string) => {
    const result = await deleteLoanDeduction(id);
    result && handleFetchLoanDeduction();
  };
  const handleFetchLoanTypes = async () => {
    const loanTypes = await getLoanTypeDetails(String(loanTypeId));
    loanTypes && setLoanDetails(loanTypes);
  };

  const handleUpdateLoanTypeDetails = (loan: LoanTypeDetailsModel) => {
    setLoanDetails(loan);
    toggleLoanDetailsForm();
  };

  const handleFetchLoanDeduction = async () => {
    const result = await getLoanDeduction(String(loanTypeId));
    result && setLoanDeduction(result);
  };

  const handleLoanTypeStatus = async () => {
    const result =
      loanDetails?.loanTypeId && (await updateLoanTypeStatus(loanDetails));

    if (result) {
      handleFetchLoanTypes();
      handleModalVisibility();
    }
  };

  const handleGstDeductionName = (name: string) => {
    const string = removeUnderscore(name);
    if (string?.toLowerCase().includes("fee")) {
      const newString = string.replaceAll('fee', '')
      return newString + ' Fee Structure';
    } else {
      return string + ' Fee Structure';
    }
  }
  
  return (
    <>
      <div className="card">
        <div className="loan-details-form">
          {loading ? (
            <div className="text-center">
              <Spin />
            </div>
          ) : (
            <Row>
              <Col md={12}>
                <Row gutter={[0, 24]}>
                  {loanValues?.map((loan, index) => {
                    return (
                      <>
                        <Col className="block__label" md={10}>
                          {loan?.title}
                        </Col>
                        <Col className="block__value" md={14}>
                          {loan?.value}
                        </Col>
                      </>
                    );
                  })}
                </Row>
              </Col>
              <Col span={8}>
                {loanDeduction?.map((data, index) => (
                  <div className="content-box mt-5">
                    <Row>
                      <Col span={24}>
                        {userAccess?.writeAccess && (
                          <Popover
                            placement="right"
                            open={openPopoverIndex === index}
                            onOpenChange={() => handleOpenPopover(index)}
                            content={
                              <Row>
                                <Col
                                  span={24}
                                  className="mb-3 cursor-pointer"
                                  onClickCapture={() => setOpenPopoverIndex(-1)}
                                  onClick={() =>
                                    handleDeduction({
                                      ...data,
                                      loanTypeId: Number(loanTypeId),
                                    })
                                  }
                                >
                                  Edit
                                </Col>
                                <Col
                                  span={24}
                                  className="mb-3 cursor-pointer"
                                  onClickCapture={() => setOpenPopoverIndex(-1)}
                                  onClick={() =>
                                    handleDeleteDeduction(String(data?.id))
                                  }
                                >
                                  Delete
                                </Col>
                              </Row>
                            }
                            trigger="click"
                          >
                            <div
                              className="text-right cursor-pointer "
                              onClick={() => handleOpenPopover(index)}
                            >
                              . . .
                            </div>
                          </Popover>
                        )}
                      </Col>
                      <Col span={24}>
                        <p className="text-bold text-heading">
                          {data && data.name && handleGstDeductionName(data.name)}
                        </p>
                      </Col>
                      <Col span={12}>
                        <p>Commission Structure</p>
                        <p>Fixed Rate</p>
                        <p>Percentage</p>
                          <p>Deduction Type</p>
                        {(data?.gstEnabled &&
                          <>
                            <p>GST Rate</p>
                          </>
                        )}
                      </Col>
                      <Col span={12}>
                        <p className="text-bold ">{fillEmptyData(data?.name)}</p>
                        <p className="text-bold ">{fillEmptyData(data?.fixedRate)}</p>
                        <p className="text-bold ">{fillEmptyData(data?.percentage)}</p>
                        {(data.deductionType && <p className="text-bold">{fillEmptyData(removeUnderscore(toTitleCase(data?.deductionType)))}</p>)}
                        {(data?.gstEnabled &&
                          <>
                            <p className="text-bold ">{data?.gst || 'N/A'}</p>
                          </>
                        )}
                      </Col>
                    </Row>
                  </div>
                ))}
              </Col>
              {userAccess?.writeAccess && (
                <Col span={4} className="text-right">
                  <Popover
                    placement="bottomLeft"
                    open={open}
                    onOpenChange={handlePopupVisibility}
                    content={
                      <Row className="personal-details__dot-details">
                        <Col
                          span={24}
                          onClickCapture={handlePopupVisibility}
                          className="mb-3"
                          onClick={() => toggleLoanDetailsForm(true)}
                        >
                          Edit
                        </Col>
                        <Col
                          span={24}
                          onClickCapture={handlePopupVisibility}
                          className="mb-3"
                          onClick={handleModalVisibility}
                        >
                          {`${loanDetails?.isActive ? selectOptions.DISABLE : selectOptions.ENABLE
                            } Loan Type`}
                        </Col>
                      </Row>
                    }
                    trigger="click"
                  >
                    <a className="personal-details__dot">. . .</a>
                  </Popover>
                </Col>
              )}
            </Row>
          )}
        </div>
        <Drawer
          placement="right"
          onClose={() => toggleLoanDetailsForm?.(false)}
          visible={isChangeLoanTypeDetails}
          width="1000"
          title="Update Loan Type"
          destroyOnClose
        >
          <LoanTypeDetailForm
            onSuccess={handleUpdateLoanTypeDetails}
            toggleLoanDetailsForm={toggleLoanDetailsForm}
            formValues={{ ...loanDetails, id: loanTypeId }}
            loanTypeId={String(loanTypeId)}
          />
        </Drawer>

        <CustomModal
          onCancel={handleModalVisibility}
          title={`${loanDetails?.isActive ? selectOptions.DISABLE : selectOptions.ENABLE} Loan Type`}
          visible={modalVisible}
          onOk={handleLoanTypeStatus}
          onClose={handleModalVisibility}
          okText={loanDetails?.isActive ? selectOptions.DISABLE : selectOptions.ENABLE}
          loading={loading}
        >
          {`Are you sure you want to ${loanDetails?.isActive ? selectOptions.DISABLE : selectOptions.ENABLE
            } this loan Type`}
        </CustomModal>
      </div>
    </>
  );
};

export default LoanTypeDetailValue;
