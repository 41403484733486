import { alias, list, primitive, serializable } from "serializr";

import { QueryParams } from "./query.params.model";

export class CenterParams extends QueryParams {
  @serializable(alias("branch_id", list(primitive())))
  branchId?: number[] = [];

  @serializable(alias("is_active", list(primitive())))
  isActive?: boolean[] = [];
}
export class Center {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("code", primitive()))
  code?: string;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("branch_id", primitive()))
  branchId?: number;

  @serializable(alias("branch_name", primitive()))
  branchName?: string;

  @serializable(alias("responsible_employee_id", primitive()))
  responsibleEmployeeId?: number;

  @serializable(alias("responsible_employee_name", primitive()))
  responsibleEmployeeName?: string;

  @serializable(alias("responsible_employee_profile_pic_url", primitive()))
  responsibleEmployeeProfilePicUrl?: string;

  @serializable(alias("is_active", primitive()))
  isActive?: boolean;
}
