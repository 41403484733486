export enum SidebarOptions {
  DASHBOARD = "dashboardManagement",
  CT_VERIFICATION = "ctVerificationManagement",
  USER = "userManagement",
  CUSTOMER = "customerConfiguration",
  PIPELINE = "pipelineManagement",
  LOAN = "loanManagement",
  REPAYMENTS = "repaymentManagement",
  DISBURSEMENT = "disbursement",
  CONFIGURATION = "configurationManagement",
  REPORTS = "reportManagement",
  WRITE_ACCESS = "writeAccess",
  DOCUMENTS = "documents",
  CLAIMS = "claimManagement"
}
