import useBreakPoint from "../../shared/hooks/useBreakPoint";

const useDashboardStatsSpan = () => {
  const { isMobile, isLargeScreen, isSmallScreen, isMediumScreen } = useBreakPoint();

  const getSpan = () => {
    if (isMobile || isSmallScreen || isLargeScreen || isMediumScreen) return 24;
    return 14;
  };

  return { getSpan };
};

export default useDashboardStatsSpan;
