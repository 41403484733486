import { alias, list, object, primitive, serializable } from "serializr";
import { FileAttachment } from "./fileAttachment.model";
import { LoanParams } from "./loan.model";
import { QueryParams } from "./query.params.model";

export class DepositsParams extends LoanParams {
  @serializable(alias("branch_ids", list(primitive())))
  branchIds?: number[] = [];

  @serializable(alias("collection_dates", list(primitive())))
  collectionDates?: string[] = [];
}
export class Deposits {
  @serializable(alias("code", primitive()))
  code?: string;
  @serializable(alias("user", primitive()))
  user?: string;
  @serializable(alias("role", primitive()))
  role?: string;
  @serializable(alias("branch", primitive()))
  branch?: string;
  @serializable(alias("deposited_date", primitive()))
  depositedDate?: string;
  @serializable(alias("created_at", primitive()))
  createdAt?: string;
  @serializable(alias("collected_amount", primitive()))
  collectedAmount?: number;
  @serializable(alias("deposited_amount", primitive()))
  depositedAmount?: number;
  @serializable(alias("cash_on_hand", primitive()))
  cashOnHand?: number;
  @serializable(alias("receipt", primitive()))
  receipt?: string;
}
