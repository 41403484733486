const claimsDocumentsUploadOptions = [
    {
        label: "Death Certificate",
        value: "death_certificate"
    },
    {
        label: "Other Documents",
        value: "other_document"
    }
]

export default claimsDocumentsUploadOptions