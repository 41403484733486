import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Comments } from "../../../../../models/comments.model";
import { Button, Col } from "antd";
import InputField from "../../../../../shared/components/InputField";
import { InputType } from "../../../../../enums/input.type";
import AttachmentUpload from "../../../../../shared/components/AttachmentUpload";
import { CloseCircleOutlined } from "@ant-design/icons";
import "./commentForm.scss";
import { CommentsService } from "../../../../../services/Comments/comments.service";
import { commentValidation } from "../commentValidation";

interface CommentFormProps {
  loanId: string;
  onSuccess: () => void;
  onClose: () => void;
}

function CommentForm(props: CommentFormProps) {
  const { onSuccess, onClose, loanId } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { addComment, loading: commentLoading } = CommentsService();

  const handleSubmit = async (values: Comments) => {
    const comment = await addComment(loanId, { ...values, step: "summary" });
    if (comment) {
      onSuccess();
      onClose();
    }
  };

  return (
    <div>
      <Formik
        initialValues={new Comments()}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={commentValidation}
      >
        {({ isSubmitting, values, isValid, dirty, setFieldValue }) => (
          <>
            <div className="comment-form">
              <div className="comment-form__content">
                <Form className="ant-form-vertical">
                  <InputField
                    type={InputType.TEXT_AREA}
                    name="description"
                    placeholder="Enter Comment"
                    label="Your Comments"
                  />

                  <AttachmentUpload
                    accept="image/*,application/pdf"
                    name="attachmentId"
                    placeholder="Upload File"
                    setFieldValue={setFieldValue}
                    isLoading={(value) => setIsLoading(value)}
                  />
                  {values.attachmentId && !isLoading && (
                    <Col className="mt-5" span={8}>
                      <span className="text-primary mr-5">File Uploaded</span>
                      <CloseCircleOutlined
                        onClick={() => setFieldValue("attachmentId", undefined)}
                      />
                    </Col>
                  )}
                  <div className="comment-form__content__footer drawer-footer">
                    <div className="drawer-footer">
                      <Button
                        onClick={onClose}
                        type="link"
                        className="drawer-footer__cancel"
                        htmlType="reset"
                      >
                        Cancel
                      </Button>
                      <Button
                        className="drawer-footer__submit"
                        htmlType="submit"
                        loading={commentLoading}
                        disabled={!dirty || !isValid || isLoading}
                      >
                        Add
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
}
export default CommentForm;
