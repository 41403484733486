const insurerNameOptions = [
    {
        label: "Life Insurance Corporation of India (LIC)",
        value: "life_insurance_corporation_of_india"
    },
    {
        label: "ICICI Prudential",
        value: "icici_prudential"
    },
    {
        label: "IndiaFirst",
        value: "india_first",
    },
    {
        label: "Pramerica",
        value: "pramerica"
    },
    {
        label: "Credit Access",
        value: "credit_access"
    }
]

export default insurerNameOptions