import { Col, Row, Table, TableColumnsType, TableProps } from "antd";
import { SorterResult } from "antd/lib/table/interface";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Loan, LoanParams } from "../../../../models/loan.model";
import {
  LoanTimeline,
  LoanTimelineParams,
} from "../../../../models/loanTimeline";

import { QueryParams } from "../../../../models/query.params.model";
import { LoanService } from "../../../../services/Loan/loan.service";
import useQueryParams from "../../../../shared/hooks/useQueryParams";
import useSorting from "../../../../shared/hooks/useSorting";
import { LoanTimelineColumn } from "./loanTimelineColumn";
import "./loanTimeline.scss";

interface LoanTimelineProps {
  loan?: Loan;
}

const LoanTimelines = (props: LoanTimelineProps) => {
  const [timeline, setLoanTimeline] = useState<LoanTimeline[]>([]);
  const { loanId } = useParams<{ loanId: string }>();
  const { params, handleSearch, updateParams } = useQueryParams({
    params: new LoanTimelineParams(),
  });
  const { loan } = props;

  //service
  const { loading, getLoanTimeline } = LoanService();

  const [loanTotalPage, setLoanTotalPage] = useState(1);

  const populateLoanTimeline = async (params?: QueryParams) => {
    if (loanId ?? loan?.id) {
      const loanTimeline = await getLoanTimeline(
        loanId ?? String(loan?.id),
        params
      );
      loanTimeline && setLoanTimeline(loanTimeline?.timeline);

      if (loanTimeline?.meta)
        setLoanTotalPage(loanTimeline?.meta?.totalCount ?? 1);
    }
  };
  const handleChange: TableProps<LoanTimeline>["onChange"] = (
    { current },
    _,
    sorter
  ) => {
    updateParams({ page: current });
  };

  useEffect(() => {
    populateLoanTimeline(params);
  }, [params, loan]);

  return (
    <div className="loan-timeline">
      <div className="card">
        <Table
          columns={LoanTimelineColumn}
          dataSource={timeline}
          loading={loading}
          onChange={handleChange}
          pagination={{
            hideOnSinglePage: true,
            total: loanTotalPage,
          }}
          scroll={{ x: true }}
        />
      </div>
    </div>
  );
};

export default LoanTimelines;
