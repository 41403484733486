import * as Yup from "yup";

export const passwordValidation = Yup.object().shape({
  newPassword: Yup.string()
    .required("Password is Required")
    .min(8, "Must be 8 characters or more")
    .matches(/[a-z]+/, "Atleast one lowercase character is required")
    .matches(/[A-Z]+/, "Atleast one uppercase character is required")
    .matches(/[@$!%*#?&]+/, "Atleast one special character is required")
    .matches(/\d+/, "At least one number is required"),
  confirmPassword: Yup.string()
    .required("Password confirmation is required!")
    .oneOf([Yup.ref("newPassword"), ""], "Passwords must match!"),
});
