import { useState } from "react";
import { generatePath } from "react-router-dom";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { AcknowledgeDocument } from "../../models/acknowledgeDocument.model";
import { deserialize, serialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import { NotificationTypes } from "../../enums/notificationTypes";
import Notification from "../../shared/components/Notification";
import { Loan } from "../../models/loan.model";
import { DocumentCreate } from "../../models/documentCreate.model";
import { DocumentUpload } from "../../models/fileAttachment.model";

export const ModService = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  const updateDocumentLocation = async (documentDetails: Partial<Loan>) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.LOAN_DETAIL, {
        loanId: documentDetails?.id?.toString(),
      });

      const serializableDocument = serialize(
        AcknowledgeDocument,
        documentDetails
      );
      const { data } = await axiosInstance.put(endpoint, {
        loan: serializableDocument,
      });
      const response = deserialize(AcknowledgeDocument, data.document);
      return response;
    } catch (error) {
      Notification({
        message: "Unable to update Loan Document Location",
        description:
          (error as Error)?.message ?? "Unable to update Loan Document Location",
        type: NotificationTypes.ERROR,
      });
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  const acknowledgeDocument = async (documentDetails: Partial<Loan>) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.ACKNOWLEDGE_DOCUMENT, {
        loanId: documentDetails?.id?.toString(),
      });

      const serializableDocument = serialize(
        AcknowledgeDocument,
        documentDetails
      );
      const { data } = await axiosInstance.put(endpoint, {
        loan: serializableDocument,
      });
      const response = deserialize(AcknowledgeDocument, data.document);
      return response;
    } catch (error) {Notification({
      message: (error as Error)?.message,
      description:
        (error as Error)?.message,
      type: NotificationTypes.ERROR,
    });
    setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  const toReturnMod = async (documentDetails: Partial<Loan>) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.RETURN_MOD_DOCUMENT, {
        loanId: documentDetails?.id?.toString(),
      });

      const serializableDocument = serialize(
        AcknowledgeDocument,
        documentDetails
      );
      const { data } = await axiosInstance.put(endpoint, {
        loan: serializableDocument,
      });
      const response = deserialize(AcknowledgeDocument, data.document);
      Notification({
        message: "Release Letter Uploaded Successfully",
        description: "",
        type: NotificationTypes.SUCCESS,
      });
      return response;
    } catch (error) {
      Notification({
        message: "Unable to Upload Release Letter",
        description:
          (error as Error)?.message ?? "Unable to Upload Release Letter",
        type: NotificationTypes.ERROR,
      });
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  const uploadReleaseLetter = async (documentDetails: Partial<Loan>) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.LOAN_DETAIL, {
        loanId: documentDetails?.id?.toString(),
      });

      const serializableDocument = serialize(
        AcknowledgeDocument,
        documentDetails
      );
      const { data } = await axiosInstance.put(endpoint, {
        loan: serializableDocument,
      });
      const response = deserialize(AcknowledgeDocument, data.document);
      Notification({
        message: "Release Letter Uploaded Successfully",
        description: "",
        type: NotificationTypes.SUCCESS,
      });
      return response;
    } catch (error) {
      Notification({
        message: "Unable to Upload Release Letter",
        description:
          (error as Error)?.message ?? "Unable to Upload Release Letter",
        type: NotificationTypes.ERROR,
      });
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  const uploadModDocument = async (documentDetails: Partial<Loan>) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.LOAN_DETAIL, {
        loanId: documentDetails?.id?.toString(),
      });

      const serializableDocument = serialize(
        AcknowledgeDocument,
        documentDetails
      );
      const { data } = await axiosInstance.put(endpoint, {
        loan: serializableDocument,
      });
      const response = deserialize(AcknowledgeDocument, data.document);
      Notification({
        message: "MOD Document Uploaded Successfully",
        description: "",
        type: NotificationTypes.SUCCESS,
      });
      return response;
    } catch (error) {
      Notification({
        message: "Unable to Upload MOD Document",
        description:
          (error as Error)?.message ?? "Unable to Upload MOD Document",
        type: NotificationTypes.ERROR,
      });
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  const createDocument = async (file: DocumentUpload, loanId: number) => {
    setLoading(true);
    try {
    const endpoint = generatePath(ApiRoutes.CREATE_CUSTOMER_DOCUMENT, {
      loanId: loanId
    });
    const serializedDocument = serialize(DocumentUpload, file)
    const { data } = await axiosInstance.post(endpoint, {
      document: serializedDocument,
    });
    const response = deserialize(DocumentCreate, data);
    Notification({
      message: "Document Uploaded Successfully",
      description: "",
      type: NotificationTypes.SUCCESS,
    });
    return response;
  }
    catch (error) {
      Notification({
        message: "Unable to Upload Document",
        description:
          (error as Error)?.message ?? "Unable to Upload Document",
        type: NotificationTypes.ERROR,
      });
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    acknowledgeDocument,
    uploadReleaseLetter,
    uploadModDocument,
    createDocument,
    updateDocumentLocation,
    toReturnMod,
    loading,
    error,
  };
};
