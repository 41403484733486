import React from 'react';
import { Field, FormikValues } from 'formik';
import { Switch } from 'antd';

interface SharedSwitchProps {
  name: string;
  onChange?: (name: string, checked: boolean) => void;
}

const SwitchComponent: React.FC<SharedSwitchProps> = ({ name, onChange }) => {
  const handleChange = (checked: boolean, form: FormikValues, fieldName: string) => {
    form.setFieldValue(fieldName, checked);
    if (onChange) {
        onChange(fieldName, checked);
      }
  };

  return (
    <Field name={name}>
      {({ field, form }: { field: any; form: FormikValues }) => (
        <Switch
          checked={field.value}
          onChange={(checked: boolean) => handleChange(checked, form, name)}
        />
      )}
    </Field>
  );
};

export default SwitchComponent;
