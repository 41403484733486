import { Col, Row } from "antd";
import React from "react";
import "./personalDetails.scss";
import { CrifReport } from "../../../models/creditReport.model";
import { Loan } from "../../../models/loan.model";
import { fillEmptyData } from "../../../shared/utils/fillEmptyData";

interface CrifPersonalDetailsProps {
  data?: CrifReport;
  loanData?: Loan;
}

interface Variation {
  value?: string | undefined;
  reportedDate?: string;
  key?: string;
}

const CrifPersonalDetails: React.FC<CrifPersonalDetailsProps> = (props) => {
  const { data, loanData } = props;

  const renderVariations = (variationList?: Variation[], label: string = '', showLabel: boolean = false) => {
    if ((!variationList || variationList.length === 0) && !showLabel) {
      return (
        <Row justify="center" className="variation-container">
          <Col className="text-danger">No data found</Col>
        </Row>
      );
    }

    return variationList?.map((variation: Variation, index: number) => (
      <Row
        justify="space-between"
        className="variation-container"
        key={variation.key || `${label}-${index}`}
      >
        <Col className="name word-break">
          {variation.value} {showLabel && `[${label}]`}
        </Col>
        <Col className="date">{variation.reportedDate}</Col>
      </Row>
    ));
  };

  return (
    <div className="crif-personal-details">
      <Row>
        <Col span={11} className="card">
          <Row justify="space-between">
            <Col className="card-label">Name Variations</Col>
            <Col className="card-label">Reported On</Col>
          </Row>
          <Row className="line-row">
            <Col className="text-gray" offset={1}>
              Borrower Name:
            </Col>
            <Col className="text-blue" offset={1}>
              {fillEmptyData(loanData?.customer?.getName())}
            </Col>
          </Row>
          {renderVariations(data?.personalInfoVariation?.nameVariations)}
        </Col>
        <Col span={11} className="card" offset={1}>
          <Row justify="space-between">
            <Col className="card-label">DOB Variations</Col>
            <Col className="card-label">Reported On</Col>
          </Row>
          <Row className="line-row">
            <Col className="text-gray">Borrower DOB:</Col>
            <Col className="text-blue" offset={1}>
              {fillEmptyData(data?.personalInfoVariation?.dateOfBirthVariations?.[0]?.value)}
            </Col>
          </Row>
          {renderVariations(data?.personalInfoVariation?.dateOfBirthVariations)}
        </Col>
      </Row>
      <Row>
        <Col span={11} className="card">
          <Row justify="space-between">
            <Col className="card-label">Phone Number Variations</Col>
            <Col className="card-label">Reported On</Col>
          </Row>
          <Row className="line-row">
            <Col className="text-gray">Borrower Phone Number:</Col>
            <Col className="text-blue" offset={1}>
              {fillEmptyData(data?.personalInfoVariation?.phoneNumberVariations?.[0]?.value)}
            </Col>
          </Row>
          {renderVariations(data?.personalInfoVariation?.phoneNumberVariations)}
        </Col>
        <Col span={11} className="card" offset={1}>
          <Row justify="space-between">
            <Col className="card-label">ID Variations</Col>
            <Col className="card-label">Reported On</Col>
          </Row>
          <Row className="line-row">
            <Col className="text-gray">Borrower ID:</Col>
            <Col className="text-blue" offset={1}>
              {`${fillEmptyData(data?.personalInfoVariation?.panVariations?.[0]?.value)} ${data?.personalInfoVariation?.panVariations?.[0]?.value ? `[PAN]` : ''}`}
            </Col>
          </Row>
          {renderVariations(data?.personalInfoVariation?.panVariations, 'PAN')}
          {renderVariations(data?.personalInfoVariation?.ckycVariations, 'CKYC', true)}
          {renderVariations(data?.personalInfoVariation?.drivingLicenseVariations, 'DRIVING LICENSE', true)}
          {renderVariations(data?.personalInfoVariation?.nRegaVariations, 'NREGA', true)}
          {renderVariations(data?.personalInfoVariation?.passPortVariations, 'PASSPORT', true)}
          {renderVariations(data?.personalInfoVariation?.rationCardVariations, 'RATION CARD', true)}
          {renderVariations(data?.personalInfoVariation?.emailVariations, 'EMAIL', true)}
        </Col>
      </Row>
      <Row>
        <Col span={11} className="card">
          <Row justify="space-between">
            <Col className="card-label">Address Variations</Col>
            <Col className="card-label">Reported On</Col>
          </Row>
          <Row className="line-row">
            <Col className="text-gray word-break">Borrower Address:</Col>
            <Col className="text-blue address-col" offset={1}>
              {fillEmptyData(data?.personalInfoVariation?.addressVariations?.[0]?.value)}
            </Col>
          </Row>
          {renderVariations(data?.personalInfoVariation?.addressVariations)}
        </Col>
      </Row>
    </div>
  );
};

export default CrifPersonalDetails;
