import { alias, list, optional, primitive, serializable } from "serializr";
import { QueryParams } from "./query.params.model";
import { LoanStatus } from "../enums/loanStatus.type";
import { getLoanStatusLabel } from "../shared/utils/getLoanStatusLabel";

export class AccountantParams extends QueryParams {
  @serializable(alias("customer_id", optional(list(primitive()))))
  customerId?: number[] = [];

  @serializable(alias("status", optional(list(primitive()))))
  status?: string[] = [];

  @serializable(alias("branch_id", optional(list(primitive()))))
  branchId? = [];
  @serializable(alias("center_id", optional(list(primitive()))))
  centerId? = [];

  @serializable(alias("applied_from_date", primitive()))
  appliedFromDate?: string;

  @serializable(alias("applied_to_date", primitive()))
  appliedToDate?: string;

  @serializable(alias("loan_type_id", optional(list(primitive()))))
  loanTypeId? = [];

  @serializable(alias("created_by_id", optional(list(primitive()))))
  createdById? = [];
  @serializable(alias("organisation_ids", optional(list(primitive()))))
  organisations? = [];
  @serializable(alias("offline_loan", primitive()))
  offlineLoan?: boolean;
  @serializable(alias("rejection_reason", primitive()))
  rejectionReason?: string;
  @serializable(alias("from_date", primitive()))
  fromDate?: string;
  @serializable(alias("to_date", primitive()))
  toDate?: string;
  @serializable(alias("disbursed_from_date", primitive()))
  disbursedFromDate?: string;
  @serializable(alias("disbursed_to_date", primitive()))
  disbursedToDate?: string;
  @serializable(alias("approved_to_date", primitive()))
  approvedToDate?: string;
  @serializable(alias("approved_from_date", primitive()))
  approvedFromDate?: string;
  @serializable(alias("submitted_to_date", primitive()))
  submittedToDate?: string;
  @serializable(alias("submitted_from_date", primitive()))
  submittedFromDate?: string;
  @serializable(alias("pipeline", primitive()))
  pipeline?: boolean;
  @serializable(alias("approved", primitive()))
  approved?: boolean;
  @serializable(alias("ct_verification", primitive()))
  ctVerification?: boolean;
  @serializable(alias("disbursed", primitive()))
  disbursed?: boolean;
}

export class Accountant {
    @serializable(primitive())
    id?: number;

    @serializable(primitive())
    status?: string;

    @serializable(alias("loan_code", primitive()))
    loanCode?: string;

    @serializable(alias("branch", primitive()))
    branch?: string;

    @serializable(alias("loan_id", primitive()))
    loanId?: number;

    @serializable(alias("customer_name", primitive()))
    customerName?: string;

    @serializable(alias("cro_name", primitive()))
    croName?: string;

    @serializable(alias("instalment_id", primitive()))
    installmentId?: number;

    @serializable(alias("due_amount", primitive()))
    dueAmount?: number;

    @serializable(alias("due_date", primitive()))
    dueDate?: string;

    @serializable(alias("entry_date", primitive()))
    entryDate?: string;

    @serializable(alias("back_date", primitive()))
    backDate?: string;

    @serializable(primitive())
    reason?: string;

    @serializable(primitive())
    description?: string;

    @serializable(alias("rejected_reason", primitive()))
    rejectedReason?: string;

    @serializable(primitive())
    attachment?: string;

    @serializable(alias('paid_amount', primitive()))
    paidAmount?: number;

    getLoanStatusLabel = () => {
        if (this.status) {
          const loanStatus: LoanStatus = this.status as LoanStatus;
          return getLoanStatusLabel(loanStatus);
        } else {
          return "Status undefined";
        }
      };
}