//Icons

//Libraries
import { Button, Tabs } from "antd";
import FiltersListing, {
  FiltersListingProps,
} from "../../../shared/components/FiltersListing";
import { Form, Formik } from "formik";

import { BranchesService } from "../../../services/Branches/branches.service";
import { CentersService } from "../../../services/Centers/centers.service";
import { Employee, EmployeeParams } from "../../../models/employee.model";
import { MetaService } from "../../../services/Meta/meta.service";
import { QueryParams } from "../../../models/query.params.model";
import React from "react";
import { LocalStorageHelper } from "../../../shared/utils/localStorageHelper";
import { LocalStorage } from "../../../enums/localStorage.enum";
import { RoleName } from "../../../enums/roleName.enum";
import { hideTab } from "../../../shared/utils/hideTab";

const userFilters = ["roleIds", "branchesId", "centersId", "organisationIds"];

interface UserFilterProps {
  onFilter: (val: EmployeeParams) => void;
  onClose: () => void;
}

const UserFilters = (props: UserFilterProps) => {
  // Services
  const { getRoles, loading: metaLoading, getPreferredChannel } = MetaService();
  const { getBranches, loading: employeesLoading } = BranchesService();
  const { getCenters, loading: centersLoading } = CentersService();
  const employee = LocalStorageHelper.getItem(
    LocalStorage.EMPLOYEE
  ) as Employee;

  //Prop
  const { onClose, onFilter } = props;

  const fetchRoles = async () => {
    const data = await getRoles();
    return { data };
  };

  const fetchBranches = async (params: QueryParams) => {
    const result = await getBranches(params);

    return {
      data: result?.branches.map((branch) => ({
        label: branch?.name,
        value: branch?.id,
      })),
      meta: result?.meta,
    };
  };

  const fetchCenters = async (params: QueryParams) => {
    const result = await getCenters(params);
    return {
      data:
        result?.centers?.map((center) => ({
          label: center?.name,
          value: center?.id,
        })) ?? [],
      meta: result?.meta,
    };
  };
  const fetchPreferredChannel = async () => {
    const result = await getPreferredChannel();

    return {
      data: result?.map((val) => ({
        label: val?.label,
        value: val?.value,
      })),
    };
  };

  const filtersTab: { title: string; tabContent: FiltersListingProps }[] = [
    {
      title: "Center",
      tabContent: {
        fetchData: fetchCenters,
        hasPagination: true,
        loading: centersLoading,
        name: "centersId",
        searchProps: {
          placeholder: "Search Center",
          hasIcon: false,
          onSearch: () => {},
        },
      },
    },
    {
      title: "Role",
      tabContent: {
        fetchData: fetchRoles,
        hasPagination: false,
        loading: metaLoading,
        name: "roleIds",
      },
    },
    {
      title: "Branch",
      tabContent: {
        fetchData: fetchBranches,
        hasPagination: true,
        loading: employeesLoading,
        name: "branchesId",
        searchProps: {
          placeholder: "Search Branch",
          hasIcon: false,
          onSearch: () => {},
        },
      },
    },
    {
      title: "Preferred Channel",
      tabContent: {
        fetchData: fetchPreferredChannel,
        hasPagination: false,
        loading: metaLoading,
        name: "organisationIds",
      },
    },
  ];

  const handleSubmit = (val: EmployeeParams) => {
    const filters = Object.fromEntries(
      Object.entries(val).filter(([key]) => userFilters.includes(key))
    );
    onFilter({ ...filters, page: 1 });
    onClose();
  };

  return (
    <div className="loan-filters">
      <Formik initialValues={new EmployeeParams()} onSubmit={handleSubmit}>
        {({ setFieldValue }) => (
          <Form>
            <Tabs tabPosition="left">
              {filtersTab
                .filter(
                  ({ tabContent: { name } }) =>
                    !hideTab(
                      employee?.roleName === RoleName.BRANCH_MANAGER,
                      name,
                      ["branchesId", "roleIds"]
                    )
                )
                .map(({ title, tabContent }, index) => (
                  <Tabs.TabPane tab={title} key={index}>
                    <FiltersListing {...tabContent} />
                  </Tabs.TabPane>
                ))}
            </Tabs>

            <div className="drawer-footer loan-filters__footer">
              <Button
                className="drawer-footer__cancel"
                htmlType="reset"
                onClick={onClose}
                type="link"
              >
                Cancel
              </Button>
              <Button className="drawer-footer__submit" htmlType="submit">
                Assign
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UserFilters;
