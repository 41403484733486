import React, { useEffect, useState } from "react";
import { Button, Drawer, Tabs } from "antd";
import Back from "../../../../shared/components/Back";
import { TabsProps } from "../../../../shared/types/Tabs.type";
import LoanTypeCondition from "./LoanTypeCondition";
import LoanTypeConfiguration from "./LoanTypeConfiguration";
import LoanTypeDetailValue from "./LoanTypeDetailValue";
import { QueryParams } from "../../../../models/query.params.model";
import useQueryParams from "../../../../shared/hooks/useQueryParams";
import { useLocation } from "react-router-dom";
import LoanDeductionForm from "./LoanDeductionForm";
import { LoanDeductionForm as LoanDeductionFormModel } from "../../../../models/loanDeduction.model";
import "./loanTypeDetails.scss";
import { LocalStorageHelper } from "../../../../shared/utils/localStorageHelper";
import { LocalStorage } from "../../../../enums/localStorage.enum";

const LoanTypeDetail = () => {
  const location = useLocation();
  const [isDeductionFormVisible, setIsDeductionFormVisible] = useState(false);
  const [deductionFormValue, setDecuctionFormValue] =
    useState<LoanDeductionFormModel>();
  const [reload, setReload] = useState(false);
  const { updateParams } = useQueryParams<QueryParams>({
    params: new QueryParams(),
  });
  const userAccess = LocalStorageHelper.getItem(
    LocalStorage.CURRENT_USER_ACCESS
  );
  const [activeTab, setActiveTab] = useState("1");
  useEffect(() => {
    setActiveTab(
      String(
        tabs?.find((tab) => location?.hash === tab?.hash)?.key ?? activeTab
      )
    );
  }, []);
  const handleTabChange = (activeKey: string) => {
    const hash = tabs?.find((tab, index) => tab?.key === activeKey)?.hash;
    updateParams({ page: 1 }, hash);
    setActiveTab(activeKey);
  };

  const handleLoanDeductionSuccess = () => {
    setReload(!reload);
    handleDeductionFormVisibility();
  };
  const handleDeduction = (values: LoanDeductionFormModel) => {
    setDecuctionFormValue(values);
    handleDeductionFormVisibility();
  };
  const tabs: TabsProps[] = [
    {
      label: "Loan Type Details",
      key: "1",
      component: (
        <LoanTypeDetailValue
          reload={reload}
          handleDeduction={handleDeduction}
        />
      ),
      hash: "#loan-type-details",
    },

    {
      label: "Loan Type Conditions",
      key: "2",
      component: <LoanTypeCondition />,
      hash: "#loan-type-conditions",
    },
    {
      label: "Loan Type Configuation",
      key: "3",
      component: <LoanTypeConfiguration />,
      hash: "#loan-type-configuration",
    },
  ];

  const getTabExtraContent = () => {
    switch (activeTab) {
      case "1":
        return (
          <div>
            {userAccess?.writeAccess && (
              <Button
                className="btn-tertiary"
                onClick={() => {
                  setIsDeductionFormVisible(true);
                  setDecuctionFormValue(new LoanDeductionFormModel());
                }}
              >
                Loan Deduction
              </Button>
            )}
          </div>
        );

      default:
        <></>;
    }
  };
  const handleDeductionFormVisibility = () =>
    setIsDeductionFormVisible(!isDeductionFormVisible);
  return (
    <div className="customer-detail">
      <div className="customer-detail__header">
        <Back />
      </div>
      <div className="customer-detail__content">
        <Tabs
          onChange={handleTabChange}
          activeKey={activeTab}
          tabBarExtraContent={getTabExtraContent()}
        >
          {tabs.map(({ component, label, key }) => (
            <Tabs.TabPane tab={label} key={key}>
              {component}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
      <Drawer
        placement="right"
        open={isDeductionFormVisible}
        destroyOnClose
        onClose={handleDeductionFormVisibility}
        title={deductionFormValue?.id ? 'Edit Deduction' : 'Add Deduction'}
        className="loan-type-details__drawer"
        width={"55vw"}
      >
        <LoanDeductionForm
          deductionFormValue={deductionFormValue}
          onSuccess={handleLoanDeductionSuccess}
          toggleHandler={handleDeductionFormVisibility}
        />
      </Drawer>
    </div>
  );
};

export default LoanTypeDetail;
