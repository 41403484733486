import { BackDateReason } from "../../enums/backDateReason.type";

export const getBackDateReason = (status: string) => {
  switch (status) {
    case BackDateReason.BANK_DEPOSIT:
      return "Paid to Branch";

    case BackDateReason.DELAY:
      return "Cro failed to update";

    default:
      return status;
  }
};
