import { Form, Formik, FormikHelpers } from "formik";
import React, { useState } from "react";
import CustomModal from "../../../shared/components/CustomModal";
import { modDocumentValidation } from "./modFormValidation";
import { Button, Col, Row } from "antd";
import AttachmentUpload from "../../../shared/components/AttachmentUpload";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Loan } from "../../../models/loan.model";
import { ModService } from "../../../services/MODOfficer/modofficer.service";
import { DocumentCreate } from "../../../models/documentCreate.model";
import { DocumentType } from "../../../enums/documentType.type";
import { useNavigate, useParams } from "react-router-dom";
import { DocumentUpload } from "../../../models/fileAttachment.model";

type Props = {
  loan?: Loan;
  onClose?: () => void;
  visible?: boolean;
  securityDocument?: boolean;
  title?: string;
  refreshData: () => void;
};

const MODUploadForm = (props: Props) => {
  const { visible, onClose, loan, securityDocument, title="Upload MOD Document", refreshData } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { createDocument } = ModService()
  const { loanId } = useParams()
  const navigate = useNavigate()
  
    const handleUploadDocumentModal = async (values: DocumentUpload, formikHelpers: FormikHelpers<DocumentUpload>) => {
      if (values?.frontSideImageId) {
        const response = await createDocument(
          {
            frontSideImageId: values.frontSideImageId,
            documentType: securityDocument ? DocumentType.MOD_DOCUMENT : DocumentType.OTHER_SECURITY,
          },
          loan?.id || 0
        );
        if (onClose) {
          formikHelpers.resetForm();
          onClose()
        }
        refreshData()
      }
    };
  return (
    <div>
      <Formik
        initialValues={new Loan()}
        onSubmit={handleUploadDocumentModal}
        validationSchema={modDocumentValidation}
      >
        {({ setFieldValue, isValid, dirty, values, resetForm }) => {
          const handleCancel = () => {
            if (onClose) onClose();
            resetForm();
          };

          return (
            <div className="security-mod-form">
              <CustomModal
                className="mod-document-modal"
                title={title}
                visible={visible ?? false}
                isCancelButton={false}
                isOkButton={false}
                onClose={onClose}
              >
                <Form className="ant-form-vertical mt-5">
                  <Row gutter={[0, 30]}>
                    <Col span={24}>
                      <AttachmentUpload
                        accept="image/*,application/pdf"
                        name="frontSideImageId"
                        placeholder="Upload File"
                        setFieldValue={setFieldValue}
                        isLoading={(value) => setIsLoading(value)}
                      />
                      {values.frontSideImageId && !isLoading && (
                        <Col className="mt-5" span={8}>
                          <span className="text-primary mr-5">
                            File Uploaded
                          </span>
                          <CloseCircleOutlined
                            onClick={() =>
                              setFieldValue("frontSideImageId", undefined)
                            }
                          />
                        </Col>
                      )}
                    </Col>
                    <Col span={24}>
                      <div className="drawer-footer text-right ">
                        <Button onClick={handleCancel}>Cancel</Button>
                        <Button
                          htmlType="submit"
                          disabled={!dirty || !isValid || isLoading}
                          loading={false}
                          type="link"
                          className="mod-upload-button"
                          onClick={()=> handleUploadDocumentModal}
                        >
                          Upload
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CustomModal>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default MODUploadForm;
