import { Col, Image, Row } from "antd";
import React, { ReactElement } from "react";

import { Customer } from "../../../../../models/customer.model";
import { percentageFormatter } from "../../../../../shared/utils/formatter";
import { getFullName } from "../../../../../shared/utils/getFullName";
import verifiedImage from "../../../../../Assets/images/verified.png";

interface ThirdPartyVerificationProps {
  customer: Customer;
}

const ThirdPartyVerification = (props: ThirdPartyVerificationProps) => {
  const { customer } = props;

  const aadharColumn = [
    {
      label: "Aadhar No",
      value: customer?.aadhaarNumber,
    },

    {
      label: "DOB",
      value: customer?.getDOB(),
    },
    {
      label: "Gender",
      value: customer?.gender,
    },
    {
      label: "Address",
      value: customer?.address?.getAddress(),
    },
  ];
  const panColumn = [
    {
      label: "PAN No",
      value: customer?.panNumber,
    },

    {
      label: "First Name",
      value: customer?.firstName,
    },
    {
      label: "Last Name",
      value: customer?.lastName,
    },
    {
      label: "DOB",
      value: customer?.getDOB(),
    },
  ];
  const bankDetailsColumn = [
    {
      label: "Bank",
      value: customer?.bankDetails?.bankName,
    },

    {
      label: "Registered Name",
      value: customer?.bankDetails?.registeredName,
    },
    {
      label: "Account No.",
      value: customer?.bankDetails?.accountNumber,
    },
    {
      label: "IFSC",
      value: customer?.bankDetails?.ifscCode,
    },
    {
      label: "Branch",
      value: customer?.bankDetails?.branchName,
    },
  ];

  return (
    <div>
      {" "}
      <Row gutter={[16, 16]} className="block">
        <Col span={24} className="block__heading">
          Aadhar Verification
        </Col>
        <Col span={24}>
          <Row gutter={[0, 16]}>
            {aadharColumn?.map((aadhar) => {
              return (
                <>
                  <Col span={5} className="block__label">
                    {aadhar?.label}
                  </Col>
                  <Col span={16} className="block__value text-capitalize">
                    {aadhar?.value || "NA"}
                  </Col>
                </>
              );
            })}
          </Row>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="block">
        <Col span={24} className="block__heading">
          PAN Verification
        </Col>
        <Col span={24}>
          <Row gutter={[0, 16]}>
            {panColumn?.map((pan) => {
              return (
                <>
                  <Col span={5} className="block__label">
                    {pan?.label}
                  </Col>
                  <Col span={16} className="block__value text-capitalize">
                    {pan?.value || "NA"}
                  </Col>
                </>
              );
            })}
          </Row>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="block">
        <Col span={24} className="block__heading">
          Bank Account Details
        </Col>
        <Col span={24}>
          <Row gutter={[0, 16]}>
            {bankDetailsColumn?.map((bank) => {
              return (
                <>
                  <Col span={5} className="block__label">
                    {bank?.label}
                  </Col>
                  <Col span={16} className="block__value text-capitalize">
                    {bank?.value || "NA"}
                  </Col>
                </>
              );
            })}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ThirdPartyVerification;
