import React, { FC } from "react";
import "./familyDetails.scss";
import CustomImage from "../../../../shared/components/CustomImage";
import { VerificationStatus } from "../../../../enums/verificationStatus";
import { getLocation } from "../../../../shared/hooks/getLocation";
import { Loan } from "../../../../models/loan.model";
import { Customer } from "../../../../models/customer.model";
import { FamilyMember } from "../../../../models/familyMember.model";
import verifiedImage from "../../../../Assets/images/verified.png";
import { removeUnderscore } from "../../../../shared/utils/removeUnderscore";
import { fillEmptyData } from "../../../../shared/utils/fillEmptyData";
import CustomDocument from "../../../../shared/components/CustomDocument";
import { currencyFormatter } from "../../../../shared/utils/formatter";
import { LoanType } from "../../../../enums/loanType.type";
import { maskNumber } from "../../../../shared/utils/maskNumber";

interface FamilyDetailsProps {
  loan?: Loan;
  customer: Customer;
  nominee?: FamilyMember | undefined;
  coBorrower?: FamilyMember | undefined;
}

const FamilyDetails: FC<FamilyDetailsProps> = ({
  loan,
  customer,
  nominee,
  coBorrower,
}) => {
  const familyData = [
    {
      label: "Name",
      value: (data: Customer | FamilyMember) => (
        <div className="family-details__name">
          <CustomDocument width={70} height={70} src={data?.profilePicUrl} />
          <span className="ml-5"> {fillEmptyData(data.getName())}</span>
        </div>
      ),
    },
    {
      label: "DOB",
      value: (data: Customer | FamilyMember) => fillEmptyData(data?.getDOB()),
    },
    {
      label: "Age",
      value: (data: Customer | FamilyMember) => fillEmptyData(data?.getAge()),
    },
    {
      label: "Gender",
      value: (data: Customer | FamilyMember) => fillEmptyData(data?.gender),
    },
    {
      label: "Address",
      value: (data: Customer | FamilyMember) =>
        fillEmptyData(data?.address?.getAddress()),
    },

    {
      label: "Location Co-ordinates",
      value: (data: Customer | FamilyMember) => {
        return data?.address?.latitude && data?.address?.longitude ? (
          <div>
            <a
              target="_blank"
              href={getLocation(
                data?.address?.latitude,
                data?.address?.longitude
              )}
              className="mr-5"
            >{`${data?.address?.latitude} ${data?.address?.longitude}`}</a>
          </div>
        ) : (
          "NA"
        );
      },
    },
    {
      label: "Aadhaar Number",
      value: (data: Customer | FamilyMember) => {
        const isAadhaarVerified =
          (data instanceof FamilyMember && data?.isAadhaarVerified) ||
          (data instanceof Customer &&
            loan?.verification?.aadhaarNumber === VerificationStatus.vertified);

        return (
          <div className="family-details__verify">
            <span className="mr-3">
              {fillEmptyData(
                maskNumber(data?.aadhaarNumber, true)
              )}
            </span>
            {isAadhaarVerified && (
              <CustomImage
                src={verifiedImage}
                width={19}
                height={19}
                isPopup={false}
              />
            )}
          </div>
        );
      },
    },
    {
      label: "PAN Number",
      value: (data: Customer | FamilyMember) => (
        <div className="family-details__verify">
          <span className="mr-3"> {fillEmptyData(data?.panNumber)}</span>
          {(data instanceof Customer &&
            loan?.verification?.panNumber === VerificationStatus.vertified) ||
          (data instanceof FamilyMember && data?.isPanVerified) ? (
            <CustomImage
              src={verifiedImage}
              width={19}
              height={19}
              isPopup={false}
            />
          ) : null}
        </div>
      ),
    },
    {
      label: "Phone Number",
      value: (data: Customer | FamilyMember) => (
        <div className="family-details__verify">
          <span className="mr-3"> {fillEmptyData(data?.getPhoneNumber())}</span>
          {loan?.customer?.isMobileVerified && (
            <CustomImage
              src={verifiedImage}
              width={19}
              height={19}
              isPopup={false}
            />
          )}
        </div>
      ),
    },
    {
      label: "Email ID",
      value: (data: Customer | FamilyMember) => (
        <div className="family-details__verify">
          <span className="mr-3"> {fillEmptyData(data?.email)}</span>
          {data?.isEmailVerified && (
            <CustomImage
              src={verifiedImage}
              width={19}
              height={19}
              isPopup={false}
            />
          )}
        </div>
      ),
    },
    {
      label: "Educational Qualification",
      value: (data: Customer | FamilyMember) =>
        fillEmptyData(removeUnderscore(data?.educationalQualification ?? "")),
    },
    {
      label: "Occupation",
      value: (data: Customer | FamilyMember) => fillEmptyData(removeUnderscore(data?.occupation)),
    },
    {
      label: "Relationship",
      value: (data: Customer | FamilyMember) => {
        const relationship = fillEmptyData(removeUnderscore(data?.relationship));
        return data instanceof Customer ? "Myself" : relationship;
      },
    },

    ...(loan?.loanTypeCode === LoanType.BUSINESS
      ? [
          {
            label: "Business Income",
            value: (data: Customer | FamilyMember) =>
              currencyFormatter({
                amount: Number(data?.businessIncome),
                hasCurrencySign: true,
              }),
          },
          {
            label: "Additional Income",
            value: (data: Customer | FamilyMember) =>
              currencyFormatter({
                amount: Number(data?.monthlyIncome),
                hasCurrencySign: true,
              }),
          },
        ]
      : []),
  ];
  return (
    <div className="block family-details">
      <div className="block__heading mb-5">
        Borrower/Co-Borrower/Additional Insured Information
      </div>
      <table className="custom-table family-loan-table">
        <thead>
          <tr>
            <th></th>
            <th>Borrower</th>
            <th>Co-Borrower</th>
            <th>Additional Insured Information</th>
          </tr>
        </thead>
        <tbody>
          {familyData.map(({ label, value }) => (
            <tr key={label} className={label.toLowerCase() === "address" ? "address-row" : ""}>
              <td className="text-capitalize">{label}</td>
              {[customer, coBorrower, nominee].map((data) => (
                <td className="text-capitalize text-bold" key={data?.id}>
                  {data ? value(data) : "NA"}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FamilyDetails;
