import { modLoansTypes } from "../../enums/modLoans.type";
import { LoanParams } from "../../models/loan.model";

export const updateSelectedValue = (
    value: string,
    setSelectedValue: React.Dispatch<React.SetStateAction<string>>,
    updateParams: (params: LoanParams) => void
  ) => {
    setSelectedValue(value);
    switch (value) {
      case modLoansTypes.SECURED:
        updateParams({ securityType: modLoansTypes.SECURED });
        break;
      case modLoansTypes.UNSECURED:
        updateParams({ securityType: modLoansTypes.UNSECURED });
        break;
      default:
        updateParams({ page: 1 });
    }
  };