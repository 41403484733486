interface ObjectWithFalsyValues {
  [key: string]: any;
}

export const removeFalsyKeys = (
  obj: ObjectWithFalsyValues
): ObjectWithFalsyValues => {
  for (const propName in obj) {
    if ([false, 0].includes(obj[propName])) continue;
    if (!obj[propName] || obj[propName].length === 0) {
      delete obj[propName];
      continue;
    }

    if (typeof obj[propName] === "object") {
      removeFalsyKeys(obj[propName]);
    }
  }

  return obj;
};
