import React from "react";
import { ColumnProps } from "antd/lib/table";
import { DateFormat } from "../../../../enums/dateFormat.type";
import { LoanInstallmentSchedule } from "../../../../models/loanInstallmentSchedule.model";
export const LoanInstallmentColumn: ColumnProps<LoanInstallmentSchedule>[] = [
  {
    title: "Installment No",
    dataIndex: "repaymentNo",
  },

  {
    title: "Installment Date",
    dataIndex: "instalmentDate",
  },
  {
    title: "Installment Amount",
    dataIndex: "amount",
  },
  {
    title: "Principal",
    dataIndex: "principal",
  },
  {
    title: "Interest",
    dataIndex: "interest",
  },
  {
    title: "Closing Pricipal",
    dataIndex: "closingPrincipal",
  },
];
