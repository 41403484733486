import { modLoansTypes } from "../../enums/modLoans.type";

export const renderModStatus = (status?: string) => {
    switch (status) {
        case modLoansTypes.MOD:
            return "Secured - MOD"
        case modLoansTypes.UNSECURED:
            return "Unsecured"
        case modLoansTypes.EQUAITABLE_MORTAGE:
            return "Secured - EM"
    }
}