import { alias, list, object, primitive, serializable } from "serializr";
import { Customer } from "./customer.model";

export class Group {
  @serializable(alias("id", primitive()))
  id?: string;

  @serializable(alias("code", primitive()))
  code?: string;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("customers", list(object(Customer))))
  customers?: Customer[];
}
