import React, { useState } from "react";
import { Modal } from "antd";
import "./customDocuments.scss";
import previewAvatarImage from "../../Constant/previewAvatarImage";
import pdfImage from "../../../Assets/images/pdf.png";
import { getFileExtension } from "../../utils/getFileExtension";
import { DocumentExtensions } from "../../../enums/DocumentExtensions.type";

interface CustomDocumentProps {
  height?: number;
  width?: number;
  src?: string;
  label?: string;
  classname?: string;
  onlyText?: boolean;
  thumbnailSrc?: string;
}

const CustomDocument: React.FC<CustomDocumentProps> = ({
  height = 130,
  width = 211,
  src,
  label,
  classname,
  onlyText = false,
  thumbnailSrc
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleImageClick = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const isPDF = (endpoint: string) =>
    getFileExtension(endpoint) === DocumentExtensions.PDF ? true : false;

  return (
    <div className="custom-document">
      {onlyText ? (
        <span className="cursor-pointer" onClick={handleImageClick}>
          {label}
        </span>
      ) : isPDF(src ?? "") ? (
        <img
          src={pdfImage}
          style={{
            objectFit: "contain",
            height: `${height}px`,
            width: `${width}px`,
            cursor: "pointer",
          }}
          onClick={handleImageClick}
        />
      ) : (
        <img
          src={thumbnailSrc || src}
          alt="custom image"
          style={{
            height: `${height}px`,
            width: `${width}px`,
            cursor: "pointer",
          }}
          onClick={handleImageClick}
        />
      )}
      {!onlyText && (
        <div className={`text-capitalize mt-5 ml-5 ${classname}`}>{label}</div>
      )}
      {src && (
        <Modal open={isModalVisible} onCancel={handleModalClose} footer={null}>
          {isPDF(src ?? "") ? (
            <iframe
              className="pdf-container__iframe"
              src={src}
              title="pdf-viewer"
            />
          ) : (
            <img className="custom-document__modal-img" src={src} alt="image" />
          )}
        </Modal>
      )}
    </div>
  );
};

export default CustomDocument;
