import React, { useEffect, useState } from "react";
import { Button, Tabs } from "antd";
import { useLocation, useParams } from "react-router-dom";
import { CustomerParams } from "../../../models/customer.model";
import { TabsProps } from "../../../shared/types/Tabs.type";
import Individual from "../../Customers/Individual";
import PersonalDetails from "./PersonalDetails";
import editIcon from "../../../Assets/images/edit.png";

import "./userDetails.scss";
import useDrawer from "../../../shared/hooks/useDrawer";
import Back from "../../../shared/components/Back";
import useQueryParams from "../../../shared/hooks/useQueryParams";
import { CustomerType } from "../../../enums/customerType";

interface UserDetailsProps {}

const UserDetails = (props: UserDetailsProps) => {
  const location = useLocation();

  const { visible: userEditVisible, toggleVisibility: toggleUserEditVisible } =
    useDrawer({});

  const { userId } = useParams<{ userId: string }>();

  const {} = props;

  const [activeTab, setActiveTab] = useState<string>("1");
  const { params, handleSearch, updateParams } = useQueryParams({
    params: {},
  });

  useEffect(() => {
    setActiveTab(
      String(
        tabs?.find((tab) => location?.hash === tab?.hash)?.key ?? activeTab
      )
    );
  }, []);

  const getTabBarExtraContent = (tab: string) => {
    switch (tab) {
      // case "0":
      //   return (
      //     <Button
      //       className="btn--outline "
      //       onClick={() => toggleUserEditVisible()}
      //     >
      //       <img
      //         alt=""
      //         className="mr-2"
      //         src={editIcon}
      //         width={15}
      //         height={15}
      //       />
      //       <span>Edit</span>
      //     </Button>
      //   );
      default:
        return <></>;
    }
  };

  const tabs: TabsProps[] = [
    {
      key: "1",
      label: "personal details",
      component: (
        <PersonalDetails
          editUser={userEditVisible}
          toggleEditUser={toggleUserEditVisible}
        />
      ),
      hash: "#personal-details",
    },
    {
      key: "2",
      label: "created customers",
      component: (
        <Individual
          customerType={CustomerType.CREATED}
          params={{
            ...new CustomerParams(),
            ...(userId && { createdById: [Number(userId)] }),
          }}
        />
      ),
      hash: "#created-customers",
    },
  ];
  const handleTabChange = (activeKey: string) => {
    const hash = tabs?.find((tab, index) => tab?.key === activeKey)?.hash;
    updateParams({}, hash);
    setActiveTab(activeKey);
  };

  return (
    <>
      <div className="user-details">
        <Back />
        <Tabs
          activeKey={activeTab}
          className="mt-5"
          onChange={handleTabChange}
          tabBarExtraContent={{ right: getTabBarExtraContent(activeTab) }}
        >
          {tabs.map(({ component, label, key }, index) => (
            <Tabs.TabPane tab={label} key={key || index}>
              {component}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
    </>
  );
};

export default UserDetails;
