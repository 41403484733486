import { alias, primitive, serializable } from "serializr";

import { BusinessBuildingOwnershipStatus } from "../enums/businessBuildingOwnershipStatus.type";
import { BusinessModelType } from "../enums/businessModelType.type";
import { HousingOwnershipStatus } from "../enums/housingOwnershipStatus.type";
import { RelationshipToResidenceOwner } from "../enums/relationshipToResidenceOwner.type";
import { TypeOfResidence } from "../enums/typeOfResidence";

export class EligibilityDetail {
  @serializable(alias("id", primitive()))
  id?: string;

  @serializable(alias("loan_id", primitive()))
  loanId?: number;

  @serializable(alias("housing_ownership_status", primitive()))
  housingOwnershipStatus?: HousingOwnershipStatus;

  @serializable(alias("span_of_residence", primitive()))
  spanOfResidence?: string;

  @serializable(alias("area_of_residence", primitive()))
  areaOfResidence?: number;

  @serializable(alias("relationship_to_residence_owner", primitive()))
  relationshipToResidenceOwner?: RelationshipToResidenceOwner;

  @serializable(alias("type_of_residence", primitive()))
  typeOfResidence?: TypeOfResidence;

  @serializable(alias("earning_members_count", primitive()))
  earningMembersCount?: number;

  @serializable(alias("business_building_ownership_status", primitive()))
  businessBuildingOwnershipStatus?: BusinessBuildingOwnershipStatus;

  @serializable(alias("years_of_business", primitive()))
  yearsOfBusiness?: number;

  @serializable(alias("months_of_business", primitive()))
  monthsOfBusiness?: number;

  @serializable(alias("type_of_business_model", primitive()))
  typeOfBusinessModel?: BusinessModelType;

  getBusinessOwnershipStatus = () => {
    if (!this.businessBuildingOwnershipStatus) return "NA";

    switch (this.businessBuildingOwnershipStatus) {
      case BusinessBuildingOwnershipStatus.FAMILY_MEMBER_BUSINESS_BUILDING:
        return "business in the family member building";

      case BusinessBuildingOwnershipStatus.OWN_BUSINESS_BUILDING:
        return "business in the own building";

      case BusinessBuildingOwnershipStatus.RENTAL_BUSINESS_BUILDING:
        return "business in the rental building";
      default:
        return "NA";
    }
  };

  getBusinessSpan = () => {
    if (!(this.yearsOfBusiness || this.monthsOfBusiness)) return "NA";

    return `${this.yearsOfBusiness ?? 0} years, ${
      this.monthsOfBusiness || ""
    } months`;
  };

  getBusinessModel = () => {
    switch (this.typeOfBusinessModel) {
      case BusinessModelType.PVT_LTD:
        return `Pvt Ltd ownership`;
      default:
        return `${this.typeOfBusinessModel || ""} ownership`;
    }
  };

  getSpanOfResidence = () => {
    switch (this.spanOfResidence) {
      case "one":
        return "1 years";
      case "two":
        return "2 years";
      case "three":
        return "3 years";
      case "four":
        return "4 years";
      case "five":
        return "5 years";
      case "five_to_ten":
        return "5 to 10 years";
      case "gt_ten":
        return "more than 10 years";
      default:
        return this.spanOfResidence;
    }
  };
  getHousingOwnershipStatus = () => {
    if (
      !(
        this.housingOwnershipStatus ||
        this.spanOfResidence ||
        this.areaOfResidence
      )
    )
      return "NA";

    let baseText = "";

    switch (this.housingOwnershipStatus) {
      case HousingOwnershipStatus.FAMILY_MEMBER: {
        baseText = "Live in own house owned by an immediate family member";
        break;
      }
      case HousingOwnershipStatus.OWN: {
        baseText = "Living in own house";
        break;
      }
      case HousingOwnershipStatus.RENTAL: {
        baseText = "Living in rental house";
        break;
      }
      default:
        baseText = "";
    }

    return `${baseText}, For ${this.getSpanOfResidence()}, ${
      this.areaOfResidence || "0"
    } SQFT`;
  };
}
