import React, { useState } from "react";

interface useDrawerProps {}

const useDrawer = (props: useDrawerProps) => {
  const [visible, setVisible] = useState(false);

  const toggleVisibility = (visible?: boolean) => {
    if (typeof visible === "boolean" && visible) return setVisible(visible);

    setVisible((visible) => !visible);
  };

  return {
    visible,
    toggleVisibility,
  };
};

export default useDrawer;
