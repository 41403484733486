import moment from "moment";
import { removeUnderscore } from "./removeUnderscore";

export const generateFileName = (val: string) => {
  return (
    val.charAt(0).toUpperCase() +
    val.substring(1) +
    "_" +
    moment().format("DDMMMYYYY")
  );
};
