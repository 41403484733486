import { alias, primitive, serializable } from "serializr";

export class Meta {
  @serializable(alias("id", primitive()))
  value?: number | string;

  @serializable(alias("code", primitive()))
  code?: string | number ;

  @serializable(alias("name", primitive()))
  label?: string;
}
