import { Col, Row, Space, Spin } from "antd";
import React from "react";
import totalWriteOff from "../../../../../Assets/images/Total Write off.svg";
import { ListMappingKeys } from "../../../../../shared/types/optionArray.type";
import { currencyFormatter } from "../../../../../shared/utils/formatter";
import { WrittenOff } from "../../../../../models/writtenOff.model";
import { fillEmptyData } from "../../../../../shared/utils/fillEmptyData";
import AppLoader from "../../../../../shared/components/AppLoader";

interface TotalWriteOffProps {
  data?: WrittenOff;
  loading: boolean;
}

const TotalWriteOff = (props: TotalWriteOffProps) => {
  const { data, loading } = props;

  const titleCardData: ListMappingKeys[] = [
    {
      label: "Total Write off Accounts",
      value: data?.totalWriteOffAccounts,
      offset: 6,
      key: "1",
    },
    {
      label: "Total Write off Amount",
      value: currencyFormatter({amount: data?.totalWriteOffAmount, hasCurrencySign: true, shouldReturnNA: false}),
      offset: 2,
      key: "2",
    },
  ];

  return (
    <div>
      <AppLoader loading={loading}>
      <Row className="card crif-card" align={"middle"}>
        <Space direction="horizontal" size={12}>
          <Col>
            <img src={totalWriteOff} alt="" />
          </Col>
          <Col>Total Write Off</Col>
        </Space>
        {titleCardData?.map((data) => (
          <Col offset={data?.offset} key={data.key} className="flex-column">
            <span className="font-weight__600 font-size__18">
              {data?.value || 0}
            </span>
            <span>{data?.label}</span>
          </Col>
        ))}
        <Col offset={4} className="flex-column">
          <div>
            <span className="font-weight__600 font-size__18">{currencyFormatter({amount: data?.totalWriteOffAmount, hasCurrencySign: true, shouldReturnNA: false})}</span>
          </div>
          <span className={`text__green text-right`}>{fillEmptyData(data?.status)}</span>
        </Col>
      </Row>
      </AppLoader>
    </div>
  );
};

export default TotalWriteOff;
