import { Button, Col, Row } from "antd";
import { Formik, Form } from "formik";
import React, { useEffect, useState } from "react";
import InputField from "../../../shared/components/InputField";
import { InputType } from "../../../enums/input.type";
import DatePickerField from "../../../shared/components/DatePickerField";
import RadioField from "../../../shared/components/RadioField";
import { SecurityTypeOptions } from "../../../shared/Constant/bookDebtSecurityTypeOptions";
import SelectInput from "../../../shared/components/SelectInput";
import { MetaService } from "../../../services/Meta/meta.service";
import useBranch from "../../../shared/hooks/useBranch";
import { Meta } from "../../../models/meta.modal";
import { LoanParams } from "../../../models/loan.model";
import { dateFormats } from "../../../enums/dateFormats.type";
import SwitchComponent from "../../../shared/components/Switch";
import moment from "moment";
import { bookDebtValidation } from "./bookDebtValidationSchema";
import { createOptionsArray } from "../../../shared/utils/helpers";
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";

interface BookDebtDrawerProps {
  toggleDrawer: () => void;
  setBookDebtStep: (step: number) => void;
  onSuccess: (formValues: LoanParams) => void;
  setSelectedLoans: React.Dispatch<React.SetStateAction<number[]>>;
  triggerRowSelection: () => void
}

const BookDebtDrawer = (props: BookDebtDrawerProps) => {
  const { toggleDrawer, setBookDebtStep, onSuccess, triggerRowSelection } = props;

  useEffect(() => {
    fetchLoanTypes();
    fetchStates();
    fetchHypothecators();
    fetchPreferredChannel()
  }, []);

  const toggleBookDebtDrawer = () => {
    toggleDrawer();
  };

  const { getLoanTypes, getHypothecators, getStates, getPreferredChannel, getTenures, tenures } = MetaService();
  const {
    branches,
    handleBranchScroll,
    loading: branchesLoading,
  } = useBranch({ allBranch: true });

  const fetchLoanTypes = async () => {
    const data = await getLoanTypes();
    data && setLoanTypeOptions(data);
  };

  const fetchStates = async () => {
    const states = await getStates();
    states && setStates(states);
  };

  const fetchHypothecators = async () => {
    const hypothecators = await getHypothecators();
    hypothecators && setHypothecators(hypothecators);
  }

  const fetchPreferredChannel = async () => {
    const channels = await getPreferredChannel();
    channels && setPreferredChannel(channels)
  }

  const [loanTypesOptions, setLoanTypeOptions] = useState<Meta[]>([]);
  const [states, setStates] = useState<Meta[]>([]);
  const [hypothecators, setHypothecators] = useState<Meta[]>([]);
  const [preferredChannel, setPreferredChannel] = useState<Meta[]>([]);

  const handleSubmit = async (values: LoanParams) => {
    const { securityType, ...restValues } = values;
    const updatedParams = { ...restValues, disbursed: true, isHypothecator: values?.isHypothecator ? values?.isHypothecator : false };
      onSuccess(updatedParams);
      toggleDrawer();
      setBookDebtStep(1);
      triggerRowSelection()
  };
  return (
    <div>
      <Formik initialValues={new LoanParams()} onSubmit={handleSubmit} validationSchema={bookDebtValidation} validateOnMount={false}>
        {({ values, setFieldValue, isValid, dirty, errors }) => {
          const handleSwitchChange = (name: string, checked: boolean) => {
            setFieldValue(name, checked);
            if (!checked) {
              setFieldValue("isHypothecator", false);
            }
          };

          const handleRadioFieldChange = (name: string, value: string) => {
            setFieldValue('securityType', value);
            setFieldValue(name, [value]);
          }

          const handleDateChange = (date: string[]) => {
            if (date) {
              setFieldValue("disbursementDate", `${moment(date[0])?.format(dateFormats.YEAR_FIRST)}-${moment(date[1])?.format(dateFormats.YEAR_FIRST)}`)
            }
          };

          const handleLoanTypeChange = (name: string, options: Meta | Meta[] ) => {
            const option = options as Meta[];
            const result = option?.map((opt) => opt.value);
            setFieldValue(name, result)
            const loanTypeIds = option.map((loanTypeId) => Number(loanTypeId.value));
            getTenures(loanTypeIds)
            setFieldValue('tenure', [])
          }

          const handleTenureOnBlur = () => {
            if (values?.loanTypeId?.length === 0) {
              Notification({
                message: "Please Select a loan type to view Applicable Tenures",
                description: "",
                type: NotificationTypes.WARNING
              })
            }
          }

          const handleChangePreferredChannel = (name: string, value: Meta | Meta[]) => {
            if (!Array.isArray(value)) {
              setFieldValue(name, [value.value]);
            } else return
          }
         
          return (
            <Form className="ant-form-vertical">
              <Row gutter={[20, 0]}>
                <Col span={24}>
                  <SelectInput
                    name="organisations"
                    options={preferredChannel}
                    label="Channel Type"
                    placeholder="Enter Channel Type"
                    onChange={handleChangePreferredChannel}
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    name="minimumLoanAmount"
                    type={InputType.NUMBER}
                    label="Minimum Loan Amount"
                    placeholder="Enter Minimum Amount"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    name="maximumLoanAmount"
                    type={InputType.NUMBER}
                    label="Maximum Loan Amount"
                    placeholder="Enter Maximum Amount"
                  />
                </Col>
                <Col span={24}>
                  <InputField
                    name="totalAmount"
                    type={InputType.NUMBER}
                    label="Total Amount (Overall)"
                    placeholder="Enter Total Amount"
                  />
                </Col>
                <Col span={24}>
                  <div className="ant-form-item-label">Disbursement Date</div>
                  <DatePickerField
                    name="disbursementDate"
                    type="rangePicker"
                    setFieldValue={setFieldValue}
                    onChange={handleDateChange}
                    placeholder="Enter Disbursement Date"
                  />
                </Col>
                <Col span={24} className="mt-5 mb-5">
                  <Row gutter={[10, 10]}>
                    <Col span={8}>Include Hypothecator</Col>
                    <Col>
                      <SwitchComponent
                        onChange={handleSwitchChange}
                        name="isHypothecator"
                      />
                    </Col>
                  </Row>
                </Col>
                {values?.isHypothecator && 
                <Col span={24}>
                  <SelectInput
                    label="Select Hypothecator"
                    placeholder="Select Hypothecator"
                    options={hypothecators}
                    name="hypothecatorId"
                    mode="multiple"
                  />
                </Col>
        }
                <Col span={24} className="mt-5">
                  <div className="ant-form-item-label">Security Type</div>
                  <RadioField
                    name="securityTypes"
                    options={SecurityTypeOptions}
                    setFieldValue={setFieldValue}
                    onChange={handleRadioFieldChange}
                    value={values.securityType}
                  />
                </Col>
                <Col span={24} className="mt-5">
                  <SelectInput
                    name="loanTypeId"
                    placeholder="Select Loan Types"
                    options={loanTypesOptions}
                    mode="multiple"
                    label="Loan Type"
                    onChange={handleLoanTypeChange}
                  />
                </Col>
                <Col span={24}>
                  <SelectInput
                    name="branchId"
                    placeholder="Select Branches"
                    options={branches}
                    mode="multiple"
                    label="Branches"
                    onScroll={handleBranchScroll}
                    loading={branchesLoading}
                  />
                </Col>
                <Col span={24}>
                  <SelectInput
                    name="tenure"
                    options={createOptionsArray(tenures)}
                    placeholder="Select Tenure"
                    label="Tenure"
                    onBlur={handleTenureOnBlur}
                    mode="multiple"
                  />
                </Col>
                <Col span={24}>
                  <SelectInput
                    name="stateId"
                    options={states}
                    placeholder="Select States"
                    mode="multiple"
                    label="State"
                  />
                </Col>
              </Row>
              <div className="ant-modal-footer drawer-footer">
                <Button
                  onClick={toggleBookDebtDrawer}
                  className="drawer-footer__cancel"
                >
                  Cancel
                </Button>
                <Button disabled={!isValid} htmlType="submit">Apply</Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default BookDebtDrawer;
