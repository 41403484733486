export enum keyFactorRoutes {
    PAYMENT_STATUS = 'payment-status',
    MONTHLY_EMI = 'monthly-emi',
    TOTAL_WRITE_OFF = 'total-write-off',
    OD = 'overdue',
    CREDIT_AGE = 'credit-age',
    TOTAL_SETTLEMENTS = 'total-settlements',
    ACCOUNT_SUMMARY = 'account-summary'
}

export enum keyFactorNames {
    PAYMENT_STATUS = 'Payment Status',
    MONTHLY_EMI = 'Monthly EMI',
    TOTAL_WRITE_OFF = 'Total Write off',
    OD = 'OD (Overdue Accounts)',
    CREDIT_AGE = 'Credit Age',
    TOTAL_SETTLEMENTS = 'Total Settlements',
    ACCOUNT_SUMMARY = 'Account Summary'    
}