export const removeUnderscore = (val?: string | number ) => {
  if (!val) {
    return "";
  }
  if (typeof val !== "string") return
  const newVal = val[0]?.toUpperCase() + val?.slice(1);
  return newVal?.replace(/_/g, " ");
};

export const removeHyphen = (val: string) => {
  if(!val) {
    return " ";
  }
  const string = val.replace('-', " ");
  return string
}