import { alias, object, primitive, serializable } from "serializr";
import { Filters } from "./filters.model";

export class PaginationMeta {
  @serializable(alias("current_page", primitive()))
  currentPage?: number;

  @serializable(alias("next_page", primitive()))
  nextPage?: number | null;

  @serializable(alias("prev_page", primitive()))
  prevPage?: number | null;

  @serializable(alias("total_count", primitive()))
  totalCount?: number;

  @serializable(alias("total_pages", primitive()))
  totalPages?: number;

  @serializable(alias("filters", object(Filters)))
  filters?: Filters;
}
