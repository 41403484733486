import React from "react";

import "./icon.scss";

interface IconProps {
  hasIconContainer?: boolean;
  url?: string;
  iconComponent?: React.FC;
  onClick?: () => void;
}

const Icon = (props: IconProps) => {
  const {
    url,
    iconComponent: IconComponent,
    hasIconContainer,
    onClick,
  } = props;

  const IconWrapper = (
    <span className="icon">
      {url && <img src={url} />}
      {IconComponent && <IconComponent />}
    </span>
  );

  return hasIconContainer ? (
    <span onClick={onClick} className="icon-container">
      {IconWrapper}
    </span>
  ) : (
    <span onClick={onClick}>{IconWrapper}</span>
  );
};

export default Icon;
