import { alias, primitive, serializable } from "serializr";

export class CreatedBy {
  @serializable(alias("id", primitive()))
  id?: string;

  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("updated_at", primitive()))
  updatedAt?: string;

  @serializable(alias("firstname", primitive()))
  firstName?: string;

  @serializable(alias("lastname", primitive()))
  lastName?: string;

  @serializable(alias("code", primitive()))
  code?: string;

  @serializable(alias("gender", primitive()))
  gender?: string;

  @serializable(alias("isd_code", primitive()))
  isdCode?: string;

  @serializable(alias("phone_number", primitive()))
  phoneNumber?: string;

  @serializable(alias("is_active", primitive()))
  isActive?: boolean;

  @serializable(alias("role_id", primitive()))
  roleId?: number;

  @serializable(alias("profile_pic_id", primitive()))
  profilePicId?: string;

  @serializable(alias("profile_pic_url", primitive()))
  profilePicUrl?: string;
}
