import { Button, Col, Row } from "antd";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { User } from "../../../models/user.model";
import OtpInput from "react18-input-otp";
import AuthService from "../../../services/AuthService/auth.service";
import { AuthContext } from "../../../context/AuthContext";
import { Employee } from "../../../models/employee.model";
import { RepaymentService } from "../../../services/Repayment/repayment.service";

interface UserOtpVerifyProps {
  onClose: () => void;
  onSuccess: () => void;
  isLoanSection?: boolean;
}

const UserOtpVerify = (props: UserOtpVerifyProps) => {
  const user: Employee | undefined = AuthContext().user?.employee;
  const { onClose, onSuccess, isLoanSection } = props;
  const [otp, setOtp] = useState("");
  const [counter, setCounter] = useState(0);
  const [resendLoading, setResendLoading] = useState(false);
  const { forgotPassword, loading, verifyEmailOtp } = AuthService();
  const { tenureChangeSendOtp, tenureChangeVerifyOtp } = RepaymentService();

  useEffect(() => {
    handleSendOTP();
  }, []);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  const handleSendOTP = async () => {
    const params = { email: user?.email };
    setResendLoading(true);
    const result = await (isLoanSection
      ? tenureChangeSendOtp(params)
      : forgotPassword(params));
    result && setCounter(30);
    setResendLoading(false);
  };

  const handleChange = (enteredOtp: string) => {
    setOtp(enteredOtp);
  };

  const handleOnSubmit = async () => {
    const params = { otp: otp, email: user?.email };
    const result = await (isLoanSection
      ? tenureChangeVerifyOtp(params)
      : verifyEmailOtp(params));
    if (result) {
      onClose();
      onSuccess();
    }
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <div className="verify-otp__form">
            <Formik initialValues={new User()} onSubmit={handleOnSubmit}>
              {({ handleSubmit }) => (
                <Form className="ant-form-vertical" onSubmit={handleSubmit}>
                  <Row gutter={[0, 30]} justify="space-evenly">
                    <Col span={18}>
                      <h1 className="">OTP Verification</h1>
                    </Col>
                    <Col span={18} className="mb-5">
                      Please enter the verification code sent to your
                      mobile/email.
                    </Col>
                    <Col span={18} className="mt-5">
                      <OtpInput
                        className="verify-otp__form-content"
                        value={otp}
                        onChange={handleChange}
                        numInputs={4}
                      />
                    </Col>
                    <Col span={12} className=" text-right ">
                      <h4 className="text-primary">
                        {counter ? (
                          `RESEND OTP in 00:${
                            counter < 10 ? `0` : ``
                          }${counter}`
                        ) : (
                          <a
                            onClick={resendLoading ? () => {} : handleSendOTP}
                            className="text-primary"
                          >
                            RESEND
                          </a>
                        )}
                      </h4>
                    </Col>

                    <Col span={18}>
                      <Button
                        loading={loading}
                        className="mt-5 w-100"
                        htmlType="submit"
                        disabled={otp.length !== 4}
                      >
                        Verify
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default UserOtpVerify;
