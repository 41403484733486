import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";

import { InputType } from "../../../enums/input.type";
import { Button } from "antd";

import { Loan } from "../../../models/loan.model";

import { LoanService } from "../../../services/Loan/loan.service";

import InputField from "../../../shared/components/InputField";
import {
  TransferLoansParams,
  TransferLoans as TransferLoansModel,
} from "../../../models/transferLoans.model";
import SelectInput from "../../../shared/components/SelectInput";
import useCenter from "../../../shared/hooks/useCenter";
import useBranch from "../../../shared/hooks/useBranch";
import { QueryParams } from "../../../models/query.params.model";
import { MetaService } from "../../../services/Meta/meta.service";
import { LoanTypeModel } from "../../../models/loanType.model";
import { Meta } from "../../../models/meta.modal";

interface TransferLoansProps {
  loans: Loan[];
  onSuccess: (
    loans: TransferLoansModel[],
    transferLoan: TransferLoansParams
  ) => void;
  onClose: () => void;
}

function TransferLoans(props: TransferLoansProps) {
  const { getTransferLoan } = LoanService();

  const {
    getLoanTypesListing,
    getPreferredChannel,
    getLoanTypes,
    loading: metaLoading,
  } = MetaService();
  const [organizations, setOrganizations] = useState<Meta[]>([]);

  const { branches } = useBranch({ allBranch: true });

  const { loans, onClose, onSuccess } = props;
  const [formValues, setFormValues] = useState<TransferLoansParams>(
    new TransferLoansParams()
  );
  const [loanTypesOptions, setLoanTypeOptions] = useState<Meta[]>([]);
  useEffect(() => {
    fetchPreferredChannel();
    fetchLoanTypes();
  }, []);

  const onSubmit = async (values: TransferLoansParams) => {
    const loans = await getTransferLoan({ ...values, toOrg: undefined });

    if (loans && values?.toOrg) {
      onSuccess(loans?.loan, { toOrg: values.toOrg, fromOrg: values.fromOrg });
    }
  };

  const fetchLoanTypes = async () => {
    const data = await getLoanTypes();
    data && setLoanTypeOptions(data);
  };

  const fetchPreferredChannel = async () => {
    const result = await getPreferredChannel();

    result && setOrganizations(result);
  };

  return (
    <div>
      <Formik initialValues={formValues} onSubmit={onSubmit} enableReinitialize>
        {({ isSubmitting, values, isValid, dirty }) => (
          <>
            <div className="transfer-loan">
              <div className="transfer-loan__content">
                <Form className="ant-form-vertical">
                  <SelectInput
                    name="fromOrg"
                    options={organizations}
                    placeholder="Select From"
                    label="From"
                  />
                  <SelectInput
                    name="toOrg"
                    options={organizations}
                    placeholder="Select To"
                    label="To"
                  />
                  <SelectInput
                    name="branchIds"
                    IsSelectAll
                    showSearch
                    options={branches}
                    placeholder="Select Branches"
                    label="Branches"
                    mode="multiple"
                  />
                  <SelectInput
                    name="loanTypeIds"
                    IsSelectAll
                    options={loanTypesOptions}
                    showSearch
                    mode="multiple"
                    placeholder="Select Loan Type"
                    label="Loan Type"
                  />{" "}
                  <InputField
                    label="Maxiumum Loan Amount"
                    type={InputType.NUMBER}
                    name="maximumLoanAmount"
                    placeholder="Enter Maximum Loan Amount"
                  />
                  <div className="transfer-loan__content__footer drawer-footer">
                    <Button
                      className="drawer-footer__cancel"
                      htmlType="reset"
                      onClick={onClose}
                      type="link"
                    >
                      Cancel
                    </Button>
                    <Button
                      loading={isSubmitting}
                      className="drawer-footer__submit mr-5"
                      htmlType="submit"
                      disabled={!isValid || !dirty}
                    >
                      APPLY
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
}
export default TransferLoans;
