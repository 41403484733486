import {
  Col,
  DatePicker,
  Drawer,
  Row,
  Select,
  TableProps,
  Table,
  TableColumnProps,
  TableColumnsType,
} from "antd";
import React, { useEffect, useState } from "react";
import { Meta } from "../../../models/meta.modal";
import { QueryParams } from "../../../models/query.params.model";
import { BranchesService } from "../../../services/Branches/branches.service";

import useBranch from "../../../shared/hooks/useBranch";

import { Loan, LoanParams } from "../../../models/loan.model";
import { ToggleDrawer } from "../../../shared/types/toggleDrawer";
import useQueryParams from "../../../shared/hooks/useQueryParams";
import LoanFilters from "../../Loans/LoanFilters";
import ColumnOptions from "../../../shared/components/ColumnOptions";
import { Deposits as DepositModel } from "../../../models/deposits.model";
import { depositColumn } from "./depositsColumn";
import { RepaymentService } from "../../../services/Repayment/repayment.service";
import FilterButtons from "../../../shared/components/FilterButtons";
import { Filters } from "../../../models/filters.model";
import useSorting from "../../../shared/hooks/useSorting";
import { SorterResult } from "antd/lib/table/interface";

interface DepositsProps {
  params: LoanParams;
  filterVisible?: boolean;
  onFilter?: ToggleDrawer;
  settingsVisible?: boolean;
  onSettings?: ToggleDrawer;
}

const sortBy = {
  code: 'code',
  name: 'customer_name',
  centerName: "center_name",
  createdByFirstName: "created_by.firstname",
  loanTypeName: "loan_type_name",
  status: "status",
  createdAt: 'created_at',
  organizationName: "organisation_name",
};

const Deposits = (props: DepositsProps) => {
  const [totalPage, setTotalPage] = useState(1);
  const [Deposits, setDeposits] = useState<DepositModel[]>([]);
  const [defaultColumns, setDefaultColumns] =
    useState<TableColumnsType<DepositModel>>(depositColumn);
  const [filteredColumns, setFilteredColumns] = useState<string[]>([]);
  const { params, updateParams, getParams } = useQueryParams({
    params: props?.params,
  });
  const [filters, setFilters] = useState<Filters>(new Filters());

  const {
    params: loanParams,
    filterVisible,
    onFilter,
    onSettings,
    settingsVisible,
  } = props;
  const { getDeposits, loading } = RepaymentService();

  const { updateSortData } = useSorting<DepositModel>({ sortBy  });

  const { getBranches } = BranchesService();
  useEffect(() => {
    const updateParams = loanParams?.searchText
      ? { ...params, page: 1, searchText: loanParams?.searchText }
      : params;
    handleFetchDeposit(updateParams);
  }, [params, loanParams?.searchText]);

  const handleFetchDeposit = async (params: QueryParams) => {
    const result = await getDeposits(params);
    if (result) setDeposits(result?.deposits);
    if (result?.meta) setTotalPage(result?.meta?.totalCount ?? 1);
    setFilters(result?.meta?.filters ?? {});
  };

  const handleChange: TableProps<DepositModel>["onChange"] = (
    { current },
    _,
    sorter
    ) => {
      const { sortBy, sortDirection } = updateSortData(
        sorter as SorterResult<DepositModel>
      );
      updateParams({ ...params, page: current, sortBy, sortDirection });
  };

  const handleUpdateParams = () => updateParams(getParams());

  return (
    <div className="deposits-details">
      <div className="card">
        {!loading && (
          <FilterButtons filters={filters} onFilter={handleUpdateParams} />
        )}
        <Table
          columns={defaultColumns.filter(
            (col) => !filteredColumns.includes(col.title as string)
          )}
          className=" custom-scrollbar"
          dataSource={Deposits}
          onChange={handleChange}
          scroll={{ x: true }}
          loading={loading}
          pagination={{
            hideOnSinglePage: true,
            total: totalPage,
          }}
        />
      </div>
      {filterVisible && onFilter && (
        <Drawer
          placement="right"
          onClose={() => onFilter(false)}
          visible={filterVisible}
          width={"70vw"}
          title="Filters"
          closable
          destroyOnClose
        >
          <LoanFilters onFilter={updateParams} onClose={onFilter} />
        </Drawer>
      )}
      {settingsVisible && onSettings && (
        <Drawer
          placement="right"
          onClose={() => onSettings(false)}
          visible={settingsVisible}
          width={500}
          title="Column Options"
          destroyOnClose
        >
          <ColumnOptions
            defaultColumns={defaultColumns}
            filteredColumns={filteredColumns}
            setFilteredColumns={setFilteredColumns}
            onClose={() => onSettings(false)}
            setDefaultColumns={setDefaultColumns}
          />
        </Drawer>
      )}
    </div>
  );
};

export default Deposits;
