import { useState } from "react";
import { generatePath } from "react-router-dom";
import { deserialize, serialize } from "serializr";
import { NotificationTypes } from "../../enums/notificationTypes";
import axiosInstance from "../../interceptor/axiosInstance";
import { Employee, EmployeeParams } from "../../models/employee.model";
import { PaginationMeta } from "../../models/pagination.model";
import { UserAccess } from "../../models/userAccess.model";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import Notification from "../../shared/components/Notification";

export const UserAccessService = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  const getUserAccess = async (params?: UserAccess) => {
    setLoading(true);
    try {
      const queryParams = params ? serialize(UserAccess, params) : {};
      const { data } = await axiosInstance.get(ApiRoutes.USER_ACCESS, {
        params: queryParams,
      });
      const deserializedAccess = deserialize(
        UserAccess,
        data?.["user_accesses"] as any[]
      );

      return deserializedAccess;
    } catch (ex) {
      Notification({
        message: "Unable to Get Users Access",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Get Users Access",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const updateUserAccess = async (params: UserAccess) => {
    setLoading(true);
    try {
      const queryParams = params ? serialize(UserAccess, params) : {};

      const { data } = await axiosInstance.put(
        ApiRoutes.USER_ACCESS_UPDATE.replace(
          ":id",
          (params?.id ?? 0).toString()
        ),
        {
          user_access: queryParams,
        }
      );
      const deserializedAccess = deserialize(
        UserAccess,
        data?.["user_access"] as UserAccess
      );

      return deserializedAccess;
    } catch (ex) {
      Notification({
        message: "Unable to Update User Access",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Update User Access",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    getUserAccess,
    updateUserAccess,
    loading,
    error,
  };
};
