import { DateFilter } from "../../enums/dateFilter.type";

const dateFilterOptions = [
  {
    label: "Today",
    value: DateFilter.TODAY,
  },
  {
    label: "This Month",
    value: DateFilter.MONTH,
  },
  {
    label: "This Quater",
    value: DateFilter.QUATER,
  },
  {
    label: "Last Six Months",
    value: DateFilter.SIX_MONTHS,
  },
  {
    label: "Last One Year",
    value: DateFilter.ONE_YEAR,
  },
  {
    label: "Custom Date",
    value: DateFilter.CUSTOM,
  },
];

export default dateFilterOptions;
