import { alias, primitive, serializable } from "serializr";

export class CreditScore {
    @serializable(alias("credit_score", primitive()))
    creditScore?: number
    @serializable(alias("dpd_days", primitive()))
    dpdDays?: number
    @serializable(alias("dpd_amount", primitive()))
    dpdAmount?: number
    @serializable(alias("active_loans_outstanding", primitive()))
    activeLoansOutstanding?: number
    @serializable(alias("enquiry_no", primitive()))
    enquiryNo?: number
    @serializable(alias("cb_credit_score", primitive()))
    cbCreditScore?: number
    @serializable(alias("cb_enquiry_number", primitive()))
    cbEnquiryNumber?: number
    @serializable(alias('loan_agreement_id', primitive()))
    loanAgreementId?: string
    @serializable(alias('special_approval_reason', primitive()))
    specialApprovalReason?: string | null
    @serializable(alias('special_approval', primitive()))
    specialApproval?: boolean
    @serializable(alias('is_ntc', primitive()))
    isNTC?: boolean
    @serializable(alias('mas_api_call', primitive()))
    masApiCall?: boolean
}