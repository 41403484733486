import React, { useEffect, useState } from "react";
import TabExtraContent from "../../../shared/components/TabExtraContent";
import useQueryParams from "../../../shared/hooks/useQueryParams";
import { Loan, LoanParams } from "../../../models/loan.model";
import useDrawer from "../../../shared/hooks/useDrawer";
import "./pipelineLoans.scss";
import '../pipeline.scss'
import FilterButtons from "../../../shared/components/FilterButtons";
import { Filters } from "../../../models/filters.model";
import { LoanService } from "../../../services/Loan/loan.service";
import { currencyFormatter } from "../../../shared/utils/formatter";
import { Drawer, Table, TableColumnsType, TableProps } from "antd";
import { pipelineColumns } from "../PipelineWrapper/pipelineColumns";
import useTableConfig from "../../../shared/hooks/useTableConfig";
import useSorting from "../../../shared/hooks/useSorting";
import { SorterResult } from "antd/lib/table/interface";
import { generatePath, useNavigate } from "react-router-dom";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import LoanFilters from "../../Loans/LoanFilters";
import ColumnOptions from "../../../shared/components/ColumnOptions";
import { DateRange } from "../../../shared/types/date.type";
import CustomRangePicker from "../../../shared/components/CustomRangePicker";
import { TableConfig } from "../../../enums/tableConfig.type";
import { AllLoanTypes } from "../../../enums/pipelineLoanTypes";
import LoanAmountDetails from "../../../shared/components/LoanDetails";

type Props = {
  activeTab: string
};

const sortBy = {
  code: "code",
  name: "customer_name",
  centerName: "center_name",
  createdByFirstName: "created_by.firstname",
  loanTypeName: "loan_type_name",
  status: "status",
  organizationName: "organisation_name",
};

const PipelineLoans = (props: Props) => {
  const { getLoans, loading } = LoanService();
  const handleUpdateParams = () => updateParams(getParams());
  const { activeTab } = props

  const handleDateChange = (date: DateRange) => {
    setDateRange(date);
    updateParams({ ...pipelineParams, ...date });
  };

  const [dateRange, setDateRange] = useState<DateRange>();
  const [filters, setFilters] = useState<Filters>(new Filters());
  const [defaultColumns, setDefaultColumns] =
    useState<TableColumnsType<Loan>>(pipelineColumns);
  const [loans, setLoans] = useState<Loan[]>([]);
  const { columnConfig, setColumnConfig } = useTableConfig();
  const [totalLoans, setTotalLoans] = useState(1);
  const [filteredColumns, setFilteredColumns] = useState<string[]>(
    columnConfig?.loans ?? []
  );
  const { updateSortData } = useSorting<Loan>({ sortBy });
  const navigate = useNavigate();

  const {
    params: pipelineParams,
    handleSearch,
    updateParams,
    getParams,
  } = useQueryParams<LoanParams>({
    params: new LoanParams(),
  });

  const {
    visible: pipelineFilterVisible,
    toggleVisibility: togglePipelineFilterVisibility,
  } = useDrawer({});

  const {
    visible: pipelineSettingsVisible,
    toggleVisibility: togglePipelineSettingsVisibility,
  } = useDrawer({});

  const handleChange: TableProps<Loan>["onChange"] = (
    pagination,
    _,
    sorter
  ) => {
    const sortData = updateSortData(sorter as SorterResult<Loan>);
    updateParams({
      ...pipelineParams,
      ...sortData,
      page: pagination?.current,
    });
  };

  useEffect(() => {
    const fetchLoans = async () => {
      const updatedParams = {
        ...pipelineParams,
        searchText: pipelineParams.searchText,
        pipeline: true,
      };
      const loanData = await getLoans(updatedParams);
      if (loanData?.loans) setLoans(loanData?.loans);
      if (loanData?.meta) {
        setTotalLoans(loanData?.meta?.totalCount ?? 1);
        loanData?.meta?.filters && setFilters(loanData?.meta?.filters);
      }
    };
    fetchLoans();
  }, [pipelineParams, pipelineParams.searchText]);

  useEffect(() => {
    const { toDate, fromDate } = getParams();
    setDateRange({ toDate, fromDate });
  }, []);

  useEffect(() => {
    setColumnConfig(TableConfig.LOANS, filteredColumns);
  }, [filteredColumns]);


  return (
    <div className="pipeline-loans">
      {!loading && (
        <FilterButtons filters={filters} onFilter={handleUpdateParams} />
      )}
      <LoanAmountDetails params={pipelineParams} type={AllLoanTypes.PIPELINE}/>
      <Table
        scroll={{ x: true }}
        className="pipeline-loans-table"
        columns={defaultColumns.filter(
          (col) => !filteredColumns.includes(col.title as string)
        )}
        dataSource={loans}
        loading={loading}
        onChange={handleChange}
        rowKey={(record: any) => record?.id}
        onRow={(record) => ({
          onClick: () => {
            navigate(
              generatePath(AppRoutes.LOAN_PIPELINE_DETAIL, {
                loanId: record?.id,
              }),
              {
                state: {
                  disbursedDate: record?.recentLoanStatus?.assignedAt,
                },
              }
            );
          },
        })}
        pagination={{
          current: pipelineParams?.page,
          hideOnSinglePage: true,
          total: totalLoans,
        }}
      />
      <div className="pipeline-extra-content">
      <CustomRangePicker
              className="mr-5 custom-range-picker"
              onChange={handleDateChange}
              values={{
                toDate: pipelineParams?.toDate,
                fromDate: pipelineParams?.fromDate,
              }}
            />
        <TabExtraContent
          searchProps={{
            onSearch: handleSearch,
            placeholder: "Search Loan",
            value: pipelineParams.searchText,
          }}
          filterClick={togglePipelineFilterVisibility}
          settingsClick={togglePipelineSettingsVisibility}
        />
      </div>
      {pipelineFilterVisible && (
        <Drawer
          placement="right"
          onClose={() => togglePipelineFilterVisibility(false)}
          visible={pipelineFilterVisible}
          width={"70vw"}
          title="Filters"
          closable
          destroyOnClose
        >
          <LoanFilters
            onClose={() => togglePipelineFilterVisibility(false)}
            onFilter={updateParams}
          />
        </Drawer>
      )}

      {pipelineSettingsVisible && (
        <Drawer
          placement="right"
          onClose={() => togglePipelineSettingsVisibility(false)}
          visible={pipelineSettingsVisible}
          width={500}
          title="Column Options"
          destroyOnClose
        >
          <ColumnOptions
            defaultColumns={defaultColumns}
            filteredColumns={filteredColumns}
            setFilteredColumns={setFilteredColumns}
            onClose={() => togglePipelineSettingsVisibility()}
            setDefaultColumns={setDefaultColumns}
          />
        </Drawer>
      )}
    </div>
  );
};

export default PipelineLoans;
