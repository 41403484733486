import * as Yup from "yup";
import { LoanDeductionType } from "../../../../../enums/loanDeduction.type";

export const loanDeductionFormValidation = Yup.object().shape({
  name: Yup.string().required("Deduction Name is Required"),
  mode: Yup.string().required("Mode is Required"),
  fixedRate: Yup.string().when('mode', {
    is: value => value !== LoanDeductionType.PERCENTAGE,
    then: Yup.string().required("Fixed Rate is Required"),
    otherwise: Yup.string().nullable(),
  }),
  percentage: Yup.string().when('mode', {
    is: value => value !== LoanDeductionType.FLAT_RATE,
    then: Yup.string().required("Percentage is Required"),
    otherwise: Yup.string().nullable(),
  }),
  gstEnabled: Yup.boolean(),
  gst: Yup.number().when('gstEnabled', {
    is: gstEnabled => gstEnabled === true,
    then: Yup.number().required('GST Rate is required').min(0,'GST Rate must be greater than or equal to 0' ),
    otherwise: Yup.number().nullable()
  })
});
