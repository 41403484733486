import React, { useEffect } from "react";
import { useState } from "react";
import { Row, Col, Button } from "antd";
import { Formik, Form } from "formik";
import InputField from "../../../shared/components/InputField";
import { MetaService } from "../../../services/Meta/meta.service";
import { Meta } from "../../../models/meta.modal";
import SelectInput from "../../../shared/components/SelectInput";
import { Employee } from "../../../models/employee.model";
import { UserRole } from "../../../enums/userRoles";
import { Gender } from "../../../enums/gender.type";
import { userFormValidation } from "./userFormValidation";
import { InputType } from "../../../enums/input.type";
import '../users.scss'
import useBranch from "../../../shared/hooks/useBranch";
import useCenter from "../../../shared/hooks/useCenter";
import { Branch } from "../../../models/branch.model";
import { Center } from "../../../models/center.model";
import { RoleName } from "../../../enums/roleName.enum";

const genderOptions: Meta[] = [
  {
    label: "Male",
    value: Gender.MALE,
    code: Gender.MALE,
  },
  {
    label: "Female",
    value: Gender.FEMALE,
    code: Gender.FEMALE,
  },
  {
    label: "Does not wish to specify",
    value: Gender.OTHER,
    code: Gender.OTHER,
  },
];

interface NewUserProps {
  formValues?: Employee;
  onSubmit: (user: Employee) => void;
  onClose: () => void;
}

const UserForm = (props: NewUserProps) => {
  const { getRoles, getStates, getCities, getPreferredChannel } = MetaService();

  const {
    branches,
    loading: branchesLoading,
    handleBranchUpdate,
    handleBranchScroll,
    handleBranchSearch,
    handleBranchReset,
  } = useBranch({ allBranch: true });

  const {
    centers,
    loading: centersLoading,
    handleDropdownScroll: handleCenterDropdownScroll,
    handleSearch: handleCenterSearch,
    setBranchId,
    handleCenterReset,
    handleCenterUpdate,
  } = useCenter({});

  const { onSubmit, formValues, onClose } = props;

  const [userDetails, setUserDetails] = useState(formValues || new Employee());

  const [states, setStates] = useState<Meta[]>([]);

  const [cities, setCities] = useState<Meta[]>([]);
  const [organizations, setOrganizations] = useState<Meta[]>([]);

  const [roles, setRoles] = useState<Meta[]>([]);

  const populateCities = async (stateId?: string) => {
    if (!stateId) return;

    const cities = await getCities(stateId);
    if (cities) setCities(cities);
  };

  const generateBranchIds = (branches?: Branch[]) => {
    const result: number[] = [];

    branches?.forEach((branch) => {
      if (branch?.id) result.push(branch?.id);
    });

    return result;
  };

  const generateCenterIds = (centers?: Center[]) => {
    const result: number[] = [];

    centers?.forEach((center) => center?.id && result.push(center?.id));

    return result;
  };

  useEffect(() => {
    const populateMeta = async () => {
      const roles = await getRoles();

      const states = await getStates();
      const organizations = await getPreferredChannel();

      if (roles) setRoles(roles);

      if (states) setStates(states);
      if (organizations) setOrganizations(organizations);
      if (formValues) {
        if (formValues?.branches?.length === 1) {
          const branch = formValues?.branches?.[0];
          setBranchId(branch?.id ? String(branch?.id) : "");
        }
        handleBranchUpdate(formValues?.branches);
        handleCenterUpdate(formValues?.centers);
        setUserDetails((details) => ({
          ...details,
          ...formValues,
          organisationIds: formValues?.preferredChannel?.map((val) =>
            Number(val.id)
          ),
          branchIds: generateBranchIds(formValues?.branches),
          centerIds: generateCenterIds(formValues?.centers),
          branches: [],
          centers: [],
        }));
        await populateCities(formValues?.address?.stateId);
      }
    };
    populateMeta();
  }, []);

  const getBranchField = (roleId: number, setFieldValue: Function) => {
    const role = roles?.find(({ value }) => roleId === value);
    switch (role?.code) {
      case UserRole.BRANCH_MANAGER: {
        return (
          <SelectInput 
            label="Branch"
            showSearch
            handleSearch={handleBranchSearch}
            loading={branchesLoading}
            name="branchIds"
            placeholder="Select Branch"
            options={branches}
            onChange={(name, options) => {
              const { value } = options as Meta;
              setFieldValue(name, [value]);
            }}
          />
        );
      }
      case UserRole.CUSTOMER_REPRESENTATIVE: {
        return (
          <SelectInput
            label="Branch"
            name="branchIds"
            placeholder="Select Branch"
            loading={branchesLoading}
            options={branches}
            showSearch
            handleSearch={handleBranchSearch}
            onChange={async (name: string, options: Meta | Meta[]) => {
              const option = options as Meta;
              handleCenterReset();
              setFieldValue(name, [option.value]);
              setBranchId(option.value ? String(option.value) : "");
              setFieldValue("centerIds", []);
            }}
          />
        );
      }
      case UserRole.CREDIT_OFFICER: {
        return (
          <SelectInput
            label="Branch"
            showSearch
            handleSearch={handleBranchSearch}
            loading={branchesLoading}
            name="branchIds"
            placeholder="Select Branches"
            options={branches}
            mode="multiple"
            IsSelectAll
          />
        );
      }
      case UserRole.HEAD_OFFICER: {
        return (
          <SelectInput
            mode="multiple"
            label="Preferred Channel"
            name="organisationIds"
            options={organizations}
            placeholder="Select Channel"
          />
        );
      }
      case UserRole.BRANCH_MIS: {
        return (
          <SelectInput
            label="Branch"
            showSearch
            handleSearch={handleBranchSearch}
            loading={branchesLoading}
            name="branchIds"
            placeholder="Select Branches"
            options={branches}
            mode="multiple"
            IsSelectAll
          />
        );
      }
        case UserRole.BRANCH_ACCOUNTANT: {
          return (
            <SelectInput
            label="Branch"
            showSearch
            handleSearch={handleBranchSearch}
            loading={branchesLoading}
            name="branchIds"
            placeholder="Select Branches"
            options={branches}
            mode="multiple"
            IsSelectAll
          />
          )
        }
      default:
        return <></>;
    }
  };

  const getCentersField = (roleId: number) => {
    const role = roles?.find(({ value }) => roleId === value);
    switch (role?.code) {
      case UserRole.CUSTOMER_REPRESENTATIVE: {
        return (
          <SelectInput
            label="Center"
            showSearch
            handleSearch={handleCenterSearch}
            onScroll={handleCenterDropdownScroll}
            loading={centersLoading}
            name="centerIds"
            placeholder="Select Centers"
            options={centers}
            mode="multiple"
          />
        );
      }
      default:
        return <></>;
    }
  };

  return (
    <div className="user-form">
      <Formik
        initialValues={userDetails}
        validationSchema={userFormValidation}
        onSubmit={() => {}}
        enableReinitialize
      >
        {({ setFieldValue, values, isSubmitting, isValid, dirty, errors }) => {
          return (
            <div className="user-form__container">
              <Form className="ant-form-vertical">
                <Row gutter={24}>
                  <Col span={12}>
                    <InputField
                      label="First Name"
                      type={InputType.TEXT}
                      name="firstName"
                      placeholder="First Name"
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      label="Last Name"
                      type={InputType.TEXT}
                      name="lastName"
                      placeholder="Last Name"
                    />
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <InputField
                      label="Employee ID"
                      type={InputType.TEXT}
                      name="code"
                      placeholder="Employee ID"
                    />
                  </Col>
                  <Col span={12}>
                    <SelectInput
                      label="Gender"
                      name="gender"
                      options={genderOptions}
                      placeholder="Select Gender"
                    />
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <InputField
                      label="Email ID"
                      type={InputType.EMAIL}
                      name="email"
                      placeholder="Email ID"
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      label="Phone Number"
                      type={InputType.TEXT}
                      name="phoneNumber"
                      placeholder="Phone Number"
                    />
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <InputField
                      label="Address line 1"
                      type={InputType.TEXT}
                      name="address.addressLine1"
                      placeholder="Address line 1"
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      label="Address line 2"
                      type={InputType.TEXT}
                      name="address.addressLine2"
                      placeholder="Address line 2"
                    />
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <SelectInput
                      label="State"
                      name="address.stateId"
                      options={states}
                      placeholder="Select States"
                      onChange={async (_, option) => {
                        const stateId = (option as Meta).value as string;
                        populateCities(stateId);
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <SelectInput
                      label="City"
                      name="address.cityId"
                      options={cities}
                      placeholder="Select Cities"
                    />
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <InputField
                      label="Pincode"
                      type={InputType.TEXT}
                      name="address.pincode"
                      placeholder="Pincode"
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      label="Landmark"
                      type={InputType.TEXT}
                      name="address.landmark"
                      placeholder="Landmark"
                    />
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <SelectInput
                      label="Role"
                      name="roleId"
                      options={roles}
                      placeholder="Select Role"
                      onChange={(name, option) => {
                        setFieldValue("role", (option as Meta).code);
                        setFieldValue("branchIds", []);
                        setFieldValue("centerIds", []);
                        handleBranchReset();
                      }}
                    />
                  </Col>
                  {/* {values?.role === UserRole.HEAD_OFFICER && (
                    <Col span={12}>
                      <SelectInput
                        mode="multiple"
                        label="Preferred Channel"
                        name="organisationIds"
                        options={organizations}
                        placeholder="Select Channel"
                      />
                    </Col>
                  )} */}
                  <Col span={12}>
                    {getBranchField(Number(values.roleId), setFieldValue)}
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>{getCentersField(Number(values.roleId))}</Col>
                </Row>
                <div className="drawer-footer">
                  <Button
                    onClick={onClose}
                    type="link"
                    className="drawer-footer__cancel"
                    htmlType="reset"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="drawer-footer__submit"
                    htmlType="submit"
                    disabled={!dirty || !isValid}
                    onClick={() => onSubmit(values)}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};
export default UserForm;
