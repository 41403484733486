import React, { useState } from "react";
import { Modal, Image } from "antd";
import "./customImage.scss";
import previewAvatarImage from "../../Constant/previewAvatarImage";

interface ImageProps {
  height: number;
  width: number;
  src: string;
  isPopup?: boolean;
}

const CustomImage: React.FC<ImageProps> = ({
  height,
  width,
  src,
  isPopup = true,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleImageClick = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="custom-image">
      <Image
        src={src || previewAvatarImage}
        alt="custom image"
        fallback={previewAvatarImage}
        preview={false}
        style={{
          height: `${height}px`,
          width: `${width}px`,
          cursor: "pointer",
        }}
        onClick={handleImageClick}
      />
      {isPopup && (
        <Modal open={isModalVisible} onCancel={handleModalClose} footer={null}>
          <Image
            src={src}
            alt="image"
            preview={false}
            fallback={previewAvatarImage}
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              padding: "20px",
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default CustomImage;
