import { ThumnailLabels } from "../../enums/thumbnailLabels.enum";
import { ThumbnailDocs } from "../../models/thumbnailDocs.model";

export const getThumbnailByLabel = (
    label?: string,
    thumbnailDocs?: ThumbnailDocs
): string | undefined => {
    if (!thumbnailDocs) return undefined;

    const lowerCasedLabel = label?.toLowerCase();

    switch (true) {
        case lowerCasedLabel?.includes(ThumnailLabels.AADHAAR):
            return thumbnailDocs.aadhaarThumb;
        case lowerCasedLabel?.includes(ThumnailLabels.AGRI):
            return thumbnailDocs.agriDocument_thumb;
        case lowerCasedLabel?.includes(ThumnailLabels.BANK_STATEMENT):
            return thumbnailDocs.bankStatementThumb;
        case lowerCasedLabel?.includes(ThumnailLabels.HOUSE):
            return thumbnailDocs.houseImageThumb;
        case lowerCasedLabel?.includes(ThumnailLabels.INCOME_STATEMENT):
            return thumbnailDocs.incomeStatementThumb;
        case lowerCasedLabel?.includes(ThumnailLabels.PAN):
            return thumbnailDocs.panThumb;
        case lowerCasedLabel?.includes(ThumnailLabels.RATION_CARD):
            return thumbnailDocs.rationCardThumb;
        default:
            return thumbnailDocs.documentDefaultThumb;
    }
};
