import { ColumnProps } from "antd/lib/table";
import React from "react";
import { DateFormat } from "../../../../enums/dateFormat.type";

import { LoanTimeline } from "../../../../models/loanTimeline";
import { dateTimeFormatter, formatTimeWithSpace } from "../../../../shared/utils/formatter";

import { removeUnderscore } from "../../../../shared/utils/removeUnderscore";
import { mapAndJoinValue } from "../../../../shared/utils/mapAndJoinValue";

export const LoanTimelineColumn: ColumnProps<LoanTimeline>[] = [
  {
    title: "Activity",
    dataIndex: "activity",
    width: "30%",
    render: (_, timeline) => mapAndJoinValue(timeline?.activity),
  },
  {
    title: "Date",
    dataIndex: "date",
  },
  {
    title: "Time",
    dataIndex: "createdAt",
    render: (time) => dateTimeFormatter(time, DateFormat.TIME),
  },
  {
    title: "Loan Status",
    dataIndex: "loanStatus",
    render: (loanStatus) => (
      <span
        className={`text-capiralize active-status--${loanStatus
          ?.split("_")
          ?.join("-")}`}
      >
        {loanStatus}
      </span>
    ),
  },
  {
    title: "Modified By",
    dataIndex: "modifiedBy.label",
    render: (_, loan) => loan?.modifiedBy?.getName(),
  },
  {
    title: "Role",
    dataIndex: "modifiedBy.role",
    render: (_, loan) => loan?.modifiedBy?.role?.name,
  },
];
