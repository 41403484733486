import { Button, Col, Modal, Radio, Row } from "antd";
import { Formik, Form } from "formik";
import React, { useEffect, useState } from "react";
import { InputType } from "../../../../../enums/input.type";
import { RegenerateRepaymentsType } from "../../../../../enums/regenerateRepayments.type";
import { Loan } from "../../../../../models/loan.model";
import { RegenerateRepaymentsModel } from "../../../../../models/regenerateRepayments.model";
import { RepaymentService } from "../../../../../services/Repayment/repayment.service";
import DatePickerField from "../../../../../shared/components/DatePickerField";
import InputField from "../../../../../shared/components/InputField";
import SelectInput from "../../../../../shared/components/SelectInput";
import { generateMonthOptions } from "../../../../../shared/utils/generateMonthOption";
import { DateFormat } from "../../../../../enums/dateFormat.type";
import { dateTimeFormatter } from "../../../../../shared/utils/formatter";
import { isEmpty } from "../../../../../shared/utils/isEmpty";
import UserOtpVerify from "../../../../Profile/UserOtpVerify";
import { LoanService } from "../../../../../services/Loan/loan.service";
import { createOptionsArray } from "../../../../../shared/utils/helpers";

interface RegenerateRepaymentsProps {
  loan: Loan;
  onClose: () => void;
}

const RegenerateRepayment = (props: RegenerateRepaymentsProps) => {
  const { loan, onClose } = props;
  const { regenerateSchedule, loading } = RepaymentService();
  const { getRepaymentMonths } = LoanService()
  const [formValues, setFormValues] = useState<RegenerateRepaymentsModel>(
    new RegenerateRepaymentsModel()
  );
  const [updatedValues, setUpdatedValues] = useState<RegenerateRepaymentsModel>(
    new RegenerateRepaymentsModel()
  );
  const [isOtpVisible, setIsOtpVisible] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [radioValue, setRadioValue] = useState(
    RegenerateRepaymentsType.DATE_AMOUNT
  );
  const [repaymentMonths, setRepaymentMonths] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      const months = await getRepaymentMonths(loan?.id, loan?.requestedLoanAmt);
      setRepaymentMonths(months)
    };

    fetchData();
  }, [loan?.id, loan?.requestedLoanAmt]);

  const repaymentMonthOptions = createOptionsArray(repaymentMonths)

  useEffect(() => {
    isOtpVerified && handleOnSubmit(updatedValues);
  }, [isOtpVerified]);

  useEffect(() => {
    const fetchData = async () => {
        const months = await getRepaymentMonths(loan?.id, loan?.requestedLoanAmt);
        setRepaymentMonths(months)
    };
  
    fetchData();
  }, [loan?.id, loan?.requestedLoanAmt]);

  useEffect(() => {
    if (loan?.id) {
      setFormValues({
        tenureInMonths: loan?.tenureInMonths,
        requestedLoanAmt: loan?.requestedLoanAmt,
        paidPrincipal: loan?.paidPrincipal,
        outstandingPrincipalAmount: loan?.outstandingPrincipalAmount,
        expectedStartDate: loan?.recentLoanStatus?.assignedAt,
      });
    }
  }, []);

  const options = [
    {
      label: "Adjust Disbursed Date/Amount",
      value: RegenerateRepaymentsType.DATE_AMOUNT,
      description:
        "To apply this condition customer shouldn’t pay a single due",
    },
    {
      label: "Change Loan Tenure",
      value: RegenerateRepaymentsType.TENURE,
      description:
        "If customer paid a single due then only loan tenure can be change",
    },
  ];

  const handleOnSubmit = async (values: RegenerateRepaymentsModel) => {
    const params =
      radioValue === RegenerateRepaymentsType.TENURE &&
        values?.tenureInMonths != loan?.tenureInMonths
        ? {
          tenureInMonths: values?.tenureInMonths,
          tenure: 1,
        }
        : radioValue === RegenerateRepaymentsType.DATE_AMOUNT
          ? {
            requestedLoanAmt: values?.requestedLoanAmt,
            tenure: 0,
            expectedStartDate: dateTimeFormatter(
              values.expectedStartDate,
              DateFormat.DATE
            ),
          }
          : {};

    if (!Object.keys(params).length) return onClose();

    if (!isOtpVerified) {
      handleOtpVisibility();
      return;
    }
    const schedule = await regenerateSchedule(String(loan?.id), params);
    if (schedule) {
      setIsOtpVerified(false);
      onClose();
    }
  };
  const handleOtpVisibility = () => setIsOtpVisible(!isOtpVisible);
  const handleOtpVerified = () => setIsOtpVerified(!isOtpVerified);

  return (
    <div className="regenerate-repayment">

      <Formik
        initialValues={formValues}
        onSubmit={handleOnSubmit}
        enableReinitialize
      >
        {({ setFieldValue, values, resetForm }) => {
          setUpdatedValues(values);
          return (
            <Form className="ant-form-vertical ">
              {options?.map((opt) => {
                return (
                  <Row className="mb-5" key={opt?.value}>
                    <Col span={24}>
                      <Radio.Group
                        onChange={(e) => {
                          setRadioValue(e.target.value);
                        }}
                        value={radioValue}
                      >
                        <Radio value={opt?.value}>
                          {opt?.label}{" "}
                          <div className=" text-italic text-link">
                            {opt?.description}
                          </div>
                        </Radio>
                      </Radio.Group>
                    </Col>
                  </Row>
                );
              })}
              {radioValue === RegenerateRepaymentsType.DATE_AMOUNT ? (
                <div>
                  <div className="mb-5 text-bold">Date</div>
                  <DatePickerField
                    defaultValue={values.expectedStartDate}
                    setFieldValue={setFieldValue}
                    name="expectedStartDate"
                    type="date"
                  />
                  <InputField
                    type={InputType.NUMBER}
                    name="requestedLoanAmt"
                    placeholder="Enter Amount"
                    label="Amount"
                    disabled
                  />
                </div>
              ) : (
                <div>
                  <SelectInput
                    name="tenureInMonths"
                    options={createOptionsArray(repaymentMonths)}
                    placeholder="Select Tenure"
                    label="Change Tenure"
                  />
                  <InputField
                    type={InputType.NUMBER}
                    name="requestedLoanAmt"
                    placeholder="Enter Original Amount"
                    label="Original Amount"
                    disabled
                  />
                  <InputField
                    type={InputType.NUMBER}
                    name="paidPrincipal"
                    placeholder="Enter Paid Principal Amount"
                    label="Paid Principal Amount"
                    disabled
                  />
                  <InputField
                    type={InputType.NUMBER}
                    name="outstandingPrincipalAmount"
                    placeholder="Enter Outstanding Principal Amount"
                    label="Outstanding Principal Amount"
                    disabled
                  />
                </div>
              )}
              <div className="drawer-footer">
                <Button
                  htmlType="reset"
                  onClick={onClose}
                  type="link"
                  className="drawer-footer__cancel"
                >
                  Cancel
                </Button>
                <Button
                  loading={loading}
                  htmlType="submit"
                  className="drawer-footer__submit"
                >
                  Apply
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
      <Modal
        className="profile__modal"
        open={isOtpVisible}
        onCancel={handleOtpVisibility}
        destroyOnClose
        centered
        footer={false}
      >
        <UserOtpVerify
          onClose={handleOtpVisibility}
          onSuccess={handleOtpVerified}
          isLoanSection={true}
        />
      </Modal>
    </div>
  );
};

export default RegenerateRepayment;
