import React, { FC } from "react";
import { Radio, RadioChangeEvent } from "antd";
import "./radioField.scss";
import { ErrorMessage, Field } from "formik";
import Error from "../Error";
import { Meta } from "../../../models/meta.modal";

interface RadioFieldProps {
  value?: string | number | boolean;
  options: Meta[];
  title?: string;
  name: string;
  defaultValue?: string;
  className?: string;
  setFieldValue: (name: string, value: any) => void;
  onChange?: (name: string, value: string) => void;
}

const RadioField: FC<RadioFieldProps> = (props) => {
  const {
    value,
    options,
    title,
    name,
    setFieldValue,
    defaultValue,
    className,
    onChange
  } = props;
  
  const handleChange = (event: RadioChangeEvent) => {
    setFieldValue(name, event.target.value);
    if (onChange) {
      onChange(name, event.target.value);
    }
  };

  return (
    <Field name={name}>
      {({ field, form: { touched, errors, setFieldTouched }, meta }: any) => {
        return (
          <div className={`radio-field mb-4 ${className}`}>
            <label htmlFor="">{title}</label>
            <Radio.Group
              onChange={handleChange}
              value={value}
              defaultValue={defaultValue}
            >
              {options.map(({ label, value }) => (
                <Radio value={value}>{label}</Radio>
              ))}
            </Radio.Group>
            <ErrorMessage name={name}>
              {(message: string) => <Error message={message} />}
            </ErrorMessage>
          </div>
        );
      }}
    </Field>
  );
};

export default RadioField;
