import { LoanStatus } from "../../enums/loanStatus.type";
import { RejectionReason } from "../../enums/rejectionReason.type";

export const loanRejectionOptions = [
  {
    label: "Insufficient Income",
    value: RejectionReason.INSUFFICIENT_INCOME,
  },
  {
    label: "Not Meeting the Loan Criteria",
    value: RejectionReason.NOT_MEETING_THE_LOAN_CRITERIA,
  },
  {
    label: "Poor Credit Score or History",
    value: RejectionReason.POOR_CREDIT_SCORE_HISTORY,
  },
  {
    label: "Indiscipline Repayment History",
    value: RejectionReason.INDICIPLINE_REPAYMENTS_HISTORY,
  },
  {
    label: "Borrower Unwilling to Avail the Loan",
    value: RejectionReason.BORROWER_UNWILLING_TO_AVAIL_THE_LOAN,
  },
  {
    label: "BWDC Customer",
    value: RejectionReason.BWDC_CUSTOMER,
  },
  {
    label: "TAT limit Reached",
    value: RejectionReason.TAT_LIMIT_REACHED,
  },
];
