import React, { useEffect, useState } from "react";
import { Col, Pagination, Row, Table, TableProps } from "antd";
import { velichamInstallmentColumn } from "./velichamRepaymentColumn";
import "./velichamRepayment.scss";
import { LoanRepayamentSchedule } from "../../../models/loanRepaymentSchedule";
import { LoanService } from "../../../services/Loan/loan.service";
import { QueryParams } from "../../../models/query.params.model";
import { PaginationMeta } from "../../../models/pagination.model";
import { LoanEMI } from "../../../models/loanEMI.model";

interface VelichamRepaymentProps {
  loanDetails?: LoanEMI;
  handleRepaymentSchedule: (params: QueryParams) => void; // Adjusted return type
  loanSchedule: LoanRepayamentSchedule[];
  pagination?: PaginationMeta;
}

const VelichamRepayment = (props: VelichamRepaymentProps) => {
  const { loanDetails, handleRepaymentSchedule, loanSchedule, pagination } =
    props;

  const handleChange: TableProps<LoanRepayamentSchedule>["onChange"] = (
    { current },
    _,
    sorter
  ) => {
    handleRepaymentSchedule({ page: current });
  };

  return (
    <div className="velicham-repayment">
      <Row className="velicham-repayment__header">
        <Col xs={24} sm={24} md={24} lg={12}>
          <p className="title ml-5">Repayment Schedule</p>
        </Col>

        <Col sm={6} xs={6} md={6} lg={8}>
          <h2 className="block__label text-right ml-5"> Loan ID</h2>
        </Col>
        <Col lg={4} sm={8} xs={8} md={8}>
          <h2 className="block__value ml-5">{loanDetails?.loancode}</h2>
        </Col>
      </Row>
      <Table
        columns={velichamInstallmentColumn}
        dataSource={loanSchedule}
        onChange={handleChange}
        pagination={{
          hideOnSinglePage: true,
          total: pagination?.totalCount,
          current: pagination?.currentPage,
        }}
      />
    </div>
  );
};

export default VelichamRepayment;
