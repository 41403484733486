import "./branchFilters.scss";

import { Button, Tabs } from "antd";
import FiltersListing, {
  FiltersListingProps,
} from "../../../../shared/components/FiltersListing";
import { Form, Formik } from "formik";

import { BranchParams } from "../../../../models/branch.model";
import { EmployeeService } from "../../../../services/Employee/employee.service";
import { MetaService } from "../../../../services/Meta/meta.service";
import { QueryParams } from "../../../../models/query.params.model";
import React from "react";
import { getFullName } from "../../../../shared/utils/getFullName";

const branchFilters = ["responsibleEmployee", "regionId", "isActive"];
interface BranchFiltersProps {
  onFilter: (val: BranchParams) => void;
  onClose: () => void;
}

const BranchFilters = (props: BranchFiltersProps) => {
  const { getRegions, getStatuses, loading: metaLoading } = MetaService();
  const { getEmployees, loading: employeesLoading } = EmployeeService();

  const { onClose, onFilter } = props;

  const fetchRegions = async () => {
    const data = await getRegions();
    return { data };
  };

  const fetchStatuses = async () => {
    const data = await getStatuses();
    return { data };
  };

  const fetchEmployees = async (params: QueryParams) => {
    const result = await getEmployees(params);

    return {
      data: result?.employees.map((emp) => ({
        label: getFullName(emp),
        value: emp?.id,
      })),
      meta: result?.meta,
    };
  };

  const filtersTab: { title: string; tabContent: FiltersListingProps }[] = [
    {
      title: "Regions",
      tabContent: {
        fetchData: fetchRegions,
        hasPagination: false,
        loading: metaLoading,
        name: "regionId",
      },
    },
    {
      title: "Status",
      tabContent: {
        fetchData: fetchStatuses,
        hasPagination: false,
        loading: metaLoading,
        name: "isActive",
      },
    },
    {
      title: "Employee",
      tabContent: {
        fetchData: fetchEmployees,
        hasPagination: true,
        loading: employeesLoading,
        name: "responsibleEmployee",
        searchProps: {
          placeholder: "Search Employee",
          hasIcon: false,
          onSearch: () => {},
        },
      },
    },
  ];

  const handleSubmit = (val: BranchParams) => {
    const filters = Object.fromEntries(
      Object.entries(val).filter(([key]) => branchFilters.includes(key))
    );
    onFilter({ ...filters, page: 1 });
    onClose();
  };

  return (
    <div className="branch-filters">
      <Formik initialValues={new BranchParams()} onSubmit={handleSubmit}>
        <Form>
          <Tabs tabPosition="left">
            {filtersTab.map((item, index) => (
              <Tabs.TabPane tab={item?.title} key={index}>
                <FiltersListing {...item.tabContent} />
              </Tabs.TabPane>
            ))}
          </Tabs>
          <div className="drawer-footer branch-filters__footer">
            <Button
              className="drawer-footer__cancel"
              htmlType="reset"
              onClick={onClose}
              type="link"
            >
              Cancel
            </Button>
            <Button className="drawer-footer__submit" htmlType="submit">
              Assign
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default BranchFilters;
