import moment, { Moment } from "moment";

import { DateFormat } from "../../enums/dateFormat.type";
import { fillEmptyData } from "./fillEmptyData";
import { toTitleCase } from "./titleCaseConverter";
import { removeUnderscore } from "./removeUnderscore";

interface CurrencyFormatterProps {
  amount?: number;
  hasCurrencySign?: boolean;
  currency?: "INR";
  shouldReturnNA?: boolean;
}

interface PercentageFormatter {
  value: number;
}

export const currencyFormatter = (props: CurrencyFormatterProps) => {
  const { amount, hasCurrencySign = false, currency = "INR", shouldReturnNA = true } = props;
  if (!amount) return `₹ ${0}`
  return amount
    ? new Intl.NumberFormat("en-IN", {
        style: hasCurrencySign ? "currency" : undefined,
        currency,
        minimumFractionDigits: 0,
      }).format(amount)
    : shouldReturnNA ? "N/A" : `₹ ${amount}`;
};

export const percentageFormatter = (props: PercentageFormatter) => {
  const { value } = props;
  if (value !== null && value !== undefined) {
    return value.toFixed(2).replace(/[.,]00$/, "") + "%";
  } else {
    return "N/A";
  }
};

export const formatTimeWithSpace = (timeString?: string) =>
  timeString ? `${timeString.slice(0, -2)} ${timeString.slice(-2)}` : undefined;

export const dateTimeFormatter = (
  date: string | Moment | undefined,
  type: string
) => {
  if (!date) {
    return "NA";
  }

  const dateObj = moment.utc(date);

  if (!dateObj.isValid() && type !== DateFormat.MONTH) {
    return "NA";
  }

  switch (type) {
    case DateFormat.DATE:
      return dateObj.local().format("DD/MM/YYYY");
    case DateFormat.MONTH:
      return date ? `${date} months` : "NA";
    case DateFormat.DATE_MONTH:
      return dateObj.local().format("DD/MM/YYYY hh:mm a");
    case DateFormat.TIME:
      return dateObj.local().format("hh:mm a") !== "NA"
        ? dateObj.local().format("hh:mm a")
        : "NA";
    case DateFormat.DATE_TIME:
      return dateObj.local().format("DD/MM/YYYY hh:mm a");
    case DateFormat.ORIGINAL_TIME:
        return dateObj.format("hh:mm a")
    case DateFormat.ORIGINAL_DATE:
      return dateObj.format("DD/MM/YYYY");
    default:
      return "NA";
  }
};

export const capitalizeWord = (str: string | undefined) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const numberFormatter = (value: number) => {
  const numStr = value.toString();
  const lastThreeDigits = numStr.slice(-3);
  const otherDigits = numStr.slice(0, -3);

  const formattedLastThreeDigits = otherDigits !== '' ? ',' + lastThreeDigits : lastThreeDigits;
  const formattedOtherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",");

  return formattedOtherDigits + formattedLastThreeDigits;
}
export const getFileName = (fileName?: string) => {
  if (fileName) {
    const parts = fileName.split("\\");
    return parts[parts.length - 1];
  }
}