//Icons

//Styles

//Libraries
import { Button, Tabs } from "antd";
import FiltersListing, {
  FiltersListingProps,
} from "../../../shared/components/FiltersListing";
import { Form, Formik } from "formik";
import React from "react";
import { MetaService } from "../../../services/Meta/meta.service";
import { QueryParams } from "../../../models/query.params.model";
import { getFullName } from "../../../shared/utils/getFullName";
import { BranchesService } from "../../../services/Branches/branches.service";
import { RepaymentsParams } from "../../../models/repayments.model";
import { Employee } from "../../../models/employee.model";
import { LocalStorageHelper } from "../../../shared/utils/localStorageHelper";
import { LocalStorage } from "../../../enums/localStorage.enum";
import { RoleName } from "../../../enums/roleName.enum";
import { hideTab } from "../../../shared/utils/hideTab";
const repaymentFilters = [
  "status",
  "loanTypeIds",
  "branchIds",
  "organisations",
];
interface repaymentFiltersProps {
  onFilter: (val: RepaymentsParams) => void;
  onClose: () => void;
}

const RepaymentFilters = (props: repaymentFiltersProps) => {
  // Services
  const {
    getLoanTypes,

    getInstallmentStatus,
    loading: metaLoading,
    getPreferredChannel,
  } = MetaService();

  const { getBranches, loading: branchesLoading } = BranchesService();
  const employee = LocalStorageHelper.getItem(
    LocalStorage.EMPLOYEE
  ) as Employee;

  //Prop
  const { onClose, onFilter } = props;

  const fetchLoanTypes = async () => {
    const data = await getLoanTypes();
    return { data };
  };

  const fetchBranches = async (params: QueryParams) => {
    const result = await getBranches(params);
    return {
      data:
        result?.branches?.map((branch) => ({
          label: branch?.name,
          value: branch?.id,
        })) ?? [],
      meta: result?.meta,
    };
  };

  const fetchInstallmentStatus = async () => {
    const data = await getInstallmentStatus();
    return { data };
  };

  const filtersTab: { title: string; tabContent: FiltersListingProps }[] = [
    {
      title: "Branch",
      tabContent: {
        fetchData: fetchBranches,
        hasPagination: true,
        loading: branchesLoading,
        name: "branchIds",
        searchProps: {
          placeholder: "Search Branch",
          hasIcon: false,
          onSearch: () => {},
        },
      },
    },
    {
      title: "Loan Type",
      tabContent: {
        fetchData: fetchLoanTypes,
        hasPagination: false,
        loading: metaLoading,
        name: "loanTypeIds",
      },
    },
    {
      title: "Status",
      tabContent: {
        fetchData: fetchInstallmentStatus,
        hasPagination: false,
        loading: metaLoading,
        name: "status",
      },
    },
  ];

  const handleSubmit = (val: RepaymentsParams) => {
    const filters = Object.fromEntries(
      Object.entries(val).filter(([key]) => repaymentFilters.includes(key))
    );

    onFilter({ ...filters, page: 1 });
    onClose();
  };

  return (
    <div className="repayment-filters">
      <Formik initialValues={new RepaymentsParams()} onSubmit={handleSubmit}>
        {({ setFieldValue }) => (
          <Form>
            <Tabs tabPosition="left">
              {filtersTab
                .filter(
                  ({ tabContent: { name } }) =>
                    !hideTab(
                      employee?.roleName === RoleName.BRANCH_MANAGER,
                      name,
                      ["branchIds"]
                    )
                )
                .map((item, index) => (
                  <Tabs.TabPane tab={item?.title} key={index}>
                    <FiltersListing {...item.tabContent} />
                  </Tabs.TabPane>
                ))}
            </Tabs>

            <div className="drawer-footer repayment-filters__footer">
              <Button
                className="drawer-footer__cancel"
                htmlType="reset"
                onClick={onClose}
                type="link"
              >
                Cancel
              </Button>
              <Button className="drawer-footer__submit" htmlType="submit">
                Assign
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RepaymentFilters;
