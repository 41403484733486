import * as Yup from "yup"
import { ClaimsDocumentTypes } from "../../../../enums/claimsDocuments.type"

export const claimDocumentValidation = Yup.object().shape({
    documentId: Yup.number().required("Document ID is Required"),
    documentType: Yup.string().required("Document type is required"),
    documentName: Yup.string().when("documentType", {
        is: (documentType: string) => documentType === ClaimsDocumentTypes.OTHER_DOCUMENT,
        then: Yup.string().required("Document name is required")
    })
})

