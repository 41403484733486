import { SortOrder } from "antd/lib/table/interface";
import { alias, optional, primitive, serializable } from "serializr";
import { SortDirection } from "../enums/sortDirection.type";

export class QueryParams {
  @serializable(alias("page", optional(primitive())))
  page? = 1;

  @serializable(alias("search_text", optional(primitive())))
  searchText?: string;

  @serializable(alias("sort_by", optional(primitive())))
  sortBy?: string;

  @serializable(alias("sort_direction", optional(primitive())))
  sortDirection?: SortDirection;

  static serializeSortDirection = (direction?: SortOrder) => {
    switch (direction) {
      case "descend":
        return SortDirection.DESCEND;
      case "ascend":
        return SortDirection.ASCEND;
      default:
        return undefined;
    }
  };
}
