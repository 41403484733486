import { Center, CenterParams } from "../../models/center.model";
import { deserialize, serialize } from "serializr";

import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { PaginationMeta } from "../../models/pagination.model";
import axiosInstance from "../../interceptor/axiosInstance";
import { useState } from "react";
import { FileAttachment } from "../../models/fileAttachment.model";
import { generatePath } from "react-router-dom";
import { DocumentCreate } from "../../models/documentCreate.model";

export const FileService = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  const uploadFile = async (attachment: File) => {
    setLoading(true);

    const endpoint = generatePath(ApiRoutes.UPLOAD_FILE);
    const formData = new FormData();
    formData.append("attachment", attachment);
    try {
      const { data } = await axiosInstance.post(endpoint, formData);
      return data;
    } catch (ex) {
      Notification({
        message: "Unable to Upload Document",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const createDocument = async (file: File, details?: DocumentCreate) => {
    setLoading(true);
    try {
    const endpoint = generatePath(ApiRoutes.CREATE_CUSTOMER_DOCUMENT, {
      loanId: details?.code
    });
    const { data } = await axiosInstance.post(endpoint, {
      document: { front_side_image_id: file, document_type: details?.documentType},
    });
    const response = deserialize(DocumentCreate, data);
    Notification({
      message: "MOD Document Uploaded Successfully",
      description: "",
      type: NotificationTypes.SUCCESS,
    });
    return response;
  }
    catch (error) {
      Notification({
        message: "Unable to Upload MOD Document",
        description:
          (error as Error)?.message ?? "Unable to Upload MOD Document",
        type: NotificationTypes.ERROR,
      });
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  return { uploadFile, createDocument, loading };
};
