import React, { useEffect, useState } from "react";
import { Drawer, TableProps, Table, TableColumnsType } from "antd";
import { BranchesService } from "../../../services/Branches/branches.service";
import { Deposits as DepositModel } from "../../../models/deposits.model";

import { RepaymentService } from "../../../services/Repayment/repayment.service";
import { waiveRequestColumn } from "./waiveRequestColumn";
import { QueryParams } from "../../../models/query.params.model";
import { WaiveRequestModel } from "../../../models/waiveRequest.model";
import useQueryParams from "../../../shared/hooks/useQueryParams";
import CustomModal from "../../../shared/components/CustomModal";
import { removeUnderscore } from "../../../shared/utils/removeUnderscore";
import { LoanStatus } from "../../../enums/loanStatus.type";
import { LocalStorageHelper } from "../../../shared/utils/localStorageHelper";
import { LocalStorage } from "../../../enums/localStorage.enum";
import { SorterResult } from "antd/lib/table/interface";
import { Repayments } from "../../../models/repayments.model";
import useSorting from "../../../shared/hooks/useSorting";

interface WaiveRequestProps {}

const sortBy = {
  loanCode: "code",
  customerCode: 'code',
  loanType: "loan_type",
  customerName: "customer_name",
  status: "status",
  repaymentDate: "repayment_date",
  paidDate: "paid_date",
  updatedAt: 'updated_at'
};

const WaiveRequest = (props: WaiveRequestProps) => {
  const [totalPage, setTotalPage] = useState(1);
  const [waiveRequest, setWaiveRequest] = useState<WaiveRequestModel[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [seletedRow, setSelectedRow] = useState<WaiveRequestModel>();
  const { params, updateParams } = useQueryParams({
    params: new QueryParams(),
  });
  const { getWaiveRequest, updateWaiveRequest, loading } = RepaymentService();
  const userAccess = LocalStorageHelper.getItem(
    LocalStorage.CURRENT_USER_ACCESS
  );

  const { updateSortData } = useSorting<WaiveRequestModel>({ sortBy });

  useEffect(() => {
    handleFetchWaiveRequest(params);
  }, [params]);

  const handleChange: TableProps<WaiveRequestModel>["onChange"] = (
    { current },
    _,
    sorter
  ) => {
    
    const { sortBy, sortDirection } = updateSortData(
      sorter as SorterResult<WaiveRequestModel>
    );
    updateParams({ page: current, sortBy, sortDirection });
  };

  const handleFetchWaiveRequest = async (params: QueryParams) => {
    const result = await getWaiveRequest(params);
    if (result) setWaiveRequest(result?.deposits);
    if (result?.meta) setTotalPage(result?.meta?.totalCount ?? 1);
  };
  const handleModalVisibility = () => setModalVisible(!modalVisible);

  const handleRowChange = (record: WaiveRequestModel) => ({
    onClick: () => {
      if (record?.status === LoanStatus.PENDING) {
        setSelectedRow(record);
        handleModalVisibility();
      }
    },
  });
  const handleWaiveRequest = async (type: LoanStatus) => {
    const result = await updateWaiveRequest(Number(seletedRow?.loanId), type);

    if (result) {
      handleFetchWaiveRequest(params);
      handleModalVisibility();
    }
  };

  return (
    <div className="waive-request">
      <div className="card">
        <Table
          columns={waiveRequestColumn}
          dataSource={waiveRequest}
          onChange={handleChange}
          onRow={handleRowChange}
          loading={loading}
          pagination={{
            hideOnSinglePage: true,
            total: totalPage,
          }}
        />
      </div>
      {userAccess?.writeAccess && (
        <CustomModal
          className="blacklist-customers__modal"
          title="Waive Request"
          visible={modalVisible}
          onOk={() => handleWaiveRequest(LoanStatus.APPROVED)}
          onCancel={() => handleWaiveRequest(LoanStatus.REJECTED)}
          onClose={handleModalVisibility}
          okText="Confirm"
          cancelText="Reject"
        >
          {`${removeUnderscore(
            seletedRow?.customerName ?? ""
          )} has requested the penalty waiver for the ${
            seletedRow?.loanType
          } loan kindly approve or reject the waive request.`}
        </CustomModal>
      )}
    </div>
  );
};

export default WaiveRequest;
