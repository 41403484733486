import React from "react";
import { ColumnProps } from "antd/lib/table";
import { removeUnderscore } from "../../../../shared/utils/removeUnderscore";
import { Blacklists } from "../../../../models/blacklists";
import { dateTimeFormatter } from "../../../../shared/utils/formatter";
import { DateFormat } from "../../../../enums/dateFormat.type";

export const blacklistPincodeColumn: ColumnProps<Blacklists>[] = [
  {
    title: "Pincode",
    dataIndex: "pincode",
  },
  {
    title: "Date",
    dataIndex: "date",
    render: (date) => dateTimeFormatter(date ?? "", DateFormat.DATE),
  },
  {
    title: "Blocked By",
    dataIndex: "blockedBy",
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (status) => {
      const className =
        "active-status--" + status?.split("_")?.join("-").toLowerCase();
      return (
        <span className={` text-capitalize  ${className}`}>
          {removeUnderscore(status ?? "")}
        </span>
      );
    },
  },
];
