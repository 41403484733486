import { alias, primitive, serializable } from "serializr";
import { QueryParams } from "./query.params.model";

export class LoanInstallmentParams extends QueryParams {}

export class LoanInstallmentSchedule {
  @serializable(alias("instalment_date", primitive()))
  instalmentDate?: string;
  @serializable(alias("instalment_no", primitive()))
  instalmentNo?: number;

  @serializable(alias("amount", primitive()))
  amount?: number;
  @serializable(alias("repayment_no", primitive()))
  repaymentNo?: number;

  @serializable(alias("pricipal", primitive()))
  principal?: number;

  @serializable(alias("interest", primitive()))
  interest?: number;

  @serializable(alias("closing_principal", primitive()))
  closingPrincipal?: number;
}
