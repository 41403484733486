import { alias, list, object, primitive, serializable } from "serializr";

export class MISDashboardParams {
  @serializable(alias("from_date", primitive()))
  fromDate?: string;
  @serializable(alias("to_date", primitive()))
  toDate?: string;
  @serializable(alias("branch", primitive()))
  branch?: string;
  @serializable(alias("type", primitive()))
  type?: string;
  @serializable(alias("user", primitive()))
  user?: string;
  @serializable(alias("loan_type", primitive()))
  loanType?: string;
}

export class LoanAppicationApproval {
  @serializable(alias("month", primitive()))
  month?: string;
  @serializable(alias("sum", primitive()))
  sum?: number;
  @serializable(alias("count", primitive()))
  count?: number;
}

export class MISDashboard {
  @serializable(alias("total_outstanding", primitive()))
  totalOutstanding?: number;
  @serializable(alias("total_disbursement", primitive()))
  totalDisbursement?: number;
  @serializable(alias("total_own_portfolio", primitive()))
  totalOwnPortfolio?: number;
  @serializable(alias("total_managed_portfolio", primitive()))
  totalManagedPortfolio?: number;
  @serializable(alias("total_borrowers", primitive()))
  totalBorrowers?: number;
  @serializable(alias("total_active_loans", primitive()))
  totalActiveLoans?: number;
  @serializable(alias("total_active_borrowers", primitive()))
  totalActiveBorrowers?: number;
  @serializable(alias("loan_application_approval", primitive()))
  loanApplications?: LoanAppicationApproval[];
  @serializable(alias("loan_approvals", list(object(LoanAppicationApproval))))
  loanApprovals?: LoanAppicationApproval[];
  @serializable(alias('total_loans', primitive()))
  totalLoans?: number;
}
