import { serializable, alias, primitive, list, object } from "serializr";
import { Customer } from "./customer.model";
import { QueryParams } from "./query.params.model";

export class BlacklistsParams extends QueryParams {
  @serializable(alias("type", primitive()))
  type?: string;
}

class BlacklistCustomer {
  @serializable(alias("code", primitive()))
  code?: string;
  @serializable(alias("name", primitive()))
  name?: string;
  @serializable(alias("aadhaar_number", primitive()))
  aadhaarNumber?: string;
  @serializable(alias("pan_number", primitive()))
  panNumber?: string;
  @serializable(alias("phone_number", primitive()))
  phoneNumber?: string;
}
export class BlacklistForm extends BlacklistCustomer {
  @serializable(alias("type", primitive()))
  type?: string;
  @serializable(alias("customer_code", primitive()))
  customerCode?: string;
  @serializable(alias("reason", primitive()))
  reason?: string;
}

export class Blacklists {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("status", primitive()))
  status?: string;
  @serializable(alias("date", primitive()))
  date?: string;
  @serializable(alias("blocked_by", primitive()))
  blockedBy?: string;
  @serializable(alias("reason", primitive()))
  reason?: string;
  @serializable(alias("pincode", primitive()))
  pincode?: string;
  @serializable(alias("type", primitive()))
  type?: string;

  @serializable(alias("customer", object(BlacklistCustomer)))
  customer?: BlacklistCustomer;
}
