import React, { FC, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import RestrictAccess from "../RestrictedAccess";

const requireAuth = (Component: any, allowedRoles: any = []) => {
  const Authentication = (props: any) => {
    const { authenticated, user } = AuthContext();

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      if ((!authenticated || !user) && location.pathname !== appRoutes.LOGIN) {
        return navigate(appRoutes.LOGIN, {
          replace: true,
        });
      }
    }, [props, authenticated]);

    if (!authenticated || !user) {
      return null
    }
    
    if (allowedRoles.length) {
      const { user } = props;
      return allowedRoles.includes(user.role) ? (
        <Component {...props} />
      ) : (
        <RestrictAccess />
      );
    }
    return authenticated || user ? <Component {...props} /> : <></>;
  };
  return <Authentication />;
};

export const isAuthenticated = (component: FC, roles?: string[]) => {
  return requireAuth(component, roles);
};

export default isAuthenticated;
