import { keyFactorNames, keyFactorRoutes } from '../../../enums/keyFactors.type';
import * as AppRoutes from '../../../routes/routeConstants/appRoutes';

type KeyFactorNameType = typeof keyFactorNames[keyof typeof keyFactorNames];

const keyFactorRouteMap = {
    [keyFactorNames.PAYMENT_STATUS]: keyFactorRoutes.PAYMENT_STATUS,
    [keyFactorNames.MONTHLY_EMI]: keyFactorRoutes.MONTHLY_EMI,
    [keyFactorNames.TOTAL_SETTLEMENTS]: keyFactorRoutes.TOTAL_SETTLEMENTS,
    [keyFactorNames.ACCOUNT_SUMMARY]: keyFactorRoutes.ACCOUNT_SUMMARY,
    [keyFactorNames.CREDIT_AGE]: keyFactorRoutes.CREDIT_AGE,
    [keyFactorNames.OD]: keyFactorRoutes.OD,
    [keyFactorNames.TOTAL_WRITE_OFF]: keyFactorRoutes.TOTAL_WRITE_OFF,
};

export const switchKeyFactorPage = (page: KeyFactorNameType, loanId: string) => {
    const keyFactor = keyFactorRouteMap[page];
    if (keyFactor) {
        return AppRoutes.INDIVIDUAL_CUSTOMER_CREDIT_REPORT_KEY_FACTOR.replace(':keyFactor', keyFactor).replace(':loanId', loanId);
    }
    return "";
};

export const keyFactorPageRoute = (page: KeyFactorNameType, loanId: string) => {
    const keyFactor = keyFactorRouteMap[page];
    if (keyFactor) {
        return AppRoutes.INDIVIDUAL_CUSTOMER_CREDIT_REPORT_KEY_FACTOR.replace(':keyFactor', keyFactor).replace(':loanId', loanId);
    }
}
export const isKeyFactorNameType = (value: string): value is KeyFactorNameType => {
    return Object.values(keyFactorNames).includes(value as KeyFactorNameType);
};
