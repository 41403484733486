import React, {
  useContext,
  createContext,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { Employee } from "../../models/employee.model";
import { Token } from "../../models/token.model";
import { LocalStorage } from "../../enums/localStorage.enum";
import { decryptBase64, encryptBase64, validateLocalStorage } from "../../shared/utils/localStorageHelper";

export interface AuthState {
  authenticated?: boolean;
  user?: { token: Token; employee: Employee };
}

type SetAuthState = Dispatch<SetStateAction<AuthState>>;

type AuthContentProps = [AuthState, SetAuthState];

// Define the default context state
const initialValues: AuthState = {
  authenticated: !!localStorage.getItem(LocalStorage.USER),
  user: {
    token: localStorage.getItem(LocalStorage.USER)
      ? JSON.parse(localStorage.getItem(LocalStorage.USER)!)
      : new Token(),
    employee: localStorage.getItem(LocalStorage.EMPLOYEE)
      ? {
          ...JSON.parse(localStorage.getItem(LocalStorage.EMPLOYEE)!),
          roleName: localStorage.getItem(LocalStorage.EMPLOYEE_ROLE)
            ? decryptBase64(localStorage.getItem(LocalStorage.EMPLOYEE_ROLE)!)
            : undefined,
        }
      : new Employee(),
  },
};

// Create the context
const AuthContent: any = createContext({});

// Create method to use context
const AuthContext = () => {
  const context = useContext<AuthContentProps>(AuthContent);
  if (!context) {
    throw new Error(`useMeContext must be used within a MeContextProvider`);
  }
  const [auth, setAuth] = context;

  const setAuthenticated = (user?: { token: Token; employee: Employee }) => {
    if (user && user.employee.roleName) {
      localStorage.setItem(LocalStorage.EMPLOYEE_ROLE, encryptBase64(user.employee.roleName));
    }
    setAuth((auth) => ({
      ...auth,
      authenticated: true,
      user,
    }));
  };

  const removeAuthenticated = () => {
    localStorage.clear()
  };

  const isValid = validateLocalStorage();
  if (!isValid) {
    removeAuthenticated();
  }

  return {
    ...auth,
    setAuthenticated,
    removeAuthenticated,
  };
};

// Create the context provider
const AuthProvider = (ownProps: any) => {
  const [auth, setAuth] = useState<AuthState>(initialValues);
  const value = useMemo(() => [auth, setAuth], [auth]);
  return <AuthContent.Provider value={value} {...ownProps} />;
};

export { AuthProvider, AuthContext };
