
import {serializable, alias, object, list, primitive} from 'serializr';

export class WrittenOff { 

	@serializable(alias('total_write_off_accounts', primitive()))
	totalWriteOffAccounts?: number;

	@serializable(alias('total_write_off_amount', primitive()))
	totalWriteOffAmount?: number;

    @serializable(primitive())
    status?: string;


}