import React, { useState, useEffect } from "react";
import { Image } from "antd";
import redirectionIcon from "../../../Assets/images/paymentLoader.gif";
import "./paymentRedirection.scss";
import { useLocation } from "react-router-dom";
import { getPaymentLinkFromURL } from "../../../shared/utils/paymentActions";

const PaymentRedirection = () => {
  const paymentLink = getPaymentLinkFromURL();
  const [displayText, setDisplayText] = useState(
    "Processing your Request  . . . ."
  );

  useEffect(() => {
    if (paymentLink) {
      const redirectTimer = setTimeout(() => {
        setDisplayText("Redirecting to Payment gateway  . . . .");
        setTimeout(() => window.location.replace(paymentLink), 7000);
      }, 3000);

      return () => clearTimeout(redirectTimer);
    }
  }, [paymentLink]);

  return (
    <div className="payment-redirection">
      <div className="centered-content">
        <Image
          className="logo"
          width={200}
          height={200}
          src={redirectionIcon}
          alt="logo"
          preview={false}
        />
        <h2 className="text-link mt-5">{displayText}</h2>
      </div>
    </div>
  );
};

export default PaymentRedirection;
