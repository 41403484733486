import { alias, object, primitive, serializable } from "serializr";
import { Address } from "./address.model";
import moment from "moment";

export class LoanCustomerDetails {
  @serializable(alias("aadhaar_back_image_url", primitive()))
  aadhaarBackImageUrl?: string;
  @serializable(alias("aadhaar_front_image_url", primitive()))
  aadhaarFrontImageUrl?: string;
  @serializable(alias("id", primitive()))
  id?: string;

  @serializable(alias("address", object(Address)))
  address = new Address();
  @serializable(alias("dob", primitive()))
  dob?: string;
  @serializable(alias("age", primitive()))
  age?: string;

  @serializable(alias("aadhaar_number", primitive()))
  aadhaarNumber?: string;
  @serializable(alias("pan_number", primitive()))
  panNumber?: string;

  @serializable(alias("business_income", primitive()))
  businessIncome?: string;
  @serializable(alias("does_own_business", primitive()))
  doesOwnBusiness?: string;
  @serializable(alias("educational_qualification", primitive()))
  educationalQualification?: string;
  @serializable(alias("email", primitive()))
  email?: string;
  @serializable(alias("firstname", primitive()))
  firstName?: string;
  @serializable(alias("is_email_verified", primitive()))
  isEmailVerified?: string;
  @serializable(alias("is_mobile_verified", primitive()))
  isMobileVerified?: string;
  @serializable(alias("lastname", primitive()))
  lastName?: string;
  @serializable(alias("monthly_income", primitive()))
  monthlyIncome?: string;
  @serializable(alias("occupation", primitive()))
  occupation?: string;
  @serializable(alias("occupation_details", primitive()))
  occupationDetails?: string;
  @serializable(alias("pan_document_url", primitive()))
  panDocumentUrl?: string;
  @serializable(alias("profile_pic_url", primitive()))
  profilePicUrl?: string;
  @serializable(alias("gender", primitive()))
  gender?: string;
  @serializable(alias("phone_number", primitive()))
  phoneNumber?: string;

  @serializable(alias("relationship", primitive()))
  relationship?: string;
  getAge = () => {
    const age = moment().diff(moment(this.dob, "YYYY-MM-DD"), "years");
    return isNaN(age) ? "NA" : `${age} years`;
  };

  getName = () =>
    this.firstName ? `${this.firstName} ${this.lastName ?? ""}  ` : "";
  getDOB = () =>
    moment(this.dob, "YYYY-MM-DD").isValid()
      ? moment(this.dob, "YYYY-MM-DD").format("D MMM YYYY")
      : "NA";
}

export class CustomerDetailsNew {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("code", primitive()))
  code?: string;

  @serializable(alias("loan_customer_detail", object(LoanCustomerDetails)))
  loanCustomerDetail?: LoanCustomerDetails;
}
