import { alias, primitive, serializable } from "serializr";

export class AcknowledgeDocument {
    @serializable(alias('doc_id', primitive())) 
    documentId?: string;

    @serializable(alias('mod_document_location', primitive()))
    modDocumentLocation?: string;

    @serializable(primitive())
    id?: number
}