import { ColumnProps } from "antd/lib/table";
import { DateFormat } from "../../../enums/dateFormat.type";
import { Loan } from "../../../models/loan.model";
import { fillEmptyData } from "../../../shared/utils/fillEmptyData";
import { dateTimeFormatter } from "../../../shared/utils/formatter";
import { removeUnderscore } from "../../../shared/utils/removeUnderscore";
import { renderModStatus } from "../../../shared/utils/renderModStatus";

export const toReturnColumns: ColumnProps<Loan>[] = [
    {
      title: "Loan ID",
      dataIndex: "code",
      render: (code: string) => code?.toUpperCase(),
      sorter: true,
    },
    {
      title: "Security",
      dataIndex: "securedType",
      render: (security: string) => renderModStatus(security),
      sorter: true,
    },
    {
      title: "Customer ID",
      dataIndex: "customer.code",
      render: (text, record) => {
        return fillEmptyData(record?.customer?.code)
      },
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      render: (text, record) => {
        return fillEmptyData(record?.customer?.getName())
      },
      sorter: true,
    },
    {
      title: "Document ID",
      dataIndex: "modDocumentId",
      render: (documentID: string) => fillEmptyData(documentID),
    },
    {
      title: "Branch",
      dataIndex: "branch",
      render: (text, record) => {
        return fillEmptyData(record?.branch?.name)
      },
    },
    {
      title: "Loan Amount",
      dataIndex: "requestedLoanAmt",
      sorter: true
    },
    {
      title: "Closed Date",
      dataIndex: "closedDate",
      render: (closedDate: string) =>
        dateTimeFormatter(closedDate, DateFormat.DATE),
    },
    {
      title: "Location",
      dataIndex: "modDocumentLocation",
    },
  ];
