import "./presentBusinessAsset.scss";

import { Col, Row } from "antd";
import React, { ReactElement } from "react";

import { BusinessDetail } from "../../../../../models/businessDetail.model";
import { fillEmptyData } from "../../../../../shared/utils/fillEmptyData";
import { currencyFormatter } from "../../../../../shared/utils/formatter";

interface PresentBusinessAssetProps {
  businessDetail: BusinessDetail;
}

const PresentBusinessAsset = (props: PresentBusinessAssetProps) => {
  const { businessDetail } = props;

  return (
    <Row gutter={[16, 16]} className="block present-business-asset">
      <Col span={24} className="block__heading">
        Business Income Summary
      </Col>

      <Col span={6} className=" block__label">
        Total monthly revenue
        <div className="block__value">
          {currencyFormatter({
            amount: businessDetail?.monthlyRevenue ?? 0,
            hasCurrencySign: true,
          })}
        </div>
      </Col>
      <Col span={6} className="block__label">
        Total monthly Expenses
        <div className="block__value">
          {currencyFormatter({
            amount: businessDetail?.monthlyExpense ?? 0,
            hasCurrencySign: true,
          })}
        </div>
      </Col>
      <Col span={6} className="block__label">
      Monthly Income Balance
        <div className="block__value">
          {currencyFormatter({
            amount: businessDetail?.monthlyIncome ?? 0,
            hasCurrencySign: true,
          })}
        </div>
      </Col>
    </Row>
  );
};

export default PresentBusinessAsset;
