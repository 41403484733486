import { Col, Row } from 'antd'
import React from 'react'
import tick from '../../../../Assets/images/tick.png'
import yellowSquare from '../../../../Assets/images/yellow-square.png'
import redSquare from '../../../../Assets/images/red-square.png'
import './tableHeader.scss';

const TableHeader = () => {
  return (
    <div>
        <Row className='table-header mt-5'>
          <Col span={7}>
              Payment history of this account (last 4 years)
          </Col>
            <Col offset={5}>
              <span><img src={tick} width={18} height={18} alt=''/></span>
              <span>On-Time Payments</span>
            </Col>
            <Col offset={1}>
            <span><img src={yellowSquare} width={18} height={18} alt=''/></span>
            <span>Late Payments</span>
            </Col>
            <Col offset={1}>
            <span><img src={redSquare} width={18} height={18} alt=''/></span>
            <span>OD Payments</span>
            </Col>
            <Col style={{marginLeft: '13px'}}>
            <span className="active-status--no-info">●</span>
            <span>No Payment Info</span>
            </Col>
        </Row>
        
    </div>
  )
}

export default TableHeader