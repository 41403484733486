
import {serializable, alias, object, list, primitive} from 'serializr';
import { AccountsDetails } from './accountsDetails.model';

export class MonthlyEmi { 

	@serializable(alias('weekly_emis', primitive()))
	weeklyEmis?: number;

	@serializable(alias('biweekly_emis', primitive()))
	biweeklyEmis?: number;

	@serializable(alias('monthly_emis', primitive()))
	monthlyEmis?: number;

	@serializable(alias('bimonthly_emis', primitive()))
	bimonthlyEmis?: number;

	@serializable(alias('quartely_emis', primitive()))
	quartelyEmis?: number;

	@serializable(alias('semi_emis', primitive()))
	semiEmis?: number;

	@serializable(alias('annual_emis', primitive()))
	annualEmis?: number;

	@serializable(alias('total_monthly_emi_amount', primitive()))
	totalMonthlyEmiAmount?: number;

	@serializable(alias('monthly_emi_accounts', list(object(AccountsDetails))))
	monthlyEmiAccounts?: AccountsDetails[] = [];
	
	@serializable(primitive())
	status?: string

}