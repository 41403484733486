//Icons

//Styles
import "./filtersListing.scss";

//Libraries
import { Checkbox, Col, Row } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import SearchInput, { SearchInputProps } from "../SearchInput";

import AppLoader from "../AppLoader";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { Meta } from "../../../models/meta.modal";
import { PaginationMeta } from "../../../models/pagination.model";
import { QueryParams } from "../../../models/query.params.model";
import debounce from "lodash.debounce";
import { useFormikContext } from "formik";
import { EmployeeParams } from "../../../models/employee.model";
import useQueryParams from "../../hooks/useQueryParams";

interface FetchDataResponse {
  data?: Meta[];
  meta?: PaginationMeta;
}
export interface FiltersListingProps {
  fetchData?: (query: QueryParams) => Promise<FetchDataResponse | undefined>;
  manualData?: Meta[];
  hasPagination: boolean;
  loading?: boolean;
  name: string;
  searchProps?: SearchInputProps;
}

const FiltersListing = (props: FiltersListingProps) => {
  const { values, setFieldValue, setValues } = useFormikContext<any>();

  const { searchProps, loading, name, hasPagination, fetchData, manualData } = props;

  const [data, setData] = useState<Meta[]>([]);

  const [params, setParams] = useState(new QueryParams());

  const [totalPage, setTotalPage] = useState(1);

  const [lastElement, setLastElement] = useState<Element | null>(null);

  const { params: queryParam } = useQueryParams<EmployeeParams>({
    params: new EmployeeParams(),
  });

  useEffect(() => {
    setValues({ ...values, ...queryParam });
  }, []);

  const populateData = async (params: QueryParams, type: "add" | "update") => {
    const result = await fetchData?.(params);
    if (result?.meta && result?.meta?.totalPages)
      setTotalPage(result?.meta?.totalPages);

    switch (type) {
      case "add":
        return setData([...(result?.data ? result.data : manualData ?? [])]);
      case "update":
        return setData((existingData) => [
          ...existingData,
          ...(result?.data ? result.data : manualData ?? []),
        ]);
    }
  };

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const firstElement = entries[0];
      if (firstElement.isIntersecting)
        setParams((params) => ({
          ...params,
          page:
            Number(params?.page) <= totalPage
              ? Number(params?.page) + 1
              : Number(params?.page),
        }));
    },
    [totalPage]
  );

  const handleSearch = debounce((searchText) => {
    setParams((params) => ({ ...params, page: 1, searchText }));
  }, 500);

  useEffect(() => {
    if (Number(params?.page) > totalPage) return;

    populateData(
      { searchText: params?.searchText, page: params?.page },
      params?.page === 1 ? "add" : "update"
    );
  }, [params]);

  useEffect(() => {
    if (!hasPagination) return;

    const currentObserver = new IntersectionObserver(handleObserver);

    if (lastElement instanceof Element) currentObserver.observe(lastElement);

    return () => {
      if (lastElement instanceof Element)
        currentObserver.unobserve(lastElement);
    };
  }, [lastElement, handleObserver, hasPagination]);

  return (
    <div className="filter-section">
      <Row>
        {searchProps && (
          <Col span={8}>
            <SearchInput {...searchProps} onSearch={handleSearch} />
          </Col>
        )}
        {data?.length > 0 ? (
          <>
            <Checkbox.Group
              name={name}
              className="mt-5"
              style={{ width: "100%" }}
              value={values?.[name]}
            >
              <Row gutter={[16, 16]}>
                {data?.map((item, index) => (
                  <Col
                    ref={(elem) => {
                      index === data?.length - 1 && setLastElement(elem);
                    }}
                    key={item.value}
                    span={8}
                  >
                    <Checkbox
                      onChange={(e) => {
                        const value = e?.target?.checked
                          ? [...values?.[name], e?.target?.value]
                          : values?.[name]?.filter(
                              (val: number) => val !== e?.target?.value
                            );
                        setFieldValue(name, value);
                      }}
                      value={item?.value}
                    >
                      {item?.label}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
            <AppLoader loading={!!loading} />
          </>
        ) : loading ? (
          <AppLoader loading={!!loading} />
        ) : (
          <div className="text-danger text-center w-100 mt-5">
            No data found
          </div>
        )}
      </Row>
    </div>
  );
};

export default FiltersListing;
