import { alias, object, primitive, serializable } from "serializr";

export class BankDetail {
  @serializable(alias("score", primitive()))
  score?: string;

  @serializable(alias("owner_type", primitive()))
  ownerType?: string;
  @serializable(alias("branch_name", primitive()))
  branchName?: string;

  @serializable(alias("owner_id", primitive()))
  ownerId?: number;

  @serializable(alias("account_number", primitive()))
  accountNumber?: string;

  @serializable(alias("bank_name", primitive()))
  bankName?: number;
  @serializable(alias("registered_name", primitive()))
  registeredName?: string;

  @serializable(alias("ifsc_code", primitive()))
  ifscCode?: string;
  @serializable(alias("name_match", primitive()))
  nameMatch?: string;

  @serializable(alias("passbook_or_cheque_document_id", primitive()))
  passbookOrChequeDocumentId?: string;

  @serializable(alias("passbook_or_cheque_document_url", primitive()))
  passbookOrChequeDocumentUrl?: string;
}

export class LoanBankDetail {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("code", primitive()))
  code?: number;
  @serializable(alias("loan_bank_detail", object(BankDetail)))
  loanBankDetail = new BankDetail();
}
