import {
  Col,
  DatePicker,
  Drawer,
  Row,
  Select,
  Table,
  TableColumnsType,
  TableProps,
} from "antd";
import React, { useEffect, useState } from "react";
import { Meta } from "../../../models/meta.modal";
import { QueryParams } from "../../../models/query.params.model";
import { BranchesService } from "../../../services/Branches/branches.service";
import { Loan, LoanParams } from "../../../models/loan.model";
import { ToggleDrawer } from "../../../shared/types/toggleDrawer";
import useQueryParams from "../../../shared/hooks/useQueryParams";
import LoanFilters from "../../Loans/LoanFilters";
import ColumnOptions from "../../../shared/components/ColumnOptions";

import { RepaymentService } from "../../../services/Repayment/repayment.service";

import { cashOnHandColumn } from "./cashOnHandColumn";
import { CashOnHand as CashOnHandModel } from "../../../models/cashOnHand.model";
import { Filters } from "../../../models/filters.model";
import FilterButtons from "../../../shared/components/FilterButtons";
import { DateRange } from "../../../shared/types/date.type";
import CustomRangePicker from "../../../shared/components/CustomRangePicker";
interface CashOnHandProps {
  params: LoanParams;
  filterVisible?: boolean;
  onFilter?: ToggleDrawer;
  settingsVisible?: boolean;
  onSettings?: ToggleDrawer;
}
const CashOnHand = (props: CashOnHandProps) => {
  const [totalPage, setTotalPage] = useState(1);
  const [cashOnHand, setCashOnHand] = useState<CashOnHandModel[]>([]);
  const [defaultColumns, setDefaultColumns] =
    useState<TableColumnsType<CashOnHandModel>>(cashOnHandColumn);
  const [filteredColumns, setFilteredColumns] = useState<string[]>([]);
  const { params, updateParams, getParams } = useQueryParams({
    params: props?.params,
  });
  const [filters, setFilters] = useState<Filters>(new Filters());

  const {
    params: loanParams,
    filterVisible,
    onFilter,
    onSettings,
    settingsVisible,
  } = props;
  const { loading, getCashOnHand } = RepaymentService();

  const { getBranches } = BranchesService();

  useEffect(() => {
    const updateParams = loanParams?.searchText
      ? { ...params, page: 1, searchText: loanParams?.searchText }
      : params;
    handleFetchCashOnHand(updateParams);
  }, [params, loanParams?.searchText]);

  const handleChange: TableProps<CashOnHandModel>["onChange"] = ({ current }, _, sort, extra) => {
    const updatedParams = {
      ...params, 
      page: current, 
    };
    updateParams(updatedParams);
  };
  
  const handleFetchCashOnHand = async (params: QueryParams) => {
    const result = await getCashOnHand(params);
    if (result) setCashOnHand(result?.cashOnHand);
    if (result?.meta) setTotalPage(result?.meta?.totalCount ?? 1);
    setFilters(result?.meta?.filters ?? {});
  };

  const handleUpdateParams = () => updateParams(getParams());

  return (
    <div className="cash-on-hand-details">
      <div className="card">
        {!loading && (
          <FilterButtons filters={filters} onFilter={handleUpdateParams} />
        )}
        <Table
          columns={defaultColumns.filter(
            (col) => !filteredColumns.includes(col.title as string)
          )}
          className=" custom-scrollbar"
          dataSource={cashOnHand}
          scroll={{ x: true }}
          loading={loading}
          onChange={handleChange}
          pagination={{
            hideOnSinglePage: true,
            total: totalPage,
          }}
        />
      </div>
      {filterVisible && onFilter && (
        <Drawer
          placement="right"
          onClose={() => onFilter(false)}
          visible={filterVisible}
          width={"70vw"}
          title="Filters"
          closable
          destroyOnClose
        >
          <LoanFilters onFilter={updateParams} onClose={onFilter} />
        </Drawer>
      )}
      {settingsVisible && onSettings && (
        <Drawer
          placement="right"
          onClose={() => onSettings(false)}
          visible={settingsVisible}
          width={500}
          title="Column Options"
          destroyOnClose
        >
          <ColumnOptions
            defaultColumns={defaultColumns}
            filteredColumns={filteredColumns}
            setFilteredColumns={setFilteredColumns}
            onClose={() => onSettings(false)}
            setDefaultColumns={setDefaultColumns}
          />
        </Drawer>
      )}
    </div>
  );
};

export default CashOnHand;
