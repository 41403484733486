import { alias, primitive, serializable } from "serializr";
import { QueryParams } from "./query.params.model";

export class PaymentsParams extends QueryParams {}

export class Payments {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("amount", primitive()))
  amount?: string;

  @serializable(alias("created_at", primitive()))
  createdAt?: number;

  @serializable(alias("receipt_no", primitive()))
  receiptNo?: string;

  @serializable(alias("type", primitive()))
  type?: string;
  @serializable(alias("laf", primitive()))
  laf?: string;

  @serializable(alias("transaction_type", primitive()))
  transactionType?: string;

  @serializable(alias("transaction_id", primitive()))
  transactionId?: string;

  @serializable(alias("loan_code", primitive()))
  loanCode?: string;

  @serializable(alias("activity", primitive()))
  activity?: number;
}
