//Styles
import "./table.scss";

//Libraries
import { Table as AntTable, TableProps as AntTableProps } from "antd";

import React from "react";

interface TableProps extends AntTableProps<any> {}

const Table = (props: TableProps) => {
  //Service

  //Context

  //Prop
  const { pagination, ...otherProps } = props;

  //State

  return (
    <AntTable
      pagination={{
        hideOnSinglePage: true,
        showSizeChanger: false,
        ...pagination,
      }}
      showSorterTooltip={false}
      {...otherProps}
    />
  );
};

export default Table;
