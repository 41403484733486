import * as Yup from "yup";

export const creditScoreValidation = Yup.object().shape({
  creditScore: Yup.number().when("isNTC", {
    is: true,
    then: Yup.number().notRequired(),
    otherwise: Yup.number()
      .required("Credit Score is Required")
      .when("specialApproval", {
        is: true,
        then: Yup.number().max(
          599,
          "Credit Score must be less than 600 for Special Approval Cases"
        ),
        otherwise: Yup.number().min(
          600,
          "Credit Score must be equal or greater than 600 for Non-Special Approval Cases"
        ),
      }),
  }).test(
    'length of number to be 3',
    'Credit Score must be a 3 digit number',
    (value) => {
      if (!value) {
        return true;
      }
      return value.toString().length <= 3;
    }
  )
,
  dpdDays: Yup.number().when(
    ["isNTC", "specialApproval"],
    (isNTC, specialApproval, schema) => {
      if (!isNTC || specialApproval) {
        return schema.required("DPD Days is Required");
      }
    }
  ),

  dpdAmount: Yup.number().when(
    ["isNTC", "specialApproval"],
    (isNTC, specialApproval, schema) => {
      if (!isNTC || specialApproval) {
        return schema.required("DPD Amount is Required");
      }
    }
  ),

  activeLoansOutstanding: Yup.number().when(
    ["isNTC", "specialApproval"],
    (isNTC, specialApproval, schema) => {
      if (!isNTC || specialApproval) {
        return schema.required("Active Loans Outstanding is Required");
      }
    }
  ),

  enquiryNo: Yup.string().required("Enquiry Number is Required").matches(/^[a-zA-Z0-9 ]*$/, "Special Characters are not allowed"),
  cbCreditScore: Yup.number().required("CB Credit Score is Required").test(
    'length of number to be 3',
    'Credit Score must be a 3 digit number',
    (value) => {
      if (!value) {
        return true;
      }
      return value.toString().length <= 3;
    }
  )
,
  cbEnquiryNumber: Yup.string().required("CB Enquiry Number is Required").matches(/^[a-zA-Z0-9 ]*$/, "Special Characters are not allowed"),

  specialApprovalReason: Yup.string()
    .nullable()
    .when(["specialApproval"], {
      is: (specialApproval) => specialApproval,
      then: Yup.string().required("Must select a special approval reason"),
      otherwise: Yup.string().notRequired(),
    }),
});
