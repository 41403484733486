import { ColumnsType } from "antd/lib/table";
import { CreatedBy } from "../../../models/createdBy.model";
import { Customer } from "../../../models/customer.model";
import React from "react";
import { getFullName } from "../../../shared/utils/getFullName";

export const columns: ColumnsType<Customer> = [
  {
    title: "Customer ID",
    dataIndex: "code",
    sorter: true,
  },
  {
    title: "Name",
    dataIndex: "firstName",
    sorter: true,
    render: (_, record) => getFullName(record),
  },
  {
    title: "Email",
    dataIndex: "email",
    sorter: true,
  },
  {
    title: "Phone",
    dataIndex: "phoneNumber",

    render: (_, record) => record?.getPhoneNumber(),
  },
  {
    title: "Aadhaar",
    dataIndex: "aadhaarNumber",
    sorter: true
  },
  {
    title: "Branch",
    dataIndex: ["center", "branch", "name"],
    sorter: true,
  },
  {
    title: "CRO",
    dataIndex: "croName",
    sorter: true,
  },

  {
    title: "Status",
    dataIndex: "isActive",
    sorter: true,
    render: (_, record) => (
      <span className="text-success active-status text-capitalize">
        {record.getStatus()}
      </span>
    ),
  },
  {
    title: "Preferred channel ",
    dataIndex: "organisation",
    render: (_, record) => record?.organisation?.name,
  },
];
