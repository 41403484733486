//Icons

//Libraries
import { Button, Col, Row } from "antd";
import { Formik, Form } from "formik";
import React from "react";
import { LoanStatus } from "../../../../enums/loanStatus.type";
import { Loan } from "../../../../models/loan.model";
import SelectInput from "../../../../shared/components/SelectInput";

//Styles
import "./changeStatus.scss";
import { Meta } from "../../../../models/meta.modal";
import { LoanService } from "../../../../services/Loan/loan.service";
import loanStatusOptions from "../../../../shared/Constant/loanStatusOption";
import DatePickerField from "../../../../shared/components/DatePickerField";
import moment from "moment";
import { DisbursementModel } from "../../../../models/disbursement.model";

interface ChangeStatusProps {
  selectedLoans: number[];
  onClose: () => void;
  activeTab?: string;
}

const ChangeStatus = (props: ChangeStatusProps) => {
  //Service
  const {
    updateLoan,
    loading,

    completeDisburseStatus,
    revertDisburseStatus,
    updateStatusCTVerification,
  } = LoanService();

  //Context

  //Prop
  const { onClose, selectedLoans, activeTab } = props;

  //State

  const handleSubmit = async (loan: DisbursementModel) => {
    const updatedParams = {
      loanIds: selectedLoans,
      date: loan?.date,
      status: loan?.status,
    };

    const result =
      loan.status === LoanStatus.DISBURSED
        ? await completeDisburseStatus(updatedParams)
        : loan.status === LoanStatus.APPROVED
        ? await revertDisburseStatus({ loanIds: selectedLoans })
        : await updateStatusCTVerification({ loanIds: selectedLoans });
    result && onClose();
  };

  const getCTVerificationStatus = () => {
    const mappedOptions = loanStatusOptions
      .filter(({ value }) =>
        activeTab === "1"
          ? value === LoanStatus.CT_VERIFICATION
          : value !== LoanStatus.CT_VERIFICATION
      )
      .map(({ label, value }) => ({ label, value }));
    return mappedOptions;
  };

  return (
    <div className="change-status">
      <Formik initialValues={new DisbursementModel()} onSubmit={handleSubmit}>
        {({ values, setFieldValue, isValid, dirty }) => {
          return (
            <Form>
              <Row className="change-status__row">
                <h1>Change Status</h1>
                <Col span={24}>
                  <div className="mb-5">Status</div>
                  <SelectInput
                    name="status"
                    placeholder="Select Status"
                    options={getCTVerificationStatus()}
                  />
                </Col>{" "}
                {values.status === LoanStatus.DISBURSED && (
                  <Col span={24}>
                    <div className="mb-5">Date</div>
                    <DatePickerField
                      setFieldValue={setFieldValue}
                      name="date"
                      type="date"
                    />
                  </Col>
                )}
              </Row>

              <div className="modal__footer text-right">
                <Button
                  htmlType="reset"
                  onClick={onClose}
                  className="modal-footer__cancel"
                  type="link"
                >
                  Cancel
                </Button>
                <Button
                  className="modal-footer__submit"
                  htmlType="submit"
                  disabled={!dirty || !isValid}
                >
                  Confirm
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ChangeStatus;
