import { serializable, alias, primitive, list, object } from "serializr";

export class LoanDeductionForm {
  @serializable(alias("name", primitive()))
  name?: string;
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("mode", primitive()))
  mode?: string;
  @serializable(alias("fixed_rate", primitive()))
  fixedRate?: number;
  @serializable(alias("percentage", primitive()))
  percentage?: number;
  @serializable(alias("loan_type_id", primitive()))
  loanTypeId?: number;
  @serializable(alias("deduction_type", primitive()))
  deductionType?: string;
  @serializable(primitive())
  gst?: number;
  @serializable(alias("gst_enabled", primitive()))
  gstEnabled?: boolean;
}

export class LoanDeduction {
  @serializable(alias("loanDeduction", list(object(LoanDeductionForm))))
  LoanDeduction: LoanDeductionForm[] = [];
}
