import { Center, CenterParams } from "../../models/center.model";
import { deserialize, serialize } from "serializr";

import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { PaginationMeta } from "../../models/pagination.model";
import axiosInstance from "../../interceptor/axiosInstance";
import { useState } from "react";
import {
  RepaymentDashboard,
  Repayments,
  RepaymentsParams,
} from "../../models/repayments.model";
import { Deposits, DepositsParams } from "../../models/deposits.model";
import { CashOnHand, CashOnHandParams } from "../../models/cashOnHand.model";
import {
  WaiveRequestModel,
  WaiveRequestModelParams,
} from "../../models/waiveRequest.model";
import { RegenerateRepaymentsModel } from "../../models/regenerateRepayments.model";
import { generatePath } from "react-router-dom";
import { LoanStatus } from "../../enums/loanStatus.type";
import { User } from "../../models/user.model";
import { Payments, PaymentsParams } from "../../models/payments.model";
import { RepaymentChart } from "../../models/repaymentChart.model";

export const RepaymentService = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  const getRepayments = async (params?: RepaymentsParams) => {
    setLoading(true);
    const queryParams = params ? serialize(RepaymentsParams, params) : {};
    try {
      const { data } = await axiosInstance.get(ApiRoutes.REPAYMENTS, {
        params: queryParams,
      });
      const deserializedData = deserialize(
        Repayments,
        data?.["repayments"] as any[]
      );
      const deserializedMeta = deserialize(PaginationMeta, data?.["meta"]);
      return { repayments: deserializedData, meta: deserializedMeta };
    } catch (ex) {
      Notification({
        message: "Unable to Get Repayment details",
        description: (ex as Error).message,
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };
  const getDeposits = async (params?: DepositsParams) => {
    setLoading(true);
    const queryParams = params ? serialize(DepositsParams, params) : {};
    try {
      const { data } = await axiosInstance.get(ApiRoutes.DEPOSITS, {
        params: queryParams,
      });
      const deserializedData = deserialize(
        Deposits,
        data?.["deposits"] as any[]
      );
      const deserializedMeta = deserialize(PaginationMeta, data?.["meta"]);
      return { deposits: deserializedData, meta: deserializedMeta };
    } catch (ex) {
      Notification({
        message: "Unable to Get Deposit details",
        description: (ex as Error)?.message,
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const getCashOnHand = async (params?: CashOnHandParams) => {
    setLoading(true);
    const queryParams = params ? serialize(CashOnHandParams, params) : {};
    try {
      const { data } = await axiosInstance.get(ApiRoutes.CASH_ON_HAND, {
        params: queryParams,
      });
      const deserializedData = deserialize(
        CashOnHand,
        data?.["cash_on_hand"] as CashOnHand[]
      );
      const deserializedMeta = deserialize(PaginationMeta, data?.["meta"]);
      return { cashOnHand: deserializedData, meta: deserializedMeta };
    } catch (ex) {
      Notification({
        message: "Unable to Get Cash on Hand details",
        description: (ex as Error)?.message,
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const getWaiveRequest = async (params?: WaiveRequestModelParams) => {
    setLoading(true);
    const queryParams = params
      ? serialize(WaiveRequestModelParams, params)
      : {};
    try {
      const { data } = await axiosInstance.get(ApiRoutes.WAIVE_REQUEST, {
        params: queryParams,
      });
      const deserializedData = deserialize(
        WaiveRequestModel,
        data?.["waive_requests"] as WaiveRequestModel[]
      );
      const deserializedMeta = deserialize(PaginationMeta, data?.["meta"]);
      return { deposits: deserializedData, meta: deserializedMeta };
    } catch (ex) {
      Notification({
        message: "Unable to Get Waive Request details",
        description: (ex as Error)?.message,
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };
  const updateWaiveRequest = async (id: number, type: LoanStatus) => {
    setLoading(true);
    try {
      const endpoint = generatePath(
        type === LoanStatus.APPROVED
          ? ApiRoutes.WAIVE_REQUEST_APPROVE
          : ApiRoutes.WAIVE_REQUEST_REJECT,
        { id }
      );
      const { data } = await axiosInstance.put(endpoint);

      Notification({
        message: `Penalty waiver request ${
          type === LoanStatus.APPROVED ? "approved" : "rejected"
        }`,
        description: "",
        type: NotificationTypes.SUCCESS,
      });
      return true;
    } catch (ex) {
      Notification({
        message: `Unable to ${
          type === LoanStatus.APPROVED ? "approve" : "reject"
        } request`,
        description: (ex as Error)?.message,
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const getRepaymentMonths = async (loanId: number | undefined, loanAmount: number | undefined) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.LOANS_REPAYMENT_TENURE_MONTHS, {
        loanId: loanId,
    })
    const { data } = await axiosInstance.get(endpoint, {
     params: {
      amount: loanAmount
     }
    });
    return data.applicable_tenures
   }
     catch (ex) {
      Notification({
        message: "Unable to Get Repayment Months",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Get Repayment Months",
        type: NotificationTypes.ERROR,
      });
    setError(ex as Error);
  } finally {
    setLoading(false);
  }
  }

  const regenerateSchedule = async (
    loanId: string,
    params: RegenerateRepaymentsModel
  ) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.REGENERATE_REPAYMENT, {
        loanId: loanId,
      });
      const serializedLoan = serialize(RegenerateRepaymentsModel, params);
      const { data } = await axiosInstance.post(endpoint, {
        loan: serializedLoan,
      });

      Notification({
        message: "Regenerated Schedule",
        description: "",
        type: NotificationTypes.SUCCESS,
      });
      return true;
    } catch (ex) {
      Notification({
        message: "Unable to Regenerate Schedule",
        description: (ex as Error)?.message,
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };
  const getRepaymentsDashboardMetrics = async (params?: RepaymentsParams) => {
    setLoading(true);
    const queryParams = serialize(RepaymentsParams, params || {});
    try {
      const { data } = await axiosInstance.get(ApiRoutes.REPAYMENTS_DASHBOARD, {
        params: queryParams,
      });
      const deserializedData = deserialize(
        RepaymentDashboard,
        data?.["repayment"]
      );
      return deserializedData;
    } catch (ex) {
      Notification({
        message: "Unable to Get Repayment Metrics",
        description: (ex as Error)?.message,
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const getRepaymentsDashboardGraph = async (params?: RepaymentsParams) => {
    setLoading(true);
    const queryParams = serialize(RepaymentsParams, params || {});
    try {
      const { data } = await axiosInstance.get(
        ApiRoutes.REPAYMENTS_DASHBOARD_GRAPH,
        {
          params: queryParams,
        }
      );

      const deserializedData = deserialize(RepaymentChart, data?.["repayment"]);
      return deserializedData;
    } catch (ex) {
      Notification({
        message: "Unable to Get Repayment Metrics",
        description:
          (ex as Error)?.message ?? "Unable to Get Repayment Metrics",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const tenureChangeSendOtp = async (user: User) => {
    try {
      setLoading(true);
      const endpoint = generatePath(ApiRoutes.TENURE_CHANGE_SEND_OTP);

      const { data } = await axiosInstance.post(endpoint, {
        employee: user,
      });

      Notification({
        message: "OTP Sent",

        type: NotificationTypes.SUCCESS,
      });
      return true;
    } catch (ex) {
      Notification({
        message: "Unable to Send OTP",
        description: (ex as Error)?.message,
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const tenureChangeVerifyOtp = async (user: User) => {
    try {
      setLoading(true);
      const endpoint = generatePath(ApiRoutes.TENURE_CHANGE_VERIFY_OTP);

      const { data } = await axiosInstance.post(endpoint, {
        employee: user,
      });

      Notification({
        message: "OTP Verified",
        type: NotificationTypes.SUCCESS,
      });
      return true;
    } catch (ex) {
      Notification({
        message: "Unable to verify OTP",
        description: (ex as Error)?.message,
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const getPayments = async (params?: PaymentsParams) => {
    setLoading(true);
    const queryParams = serialize(PaymentsParams, params || {});
    try {
      const { data } = await axiosInstance.get(ApiRoutes.PAYMENTS, {
        params: queryParams,
      });
      const deserializedData = deserialize(
        Payments,
        data?.["payments"] as Payments[]
      );
      const deserializedMeta = deserialize(PaginationMeta, data?.["meta"]);
      return { payments: deserializedData, meta: deserializedMeta };
    } catch (ex) {
      Notification({
        message: "Unable to Get payment details",
        description: (ex as Error)?.message,
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    getRepayments,
    getDeposits,
    getCashOnHand,
    getWaiveRequest,
    regenerateSchedule,
    getRepaymentsDashboardMetrics,
    updateWaiveRequest,
    tenureChangeSendOtp,
    tenureChangeVerifyOtp,
    getPayments,
    getRepaymentsDashboardGraph,
    getRepaymentMonths,
    loading,
    error,
  };
};
