import { ColumnProps } from "antd/lib/table";
import { DateFormat } from "../../../enums/dateFormat.type";
import { Loan } from "../../../models/loan.model";
import { fillEmptyData } from "../../../shared/utils/fillEmptyData";
import { dateTimeFormatter } from "../../../shared/utils/formatter";
import { removeUnderscore } from "../../../shared/utils/removeUnderscore";
import { renderModStatus } from "../../../shared/utils/renderModStatus";

export const pendingColumns: ColumnProps<Loan>[] = [
    {
      title: "Loan ID",
      dataIndex: "code",
      render: (code: string) => code?.toUpperCase(),
      sorter: true,
    },
    {
      title: "Security",
      dataIndex: "securedType",
      render: (security: string) => renderModStatus(security),
      sorter: true,
    },
    {
      title: "Customer ID",
      dataIndex: "customerId",
      render: (text, record) => {
        return fillEmptyData(record?.customer?.code)
      },
      sorter: true
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      render: (text, record) => {
        return fillEmptyData(record?.customer?.getName())
      },
      sorter: true,
    },
    {
      title: "CRO Name",
      dataIndex: "createdByFirstName",
      sorter: true,
      render: (text: string, record: Loan) => {
        return {
          children: record.createdBy?.firstName
            ? record?.createdBy?.firstName +
              " " +
              (record?.createdBy?.lastName ?? "")
            : "",
        };
      },
    },
    {
      title: "Branch",
      dataIndex: "branch",
      render: (text, record) => {
        return fillEmptyData(record?.branch?.name)
      },
    },
    {
      title: "Loan Amount",
      dataIndex: "requestedLoanAmt",
      sorter: true
    },
    {
      title: "Approved Date",
      dataIndex: "approvedDate",
      render: (approvedDate: string) =>
        dateTimeFormatter(approvedDate, DateFormat.DATE),
    },
  ];
