import { InstallmentStatus } from "../../enums/installmentStatus.type";

export const getInstallmentStatusLabel = (status?: InstallmentStatus) => {
  switch (status) {
    case InstallmentStatus.DUE_PAID:
      return "Due Paid";

    case InstallmentStatus.DUE_UPCOMING:
      return "Due Upcoming";

    case InstallmentStatus.DUE_TODAY:
      return "Due Today";

    case InstallmentStatus.OVERDUE:
      return "Overdue";

    case InstallmentStatus.WRITTEN_OFF:
      return "Written Off";

    case InstallmentStatus.PRE_CLOSED:
      return "Preclosed";

    default:
      return status;
  }
};
