import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { Meta } from "../../../models/meta.modal";

interface CustomSelectProps<T extends string | number> {
  name: string;
  value?: T;
  options: Meta[];
  onChange: (name: string, value: T) => void;
  placeholder?: string;
  onScroll?: React.UIEventHandler<HTMLDivElement>;
  handleSearch?: (value: string) => void;
  showSearch?: boolean;
  loading?: boolean;
  allowClear?: boolean;
}

export function CustomSelect<T extends string | number>({
  name,
  value,
  options,
  onChange,
  placeholder,
  onScroll,
  handleSearch,
  showSearch,
  loading,
  allowClear,
}: CustomSelectProps<T>) {
  const [valueOptions, setValueOptions] = useState<Meta[]>([]);
  const [selectedValue, setSelectedValue] = useState(value);

  useEffect(() => {
    setValueOptions(options);
  }, [options]);

  const handleChange = (value: T) => {
    setSelectedValue(value);
    onChange(name, value);
  };

  return (
    <Select
      placeholder={placeholder}
      options={valueOptions}
      value={selectedValue}
      onChange={handleChange}
      onPopupScroll={onScroll}
      onSearch={handleSearch || undefined}
      showSearch={showSearch}
      loading={loading}
      allowClear={allowClear}
    />
  );
}
