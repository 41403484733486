//Icons

//Styles
import "./disburesementFilter.scss";

//Libraries
import { Button, Tabs } from "antd";
import FiltersListing, {
  FiltersListingProps,
} from "../../../../shared/components/FiltersListing";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";

import { CentersService } from "../../../../services/Centers/centers.service";
import { CustomersService } from "../../../../services/Customers/customers.service";
import { EmployeeService } from "../../../../services/Employee/employee.service";
import { LoanParams } from "../../../../models/loan.model";
import { Meta } from "../../../../models/meta.modal";
import { MetaService } from "../../../../services/Meta/meta.service";
import { QueryParams } from "../../../../models/query.params.model";
import { getFullName } from "../../../../shared/utils/getFullName";
import { BranchesService } from "../../../../services/Branches/branches.service";
import { hideTab } from "../../../../shared/utils/hideTab";
import { useLocation } from "react-router-dom";
import * as AppRoutes from '../../../../routes/routeConstants/appRoutes'
const loanFilters = [
  "customerId",
  "status",
  "centerId",
  "loanTypeId",
  "createdById",
  "branchId",
  "organisations",
];

interface LoanFiltersProps {
  onFilter: (val: LoanParams) => void;
  onClose: () => void;
}

const LoanFilters = (props: LoanFiltersProps) => {
  // Services
  const { getLoanTypes, getLoanStatus, loading: metaLoading } = MetaService();
  const { getEmployees, loading: employeesLoading } = EmployeeService();
  const { getCenters, loading: centersLoading } = CentersService();
  const { getCustomers, loading: customerLoading } = CustomersService();
  const { getBranches, loading: branchesLoading } = BranchesService();
  const location = useLocation()
  const isDisbursement = location.pathname.includes(AppRoutes.DISBURSEMENT)
  //Prop
  const { onClose, onFilter } = props;

  const fetchLoanTypes = async () => {
    const data = await getLoanTypes();
    return { data };
  };

  const fetchBranches = async (params: QueryParams) => {
    const result = await getBranches(params);
    return {
      data:
        result?.branches?.map((branch) => ({
          label: branch?.name,
          value: branch?.id,
        })) ?? [],
      meta: result?.meta,
    };
  };

  const fetchLoanStatuses = async () => {
    const data = await getLoanStatus();
    return { data };
  };

  const fetchEmployees = async (params: QueryParams) => {
    const result = await getEmployees(params);

    return {
      data: result?.employees.map((emp) => ({
        label: getFullName(emp),
        value: emp?.id,
      })),
      meta: result?.meta,
    };
  };

  const fetchCenters = async (params: QueryParams) => {
    const result = await getCenters(params);
    return {
      data:
        result?.centers?.map((center) => ({
          label: center?.name,
          value: center?.id,
        })) ?? [],
      meta: result?.meta,
    };
  };

  const fetchCustomers = async (params: QueryParams) => {
    const result = await getCustomers(params);
    return {
      data: result?.customers?.map((customer) => ({
        code: customer?.code,
        label: getFullName(customer),
        value: customer?.id,
      })),
      meta: result?.meta,
    };
  };

  const filtersTab: { title: string; tabContent: FiltersListingProps }[] = [
    {
      title: "Center",
      tabContent: {
        fetchData: fetchCenters,
        hasPagination: true,
        loading: centersLoading,
        name: "centerId",
        searchProps: {
          placeholder: "Search Center",
          hasIcon: false,
          onSearch: () => {},
        },
      },
    },
    {
      title: "Branch",
      tabContent: {
        fetchData: fetchBranches,
        hasPagination: true,
        loading: branchesLoading,
        name: "branchId",
        searchProps: {
          placeholder: "Search Branch",
          hasIcon: false,
          onSearch: () => {},
        },
      },
    },
    {
      title: "Loan Type",
      tabContent: {
        fetchData: fetchLoanTypes,
        hasPagination: false,
        loading: metaLoading,
        name: "loanTypeId",
      },
    },
    {
      title: "Status",
      tabContent: {
        fetchData: fetchLoanStatuses,
        hasPagination: false,
        loading: metaLoading,
        name: "status",
      },
    },
    {
      title: "Employee",
      tabContent: {
        fetchData: fetchEmployees,
        hasPagination: true,
        loading: employeesLoading,
        name: "createdById",
        searchProps: {
          placeholder: "Search Employee",
          hasIcon: false,
          onSearch: () => {},
        },
      },
    },
    {
      title: "Customer",
      tabContent: {
        fetchData: fetchCustomers,
        hasPagination: true,
        loading: customerLoading,
        name: "customerId",
        searchProps: {
          placeholder: "Search Customer",
          hasIcon: false,
          onSearch: () => {},
        },
      },
    },
  ];

  const handleSubmit = (val: LoanParams) => {
    const filters = Object.fromEntries(
      Object.entries(val).filter(([key]) => loanFilters.includes(key))
    );

    onFilter({ ...filters, page: 1 });
    onClose();
  };

  return (
    <div className="loan-filters">
      <Formik initialValues={new LoanParams()} onSubmit={handleSubmit}>
        {({ setFieldValue }) => (
          <Form>
            <Tabs tabPosition="left">
              {filtersTab.filter(({ tabContent: { name }}) => {
                return (
                  !hideTab(isDisbursement, name, ["status", "customerId"])
                )
              }).map((item, index) => (
                <Tabs.TabPane tab={item?.title} key={index}>
                  <FiltersListing {...item.tabContent} />
                </Tabs.TabPane>
              ))}
            </Tabs>
            <div className="drawer-footer loan-filters__footer">
              <Button
                className="drawer-footer__cancel"
                htmlType="reset"
                onClick={onClose}
                type="link"
              >
                Cancel
              </Button>
              <Button className="drawer-footer__submit" htmlType="submit">
                Assign
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LoanFilters;
