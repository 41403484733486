import { Center, CenterParams } from "../../models/center.model";
import { deserialize, serialize } from "serializr";

import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { PaginationMeta } from "../../models/pagination.model";
import axiosInstance from "../../interceptor/axiosInstance";
import { useState } from "react";
import { Comments } from "../../models/comments.model";
import { generatePath } from "react-router-dom";

export const CommentsService = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  const addComment = async (loanId: string, params: Comments) => {
    setLoading(true);
    const queryParams = params ? serialize(Comments, params) : {};

    const endpoint = generatePath(ApiRoutes.ADD_COMMENT, {
      loanId: String(loanId),
    });
    try {
      const { data } = await axiosInstance.post(endpoint, {
        comment: queryParams,
      });
      const deserializedComment = deserialize(
        Comments,
        data?.["comment"] as Comments[]
      );
      Notification({
        message: "Comment Added",

        type: NotificationTypes.SUCCESS,
      });
      return deserializedComment;
    } catch (ex) {
      Notification({
        message: "Unable to Add Comment",

        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  return { addComment, loading, error };
};
