import { RoleName } from "../../enums/roleName.enum";
import { User } from "../../enums/user.type";

export function switchUserRole(inputString: User) {
  switch (inputString.toLowerCase()) {
    case User.SUPER_ADMIN:
      return RoleName.SUPER_ADMIN;
    case User.HO:
      return RoleName.HEAD_OFFICER;
    case User.ADMIN:
      return RoleName.ADMIN;
    default:
      return;
  }
}
