import { Drawer, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { DepositsParams } from "../../models/deposits.model";
import { LoanParams } from "../../models/loan.model";
import { RepaymentsParams } from "../../models/repayments.model";
import TabExtraContent from "../../shared/components/TabExtraContent";
import useDrawer from "../../shared/hooks/useDrawer";
import useQueryParams from "../../shared/hooks/useQueryParams";
import { TabsProps } from "../../shared/types/Tabs.type";
import LoanFilters from "../Loans/LoanFilters";
import CashOnHand from "./CashOnHand";
import Deposits from "./Deposits";
import RepaymentDetails from "./RepaymentDetails";
import WaiveRequest from "./WaiveRequest";
import RepaymentsDashboard from "./RepaymentsDashboard";
import { useLocation } from "react-router-dom";
import Payments from "./Payments";
import { LocalStorageHelper } from "../../shared/utils/localStorageHelper";
import { LocalStorage } from "../../enums/localStorage.enum";
import { Employee } from "../../models/employee.model";
import { RoleName } from "../../enums/roleName.enum";
import './repayments.scss'

const Repayments = () => {
  const employee = LocalStorageHelper.getItem(
    LocalStorage.EMPLOYEE
  ) as Employee;
  const isBranchAccountant = (employee?.roleName === RoleName.BRANCH_ACCOUNTANT || employee?.roleName === RoleName.ACCOUNTANT || employee?.roleName === RoleName.BRANCH_MIS )
  const isBranchMIS = employee?.roleName === RoleName.BRANCH_MIS;
  const location = useLocation();
  const isHeadOfficer = employee?.roleName === RoleName.HEAD_OFFICER;
  const [activeTab, setActiveTab] = useState((isBranchAccountant || isHeadOfficer) ? "2" : "1");

  const {
    params: depositParams,
    handleSearch: handleDepositSearch,
    updateParams,
  } = useQueryParams<DepositsParams>({
    params: new DepositsParams(),
  });
  const { params: repaymentsParams, handleSearch: handleRepaymentsSearch } =
    useQueryParams<RepaymentsParams>({
      params: new RepaymentsParams(),
    });
  const { params: cashOnHandParams, handleSearch: handleCashOnHandSearch } =
    useQueryParams<RepaymentsParams>({
      params: new RepaymentsParams(),
    });

  const {
    visible: repaymentFilterVisible,
    toggleVisibility: toggleRepaymentFilterVisibility,
  } = useDrawer({});
  const {
    visible: repaymentSettingsVisible,
    toggleVisibility: toggleRepaymentSettingsVisibility,
  } = useDrawer({});

  const {
    visible: depositFilterVisible,
    toggleVisibility: toggleDepositFilterVisibility,
  } = useDrawer({});
  const {
    visible: depositSettingVisible,
    toggleVisibility: toggleDepositSettingVisibility,
  } = useDrawer({});

  const {
    visible: cashOnHandFilterVisible,
    toggleVisibility: toggleCashOnHandFilterVisibility,
  } = useDrawer({});
  const {
    visible: cashOnHandSettingVisible,
    toggleVisibility: toggleCashOnHandSettingVisibility,
  } = useDrawer({});

  useEffect(() => {
    setActiveTab(
      String(
        tabs?.find((tab) => location?.hash === tab?.hash)?.key ?? activeTab
      )
    );
  }, []);

  const tabs: TabsProps[] = [
    {
      label: "Dashboard",
      key: "1",
      component: <RepaymentsDashboard />,
      hash: "#dashboard",
      condition: !isBranchAccountant && !isHeadOfficer
    },
    {
      label: "Repayments",
      key: "2",
      component: (
        <RepaymentDetails
          params={repaymentsParams}
          filterVisible={repaymentFilterVisible}
          onFilter={toggleRepaymentFilterVisibility}
          settingsVisible={repaymentSettingsVisible}
          onSettings={toggleRepaymentSettingsVisibility}
        />
      ),
      hash: "#repayments"
    },

    {
      label: "Deposits",
      key: "3",
      component: (
        <Deposits
          params={depositParams}
          filterVisible={depositFilterVisible}
          onFilter={toggleDepositFilterVisibility}
          settingsVisible={depositSettingVisible}
          onSettings={toggleDepositSettingVisibility}
        />
      ),
      hash: "#deposits",
      condition: !isHeadOfficer
    },
    isHeadOfficer
      ? null
      : {
          label: "Cash On Hand",
          key: "4",
          component: (
            <CashOnHand
              params={cashOnHandParams}
              filterVisible={cashOnHandFilterVisible}
              onFilter={toggleCashOnHandFilterVisibility}
              settingsVisible={cashOnHandSettingVisible}
              onSettings={toggleCashOnHandSettingVisibility}
            />
          ),
          hash: "#cash-on-hand",
        },
    {
      label: "Waive Request",
      key: "5",
      component: <WaiveRequest />,
      hash: "#waive-request",
    },
    {
      label: "Payments",
      key: "6",
      component: <Payments />,
      hash: "#payments",
    },
  ].filter(Boolean) as TabsProps[];
  const tabExtraContent = (activeTabKey = activeTab) => {
    switch (activeTabKey) {
      case "2":
        return (
          <TabExtraContent
            searchProps={{
              onSearch: handleRepaymentsSearch,
              placeholder: "Search Loan",
            }}
            filterClick={toggleRepaymentFilterVisibility}
            settingsClick={toggleRepaymentSettingsVisibility}
          />
        );
      case "3":
        return (
          <TabExtraContent
            settingsClick={toggleDepositSettingVisibility}
          />
        );
      case "4":
        return (
          <TabExtraContent
            filterClick={toggleCashOnHandFilterVisibility}
            settingsClick={toggleCashOnHandSettingVisibility}
          />
        );

      default:
        return <></>;
    }
  };

  const handleTabChange = (activeKey: string) => {
    const hash = tabs?.find((tab, index) => tab?.key === activeKey)?.hash;
    updateParams({ page: 1 }, hash);
    setActiveTab(activeKey);
  };

  return (
    <div className="repayments">
      <Tabs
        activeKey={activeTab}
        defaultActiveKey="1"
        onChange={handleTabChange}
        className="repayments-tablist"
        tabBarExtraContent={tabExtraContent(activeTab)}
      >
        {tabs
          .filter((tab) => !isBranchMIS || tab.key !== "1")
          .filter(({ condition }) => condition !== false)
          .map(({ component, ...tab }) => (
            <Tabs.TabPane tab={tab.label} key={tab.key}>
              {component}
            </Tabs.TabPane>
          ))}
      </Tabs>
    </div>
  );
};

export default Repayments;
