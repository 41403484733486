import axios from "axios";
import * as AppRoutes from "../routes/routeConstants/appRoutes";
import { ApiRoutes } from "../routes/routeConstants/apiRoutes";
import AuthService from "../services/AuthService/auth.service";
import { TokenService } from "../services/TokenService/token.service";
import { LocalStorage } from "../enums/localStorage.enum";

export const getHeaders = (): any => {
  const { getLocalAccessToken } = TokenService();

  let headers;
  const user = getLocalAccessToken();

  headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${user?.accessToken}`,
  };
  return headers;
};

const axiosInstance = axios.create({
  baseURL: ApiRoutes.BASE_URL,
  timeout: 20000,
});

axiosInstance.interceptors.request.use(function (config) {
  config.headers = getHeaders();
  return config;
});

axiosInstance.interceptors.response.use(
  (response): any => {
    return {
      data: response.data,
      message: response.statusText,
      status: response.status,
    };
  },
  async (error) => {
    const { response, config } = error;

    if (
      response?.status === 401 &&
      !config._retry &&
      !!localStorage.getItem(LocalStorage.USER)
    ) {
      config._retry = true;
      await TokenService().updateLocalAccessToken();
      return axiosInstance(config);
    }

    if (response?.status === 401) {
      TokenService().removeLocalAccessToken();
      window.location.href = AppRoutes.LOGIN;
    }

    const message =
      response?.status === 500 || response?.status === 502
        ? "Server is under maintenance. Please try after some time!"
        : response?.data?.error ?? "Something went wrong. Please try again";

    return Promise.reject(new Error(message));
  }
);

export default axiosInstance;
