//Icons
import ImgThumbnail from "../../../../../Assets/images/thumbnail.png";
import PdfThumbnail from "../../../../../Assets/images/pdf.png";

//Libraries
import React from "react";

//Styles
import "./document.scss";
import { Col, Row } from "antd";
import { getFileExtension } from "../../../../../shared/utils/getFileExtension";
import { DocumentExtensions } from "../../../../../enums/DocumentExtensions.type";

interface DocumentProps {
  title: string;
  frontImg: {
    url?: string;
    name?: string;
  };
  backImg?: {
    url?: string;
    name?: string;
  };
  onClick: (url: string) => void;
}

const getShortFileName = (url: string) => url.split("/").pop()?.split("?")[0];

const Document = (props: DocumentProps) => {
  //Service

  //Context

  //Prop
  const { title, frontImg, backImg, onClick } = props;

  //State

  const isPDF = (endpoint: string) =>
    getFileExtension(endpoint) === DocumentExtensions.PDF ? true : false;

  console.log(frontImg?.url, isPDF(frontImg?.url ?? ""));

  return (
    <div className="document">
      <Row gutter={[24, 16]}>
        <Col span={24}>
          <div className="document__header text-capitalize">{title}</div>
        </Col>
        {frontImg?.url && (
          <Col
            className="document__info"
            onClick={() => onClick(frontImg?.url!)}
            span={24}
          >
            {isPDF(frontImg.url) ? (
              <img
                width={45}
                height={60}
                className="document__img"
                src={PdfThumbnail}
                alt=""
              />
            ) : (
              <img
                width={45}
                height={45}
                className="document__img"
                src={ImgThumbnail}
                alt=""
              />
            )}
            <span className="text-capitalize">
              {frontImg.name ?? getShortFileName(frontImg?.url)}
            </span>
          </Col>
        )}
        {backImg?.url && (
          <Col
            className="document__info"
            onClick={() => onClick(backImg?.url!)}
            span={24}
          >
            {isPDF(backImg.url) ? (
              <img
                width={45}
                height={60}
                className="document__img"
                src={PdfThumbnail}
                alt=""
              />
            ) : (
              <img
                width={45}
                height={45}
                className="document__img"
                src={ImgThumbnail}
                alt=""
              />
            )}
            <span className="text-capitalize">
              {backImg.name ?? getShortFileName(backImg?.url)}
            </span>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Document;
