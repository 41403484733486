import { alias, list, object, primitive, serializable } from "serializr";

class LoanAppicationApproval {
  @serializable(alias("month", primitive()))
  month?: string;
  @serializable(alias("sum", primitive()))
  sum?: number;
  @serializable(alias("count", primitive()))
  count?: number;
}

export class HeadOfficerDashboardParams {
  @serializable(alias("from_date", primitive()))
  fromDate?: string;
  @serializable(alias("to_date", primitive()))
  toDate?: string;
  @serializable(alias("branch", primitive()))
  branch?: string;
  @serializable(alias("type", primitive()))
  type?: string;
  @serializable(alias("user", primitive()))
  user?: string;
  @serializable(alias("loan_type", primitive()))
  loanType?: string;
}

export class HeadOfficerDashboard {
  @serializable(alias("ct_verification_count", primitive()))
  ctVerificationCount?: number;
  @serializable(alias("ct_verification_amount", primitive()))
  ctVerificationAmount?: number;
  @serializable(alias("approved_count", primitive()))
  approvedCount?: number;
  @serializable(alias("approved_amount", primitive()))
  approvedAmount?: number;
  @serializable(alias("disbursed_count", primitive()))
  disbursedCount?: number;
  @serializable(alias("disbursed_amount", primitive()))
  disbursedAmount?: number;
  @serializable(alias("rejected_count", primitive()))
  rejectedCount?: number;
  @serializable(alias("rejected_amount", primitive()))
  rejectedAmount?: number;
  @serializable(alias("pending_esign_count", primitive()))
  pendingEsignCount?: number;
  @serializable(alias("pending_esign_amount", primitive()))
  pendingEsignAmount?: number;
  @serializable(alias("average_tat", primitive()))
  averageTat?: number;
  @serializable(
    alias("loan_applications", list(object(LoanAppicationApproval)))
  )
  loanApplications?: LoanAppicationApproval[];
  @serializable(alias("loan_approvals", list(object(LoanAppicationApproval))))
  loanApprovals?: LoanAppicationApproval[];
}
