import * as Yup from "yup";

export const branchCollectionDueValidation = Yup.object().shape({
  maxDueCollectionPerDay: Yup.string()
    .required()
    .label("Max Collection is required"),
  dueCollectionDates: Yup.string()
    .required()
    .label("Due Collection date is required"),
});
