import "./centerFilters.scss";

import { Button, Tabs } from "antd";
import FiltersListing, {
  FiltersListingProps,
} from "../../../../shared/components/FiltersListing";
import { Form, Formik } from "formik";

import { BranchesService } from "../../../../services/Branches/branches.service";
import { CenterParams } from "../../../../models/center.model";
import { MetaService } from "../../../../services/Meta/meta.service";
import { QueryParams } from "../../../../models/query.params.model";
import React from "react";

const centerFilters = ["branchId", "isActive"];
interface CenterFiltersProps {
  onFilter: (val: CenterParams) => void;
  onClose: () => void;
}

const CenterFilters = (props: CenterFiltersProps) => {
  const { getStatuses, loading: metaLoading } = MetaService();
  const { getBranches, loading: branchesLoading } = BranchesService();

  const { onClose, onFilter } = props;

  const fetchStatuses = async () => {
    const data = await getStatuses();
    return { data };
  };

  const fetchBranches = async (params: QueryParams) => {
    const result = await getBranches(params);

    return {
      data: result?.branches.map((branch) => ({
        label: branch?.name,
        value: branch?.id,
      })),
      meta: result?.meta,
    };
  };

  const filtersTab: { title: string; tabContent: FiltersListingProps }[] = [
    {
      title: "Status",
      tabContent: {
        fetchData: fetchStatuses,
        hasPagination: false,
        loading: metaLoading,
        name: "isActive",
      },
    },
    {
      title: "Branch",
      tabContent: {
        fetchData: fetchBranches,
        hasPagination: true,
        loading: branchesLoading,
        name: "branchId",
        searchProps: {
          placeholder: "Search Branch",
          hasIcon: false,
          onSearch: () => {},
        },
      },
    },
  ];

  const handleSubmit = (val: CenterParams) => {
    const filters = Object.fromEntries(
      Object.entries(val).filter(([key]) => centerFilters.includes(key))
    );
    onFilter({ ...filters, page: 1 });
    onClose();
  };

  return (
    <div className="center-filters">
      <Formik initialValues={new CenterParams()} onSubmit={handleSubmit}>
        <Form>
          <Tabs tabPosition="left">
            {filtersTab.map((item, index) => (
              <Tabs.TabPane tab={item?.title} key={index}>
                <FiltersListing {...item.tabContent} />
              </Tabs.TabPane>
            ))}
          </Tabs>
          <div className="drawer-footer center-filters__footer">
            <Button
              className="drawer-footer__cancel"
              htmlType="reset"
              onClick={onClose}
              type="link"
            >
              Cancel
            </Button>
            <Button className="drawer-footer__submit" htmlType="submit">
              Assign
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default CenterFilters;
