import { alias, list, object, primitive, serializable } from "serializr";
import { Branch } from "./branch.model";

export class TransferLoansParams {
  @serializable(alias("from_org", primitive()))
  fromOrg?: number;
  @serializable(alias("to_org", primitive()))
  toOrg?: number;
  @serializable(alias("branch_ids", list(primitive())))
  branchIds?: number[];
  @serializable(alias("loan_type_ids", list(primitive())))
  loanTypeIds?: number[];
  @serializable(alias("loan_ids", list(primitive())))
  loanIds?: number[];

  @serializable(alias("maxiumum_loan_amount", primitive()))
  maximumLoanAmount?: string;
}
export class TransferLoans {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("code", primitive()))
  code?: string;
  @serializable(alias("center", primitive()))
  center?: number;
  @serializable(alias("loan_type", primitive()))
  loanType?: string;
  @serializable(alias("status", primitive()))
  status?: string;
  @serializable(alias("total_amount", primitive()))
  totalAmount?: number;
  @serializable(alias("channel", primitive()))
  channel?: string;
}
