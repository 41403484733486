import React, { useState } from "react";
import { Button, Col, Row } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import "../auth.scss";
import AuthService from "../../../services/AuthService/auth.service";
import { User } from "../../../models/user.model";
import mainLogo from "../../../Assets/images/main-logo.png";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import OtpInput from "react18-input-otp";
import InputField from "../../../shared/components/InputField";
import { InputType } from "../../../enums/input.type";
import { LocalStorageHelper } from "../../../shared/utils/localStorageHelper";
import { LocalStorage } from "../../../enums/localStorage.enum";
import { passwordValidation } from "./createPasswordValidation";

function CreatePassword() {
  const { loading, login, resetPassword } = AuthService();
  const employee = LocalStorageHelper.getItem(LocalStorage.EMPLOYEE);
  const navigate = useNavigate();

  const onSubmit = async (values: User) => {
    const emp = {
      email: employee?.email,
      newPassword: values.newPassword,
      resetPasswordToken: employee?.token,
    };
    const result = await resetPassword(emp);
    if (result) {
      localStorage.clear();
      navigate(AppRoutes.LOGIN);
    }
  };

  return (
    <Row className="create-password">
      <Col span={12} className="login__logo-container">
        <img src={mainLogo} alt="logo" />
      </Col>
      <Col span={12} className="content-container">
        <div className="create-password__form">
          <Formik
            validationSchema={passwordValidation}
            initialValues={new User()}
            onSubmit={onSubmit}
          >
            {(props) => (
              <Form className="ant-form-vertical" onSubmit={props.handleSubmit}>
                <Row gutter={[0, 30]} justify="space-evenly">
                  <Col span={18}>
                    <h1 className="create-password__form__heading">
                      Enter New Password
                    </h1>
                  </Col>
                  <Col span={18}>
                    <InputField
                      label="New Password"
                      type={InputType.PASSWORD}
                      name="newPassword"
                      placeholder="Enter New Password"
                    />
                  </Col>
                  <Col span={18} className="mb-5">
                    <InputField
                      label="Confirm Password"
                      type={InputType.PASSWORD}
                      name="confirmPassword"
                      placeholder="Re Enter Password"
                    />
                  </Col>
                  <Col span={18}>
                    <Button
                      className="mt-5 w-100"
                      htmlType="submit"
                      loading={loading}
                    >
                      RESET PASSWORD
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      </Col>
    </Row>
  );
}

export default CreatePassword;
