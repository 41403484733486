import React, { useState } from "react";
import "./totalAccounts.scss";
import { Button, Col, Collapse, Row } from "antd";
import image from "../../../Assets/images/Credit Age.svg";
import "./panelHeader.scss";
import { ColumnProps } from "antd/lib/table";
import { shortMonths } from "../../../shared/Constant/monthsOptions";
import Table from "../../../shared/components/Table";
import TableHeader from "./TableHeader";
import { renderPaymentStatus } from "../../../shared/utils/renderPaymentStatus";
import { CrifReport } from "../../../models/creditReport.model";
import { OptionArray } from "../../../shared/types/optionArray.type";
import { currencyFormatter } from "../../../shared/utils/formatter";
import { fillEmptyData } from "../../../shared/utils/fillEmptyData";
import { AccountsDetails } from "../../../models/accountsDetails.model";
import { LoanPaymentHistory } from "../../../models/loanPaymentHistory.model";
import { PaymentStatuses } from "../../../enums/paymentStatus.type";
import PaymentHistoryTable from "./PaymentTable";
import { convertStringForClassName, getCrifRating } from "../../../shared/utils/creditReportUtil";

interface TotalAccountsProps {
  data?: CrifReport;
}

const TotalAccounts = (props: TotalAccountsProps) => {
  const { data } = props;

  const { Panel } = Collapse;
  const [activeCollapseKeys, setActiveCollapseKeys] = useState<string[]>([]);

  const handleExpandAll = () => {
    setActiveCollapseKeys((prev) => {
      if (prev.length === 0) {
        return (
          data?.creditAccounts?.accountsDetails?.map((_, index) =>
            (index + 1).toString()
          ) || []
        );
      } else {
        return [];
      }
    });
  };

  const handlePanelChange = (keys: string | string[]) => {
    setActiveCollapseKeys(Array.isArray(keys) ? keys : [keys]);
  };

  const renderPanelContent = (account: AccountsDetails) => {
    const panelDetails: OptionArray[] = [
      {
        label: account.accountType,
        value: fillEmptyData(account.lender),
        key: "1",
      },
      {
        label: "Account Number",
        value: fillEmptyData(account.accountNumber),
        key: "2",
      },
      {
        label: "Account Holder Type",
        value: fillEmptyData(account.accountHolderType),
        key: "3",
      },
      {
        label: "Loan Amount",
        value: currencyFormatter({
          amount: account.disbursedAmount,
          hasCurrencySign: true,
        }),
        key: "4",
      },
      { label: "Loan Disbursed", value: fillEmptyData(account.disbursedDate) },
      {
        label: "Write-Off Amount",
        value: currencyFormatter({
          amount: account.writeOffAmount,
          hasCurrencySign: true,
        }),
        key: "5",
      },
      {
        label: "Status",
        value: fillEmptyData(account.accountStatus),
        key: "6",
        className: convertStringForClassName(account.accountStatus)
      },
    ];

    const panelOptions: OptionArray[] = [
      {
        label: "Amount Remaining",
        value: currencyFormatter({
          amount: account.currentBalance,
          hasCurrencySign: true,
        }),
        key: "1",
      },
      {
        label: "Amount Paid",
        value: currencyFormatter({
          amount: account.amountpaid,
          hasCurrencySign: true,
        }),
        key: "2",
      },
      {
        label: "Instalment Amount",
        value: currencyFormatter({
          amount: account.instalmentAmount,
          hasCurrencySign: true,
        }),
        key: "3",
      },
      {
        label: "Days Past Due",
        value: fillEmptyData(account.daysPastDue),
        key: "4",
      },
      {
        label: "Last Updated",
        value: fillEmptyData(account.reportedOn),
        key: "5",
      },
      {
        label: "Instalment Frequency",
        value: fillEmptyData(account?.instalementFrequency),
        key: "6",
      },
    ];

    return (
      <div>
        <Row gutter={[16, 16]} className="panel-component-first">
          <Col span={2}>
            <img src={image} alt="Total Accounts Logo" />
          </Col>
          {panelDetails.map((detail) => (
            <Col
              key={detail?.key}
              span={detail.label !== "Account Holder Type" ? 3 : 4}
              className="block__value"
            >
              <span className={`status--${convertStringForClassName(detail.className)}`}>{detail.value}</span>
              <div className="block__label text-capitalize">{detail.label}</div>
            </Col>
          ))}
        </Row>
        <Row gutter={[16, 16]} className="panel-header-second">
          <Col span={2}>
            <img src={image} alt="" className="hidden" />
          </Col>
          {panelOptions.map((detail) => (
            <Col
              key={detail?.key}
              span={detail.label !== "Instalment Amount" ? 3 : 4}
              className="block__value"
            >
              <span>{detail.value}</span>
              <div className="block__label text-capitalize">{detail.label}</div>
            </Col>
          ))}
        </Row>
      </div>
    );
  };

  const accountTables = data?.creditAccounts?.accountsDetails?.map(
    (account, index) => {
      return (
        <Panel key={index + 1} header={renderPanelContent(account)}>
          <PaymentHistoryTable account={account} />
        </Panel>
      );
    }
  );

  return (
    <div className="total-accounts-component">
      <div className="card">
        <Row className="header">
          <Col className="block__heading" span={8}>
            Total Accounts
          </Col>
          <Col span={3} className="block__heading">
            {fillEmptyData(data?.crifScore)}
            <span className={`crif-badge active-status--approved`}>
              {getCrifRating(data?.crifScore)}
            </span>
          </Col>
          <Col span={3} className="block__heading">
            {fillEmptyData(data?.creditAccounts?.totalActiveAccounts)}
          </Col>
          <Col span={3} className="block__heading">
            {fillEmptyData(data?.creditAccounts?.totalClosedAccounts)}
          </Col>
          <Col span={3} className="block__heading">
            {fillEmptyData(data?.creditAccounts?.totalCreditAccounts)}
          </Col>
          <Col span={2}>
            <Button className="expand-all-button" onClick={handleExpandAll}>
              + EXPAND ALL
            </Button>
          </Col>
        </Row>
        <Row className="header-details">
          <Col offset={8}>Overall Score</Col>
          <Col offset={1}>Active Accounts</Col>
          <Col offset={1}>Closed Accounts</Col>
          <Col offset={1}>Total Accounts</Col>
        </Row>
        <Collapse
          className="mt-5 mb-5"
          activeKey={activeCollapseKeys}
          expandIconPosition="end"
          onChange={handlePanelChange}
        >
          {accountTables}
        </Collapse>
      </div>
    </div>
  );
};

export default TotalAccounts;
