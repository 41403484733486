import { Col, Row } from "antd";
import React, { ReactElement } from "react";
import { LoanType } from "../../../../../enums/loanType.type";
import { Customer } from "../../../../../models/customer.model";

import { EligibilityDetail } from "../../../../../models/eligibilityDetail.model";
import { Loan } from "../../../../../models/loan.model";

interface BasicEligibilityProps {
  details: EligibilityDetail;
  loan: Loan;
}

const BasicEligibility = (props: BasicEligibilityProps) => {
  const { details, loan } = props;

  return (
    <Row className="block" gutter={[16, 16]}>
      <Col span={24} className="block__heading">
        basic eligibility check
      </Col>
      <Col span={8} className="block__label">
        Status of Customer Housing
        <div className="block__value">
          {details?.housingOwnershipStatus
            ? details?.getHousingOwnershipStatus()
            : "NA"}
        </div>
      </Col>

      <Col span={8} className="block__label">
        Type of house
        <div className="block__value text-capitalize">
          {details?.typeOfResidence} house
        </div>
      </Col>

      <Col span={8} className="block__label">
        Number of income sources in the family
        <div className="block__value">
          {details?.earningMembersCount} Members
        </div>
      </Col>

      {loan?.loanTypeCode === LoanType.BUSINESS ? (
        <>
          <Col span={8} className="block__label">
            Ownership of the business building
            <div className="block__value text-capitalize">
              {details?.getBusinessOwnershipStatus()}
            </div>
          </Col>
          <Col span={8} className="block__label">
            How old is the business
            <div className="block__value">{details?.getBusinessSpan()}</div>
          </Col>

          <Col span={8} className="block__label">
            Business Model type
            <div className="block__value text-capitalize">
              {details?.getBusinessModel()}
            </div>
          </Col>
        </>
      ) : (
        ""
      )}
    </Row>
  );
};

export default BasicEligibility;
