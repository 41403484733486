import * as Yup from "yup";

export const branchTargetFormValidation = Yup.object().shape({
    name: Yup.string()
        .min(2, "Minimum two characters")
        .max(25, "Maximum 25 characters")
        .required()
        .label("Branch"),
    year: Yup.number()
        .min(new Date().getFullYear(), "Must be a valid year")
        .required()
        .label("Year"),
    month: Yup.string()
        .required()
        .label("Month"),
    target: Yup.number()
        .typeError("Must be a number")
        .required()
        .label("Target"),
});