import React from "react";
import { ColumnsType } from "antd/lib/table";
import { Loan } from "../../../../models/loan.model";
import { getFullName } from "../../../../shared/utils/getFullName";
import { removeUnderscore } from "../../../../shared/utils/removeUnderscore";
import { fillEmptyData } from "../../../../shared/utils/fillEmptyData";
import moment from "moment";
import { DateFormat } from "../../../../enums/dateFormat.type";
import { dateTimeFormatter } from "../../../../shared/utils/formatter";

export const loanColumns: ColumnsType<Loan> = [
  {
    dataIndex: "code",
    // sorter: true,
    title: "Loan ID",
    render: (code: string) => code?.toUpperCase(),
  },
  {
    dataIndex: "loanTypeName",
    // sorter: true,
    title: "Loan Type",
  },
  {
    title: "Status",
    dataIndex: "status",

    render: (_: string, record: Loan) => {
      const className =
        "active-status--" + record.status?.split("_")?.join("-");
      return (
        <span
          className={`text-success text-capitalize active-status ${className}`}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {removeUnderscore(record.getLoanStatusLabel() ?? "")}
        </span>
      );
    },
  },
  // {
  //   title: "Remark",
  //   dataIndex: "remark",
  //   render: (remark: string) => fillEmptyData(remark),
  // },
  {
    title: "Disbursed Date",
    dataIndex: "assignedAt",
    render: (_: string, record: Loan) =>
      dateTimeFormatter(
        record?.recentLoanStatus?.assignedAt ?? "",
        DateFormat.DATE
      ),
  },
  {
    title: "Closed Date",
    dataIndex: "closedDate",
    render: (closedDate: string, record: Loan) =>
      dateTimeFormatter(closedDate, DateFormat.DATE),
  },
  {
    title: "Loan Amount",
    dataIndex: "loanAmount",
    render: (_: string, record: Loan) => record?.requestedLoanAmt,
  },
  {
    title: "Outstanding Amount",
    dataIndex: "outstandingAmount",
  },
];
