export enum DocumentType {
  DRIVING_LICENSE = "driving_license",
  RATION_CARD = "ration_card",
  WATER_BILL = "water_bill",
  INCOME_TAX_RECEIPT = "income_tax_receipt",
  INCOME_CERTIFICATE = "income_certificate",
  VOTER_ID = "voter_id",
  OTHER = "other",
  BANK_STATEMENT = "bank_statement",
  MSME_UDHAYAM_CERTIFICATE = "msme_udhayam_certificate",
  BUSINESS_VINTAGE_PROOF = "business_vintage_proof",
  OTHER_BUSINESS_PROOFS = "other_business_proofs",
  PROPERTY_DOCUMENT = "property_document",
  EC_DOCUMENT = "ec",
  RELEASE_LETTER = "release_letter",
  OTHER_SECURITY = "other_security",
  MOD_DOCUMENT = "mod",
  OTHERS = "others",
  LOAN_AGREEMENT = "loan_agreement",
  PDC = "pdc",
  EB_BILL = "eb_bill",
}
