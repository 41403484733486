import "./centerForm.scss";

import { Form, Formik, FormikHelpers } from "formik";

import { Button } from "antd";
import { Center } from "../../../../models/center.model";
import { CentersService } from "../../../../services/Centers/centers.service";
import InputField from "../../../../shared/components/InputField";
import { InputType } from "../../../../enums/input.type";
import React from "react";
import SelectInput from "../../../../shared/components/SelectInput";
import { centerFormValidation } from "./centerFormValidation";
import useBranch from "../../../../shared/hooks/useBranch";

interface CenterFormProps {
  onAdd?: (center: Center) => void;
  onUpdate?: (center: Center) => void;
  closeDrawer: () => void;
  centerData?: Center;
}
function CenterForm({
  onAdd,
  closeDrawer,
  centerData,
  onUpdate,
}: CenterFormProps) {
  const { addCenter, updateCenter, loading } = CentersService();
  const {
    loading: branchesLoading,
    branches,
    handleBranchScroll,
    handleBranchSearch,
  } = useBranch({ allBranch: true });

  const handleSubmit = async (values: any, helpers: FormikHelpers<any>) => {
    const center = centerData?.id
      ? await updateCenter(values)
      : await addCenter(values);
    if (center) {
      centerData?.id ? onUpdate && onUpdate(center) : onAdd && onAdd(center);
      closeDrawer();
    }
  };

  return (
    <div className="center-form">
      <Formik
        initialValues={centerData ?? new Center()}
        validationSchema={centerFormValidation}
        onSubmit={handleSubmit}
      >
        {({ values, dirty, isValid }) => (
          <Form className="ant-form-vertical center-form">
            <InputField
              type={InputType.TEXT}
              name="name"
              placeholder="Center Name"
              label="Center Name"
            />
            <InputField
              type={InputType.TEXT}
              name="code"
              placeholder="Center ID"
              label="Center ID"
            />
            <SelectInput
              name="branchId"
              options={branches}
              value={values?.branchId}
              showSearch
              handleSearch={handleBranchSearch}
              placeholder="Select Branch"
              label="Branch"
              onScroll={handleBranchScroll}
              loading={branchesLoading}
            />
            <div className="drawer-footer">
              <Button
                htmlType="reset"
                onClick={closeDrawer}
                type="link"
                className="drawer-footer__cancel"
              >
                Cancel
              </Button>
              <Button
                loading={loading}
                htmlType="submit"
                disabled={!dirty || !isValid}
                className="drawer-footer__submit"
              >
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
export default CenterForm;
