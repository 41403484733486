import { alias, list, object, primitive, serializable } from "serializr";
import { LoanFamilyMember } from "./loanFamilyMember";

export class CustomerFamilyMember {
  @serializable(alias("id", primitive()))
  id?: number;
  
  @serializable(alias("code", primitive()))
  code?: string
  
  @serializable(alias("loan_family_members", list(object(LoanFamilyMember))))
  loanFamilyMembers: LoanFamilyMember[] = [];
 
  

}
