import { Col, Row } from "antd";
import React, { FC } from "react";
import creditAge from "../../../Assets/images/Credit Age.svg";
import monthlyEMI from "../../../Assets/images/Monthly EMI.svg";
import accountSummary from "../../../Assets/images/Group 53380.svg";
import overDue from "../../../Assets/images/OD (Overdue Accounts).png";
import onTimePayments from "../../../Assets/images/On-Time Payments.svg";
import totalWriteOff from "../../../Assets/images/Total Write off.svg";
import totalSettlements from "../../../Assets/images/Total Settlements.png";
import "../CreditReportWrapper/creditReport.scss"
import KeyFactor from "../../../shared/components/KeyFactor";
import { CrifReport } from "../../../models/creditReport.model";
import { currencyFormatter } from "../../../shared/utils/formatter";
import { fillEmptyData } from "../../../shared/utils/fillEmptyData";

type KeyFactorsProps = {
  loanId?: string
  data?: CrifReport
  loading: boolean
};

const KeyFactorsComponent: FC<KeyFactorsProps> = (props) => {

  const { loanId, data, loading } = props;

  return (
    <div>
      <div className="card key-factors-container">
        <Row gutter={[50, 10]} className="first-row">
          <Col span={8}>
            <KeyFactor
              loading={loading}
              data={data}
              loanId={loanId}
              name="Payment Status"
              icon={onTimePayments}
              // To be Confirmed from BE
              mainText={`0%`}
              paymentStatus="On Time"
              // To be Confirmed from BE
              tagText={fillEmptyData(data?.paymentStatus?.paymentStatus)}
              className="payment-status-container"
            />
          </Col>
          <Col span={8}>
            <KeyFactor
              loading={loading}
              data={data}
              loanId={loanId}
              name="Monthly EMI"
              icon={monthlyEMI}
              mainText={currencyFormatter({amount: data?.monthlyEmi?.totalMonthlyEmiAmount, hasCurrencySign: true, shouldReturnNA: false})}
              extraMainText="/month"
              tagText={fillEmptyData(data?.monthlyEmi?.status)}
              className="monthly-emi-container"
            />
          </Col>
          <Col span={8}>
            <KeyFactor
              loading={loading}
              data={data}
              loanId={loanId}
              name="Total Write off"
              icon={totalWriteOff}
              mainText={currencyFormatter({ amount: data?.writtenOff?.totalWriteOffAmount, hasCurrencySign: true, shouldReturnNA: false})}
              tagText={fillEmptyData(data?.writtenOff?.status)}
              className="total-write-off-container"
            />
          </Col>
        </Row>
        <Row className="first-row" gutter={[50, 10]}>
          <Col span={8}>
            <KeyFactor
              loading={loading}
              data={data}
              loanId={loanId}
              name="OD (Overdue Accounts)"
              icon={overDue}
              mainText={data?.overdueAccounts?.totalOdAccounts || 0}
              tagText={data?.overdueAccounts?.status}
              className="od-container"
            />
          </Col>
          <Col span={8}>
            <KeyFactor
              data={data}
              loading={loading}
              loanId={loanId}
              name="Credit Age"
              icon={creditAge}
              creditYear={data?.creditAccounts?.creditYears || 0}
              creditMonth={data?.creditAccounts?.creditMonths || 0}
              tagText={fillEmptyData(data?.creditAccounts?.creditStatus)}
              className="credit-age-container"
            />
          </Col>
          <Col span={8}>
            <KeyFactor
              loading={loading}
              loanId={loanId}
              name="Total Settlements"
              icon={totalSettlements}
              mainText={currencyFormatter({amount: data?.settledAccounts?.totalSettlementsAmount, hasCurrencySign: true, shouldReturnNA: false})}
              tagText={fillEmptyData(data?.settledAccounts?.status)}
              className="total-settlements-container"
            />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <KeyFactor
              loading={loading}
              loanId={loanId}
              name="Account Summary"
              icon={accountSummary}
              mainText={data?.creditAccounts?.totalCreditAccounts || 0}
              smallText={`${data?.creditAccounts?.totalActiveAccounts || 0} active / `}
              className="account-summary-container"
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default KeyFactorsComponent;
