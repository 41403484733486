import { LoanStepName } from "../../enums/loanStepName";
import { LoanTypeConfigurationModel } from "../../models/loanType.model";

export const checkCustomerInfo = (
  loanTypeConfig: LoanTypeConfigurationModel,
  name: string,
  bool: boolean
) => {
  if (
    name === LoanStepName.FAMILY_DETAILS ||
    name === LoanStepName.ASSERT_DETAILS ||
    name === LoanStepName.BANK_DETAILS ||
    name === LoanStepName.DOCUMENT ||
    name === LoanStepName.INCOME_EXPENSES
  ) {
    const data = loanTypeConfig?.loanTypeSteps?.filter(
      (loan) =>
        loan?.loanSteps?.name === LoanStepName.CUSTOMER_INFORMATION &&
        loan?.isRequired
    );
    if (data?.length) return true;
  } else return true;
};
