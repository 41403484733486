export enum ReportDate {
  ALL_TIME = "all_time",
  LAST_YEAR = "last_year",
  THIS_YEAR = "this_year",
  THIS_MONTH = "this_month",
  LAST_MONTH = "last_month",
  LAST_QUATER = "last_quater",
  THIS_QUATER = "this_quater",
  CUSTOM_DATE = "custom_date"
}
