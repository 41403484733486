import { ReportDate } from "../../enums/reportDate.type";

const reportDateOptions = [
  // {
  //   label: "All Time",
  //   value: ReportDate.ALL_TIME,
  // },
  // {
  //   label: "Last Year",
  //   value: ReportDate.LAST_YEAR,
  // },
  // {
  //   label: "This Year",
  //   value: ReportDate.THIS_YEAR,
  // },
  {
    label: "This Month",
    value: ReportDate.THIS_MONTH,
  },
  {
    label: "Last Month",
    value: ReportDate.LAST_MONTH,
  },
  {
    label: "Last Quarter",
    value: ReportDate.LAST_QUATER,
  },
  {
    label: "This Quarter",
    value: ReportDate.THIS_QUATER,
  },
  {
    label: "Custom Date",
    value: ReportDate.CUSTOM_DATE,
  }
];

export default reportDateOptions;
