import { useState } from "react";
import { generatePath } from "react-router-dom";
import { deserialize, serialize } from "serializr";
import { NotificationTypes } from "../../enums/notificationTypes";
import axiosInstance from "../../interceptor/axiosInstance";
import { Employee, EmployeeParams } from "../../models/employee.model";
import { PaginationMeta } from "../../models/pagination.model";
import { User } from "../../models/user.model";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import Notification from "../../shared/components/Notification";

export const EmployeeService = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  const getEmployees = async (params?: EmployeeParams) => {
    setLoading(true);
    try {
      const queryParams = params ? serialize(EmployeeParams, params) : {};
      const { data } = await axiosInstance.get(ApiRoutes.EMPLOYEES, {
        params: queryParams,
      });
      const deserializedEmployee = deserialize(
        Employee,
        data?.["employees"] as any[]
      );
      const deserializedEmployeeMeta = deserialize(PaginationMeta, data?.meta);
      return {
        employees: deserializedEmployee,
        meta: deserializedEmployeeMeta,
      };
    } catch (ex) {
      Notification({
        message: "Unable to Get Users details",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Get Users details",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const getEmployee = async (employeeId: string) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.EMPLOYEE_DETAIL, {
        employeeId,
      });
      const { data } = await axiosInstance.get(endpoint);
      const deserializedEmployee = deserialize(Employee, data?.["employee"]);
      return deserializedEmployee;
    } catch (ex) {
      Notification({
        message: "Unable to Get User details",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Get User details",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const addEmployee = async (employee: Employee) => {
    try {
      setLoading(true);
      const serializedEmployee = serialize(Employee, employee);
      const { data } = await axiosInstance.post(ApiRoutes.EMPLOYEES, {
        employee: serializedEmployee,
      });
      const newEmployee = deserialize(Employee, data.employee);
      Notification({
        message: "User Added Successfully",
        description: "New User Added Successfully",
        type: NotificationTypes.SUCCESS,
      });
      return newEmployee;
    } catch (ex) {
      Notification({
        message: "Unable to Add User",
        description: (ex as Error)?.message ?? "Unable to Add User details",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const updateEmployee = async (employee: Employee) => {
    try {
      setLoading(true);
      const endpoint = generatePath(ApiRoutes.EMPLOYEE_DETAIL, {
        employeeId: employee?.id,
      });
      const serializedEmployee = serialize(Employee, employee);

      const { data } = await axiosInstance.put(endpoint, {
        employee: serializedEmployee,
      });
      const updatedEmployee = deserialize(Employee, data.employee);
      Notification({
        message: "User Updated Successfully",
        description: "New User Updated Successfully",
        type: NotificationTypes.SUCCESS,
      });
      return updatedEmployee;
    } catch (ex) {
      Notification({
        message: "Unable to Update User",
        description: (ex as Error)?.message ?? "Unable to Update User details",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const updateEmployeeStatus = async (param: User) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.EMPLOYEE_STATUS);

      const params = serialize(User, param);
      const { data } = await axiosInstance.post(endpoint, {
        employee: params,
      });
      const deserializedEmployee = deserialize(Employee, data?.["employee"]);
      if (deserializedEmployee) return deserializedEmployee;
      else {
        return Object.assign(new Employee(), { success: data?.["success"] });
      }
    } catch (ex) {
      Notification({
        message: "Unable to Get User details",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Get User details",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const updateAssignUser = async (param: User) => {
    setLoading(true);

    const params = serialize(User, param);

    try {
      const endpoint = generatePath(ApiRoutes.ASSIGN_USER);
      const { data } = await axiosInstance.post(endpoint, {
        assign: params,
      });
      Notification({
        message: "User Updated Successfully",
        type: NotificationTypes.SUCCESS,
      });

      return data;
    } catch (ex) {
      Notification({
        message: "Unable to Get User details",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Get User details",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const updateEmployeeOfflineLoan = async (ids: number[]) => {
    try {
      setLoading(true);

      const { data } = await axiosInstance.put(
        ApiRoutes.EMPLOYEE_OFFLINE_LOAN,
        {
          employee: { ids },
        }
      );

      Notification({
        message: "Offline loan enabled",
        type: NotificationTypes.SUCCESS,
      });
      return true;
    } catch (ex) {
      Notification({
        message: "Unable to enable offline loan",
        description: (ex as Error)?.message,
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    getEmployees,
    getEmployee,
    addEmployee,
    updateEmployee,
    updateAssignUser,
    updateEmployeeStatus,
    updateEmployeeOfflineLoan,
    loading,
    error,
  };
};
