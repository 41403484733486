import React, { useEffect, useState } from "react";
import { Switch, Table } from "antd";
import { UserAccess } from "../../../models/userAccess.model";
import { UserAccessService } from "../../../services/UserAccess/userAccess.service";
import "./userAccess.scss";
import { RoleName } from "../../../enums/roleName.enum";
import { userAccesssOptions } from "./userAccessOptions";
import { SidebarOptions } from "../../../enums/sidebarOptions.enum";

const UserAcess = () => {
  const [userRole, setUserRole] = useState<UserAccess[]>([]);
  const { getUserAccess, updateUserAccess, loading } = UserAccessService();

  useEffect(() => {
    handleUserAccess();
  }, []);
  const handleUserAccess = async () => {
    const userRole = await getUserAccess();
    if (userRole) setUserRole(userRole);
  };

  const columns = [
    {
      title: "Roles",
      dataIndex: "name",
      key: "name",
      render: (text: string) => <h4 className="text-primary">{text}</h4>,
    },
    ...userAccesssOptions?.map(({ key, label }) => ({
      title: label,
      dataIndex: key,
      key: key,
      render: (text: boolean, record: UserAccess) => (
        <Switch
          checked={!!text}
          onChange={(roleValue) => handleAccessChange(record, roleValue, key)}
          disabled={shouldDisableSwitch(record, key, text)}
        />
      ),
    })),
  ];
  const shouldDisableSwitch = (
    record: UserAccess,
    key: string,
    text: boolean
  ) => {
    return (
      record?.name === RoleName.SUPER_ADMIN &&
      (key === SidebarOptions.CONFIGURATION ||
        key === SidebarOptions.WRITE_ACCESS) &&
      text === true
    );
  };

  const handleAccessChange = async (
    userAccess: UserAccess,
    value: boolean,
    type: string
  ) => {
    const user = await updateUserAccess({
      ...userAccess,
      [type]: value,
    });
    if (user) {
      setUserRole((prevUserRole) =>
        prevUserRole.map((data) => (user && data.id === user.id ? user : data))
      );
    }
  };
  return (
    <div className="card">
      <Table
        className="custom-scrollbar"
        dataSource={userRole}
        columns={columns}
        pagination={false}
        scroll={{ x: true }}
        rowKey="id"
      />
    </div>
  );
};

export default UserAcess;
