import { Col, Row, Space, Spin } from "antd";
import React from "react";
import creditAge from "../../../../../Assets/images/Credit Age.svg";
import { ListMappingKeys } from "../../../../../shared/types/optionArray.type";
import { CrifReport } from "../../../../../models/creditReport.model";
import { fillEmptyData } from "../../../../../shared/utils/fillEmptyData";
import '../individualKeyFactor.scss'
import AppLoader from "../../../../../shared/components/AppLoader";

interface CreditAgeProps {
  data?: CrifReport;
  loading: boolean
}

const CreditAge = (props: CreditAgeProps) => {
  const { data, loading } = props;

    const creditAgeData = data?.creditAccounts?.accountsDetails?.map((account) => [
    {
      label: "Account Number",
      value: fillEmptyData(account.accountNumber),
      key: "1",
      offset: 3
    },
    {
      label: "Years",
      value: fillEmptyData(account.loanCreditYears),
      key: "2",
    },
    {
      label: "Last Updated",
      value : fillEmptyData(account.reportedOn),
      key: "3",
      offset: 1,
    },
    {
      label: "Status",
      value: fillEmptyData(account.accountStatus),
      key: "4",
      className: `${account.accountStatus}`
    }
  ]
  )

  const titleCardData: ListMappingKeys[] = [
    {
      label: "Your Active Loans",
      value: data?.creditAccounts?.totalActiveAccounts,
      offset: 6,
      key: "1"
    },
    {
      label: "Your Closed Loans",
      value: data?.creditAccounts?.totalClosedAccounts,
      offset: 2,
      key: "2"
    }
  ]


  return (
    <div>
      <AppLoader loading={loading}>
      <Row className="card crif-card" align="middle">
        <Space direction="horizontal" size={12}>
          <Col>
            <img src={creditAge} alt="" />
          </Col>
          <Col>Credit Age</Col>
        </Space>
        {titleCardData?.map((data) => (
          <Col offset={data?.offset} key={data.key} className="flex-column">
            <span className="font-weight__600 font-size__18">
              {data?.value || 0}
            </span>
            <span>{data?.label}</span>
          </Col>
        ))}
        <Col offset={4} className="flex-column">
          <div>
            <Space>
              <span className="font-weight__600 font-size__18">{data?.creditAccounts?.creditYears || 0}</span>
              <span>Years</span>
              <span className="font-weight__600 font-size__18">{data?.creditAccounts?.creditMonths || 0}</span>
              <span>Months</span>
            </Space>
          </div>
          <span className="text__green text-right">{fillEmptyData(data?.creditAccounts?.creditStatus)}</span>
        </Col>
        
      </Row>
     {creditAgeData?.map((account, index) => (
        <Row className={`card crif-card mt-5 credit-age-card`} align={"middle"} key={index}>
          <Space direction="horizontal">
            <Col>
              <img src={creditAge} alt="" />
            </Col>
            <Col className="flex-column key-factor-col">
              <span className="text__value">{fillEmptyData(data?.creditAccounts?.accountsDetails?.[index]?.lender)}</span>
              <span className="text__label">{fillEmptyData(data?.creditAccounts?.accountsDetails?.[index]?.accountType)}</span>
            </Col>
          </Space>
          {account?.map((data) => (
            <Col offset={data.offset} key={data.key} className={`flex-column key-factor-col ${data?.className} col-${data.key}`} span={4}>
              <span className={`text__value status--${data?.className?.toLowerCase()}`}>{data?.value}</span>
              <span className="text__label">{data?.label}</span>
            </Col>
          ))}
        </Row>
      ))}
      </AppLoader>
    </div>
  );
};

export default CreditAge;
