import React, { createContext, useState, useContext } from "react";
import { Report } from "../../models/report.model";
interface ReportContextProps {
  reportData: Report | null;
  updateReportData: (data: Report | null) => void;
}
const ReportContext = createContext<ReportContextProps>({
  reportData: null,
  updateReportData: () => {},
});

const useReportContext = () => {
  const context = useContext(ReportContext);
  if (!context) {
    throw new Error("useReportContext must be used within a ReportProvider");
  }
  return context;
};

const ReportProvider: React.FC = ({ children }) => {
  const [reportData, setReportData] = useState<Report | null>(null);

  const updateReportData = (data: Report | null) => setReportData(data);

  return (
    <ReportContext.Provider value={{ reportData, updateReportData }}>
      {children}
    </ReportContext.Provider>
  );
};

export { ReportProvider, useReportContext };
