//Icons

//Libraries
import { Button, Tabs } from "antd";
import FiltersListing, {
  FiltersListingProps,
} from "../../../../shared/components/FiltersListing";
import { Form, Formik } from "formik";

import { BranchesService } from "../../../../services/Branches/branches.service";
import { CustomerParams } from "../../../../models/customer.model";
import { EmployeeService } from "../../../../services/Employee/employee.service";
import { QueryParams } from "../../../../models/query.params.model";
import React from "react";
import { getFullName } from "../../../../shared/utils/getFullName";
import { MetaService } from "../../../../services/Meta/meta.service";
import { hideTab } from "../../../../shared/utils/hideTab";
import { LocalStorageHelper } from "../../../../shared/utils/localStorageHelper";
import { LocalStorage } from "../../../../enums/localStorage.enum";
import { Employee } from "../../../../models/employee.model";
import { RoleName } from "../../../../enums/roleName.enum";

const customerFilters = ["centerBranchId", "createdById", "organisations"];

interface CustomerFiltersProps {
  onFilter: (val: CustomerParams) => void;
  onClose: () => void;
}

const CustomerFilters = (props: CustomerFiltersProps) => {
  // Services
  const { getEmployees, loading: employeesLoading } = EmployeeService();
  const { getBranches, loading: branchesLoading } = BranchesService();
  const { loading: metaLoading, getPreferredChannel } = MetaService();
  const employee = LocalStorageHelper.getItem(
    LocalStorage.EMPLOYEE
  ) as Employee;

  //Prop
  const { onClose, onFilter } = props;

  const fetchEmployees = async (params: QueryParams) => {
    const result = await getEmployees(params);

    return {
      data: result?.employees.map((emp) => ({
        label: getFullName(emp),
        value: emp?.id,
      })),
      meta: result?.meta,
    };
  };

  const fetchBranches = async (params: QueryParams) => {
    const result = await getBranches(params);
    return {
      data:
        result?.branches?.map((branch) => ({
          label: branch?.name,
          value: branch?.id,
        })) ?? [],
      meta: result?.meta,
    };
  };
  const fetchPreferredChannel = async () => {
    const result = await getPreferredChannel();

    return {
      data: result?.map((val) => ({
        label: val?.label,
        value: val?.value,
      })),
    };
  };

  const filtersTab: { title: string; tabContent: FiltersListingProps }[] = [
    {
      title: "Branch",
      tabContent: {
        fetchData: fetchBranches,
        hasPagination: true,
        loading: branchesLoading,
        name: "centerBranchId",
        searchProps: {
          placeholder: "Search Branch",
          hasIcon: false,
          onSearch: () => {},
        },
      },
    },
    {
      title: "Employee",
      tabContent: {
        fetchData: fetchEmployees,
        hasPagination: true,
        loading: employeesLoading,
        name: "createdById",
        searchProps: {
          placeholder: "Search Employee",
          hasIcon: false,
          onSearch: () => {},
        },
      },
    },
    {
      title: "Preferred Channel",
      tabContent: {
        fetchData: fetchPreferredChannel,
        hasPagination: false,
        loading: metaLoading,
        name: "organisations",
      },
    },
  ];

  const handleSubmit = (val: CustomerParams) => {
    const filters = Object.fromEntries(
      Object.entries(val).filter(([key]) => customerFilters.includes(key))
    );
    onFilter({ ...filters, page: 1 });
    onClose();
  };

  return (
    <div className="loan-filters">
      <Formik initialValues={new CustomerParams()} onSubmit={handleSubmit}>
        {({ setFieldValue }) => (
          <Form>
            <Tabs tabPosition="left">
              {filtersTab
                .filter(
                  ({ tabContent: { name } }) =>
                    !hideTab(
                      employee?.roleName === RoleName.BRANCH_MANAGER,
                      name,
                      ["centerBranchId"]
                    )
                )
                .map((item, index) => (
                  <Tabs.TabPane tab={item?.title} key={index}>
                    <FiltersListing {...item.tabContent} />
                  </Tabs.TabPane>
                ))}
            </Tabs>

            <div className="drawer-footer loan-filters__footer">
              <Button
                className="drawer-footer__cancel"
                htmlType="reset"
                onClick={onClose}
                type="link"
              >
                Cancel
              </Button>
              <Button className="drawer-footer__submit" htmlType="submit">
                Assign
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CustomerFilters;
