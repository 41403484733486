export const formatNumber = (
  input?: number | string,
  decimal = false
): string =>
  input === null || input === "" || isNaN(Number(input))
    ? "NA"
    : new Intl.NumberFormat("en-IN", {
        maximumFractionDigits: decimal ? 2 : 0,
      })
        .format(Number(input))
        .replace(/(\.\d)$/, "$10")
        .replace(/(\.00)$/, decimal ? "" : ".00");
