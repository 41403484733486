import { alias, list, object, primitive, serializable } from "serializr";

export class LoanSummary {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("status", primitive()))
  status?: string;

  @serializable(alias("remark", primitive()))
  remark?: string;
  @serializable(alias("requested_loan_amt", primitive()))
  requestedLoanAmt?: number;

  @serializable(alias("tenure_in_months", primitive()))
  tenureInMonths?: string;

  @serializable(alias("loan_type", primitive()))
  loanType?: string;
}
