import { Button, Col, Drawer, Popover, Row, Table, TableProps } from "antd";
import React, { useEffect, useState } from "react";
import { blackListCustomerColumn } from "./blacklistCustomerColumn";
import { BlacklistService } from "../../../../services/BlacklistService/blacklist.service";
import { Blacklists, BlacklistsParams } from "../../../../models/blacklists";
import { BlacklistType } from "../../../../enums/blacklist.type";
import BlacklistCustomerForm from "../BlackListCustomerForm";
import useQueryParams from "../../../../shared/hooks/useQueryParams";
import CustomModal from "../../../../shared/components/CustomModal";
import "./blacklistCustomers.scss";
import customerIcon from "../../../../Assets/images/customers.png";
import { UserAccess } from "../../../../models/userAccess.model";
import CustomImage from "../../../../shared/components/CustomImage";

interface BlacklistCustomersProps {
  isBlacklistCustomerForm: boolean;
  toggleBlacklistCustomerForm: (val: boolean) => void;
  userAccess?: UserAccess;
}

const BlackListCustomers = (props: BlacklistCustomersProps) => {
  const { isBlacklistCustomerForm, toggleBlacklistCustomerForm, userAccess } =
    props;
  const [totalPage, setTotalPage] = useState(1);
  const { getBlacklistDetails, unblockCustomerPincode, loading } =
    BlacklistService();
  const [blacklistCustomerList, setBlackListCustomerList] = useState<
    Blacklists[]
  >([]);
  const [selectedCustomer, setSelectedCustomer] = useState<Blacklists>();
  const [modalVisible, setModalVisible] = useState(false);
  const { params, updateParams } = useQueryParams({
    params: new BlacklistsParams(),
  });

  useEffect(() => {
    getBlacklistCustomers(params);
  }, []);

  const getBlacklistCustomers = async (params?: BlacklistsParams) => {
    const result = await getBlacklistDetails({
      ...params,
      type: BlacklistType.CUSTOMER,
    });
    if (result) {
      setBlackListCustomerList(result?.blacklist);
      setTotalPage(result?.meta?.totalCount ?? 1);
    }
  };

  const handleBlacklistCustomerSuccess = (value: Blacklists) => {
    setBlackListCustomerList([...blacklistCustomerList, value]);
    toggleBlacklistCustomerForm(false);
  };

  const handleChange: TableProps<Blacklists>["onChange"] = (
    { current },
    _,
    sorter
  ) => {
    getBlacklistCustomers({ ...params, page: current });
  };
  const handleUnblockCustomer = async () => {
    const result =
      selectedCustomer?.id &&
      (await unblockCustomerPincode(String(selectedCustomer?.id)));
    if (result) {
      handleModalVisibility();
      getBlacklistCustomers();
    }
  };
  const handleModalVisibility = () => setModalVisible(!modalVisible);

  const columns = [
    ...blackListCustomerColumn,
    {
      title: "",
      dataIndex: "",
      width: "50px",
      render: (_: string, record: Blacklists) =>
        userAccess?.writeAccess && (
          <Popover
            content={
              <div
                className="cursor-pointer"
                onClick={() => {
                  setSelectedCustomer(record);
                  handleModalVisibility();
                }}
              >
                Unblock Customers
              </div>
            }
            trigger="click"
          >
            . . .
          </Popover>
        ),
    },
  ];

  const sampleData: Blacklists[] = [
    {
      id: 1,
      status: "blocked",
      date: "2023-03-28",
      blockedBy: "CRO",
      reason: "sample",
      customer: {
        code: "CU_1673457773",
        name: "R Jagan",
        aadhaarNumber: "646739465838",
        panNumber: "AXZPG4395G",
        phoneNumber: "7010584222",
      },
    },
    {
      id: 2,
      status: "blocked",
      date: "2023-03-28",
      blockedBy: "CRO",
      reason: "sample",
      customer: {
        code: "CU_1673457773",
        name: "R Jagan",
        aadhaarNumber: "646739465838",
        panNumber: "AXZPG4395G",
        phoneNumber: "7010584222",
      },
    },
  ];
  return (
    <div className="blacklist-customers">
      {" "}
      <Table
        loading={loading}
        columns={columns}
        dataSource={blacklistCustomerList}
        onChange={handleChange}
        pagination={{
          hideOnSinglePage: true,
          total: totalPage,
        }}
      />
      <Drawer
        placement="right"
        onClose={() => toggleBlacklistCustomerForm(false)}
        open={isBlacklistCustomerForm}
        width="916"
        title="Blacklist Customer"
        destroyOnClose
      >
        <BlacklistCustomerForm
          onSuccess={handleBlacklistCustomerSuccess}
          onClose={() => {
            toggleBlacklistCustomerForm(false);
          }}
        />
      </Drawer>
      <CustomModal
        className="blacklist-customers__modal"
        title="Unblock Customer"
        visible={modalVisible}
        onCancel={handleModalVisibility}
        isCancelButton={false}
        isOkButton={false}
      >
        <Row gutter={[20, 0]}>
          <Col span={4} className="mt-4">
            <CustomImage src={customerIcon} height={50} width={50} />
          </Col>
          <Col span={8}>
            <h2>{selectedCustomer?.customer?.name}</h2>
            <span>{selectedCustomer?.customer?.code}</span>
          </Col>
          <Col span={12}>
            <Button
              loading={loading}
              className="btn-primary blacklist-customers__btn"
              onClick={handleUnblockCustomer}
            >
              UNBLOCK
            </Button>
          </Col>
        </Row>
      </CustomModal>
    </div>
  );
};

export default BlackListCustomers;
