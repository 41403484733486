import * as Yup from "yup";
import { ReportNameType } from "../../enums/reportName.type";
import { ReportDate } from "../../enums/reportDate.type";

export const reportValidation = Yup.object().shape({
  reportName: Yup.string().nullable().required("Report name is required"),
  branch: Yup.string().when('reportName', {
    is: (reportName) => reportName === ReportNameType.OUTSTANDING,
    then: Yup.string().required('At least one branch must be selected for Outstanding report'),
  }),
  organisation: Yup.string().when('reportName', {
    is: (reportName) => reportName === ReportNameType.BOOK_DEBT,
    then: Yup.string().required('Preferred Channel is required for Book Debt Report')
  }),
  asOnDate: Yup.string().when('reportName', { 
    is: (reportName) => reportName === ReportNameType.BOOK_DEBT,
    then: Yup.string().required('Date is required for Book Debt Report')
  }),
  customDate: Yup.string().when('date', {
    is: (date) => date === ReportDate.CUSTOM_DATE,
    then: Yup.string().required('Custom Date is required')
  })
});
