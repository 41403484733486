import { alias, object, primitive, serializable } from "serializr";
import { Branch } from "./branch.model";

export class CustomerCenter {
    @serializable(alias("id", primitive()))
    id?: string;
  
    @serializable(alias("code", primitive()))
    code?: string;
  
    @serializable(alias("name", primitive()))
    name?: string;
  
    @serializable(alias("branch", object(Branch)))
    branch?: Branch;
  }
  