import moment from "moment";
import { alias, object, primitive, serializable } from "serializr";

import { Location } from "../enums/location.type";
import { RelationshipToResidenceOwner } from "../enums/relationshipToResidenceOwner.type";
import { Address } from "./address.model";
import { Gender } from "../enums/gender.type";

export class LoanFamilyMember {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("name", primitive()))
  name?: string;
  @serializable(alias("dob", primitive()))
  dob?: string;

  @serializable(alias("age", primitive()))
  age?: number;
  @serializable(alias("business_income", primitive()))
  businessIncome?: number;

  @serializable(alias("relationship", primitive()))
  relationship?: RelationshipToResidenceOwner;

  @serializable(alias("occupation", primitive()))
  occupation?: number;
  @serializable(alias("occupation_details", primitive()))
  occupationDetail?: string;

  @serializable(alias("is_house_owner", primitive()))
  isHouseOwner?: boolean;

  @serializable(alias("address_proof_number", primitive()))
  addressProofNumber?: string;
  @serializable(alias("address", object(Address)))
  address = new Address();

  @serializable(alias("address_proof_id", primitive()))
  addressProofId?: string;

  @serializable(alias("address_proof_url", primitive()))
  addressProofUrl?: string;

  @serializable(alias("is_co_borrower", primitive()))
  isCoBorrower?: boolean;

  @serializable(alias("is_nominee", primitive()))
  isNominee?: boolean;

  @serializable(alias("location", primitive()))
  location?: Location;

  @serializable(alias("mobile_number", primitive()))
  mobileNumber?: string;

  @serializable(alias("monthly_income", primitive()))
  monthlyIncome?: number;

  @serializable(alias("aadhaar_number", primitive()))
  aadhaarNumber?: string;

  @serializable(alias("pan_number", primitive()))
  panNumber?: string;

  @serializable(alias("pan_document_id", primitive()))
  panDocumentId?: string;

  @serializable(alias("pan_document_url", primitive()))
  panDocumentUrl?: string;
  @serializable(alias("aadhaar_back_image_url", primitive()))
  aadhaarBackImageUrl?: string;
  @serializable(alias("aadhaar_front_image_url", primitive()))
  aadhaarFrontImageUrl?: string;
  @serializable(alias("profile_pic_url", primitive()))
  profilePicUrl?: string;
  @serializable(alias("is_mobile_verified", primitive()))
  isMobileVerified?: boolean;
  @serializable(alias("is_email_verified", primitive()))
  isEmailVerified?: boolean;
  @serializable(alias("gender", primitive()))
  gender?: Gender;
  @serializable(alias("email", primitive()))
  email?: string;
  @serializable(alias("occupation_details", primitive()))
  occupationDetails?: string;
  @serializable(alias("additonal_income", primitive()))
  additonalIncome?: string;
  @serializable(alias("phone_number", primitive()))
  phoneNumber?: string;

  @serializable(alias("is_aadhaar_verified", primitive()))
  isAadhaarVerified?: boolean;
  @serializable(alias("is_pan_verified", primitive()))
  isPanVerified?: boolean;

  @serializable(alias("educational_qualification", primitive()))
  educationalQualification?: string;

  getName = () => this.name || "NA";

  getDOB = () =>
    moment(this.dob, "YYYY-MM-DD").isValid()
      ? moment(this.dob, "YYYY-MM-DD").format("D MMM YYYY")
      : "NA";

  getLocation = () => {
    switch (this.location) {
      case Location.WITH_APPLICANT:
        return "With Applicant";
      case Location.OTHER_STATE:
        return "Other State";
      case Location.OTHER_COUNTRY:
        return "Other Country";
      default:
        return "-";
    }
  };
  getAge = () => {
    const age = moment().diff(moment(this.dob, "YYYY-MM-DD"), "years");
    return isNaN(age) ? "NA" : `${age} years`;
  };

  getPhoneNumber = () => {
    const phoneNumber = this.mobileNumber ?? "";
    return phoneNumber;
  };
}
