// Libraries
import React, { ReactNode } from "react";
import ReactApexChart from "react-apexcharts";

// Styles
import "./statsGraph.scss";
import { Col, Row } from "antd";
import useBreakPoint from "../../hooks/useBreakPoint";

interface StatsGraphProps {
  title?: string;
  hasCard?: boolean;
  width?: number;
  height?: number;
  data: {
    key: string | number;
    value: number;
    count: number;
  }[];
  axes: {
    x: string;
    y: string;
  };

  barColor?: string;
  extraContent?: ReactNode;
}

function floorToString(num: number) {
  return Math.floor(num).toString();
}

const StatsGraph = (props: StatsGraphProps) => {
  // Props
  const { title, hasCard, data, axes, barColor, extraContent } = props;
  const { isLargeScreen } = useBreakPoint()
  const sum = data.reduce((acc, curr) => acc + curr.count, 0); // Calculate the sum of counts

  const options: ApexCharts.ApexOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "40%",
        borderRadius: [5, 5, 5, 5] as any,
      },
    },
    colors: [barColor],
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: data.map((item) => item.key),
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    fill: {
      opacity: 1,
    },
    yaxis: {
      show: false,
    },
    tooltip: {
      y: {
        formatter: (value) => floorToString(value),
      },
      marker: {
        show: false,
      },
      custom: ({ seriesIndex, dataPointIndex, w }) => {
        const { key, value, count } = data[dataPointIndex];
        return (
          '<div class="custom-tooltip">' +
          `<span>${axes.x}: </span>` +
          `<span class="value">${floorToString(value)}</span>` +
          `<br>` +
          // Conditionally render the span element
          `${
            sum !== 0 && sum !== undefined
              ? `<span>${axes.y}: </span><span class="value">${floorToString(
                  count
                )}</span>`
              : ""
          }`
        );
      },
    },
  };

  const series = [
    {
      name: axes.y,
      data: data.map((item) => item.value),
    },
  ];

  return (
    <div className={`${hasCard ? "card" : ""} stats-graph`}>
      <Row className="d-flex-align-center">
        <Col xs={24} sm={24} lg={8}>
          <span className="text-bold text-capitalize">{title}</span>
        </Col>
        <Col className={`text-right ${!isLargeScreen && `mt-5`}`}>
          {extraContent}
        </Col>
      </Row>
      <ReactApexChart key={JSON.stringify(data)} options={options} series={series} type="bar" />
    </div>
  );
};

export default StatsGraph;
