import React from 'react'
import './emptyDocument.scss'

type Props = {
    documentText?: string
    className?: string
}

const EmptyDocument = (props: Props) => {

    const { documentText, className } = props

    return (
        <div>
            <div className={`empty-document ${className}`}>
                <div className="empty-document-content">
                    {documentText}
                </div>
            </div>
        </div>
    )
}

export default EmptyDocument