import { Col, Drawer, Row, Table, TableColumnsType, TableProps } from "antd";
import { SorterResult } from "antd/lib/table/interface";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { DateFormat } from "../../../../enums/dateFormat.type";
import { Loan, LoanParams } from "../../../../models/loan.model";
import {
  LoanRepayamentSchedule,
  LoanRepayamentScheduleParams,
} from "../../../../models/loanRepaymentSchedule";
import { QueryParams } from "../../../../models/query.params.model";
import { LoanService } from "../../../../services/Loan/loan.service";
import ColumnOptions from "../../../../shared/components/ColumnOptions";

import useQueryParams from "../../../../shared/hooks/useQueryParams";
import useSorting from "../../../../shared/hooks/useSorting";
import { ToggleDrawer } from "../../../../shared/types/toggleDrawer";
import {
  currencyFormatter,
  dateTimeFormatter,
  percentageFormatter,
} from "../../../../shared/utils/formatter";
import { removeUnderscore } from "../../../../shared/utils/removeUnderscore";
import { roundOffValue } from "../../../../shared/utils/roundOffValue";

import { LoanRepayamentColumn } from "./loanRepaymentColumn";
import { fillEmptyData } from "../../../../shared/utils/fillEmptyData";

const sortBy = {
  status: "status",
};

interface LoanRepayamentScheduleProps {
  loan: Loan;
  settingsVisible?: boolean;
  onSettings?: ToggleDrawer;
  isLoanChange?: boolean;
}

const LoanRepaymentSchedule = (props: LoanRepayamentScheduleProps) => {
  const { loan, onSettings, settingsVisible, isLoanChange } = props;
  const location = useLocation();
  const { loanId } = useParams<{ loanId: string }>();
  const { params, handleSearch, updateParams } = useQueryParams({
    params: new LoanRepayamentScheduleParams(),
  });

  //service
  const { getLoan, loading, getRepaymentSchedule } = LoanService();
  const { updateSortData } = useSorting<LoanRepayamentSchedule>({
    sortBy,
  });
  const [loanSchedule, setLoanSchedule] = useState<LoanRepayamentSchedule[]>(
    []
  );
  const [filteredColumns, setFilteredColumns] = useState<string[]>([]);
  const [defaultColumns, setDefaultColumns] =
    useState<TableColumnsType<LoanRepayamentSchedule>>(LoanRepayamentColumn);

  const [loanTotalPage, setLoanTotalPage] = useState(1);

  const populateRepaymentSchedule = async (params?: QueryParams) => {
    if (loanId) {
      const loanDetails = await getRepaymentSchedule(loanId, params);

      if (loanDetails?.loans) setLoanSchedule(loanDetails?.loans);
      if (loanDetails?.meta)
        setLoanTotalPage(loanDetails?.meta?.totalCount ?? 1);
    }
  };

  const handleChange: TableProps<LoanRepayamentSchedule>["onChange"] = (
    { current },
    _,
    sorter
  ) => {
    const { sortBy, sortDirection } = updateSortData(
      sorter as SorterResult<LoanRepayamentSchedule>
    );
    updateParams({ page: current, sortBy, sortDirection });
  };

  useEffect(() => {
    populateRepaymentSchedule(params);
  }, [params, isLoanChange]);

  const loanDetail = [
    {
      label: "LOAN ID",
      value: loan?.code?.toLocaleUpperCase(),
    },
    {
      label: "Partner ID",
      value: fillEmptyData(loan?.laf),
    },
    {
      label: "LOAN Type",
      value: loan?.loanTypeName,
    },
    {
      label: "LOAN Purpose",
      value: loan?.loanPurposeName,
    },
    {
      label: "LOAN Amount",
      value: currencyFormatter({
        amount: loan?.requestedLoanAmt,
        hasCurrencySign: true,
      }),
    },
    {
      label: "Interest",
      value: loan?.interestRate
        ? percentageFormatter({
            value: Number(loan?.interestRate),
          })
        : "NA",
    },
    {
      label: "LOAN Tenure",
      value: dateTimeFormatter(loan?.tenureInMonths, DateFormat.MONTH),
    },
    {
      label: "Applied Date",
      value: dateTimeFormatter(loan?.appliedDate, DateFormat.DATE),
    },
    {
      label: "Disbursed Date",
      value: dateTimeFormatter(
        loan?.recentLoanStatus?.assignedAt ?? "",
        DateFormat.DATE
      ),
    },
    {
      label: "CRO Name",
      value: loan?.currentCro ?? "NA",
    },
    {
      label: "Current Outstanding",
      value: roundOffValue(loan?.outstandingAmount ?? ""),
    },
    {
      label: "Loan Status",
      value: (
        <span
          className={` text-capitalize active-status--${loan?.status
            ?.split("_")
            .join("-")}`}
        >
          {removeUnderscore(loan?.status ?? "")}
        </span>
      ),
    },
  ];

  return (
    <div className="loan-repayment-schedule">
      <div className="card mb-5">
        <Row gutter={[10, 30]}>
          {loanDetail?.map((loan) => {
            return (
              <Col span={4} key={loan?.label}>
                <div className="block__label mb-2">{loan.label}</div>
                <div className="block__value break-word">{loan.value}</div>
              </Col>
            );
          })}
        </Row>
      </div>
      <div className="card">
        <Table
          columns={LoanRepayamentColumn}
          dataSource={loanSchedule}
          loading={loading}
          scroll={{ x: true }}
          onChange={handleChange}
          pagination={{
            hideOnSinglePage: true,
            total: loanTotalPage,
          }}
        />
        {settingsVisible && onSettings && (
          <Drawer
            placement="right"
            onClose={() => onSettings(false)}
            visible={settingsVisible}
            width={500}
            title="Column Options"
            destroyOnClose
          >
            <ColumnOptions
              defaultColumns={defaultColumns}
              filteredColumns={filteredColumns}
              setFilteredColumns={setFilteredColumns}
              onClose={() => onSettings(false)}
              setDefaultColumns={setDefaultColumns}
            />
          </Drawer>
        )}
      </div>
    </div>
  );
};

export default LoanRepaymentSchedule;
