import {
  SKIP,
  alias,
  custom,
  list,
  object,
  optional,
  primitive,
  serializable,
} from "serializr";
import { Customer } from "./customer.model";
import { ModifiedBy } from "./modifiedBy.model";
import { QueryParams } from "./query.params.model";

export class LoanTimelineParams extends QueryParams {
  @serializable(alias("status", optional(list(primitive()))))
  status?: string[] = [];
}

export class LoanTimeline {
  @serializable(alias("date", primitive()))
  date?: string;
  @serializable(alias("time", primitive()))
  time?: string;
  @serializable(alias("created_at", primitive()))
  createdAt?: string;
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("loan_status", primitive()))
  loanStatus?: string;

  @serializable(alias("modified_by", object(ModifiedBy)))
  modifiedBy?: ModifiedBy;
  @serializable(
    alias(
      "activity",
      custom(
        () => SKIP,
        (values) => {
          return values;
        }
      )
    )
  )
  activity?: string[];
}
