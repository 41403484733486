import { Col, Row } from "antd";
import React from "react";
import "./termsAndConditions.scss";

const TermssAndConditions = () => {
  return (
    <div className="terms-and-conditions">
      <Row>
        <Col span={24} className="text-center text-bold text-underline">
          Velicham Finance Private Limited
        </Col>
        <Col span={24} className="text-center text-bold mt-5">
          Term Loan Terms & conditions
        </Col>
        <Col span={24} className=" mt-5">
          I/We request to the Velicham Finance Pvt Ltd to sanction a Term Loan
          for the requested amount.
        </Col>
        <Col span={24} className=" mt-5">
          I/We ensure to give correct details to Velicham Finance Private
          Limited to process the loan application and I/We give consent to
          Velicham Finance to store my data.
        </Col>
        <Col span={24} className=" mt-5">
          I/We give our consent to VFPL to take life insurance cover attached
          with my/our loan on applicable insurance premium payable by me/us.
        </Col>
        <Col span={24} className=" text-bold mt-5">
          COMMON DECELARATIONS:
        </Col>
        <Col span={24} className=" mt-5">
          I/We hereby acknowledge and confirm that:
        </Col>
        <Col span={24} className=" mt-5">
          <div className="terms-and-conditions__list">
            <ol>
              {" "}
              <li>
                The executive of Velicham Finance Private Limited (Lender),
                collecting the application/documents has informed me/us of
                applicable schedule of charges, fees, commissions, and key
                facts, as more particulars mentioned in the ‘schedule of
                charges’ on the website of the company.
              </li>
              <li>
                The executive collecting the application/documents has informed
                me/us of rate of interest and the approach for gradation of
                risks and rational charging different rate of interest to
                different categories of borrowers, the particulars whereof have
                been specified in the Loan Application Form.
              </li>
              <li>
                Submission of loan application to the Lender does not imply
                automatic approval by the lender and the lender will decide the
                quantum of the loan at its sole and absolute discretion. The
                Lender in its sole and absolute discretion may either approve or
                reject the application for granting the loan. In case of
                rejection, the lender shall not be required to give any reason.
              </li>
              <li>
                The lenders shall have the right to make disclosure of any
                information relating to me/us including personal information,
                details in relation to loan, defaults, security, etc to credit
                information companies and / any other governmental / regulatory
                / statutory /or private agency/entity, RBI, lender’s other
                branches /subsidiaries/affiliates/credit bureaus /credit
                information bureau of India (CIBIL)/rating agencies, service
                providers, other banks /financial institution’s, any third
                parties, any assigns / potential assignees or transfers, who may
                need, process and publish the information in such manner and
                through such medium as it may be deemed necessary by the
                publisher / lender / RBI, Including publishing the name as part
                of wilful defaulter’s list from time to time, and also use the
                same for KYC information verification, credit risk analysis, or
                for other related purpose.
              </li>
              <li>
                I/we have been informed of the documents to be submitted with
                the loan application form and have submitted the same. I/we
                shall furnish any additional documents as and when required by
                the Lender.
              </li>
              <li>
                The credit decision is based on a credit model which includes
                factors like credit history, repayment track record, banking
                habits, business stability & cash flow analysis which is
                assessed through a combination of personal discussion and
                documentation.
              </li>
              <li>
                Incomplete /defective application will not be processed, and the
                lender shall not be responsible in any manner of for the
                resulting delay or otherwise.
              </li>
              <li>Loan foreclosure charges should be as per sanction terms.</li>
              <li>
                The Loan terms as sanctioned are applicable for the specified
                product as indicated in the loan application and are valid for
                period of 30 days only. Where for some reasons, there is a delay
                in concluding the loan, the lender reserves the right to revise
                the loan terms as may be applicable at the time of actual loan
                availment upon providing a copy of revisions to me/us.
              </li>
              <li>
                All the particulars and information and details give / filled in
                this application form are true/correct/complete and up to date
                in all respects and that I/we have not withheld and information
                whatsoever.
              </li>
              <li>
                . I/we shall inform the lender regarding any changes in my/our
                address or my employment or profession, or any material
                deviations from the information provided in the loan application
                form.
              </li>
              <li>
                I/we hereby confirm that I/we/am/are competent and fully
                authorized to give declarations, undertakings etc and to execute
                and submit this Application Form and all other documents for the
                purpose of availing the loan, creation of security and
                representing generally for all the purpose mentioned / required
                to be done for these presents.
              </li>
              <li>
                I give our consent to VFPL to take life insurance cover attached
                with my/our loan on applicable insurance premium payable by
                me/us.
              </li>
              <li>
                I/we understand that processing fees, Insurance fees and
                stamping charges would be deducted from disbursement, if paid up
                front the same would be not refunded.
              </li>{" "}
              <li>
                “The rate of interest is arrived at based on various factors
                such as cost of funds, administrative costs, risk premium,
                margin etc. The decision to give a loan and the interest rate
                applicable to each loan account is assessed on a case-to-case
                basis, based on multiple parameters such as borrower profile,
                repayment capacity, asset being financed, borrower’s other
                financial commitments, past repayment track record, if any,
                security, tenure etc. The rate of interest is subject to change
                as the situation warrants and is subject to the discretion of
                the Company.
              </li>
              <li>
                The Lender has the right to retain the documents along with the
                photographs submitted with the loan application, and the same
                will not be returned to the Applicant.
              </li>
              <li>
                {" "}
                I/ We have read & understood the contents of the application.
                Additionally, the contents of the same have been read out me/us
                in our vernacular language(s), & we have understood the same.
              </li>
              {/* <li>Loan foreclosure charges should be as per sanction terms.</li>
              <li>Loan foreclosure charges should be as per sanction terms.</li> */}
            </ol>
          </div>
          <Col span={24} className=" text-bold mt-5 mb-5">
            Other Terms and Conditions:
          </Col>
          <div className="terms-and-conditions__list">
            <ol>
              <li>
                Payment: No cash / Bearer cheque has been collected from me up
                -front towards processing the loan application.
              </li>
              <li>
                Details with respect to the EMI presentation dates, numbers of
                EMIs, amount and other terms and conditions of the loan will be
                communicated separately.
              </li>
              <li>
                No discount / free gift or any other commitment is given
                whatsoever which is not documented in the loan agreement by the
                lender or any of its authorized representatives.
              </li>
              <li>
                The lender shall make attempts to process the application and
                disburse the loan within 30(thirty)working days from the date of
                the completion and submission of all relevant loan documents as
                specified therein.
              </li>
              <li>
                Other Charges: Loan Processing fees would be up to 2% of the
                loan amount.
              </li>
              <li>
                Charges which are in nature of fees are EXCLUSIVE of goods and
                service tax and other government levies, as applicable, would be
                charged additionally.
              </li>
            </ol>{" "}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TermssAndConditions;
