import React, { useEffect, useState } from "react";
import { Button, Col, Input, Popover, Row, Table, TableProps } from "antd";
import { BlacklistService } from "../../../../services/BlacklistService/blacklist.service";
import { Blacklists, BlacklistsParams } from "../../../../models/blacklists";
import { BlacklistType } from "../../../../enums/blacklist.type";
import { blacklistPincodeColumn } from "./blacklistPincodeColumn";
import useQueryParams from "../../../../shared/hooks/useQueryParams";
import CustomModal from "../../../../shared/components/CustomModal";
import CustomImage from "../../../../shared/components/CustomImage";
import pincodeIcon from "../../../../Assets/images/pincode.png";
import { UserAccess } from "../../../../models/userAccess.model";
import { Formik } from "formik";
import InputField from "../../../../shared/components/InputField";
import { InputType } from "../../../../enums/input.type";
import { blackListPincodeValidation } from "./blackListPincodeValidation";
import { BlacklistPincode } from "../../../../models/blacklistPincode";

interface BlackListPincodeProps {
  toggleIsRestrictedPincode: (val: boolean) => void;
  isRestrictedPincode: boolean;
  userAccess?: UserAccess;
}

const BlackListPincode = (props: BlackListPincodeProps) => {
  const { isRestrictedPincode, toggleIsRestrictedPincode, userAccess } = props;
  const [totalPage, setTotalPage] = useState(1);
  const [curentPincode, setCurrentPincode] = useState<string>();
  const {
    getBlacklistDetails,
    loading,
    unblockCustomerPincode,
    restrictPincode,
  } = BlacklistService();
  const [blacklistPincodeList, setBlackListPincodeList] = useState<
    Blacklists[]
  >([]);
  const [selectedPincode, setSelectedPincode] = useState<Blacklists>();
  const [modalVisible, setModalVisible] = useState(false);
  const { params, updateParams } = useQueryParams({
    params: new BlacklistsParams(),
  });

  useEffect(() => {
    getBlacklistPincode(params);
  }, []);

  const getBlacklistPincode = async (params?: BlacklistsParams) => {
    const result = await getBlacklistDetails({
      ...params,
      type: BlacklistType.PINCODE,
    });
    setBlackListPincodeList(result?.blacklist || []);
    setTotalPage(result?.meta?.totalCount ?? 1);
  };

  const handleChange: TableProps<Blacklists>["onChange"] = (
    { current },
    _,
    sorter
  ) => {
    getBlacklistPincode({ ...params, page: current });
  };
  const handleModalVisibility = () => setModalVisible(!modalVisible);
  const handleUnblockPincode = async () => {
    const result =
      selectedPincode?.id &&
      (await unblockCustomerPincode(String(selectedPincode?.id)));
    if (result) {
      getBlacklistPincode();
      handleModalVisibility();
    }
  };
  const handlePincodeRestrict = async (pincode: string) => {
    const result = await restrictPincode({
      pincode: pincode,
      type: BlacklistType.PINCODE,
    });
    if (result) {
      setBlackListPincodeList([...blacklistPincodeList, result]);
      toggleIsRestrictedPincode(false);
    }
  }

  const columns = [
    ...blacklistPincodeColumn,
    {
      title: "",
      dataIndex: "",
      width: "50px",
      render: (_: string, record: Blacklists) =>
        userAccess?.writeAccess && (
          <Popover
            content={
              <div
                className="cursor-pointer"
                onClick={() => {
                  setSelectedPincode(record);
                  handleModalVisibility();
                }}
              >
                Unblock Pincode
              </div>
            }
            trigger="click"
          >
            . . .
          </Popover>
        ),
    },
  ];

  const handleSubmit = async (values: BlacklistPincode) => {
      handlePincodeRestrict(values.pincode || '');
  }
  return (
    <div>
      <Table
        columns={columns}
        dataSource={blacklistPincodeList}
        onChange={handleChange}
        loading={loading}
        pagination={{
          hideOnSinglePage: true,
          total: totalPage,
        }}
      />
      <CustomModal
        className="blacklist-customers__modal"
        title="Unblock Pincode"
        visible={modalVisible}
        onCancel={handleModalVisibility}
        isCancelButton={false}
        isOkButton={false}
      >
        <Row gutter={[20, 0]}>
          <Col span={4}>
            <CustomImage src={pincodeIcon} height={50} width={50} />
          </Col>
          <Col span={8}>
            <h2>{selectedPincode?.pincode}</h2>
          </Col>
          <Col span={12}>
            <Button
              loading={loading}
              className="btn-primary blacklist-customers__btn"
              onClick={handleUnblockPincode}
            >
              UNBLOCK
            </Button>
          </Col>
        </Row>
      </CustomModal>
      <Formik
        initialValues={new BlacklistPincode()}
        onSubmit={handleSubmit}
        validationSchema={blackListPincodeValidation}
      >
        {({ values, handleChange, handleSubmit, isValid, dirty, resetForm }) => {
          const handleCancel = () => {
              resetForm()
              toggleIsRestrictedPincode(false);
            }
          return (
            <CustomModal
              title="Restrict Pincode"
              okText="Confirm"
              visible={isRestrictedPincode}
              onCancel={handleCancel}
              onOk={handleSubmit}
              okButtonProps={{disabled: !dirty || !isValid}}
            >
              <div className="blacklist-pincode-input">
                <p>Pincode</p>
                <InputField
                  name="pincode"
                  type={InputType.NUMBER}
                  placeholder={"Enter Pincode"}
                />
              </div>
            </CustomModal>
          )
        }}
      </Formik>
    </div>
  );
};

export default BlackListPincode;
