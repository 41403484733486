import { alias, object, primitive, serializable } from "serializr";

import { Address } from "./address.model";
import { BusinessNature } from "../enums/businessNature.type";

export class BusinessDetail {
  @serializable(alias("address", object(Address)))
  address?: Address;

  @serializable(alias("bank_statement_id", primitive()))
  bankStatementId?: string;

  @serializable(alias("bank_statement_url", primitive()))
  bankStatementUrl?: string;

  @serializable(alias("direct_cost", primitive()))
  directCost?: number;

  @serializable(primitive())
  cost?: number;

  @serializable(primitive())
  description?: string;

  @serializable(alias('type_of_agriculture', primitive()))
  typeOfAgriculture?: string;

  @serializable(primitive())
  acres?: string;

  @serializable(alias('land_ownership', primitive()))
  landOwnership?: string;

  @serializable(alias('proof_of_agriculture_id', primitive())) 
  proofOfAgricultureId?: number

  @serializable(alias('nature_of_activity', primitive()))
  natureOfActivity?: string

  @serializable(primitive())
  vintage?: string

  @serializable(primitive())
  details?: string

  @serializable(alias("year_of_establishment", primitive()))
  establishmentYear?: number;
  @serializable(alias("years_of_business", primitive()))
  yearsOfBusiness?: number;

  @serializable(alias("gst_document_id", primitive()))
  gstDocumentId?: string;

  @serializable(alias("gst_document_url", primitive()))
  gstDocumentUrl?: string;

  @serializable(alias("gst_number", primitive()))
  gstNumber?: string;

  @serializable(alias("id", primitive()))
  id?: string;

  @serializable(alias("image_id", primitive()))
  imageId?: number;

  @serializable(alias("image_url", primitive()))
  imageUrl?: string;

  @serializable(alias("indirect_cost", primitive()))
  indirectCost?: number;

  @serializable(alias("infrastructure_in_asset_value", primitive()))
  infrastructureInAssetValue?: string;

  @serializable(alias("loan_id", primitive()))
  loanId?: number;

  @serializable(alias("monthly_expense", primitive()))
  monthlyExpense?: number;

  @serializable(alias("monthly_income", primitive()))
  monthlyIncome?: number;

  @serializable(alias("monthly_revenue", primitive()))
  monthlyRevenue?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("nature_of_business", primitive()))
  natureOfBusiness?: BusinessNature;

  @serializable(alias("no_of_goods_in_asset", primitive()))
  noOfGoodsInAsset?: string;

  @serializable(alias("no_of_infrastructure_in_asset", primitive()))
  noOfInfrastructureInAsset?: string;

  @serializable(alias("no_of_infrastructure_in_liability", primitive()))
  noOfInfrastructureInLiability?: number;

  @serializable(alias("payables_to_suppliers", primitive()))
  payablesToSuppliers?: number;

  @serializable(alias("rc_document_id", primitive()))
  rcDocumentId?: string;

  @serializable(alias("rc_document_url", primitive()))
  rcDocumentUrl?: string;

  @serializable(alias("rc_number", primitive()))
  rcNumber?: string;

  @serializable(alias("receivable_cash_or_debit", primitive()))
  receivableCashOrDebit?: string;
  /* job details */

  @serializable(alias("purpose", primitive()))
  purpose?: string;
  @serializable(alias("working_place", primitive()))
  workingPlace?: string;
  @serializable(alias("designation", primitive()))
  designation?: string;
  @serializable(alias("monthly_salary", primitive()))
  monthlySalary?: number;
  @serializable(alias("years_of_experience", primitive()))
  yearsOfExperience?: number;
  @serializable(alias("picture_url", primitive()))
  pictureUrl?: string;
  @serializable(alias("proof_of_agriculture_url", primitive()))
  proofOfAgricultureUrl?: string;
  @serializable(alias("salary_proof_url", primitive()))
  salaryProofUrl?: string;
  @serializable(alias("identity_card_url", primitive()))
  identityCardUrl?: string;

  @serializable(alias("enterprise_name", primitive()))
  enterpriseName?: string;
  @serializable(alias("business_description", primitive()))
  businessDescription?: string;
  @serializable(alias('cost', primitive()))
  totalCost?: number;

  getBusinessNature = () => {
    switch (this.natureOfBusiness) {
      case BusinessNature.AGRICULTURE:
        return "Agriculture";
    }
  };

  getEstablishmentYear = () =>
    this.establishmentYear ? `${this.establishmentYear}` : "NA";
}