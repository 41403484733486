import { alias, primitive, serializable } from "serializr";
import { QueryParams } from "./query.params.model";

export class LoanEMIParams extends QueryParams {
  @serializable(alias("loan_code", primitive()))
  loanCode?: string;
  @serializable(alias("other_amount", primitive()))
  otherAmount?: number;
  @serializable(alias("pay_emi", primitive()))
  payEMI?: string;

  @serializable(alias("instalment_id", primitive()))
  instalmentId?: number;

  @serializable(alias("id", primitive()))
  id?: number;
}

export class LoanEMI {
  @serializable(alias("customer_name", primitive()))
  customerName?: string;
  @serializable(alias("instalment_id", primitive()))
  instalmentId?: number;

  @serializable(alias("loan_id", primitive()))
  loanId?: number;
  @serializable(alias("loan_code", primitive()))
  loancode?: string;
  @serializable(alias("branch", primitive()))
  branch?: string;

  @serializable(alias("loan_amount", primitive()))
  loanAmount?: number;
  @serializable(alias("tenure", primitive()))
  tenure?: number;
  @serializable(alias("roi", primitive()))
  roi?: number;
  @serializable(alias("outstanding_balance", primitive()))
  outstandingBalance?: number;
  @serializable(alias("payment_link", primitive()))
  paymentLink?: string;
  @serializable(alias("outstanding_principal", primitive()))
  outstandingPrincipal?: string;

  @serializable(alias("amount", primitive()))
  amount?: number;
  @serializable(alias("due_amount", primitive()))
  dueAmount?: number;
  @serializable(alias("due_date", primitive()))
  dueDate?: string;
  @serializable(alias("wallet_amount", primitive()))
  walletAmount?: number;
}
