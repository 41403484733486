import { alias, list, primitive, serializable } from "serializr"

export class Regularisation {

    @serializable(alias('back_date', primitive()))
    backDate?: string

    @serializable(primitive())
    reason?: string

    @serializable(primitive())
    description?: string
}

export class ChangeRegularisationStatus {
    @serializable(alias('rejected_reason', primitive()))
        rejectedReason?: string
        
        @serializable(alias('status', primitive()))
        status?: string

        @serializable(alias('regularise_ids', list(primitive())))
        regulariseIds?: number[]

}