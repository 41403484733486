import { alias, primitive, serializable } from "serializr";

export class PreCloseLoan {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("customer_name", primitive()))
  customerName?: string;

  @serializable(alias("outstanding_amount", primitive()))
  outstandingAmount?: number;

  @serializable(alias("amount_collected", primitive()))
  amountCollected?: number;

  @serializable(alias("number_of_pending_dues", primitive()))
  numberOfpendingDues?: number;
}
