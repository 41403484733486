import React from "react";
import { FamilyMember } from "../../../../../models/familyMember.model";
import { FamilyDetail } from "../../../../../models/FamilyDetail.model";
import { Loan } from "../../../../../models/loan.model";
import { Customer } from "../../../../../models/customer.model";
import { removeUnderscore } from "../../../../../shared/utils/removeUnderscore";
import "./familyInfomation.scss";
import { currencyFormatter } from "../../../../../shared/utils/formatter";
import { fillEmptyData } from "../../../../../shared/utils/fillEmptyData";

interface FamilyInformationProps {
  familyMembers: FamilyMember[];
  familyDetail: FamilyDetail;
  nominee?: FamilyMember;
  coBorrower?: FamilyMember;
  loan?: Loan;
  customer: Customer;
}
const FamilyInformation = (props: FamilyInformationProps) => {
  const { familyMembers, familyDetail, nominee, loan, customer, coBorrower } =
    props;

  const data = [
    {
      label: "Name",
      value: (data: Customer | FamilyMember) => {
        return data instanceof Customer
          ? "Myself"
          : fillEmptyData(data?.getName());
      },
    },
    {
      label: "Age",
      value: (data: Customer | FamilyMember) => fillEmptyData(data?.getAge()),
    },
    {
      label: "Location",
      value: (data: Customer | FamilyMember) =>
        fillEmptyData(removeUnderscore(data?.location ?? "")),
    },
    {
      label: "Relationship",
      value: (data: Customer | FamilyMember) =>
        fillEmptyData(removeUnderscore(data?.relationship ?? "")),
    },
    {
      label: "Educational Qualification",
      value: (data: Customer | FamilyMember) =>
        fillEmptyData(removeUnderscore(data?.educationalQualification ?? "")),
    },
    {
      label: "Occupation",
      value: (data: Customer | FamilyMember) => fillEmptyData(removeUnderscore(data?.occupation)),
    },
    {
      label: "Occupation Details",
      value: (data: Customer | FamilyMember) =>
        fillEmptyData(removeUnderscore(data?.occupationDetails)),
    },
    {
      label: "Type",
      value: (data: Customer | FamilyMember) => {
        if (data instanceof Customer) return "Borrower";
        if (data?.isNominee && data?.isCoBorrower)
          return "Co-Borrower, Additional Insured";
        if (data?.isNominee) return "Additional Insured";
        if (data.isCoBorrower) return "Co-Borrower";
        return "Family Member";
      },
    },
    {
      label: "Income",
      value: (data: Customer | FamilyMember) =>
        currencyFormatter({
          amount: Number(data?.monthlyIncome) + Number(data?.businessIncome),
          hasCurrencySign: true,
        }),
    },
  ];

  const uniquePeople = new Set([
    customer,
    coBorrower,
    nominee,
    ...familyMembers,
  ]);
  const peopleArray = Array.from(uniquePeople);
  return (
    <div className="block family-informations">
      <div className="block__heading mb-5 mt-5">Family Information</div>
      <table className="custom-table">
        <thead>
          <tr>
            <th>S.No</th>
            {data.map((field) => (
              <th key={field.label}>{field.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {peopleArray.map((person, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              {data.map((field) => (
                <td className="text-capitalize" key={field.label}>
                  {person ? field.value(person) : "NA"}
                </td>
              ))}
            </tr>
          ))}
          <tr className="family-informations__last-child">
            <td colSpan={11}>
              <h3 className="text-heading text-bold">
                Total Income :
                <span className="ml-5">
                  {currencyFormatter({
                    amount: familyDetail?.totalMonthlyIncome ?? 0,
                    hasCurrencySign: true,
                  })}
                </span>
              </h3>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default FamilyInformation;
