export enum modLoansTypes {
    ALL = "all",
    SECURED = "secured",
    MOD_SECURED = "mod",
    UNSECURED = "not_secured",
    MOD = "mod",
    PENDING = "pending",
    RETURNED = "returned",
    STORED = "stored",
    TO_RETURNED = "to_return",
    EQUAITABLE_MORTAGE = "equitable_mortgage"

}