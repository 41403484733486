import { Center, CenterParams } from "../../models/center.model";
import { deserialize, serialize } from "serializr";

import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { PaginationMeta } from "../../models/pagination.model";
import axiosInstance from "../../interceptor/axiosInstance";
import { useState } from "react";

export const CentersService = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  const getCenters = async (params?: CenterParams) => {
    setLoading(true);
    const queryParams = params ? serialize(CenterParams, params) : {};
    try {
      const { data } = await axiosInstance.get(ApiRoutes.CENTERS, {
        params: queryParams,
      });
      const deserializedCenters = deserialize(
        Center,
        data?.["centers"] as any[]
      );
      const deserializedCentersMeta = deserialize(
        PaginationMeta,
        data?.["meta"]
      );
      return { centers: deserializedCenters, meta: deserializedCentersMeta };
    } catch (ex) {
      Notification({
        message: "Unable to Get Centers details",
        description: "Unable to Get Centers details",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };
  const addCenter = async (center: Center) => {
    setLoading(true);
    try {
      const serializedCenter = serialize(Center, center);
      const { data } = await axiosInstance.post(ApiRoutes.CENTERS, {
        center: serializedCenter,
      });
      const newCenter = deserialize(Center, data?.["center"]);
      Notification({
        message: "New Center Added",
        description: "New Center Created",
        type: NotificationTypes.SUCCESS,
      });
      return newCenter;
    } catch (ex) {
      Notification({
        message: "Unable to Add Center details",
        description: "Unable to Add Center details",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };
  const updateCenter = async (center: Center) => {
    setLoading(true);
    try {
      const serializedCenter = serialize(Center, center);
      const { data } = await axiosInstance.put(
        ApiRoutes.CENTERS + `/${center?.id}`,
        {
          center: serializedCenter,
        }
      );
      const newCenter = deserialize(Center, data?.["center"]);
      Notification({
        message: "Center Updated",
        description: "Center Updated",
        type: NotificationTypes.SUCCESS,
      });
      return newCenter;
    } catch (ex) {
      Notification({
        message: "Unable to Update Center details",
        description: "Unable to Update Center details",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  return { getCenters, addCenter, updateCenter, loading, error };
};
