import { Col, Row } from "antd";
import React, { ReactElement } from "react";

import { BusinessDetail } from "../../../../../models/businessDetail.model";
import { fillEmptyData } from "../../../../../shared/utils/fillEmptyData";
import { removeUnderscore } from "../../../../../shared/utils/removeUnderscore";
import { getLocation } from "../../../../../shared/hooks/getLocation";

interface AgriNatureProps {
  AgriDetail: BusinessDetail;
}

const AgriNature = (props: AgriNatureProps) => {
  const { AgriDetail } = props;

  return (
    <Row gutter={[16, 16]} className="block business-nature">
      <Col className="text-capitalize block__heading" span="24">
        nature of Agriculture
      </Col>
      <Col span={4} className="block__label">
        Type of Agriculture
        <div className="block__value text-capitalize">
          {fillEmptyData(
            removeUnderscore(AgriDetail?.typeOfAgriculture ?? "")
          )}
        </div>
      </Col>

      <Col span={4} className="block__label">
        Acres
        <div className="block__value text-capitalize">
          {fillEmptyData(AgriDetail?.acres)}
        </div>
      </Col>
      <Col span={4} className="block__label">
       Land Ownership
        <div className="block__value text-capitalize">
          {fillEmptyData(AgriDetail?.landOwnership)}
        </div>
      </Col>
      <Col span={6} className="block__label">
       Details
        <div className="block__value text-capitalize">
          {fillEmptyData(AgriDetail?.details)}
        </div>
      </Col>
    </Row>
  );
};

export default AgriNature;
