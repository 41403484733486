import React from "react";
import { ColumnProps } from "antd/lib/table";
import { DateFormat } from "../../../enums/dateFormat.type";
import { dateTimeFormatter } from "../../../shared/utils/formatter";
import { WaiveRequestModel } from "../../../models/waiveRequest.model";

export const waiveRequestColumn: ColumnProps<WaiveRequestModel>[] = [
  {
    title: "Customer ID",
    dataIndex: "customerCode",
  },
  {
    title: "Name",
    dataIndex: "customerName",
    sorter: true
  },
  {
    title: "Loan ID",
    dataIndex: "loanCode",
    sorter: true
  },
  {
    title: "Penalty Amount",
    dataIndex: "penaltyAmt",
  },
  {
    title: "Date",
    dataIndex: "updatedAt",
    render: (date: string) => dateTimeFormatter(date, DateFormat.DATE_MONTH),

  },
  {
    title: "Waived By",
    dataIndex: "croName",
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (status) => {
      const className = "active-status--" + status?.split("_")?.join("-");
      return <span className={` text-capitalize  ${className}`}>{status}</span>;
    },
  },
];
