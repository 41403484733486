import { Button, Col, Row } from "antd";
import { Form, Formik, FormikHelpers } from "formik";
import React, { useState } from "react";
import InputField from "../../../shared/components/InputField";
import { InputType } from "../../../enums/input.type";
import AttachmentUpload from "../../../shared/components/AttachmentUpload";
import "./ugroDocumentUpload.scss";
import { CloseCircleOutlined } from "@ant-design/icons";
import deleteIcon from "../../../Assets/images/delete-icon.png";
import documentIcon from "../../../Assets/images/docIcon.png";
import { ugroDocumentUploadValidation } from "./ugroDocumentUploadValidation";
import { LoanService } from "../../../services/Loan/loan.service";
import { UgroLoanDocument } from "../../../models/ugroDocument.model";
import { DocumentType } from "../../../enums/documentType.type";
import { Loan } from "../../../models/loan.model";
import { getFileName } from "../../../shared/utils/formatter";
import { isObjectEmpty } from "../../../shared/utils/isObjectEmpty";
import { useNavigate } from "react-router-dom";
import * as AppRoutes from '../../../routes/routeConstants/appRoutes'

type UgroDocumentUploadProps = {
  loan?: Loan;
  onClose: () => void;
};

const UgroDocumentUploadDrawer = (props: UgroDocumentUploadProps) => {
  const { loan, onClose } = props;
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState<UgroLoanDocument[]>([]);
  const { loanDocumentsUpload } = LoanService();

  const handleSubmit = (
    values: UgroLoanDocument,
    formikHelpers: FormikHelpers<UgroLoanDocument>
  ) => {
    setDocuments((prev) => [...prev, values]);
    formikHelpers.resetForm();
  };

  const handleDelete = (document: number) => {
    setDocuments((prevDocuments) =>
      prevDocuments.filter((_, i) => i !== document)
    );
  };

  const handleUpload = () => {
    const submittedDocuments = documents.map((document) => {
      return {
        documentName: document.documentName,
        documentType: DocumentType.OTHERS,
        fileName: document?.frontSideImageId?.fileName,
        frontSideImageId: document.frontSideImageId,
      } as UgroLoanDocument;
    });
    const result = loanDocumentsUpload(Number(loan?.id), submittedDocuments);
    onClose()
    navigate(AppRoutes.BRANCH_ACCOUNTANT_HOME)
  };

  return (
    <div className="ugro-doc-drawer">
      <Formik
        initialValues={new UgroLoanDocument()}
        onSubmit={handleSubmit}
        validationSchema={ugroDocumentUploadValidation}
      >
        {({ isValid, dirty, setFieldValue, values }) => {
          return (
            <div>
              <Form className="ant-form-vertical">
                <Row className="document-upload">
                  <Col span={12}>
                    <InputField
                      name="documentName"
                      placeholder="Enter Document Name"
                      label="Document Name"
                      type={InputType.TEXT}
                    />
                  </Col>
                  <Col offset={4}>
                    <AttachmentUpload
                      accept="image/*,application/pdf"
                      name="frontSideImageId"
                      placeholder="Upload Document"
                      setFieldValue={setFieldValue}
                      isLoading={setLoading}
                      fileNameRequired={true}
                    />
                    {values.frontSideImageId &&
                      isObjectEmpty(values.frontSideImageId) &&
                      !loading && (
                        <Col className="mt-5 mb-5">
                          <span className="text-primary mr-5">
                            File Uploaded
                          </span>
                          <CloseCircleOutlined
                            onClick={() =>
                              setFieldValue("frontSideImageId", undefined)
                            }
                          />
                        </Col>
                      )}
                  </Col>
                </Row>
                <Row className="ugro-doc-submit">
                  <Col offset={16}>
                    <Button disabled={!isValid || !dirty} htmlType="submit">
                      Add Document
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          );
        }}
      </Formik>
      <Row className="mt-5">
        {documents.map((doc, key) => {
          const fileName = getFileName(doc?.frontSideImageId?.fileName);
          return (
            <div className="card document-card">
              <div className="document-card-details">
                <div className="document-card-details__name">
                  {doc.documentName}
                </div>
                <div>
                  <img
                    src={deleteIcon}
                    width={20}
                    height={20}
                    onClick={() => handleDelete(key)}
                  />
                </div>
              </div>
              <div className="file-name-container">
                <div>
                  <img src={documentIcon} width={12} height={15} />
                </div>
                <div>
                  <p>{fileName}</p>
                </div>
              </div>
            </div>
          );
        })}
      </Row>
      <div className="drawer-footer">
        <Button
          className="drawer-footer__cancel"
          htmlType="reset"
            onClick={onClose}
          type="link"
        >
          Cancel
        </Button>
        <Button
          className="drawer-footer__submit"
          htmlType="submit"
          disabled={!documents.length}
          onClick={handleUpload}
        >
          {documents.length > 1 ? "Upload All" : "Upload"}
        </Button>
      </div>
    </div>
  );
};

export default UgroDocumentUploadDrawer;
