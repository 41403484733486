import { Col, Collapse, Row, Space, Spin } from "antd";
import React from "react";
import overDue from "../../../../../Assets/images/OD (Overdue Accounts).png";
import { ListMappingKeys } from "../../../../../shared/types/optionArray.type";
import { currencyFormatter } from "../../../../../shared/utils/formatter";
import { fillEmptyData } from "../../../../../shared/utils/fillEmptyData";
import { OverdueAccounts } from "../../../../../models/overdueAccounts.model";
import PaymentHistory from "../../../Total Accounts/PaymentTable";
import AppLoader from "../../../../../shared/components/AppLoader";

interface OverDueProps {
  data?: OverdueAccounts;
  loading: boolean
}

const OverDue = (props: OverDueProps) => {
  const { data, loading } = props;

  const { Panel } = Collapse;

  const overDueData: ListMappingKeys[] = [
    {
      label: "Total OD Accounts",
      value: data?.totalOdAccounts,
      offset: 8,
      key: "1"
    },
    {
      label: "Total OD Amount",
      value: currencyFormatter({amount: data?.totalOdAmount, hasCurrencySign: true, shouldReturnNA: false}),
      offset: 3,
      key: "2"
    },
  ];


  const renderPanelContent = (content: any) => (
    <Row className={`card crif-card mt-5`} align={"middle"}>
      <Space direction="horizontal" size={12}>
        <Col>
          <img src={overDue} alt="" />
        </Col>
        <Col className="flex-column">
          <span className="text__value">Axis Bank</span>
          <span className="text__label">Home Loan</span>
        </Col>
      </Space>
      {content.map((item: any) => (
        <Col offset={item.offset} key={item.key} className="flex-column">
          <span className="text__value">{item.value}</span>
          <span className="text__label">{item.label}</span>
        </Col>
      ))}
    </Row>
  );

  return (
    <div>
      <AppLoader loading={loading}>
      <Row className="card crif-card" align={"middle"}>
        <Space direction="horizontal" size={12}>
          <Col>
            <img src={overDue} alt="" />
          </Col>
          <Col>Payment Status</Col>
        </Space>
        {overDueData.map((data) => {
          return (
            <>
              <Col offset={data.offset} className="flex-column">
                <span className="font-weight__600 font-size__18">
                  {data.value || 0}
                </span>
                <span>{data.label}</span>
              </Col>
            </>
          );
        })}
        <Col offset={3} className="flex-column">
          <div>
            <span className="font-weight__600 font-size__18">{currencyFormatter({amount: data?.totalOdAmount, hasCurrencySign: true, shouldReturnNA: false})}</span>
          </div>
          <span className={`text__green text-right`}>{fillEmptyData(data?.status)}</span>
        </Col>
      </Row>
      <div className="payment-status">
        <Collapse expandIconPosition="end" bordered={false} ghost>
          {data?.totalSettlementsAccounts?.map((emiDetail, index) => (
            <Panel header={renderPanelContent(emiDetail)} key={index + 1}>
              <PaymentHistory account={emiDetail} />
            </Panel>
          ))}
        </Collapse>
      </div>
      </AppLoader>
    </div>
  );
};

export default OverDue;
