import { alias, list, object, primitive, serializable } from "serializr";
import { Customer } from "./customer.model";
import { DashboardGraph } from "./dashboardGraph.model";
import { QueryParams } from "./query.params.model";
import {
  HeadOfficerDashboard,
  HeadOfficerDashboardParams,
} from "./headOfficerDashboard";
import { LoanAppicationApproval } from "./misDashboards.model";

export class DashboardParams extends HeadOfficerDashboardParams {
  @serializable(alias("user", primitive()))
  user?: string;
  @serializable(alias("branch", primitive()))
  branch?: string;
  @serializable(alias("type", primitive()))
  type?: string;
  @serializable(alias("status", primitive()))
  status?: string;
  @serializable(alias("role", primitive()))
  role?: string;
  @serializable(alias("region", primitive()))
  region?: string;
  @serializable(alias("gender", primitive()))
  gender?: string;
  @serializable(alias("month", primitive()))
  month?: string;

  @serializable(alias("from_date", primitive()))
  fromDate?: string;
  @serializable(alias("to_date", primitive()))
  toDate?: string;
}

export class Dashboard extends HeadOfficerDashboard {
  @serializable(alias("total_customer", primitive()))
  totalCustomer?: number;
  @serializable(alias("total_loan_amount", primitive()))
  totalLoanAmount?: number;
  @serializable(alias("total_active_loan", primitive()))
  totalActiveLoan?: number;
  @serializable(alias("total_active_loan_amount", primitive()))
  totalActiveLoanAmount?: number;
  @serializable(alias("total_own_portfolio", primitive()))
  totalOwnPortfolio?: number;
  @serializable(alias("total_own_portfolio_amount", primitive()))
  totalOwnPortfolioAmount?: number;
  @serializable(alias("average_tat", primitive()))
  averageTat?: number;
  @serializable(alias("total_outstanding", primitive()))
  totalOutstanding?: number;
  @serializable(alias("total_outstanding_amount", primitive()))
  totalOutstandingAmount?: number;
  @serializable(alias("total_managed_portfolio", primitive()))
  totalManagedPortfolio?: number;
  @serializable(alias("total_managed_portfolio_amount", primitive()))
  totalManagedPortfolioAmount?: number;
  @serializable(alias("total_users", primitive()))
  totalUsers?: number;
  @serializable(alias("inactive_users", primitive()))
  inactiveUsers?: number;
  @serializable(alias("active_users", primitive()))
  activeUsers?: number;
  @serializable(alias("total_branches", primitive()))
  totalBranches?: number;
  @serializable(alias("outstanding_graph", list(object(DashboardGraph))))
  outstandingGraph?: DashboardGraph[];
  @serializable(alias("disbursement_graph", list(object(DashboardGraph))))
  disbursementGraph?: DashboardGraph[];
  @serializable(alias("graph", list(object(DashboardGraph))))
  adminGraph?: DashboardGraph[];
  
  /* MIS Dashboard Params */

  @serializable(alias("total_disbursement", primitive()))
  totalDisbursement?: number;
  @serializable(alias("total_borrowers", primitive()))
  totalBorrowers?: number;
  @serializable(alias('total_loans', primitive()))
  totalLoans?: number;
  @serializable(alias("total_active_loans", primitive()))
  totalActiveLoans?: number;
  @serializable(alias("total_active_borrowers", primitive()))
  totalActiveBorrowers?: number;
  @serializable(alias("loan_application_approval", primitive()))
  loanApplications?: LoanAppicationApproval[];
  @serializable(alias("loan_approvals", list(object(LoanAppicationApproval))))
  loanApprovals?: LoanAppicationApproval[];
}
