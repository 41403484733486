import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { Meta } from "../../models/meta.modal";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize, serialize } from "serializr";
import { generatePath, useLocation } from "react-router-dom";
import { useState } from "react";
import { LoanStatus } from "../../enums/loanStatus.type";
import { getLoanStatusLabel } from "../../shared/utils/getLoanStatusLabel";
import { LoanTypeModel } from "../../models/loanType.model";
import { LoanParams } from "../../models/loan.model";
import { getInstallmentStatusLabel } from "../../shared/utils/getInstallmentStatusLabel";
import { InstallmentStatus } from "../../enums/installmentStatus.type";
import { getEndpointWithParams } from "../../shared/utils/pathNameFinder";

export const MetaService = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const [hypothecators, setHypothecators] = useState<Meta[]>([])
  const [ tenures, setTenures ] = useState<number[]>([])
  const { pathname } = useLocation()

  const getRegions = async () => {
    setLoading(true);
    try {
      const endpoint = ApiRoutes.GET_REGIONS;
      const { data } = await axiosInstance.get(endpoint);
      return deserialize(Meta, data?.["regions"] as any[]);
    } catch (ex) {
      Notification({
        message: "Unable to get Regions",
        description: "Unable to get Regions List",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const getStates = async () => {
    setLoading(true);
    try {
      const endpoint = ApiRoutes.GET_INDIAN_STATES;
      const { data } = await axiosInstance.get(endpoint);
      return deserialize(Meta, data?.["states"] as any[]);
    } catch (ex) {
      Notification({
        message: "Unable to get State",
        description: "Unable to get State List",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const getCities = async (stateId: string) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.GET_CITIES, {
        stateId,
      });
      const { data } = await axiosInstance.get(endpoint);
      return deserialize(Meta, data?.["cities"] as any[]);
    } catch (ex) {
      Notification({
        message: "Unable to get State",
        description: "Unable to get State List",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const getPreferredChannel = async () => {
    setLoading(true);
    try {
      const endpoint = ApiRoutes.GET_ORGANIZATIONS;
      const { data } = await axiosInstance.get(endpoint);
      return deserialize(Meta, data?.["organisations"] as any[]);
    } catch (ex) {
      Notification({
        message: "Unable to get Organizations",
        description: "Unable to get Organizations List",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const getRoles = async () => {
    setLoading(true);
    try {
      const endpoint = ApiRoutes.GET_ROLES;
      const { data } = await axiosInstance.get(endpoint);
      return deserialize(Meta, data?.["roles"] as any[]);
    } catch (ex) {
      Notification({
        message: "Unable to get Roles",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const getLoanTypes = async () => {
    setLoading(true);
    try {
      const endpoint = ApiRoutes.GET_LOAN_TYPES;
      const { data } = await axiosInstance.get(endpoint);
      return deserialize(Meta, data?.["loan_types"] as any[]);
    } catch (ex) {
      Notification({
        message: "Unable to get Loan Types",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };
  const getLoanTypesListing = async (params?: LoanParams) => {
    setLoading(true);

    try {
      const queryParams = params ? serialize(LoanParams, params) : {};
      const endpoint = ApiRoutes.GET_LOAN_TYPES_LIST;
      const { data } = await axiosInstance.get(endpoint, {
        params: queryParams,
      });
      return deserialize(LoanTypeModel, data?.["loan_type_details"] as any[]);
    } catch (ex) {
      Notification({
        message: "Unable to get Loan Types",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const getLoanStatus = async () => {
    setLoading(true);
    try {
      const endpoint = getEndpointWithParams(ApiRoutes.GET_LOAN_STATUS, pathname);
      const { data } = await axiosInstance.get(endpoint);
      const meta: Meta[] = data?.["loan_statuses"]?.map(
        (status: LoanStatus) => ({
          label: getLoanStatusLabel(status),
          value: status,
        })
      );
      return meta;
    } catch (ex) {
      Notification({
        message: "Unable to get Loan Status",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const getInstallmentStatus = async () => {
    setLoading(true);
    try {
      const endpoint = ApiRoutes.GET_INSTALLMENT_STATUS;
      const { data } = await axiosInstance.get(endpoint);
      const meta: Meta[] = data?.["instalment_statuses"]?.map(
        (status: InstallmentStatus) => ({
          label: getInstallmentStatusLabel(status),
          value: status,
        })
      );

      return meta;
    } catch (ex) {
      Notification({
        message: "Unable to get Loan Status",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const getStatuses = async () => {
    setLoading(true);
    try {
      const endpoint = ApiRoutes.GET_STATUSES;
      const { data } = await axiosInstance.get(endpoint);
      const meta: Meta[] = data?.["statuses"].map((status: boolean) => ({
        label: status ? "Active" : "Inactive",
        value: status ? "active" : "inactive",
        code: status,
      }));
      return meta;
    } catch (ex) {
      Notification({
        message: "Unable to get Status",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const getHypothecators = async () => {
    setLoading(true);
    try {
      const endpoint = ApiRoutes.GET_HYPOTHECATORS
      const { data } = await axiosInstance.get(endpoint);
      const meta: Meta[] = data?.["hypothecators"] as Meta[]
      const deserializedHypothecators = deserialize(Meta, meta)
      setHypothecators(deserializedHypothecators)
      return deserializedHypothecators;
    } catch (ex) {
      Notification({
        message: "Unable to get Hypothecators",
        type: NotificationTypes.ERROR,
      })
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  }

  const getTenures = async (loanTypeIds?: number[]) => {
    setLoading(true);
    try {
      const endpoint = ApiRoutes.GET_TENURES
      const { data } = await axiosInstance.get(endpoint, {
        params: {
          loan_type_id: loanTypeIds
        }
      })
      setTenures(data.tenures)
    } catch (ex) {
      Notification({
        message: "Unable to get Tenures",
        type: NotificationTypes.ERROR,
      })
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  }

  return {
    getStates,
    getStatuses,
    getRegions,
    getCities,
    getLoanTypesListing,
    getPreferredChannel,
    getInstallmentStatus,
    getRoles,
    getLoanTypes,
    getLoanStatus,
    getHypothecators,
    loading,
    error,
    hypothecators,
    getTenures,
    tenures
  };
};
