import { alias, object, primitive, serializable } from "serializr";

export class Token {
  @serializable(alias("access_token", primitive()))
  accessToken?: string;

  @serializable(alias("token_type", primitive()))
  tokenType?: string;

  @serializable(alias("expires_in", primitive()))
  expiresIn?: number;

  @serializable(alias("refresh_token", primitive()))
  refreshToken?: string;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;
}
