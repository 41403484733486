import { serializable, alias, primitive, list } from "serializr";

export class User {
  @serializable(alias("email", primitive()))
  email?: string;
  @serializable(alias("otp", primitive()))
  otp?: string;
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("password", primitive()))
  password?: string;
  @serializable(alias("reset_password_token", primitive()))
  resetPasswordToken?: string;

  @serializable(alias("new_password", primitive()))
  newPassword?: string;
  @serializable(alias("grant_type", primitive()))
  grantType?: string;
  @serializable(alias("old_password", primitive()))
  oldPassword?: string;
  @serializable(alias("mobile_number", primitive()))
  mobileNumber?: string;

  @serializable(alias("employee_id", primitive()))
  employeeId?: Number;

  @serializable(alias("assigned_for_id", primitive()))
  assignedForId?: Number;
  @serializable(alias("is_active", primitive()))
  isActive?: boolean;
  @serializable(alias("customer_ids", list(primitive())))
  customerIds?: [];
}
