import { Table, TableProps } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import {
  LoanInstallmentParams,
  LoanInstallmentSchedule,
} from "../../../../models/loanInstallmentSchedule.model";

import { QueryParams } from "../../../../models/query.params.model";
import { LoanService } from "../../../../services/Loan/loan.service";
import useQueryParams from "../../../../shared/hooks/useQueryParams";

import { LoanInstallmentColumn } from "./loanInstallmentColumn";

const LoanInstallment = () => {
  const { loanId } = useParams<{ loanId: string }>();

  const [loanInstallment, setLoanInstallment] = useState<
    LoanInstallmentSchedule[]
  >([]);
  const { params, handleSearch, updateParams } = useQueryParams({
    params: new LoanInstallmentParams(),
  });

  useEffect(() => {
    populateLoanInstallment(params);
  }, [params]);

  //service
  const { loading, getLoanInstallment } = LoanService();

  const [loanTotalPage, setLoanTotalPage] = useState(1);

  const populateLoanInstallment = async (params: LoanInstallmentParams) => {
    const installment = await getLoanInstallment(params, loanId ?? "");
    if (installment?.loan) setLoanInstallment(installment?.loan);
    if (installment?.meta) setLoanTotalPage(installment?.meta?.totalCount ?? 1);
  };
  const handleChange: TableProps<LoanInstallmentSchedule>["onChange"] = (
    { current },
    _,
    sorter
  ) => {
    populateLoanInstallment({ ...params, page: current });
  };

  return (
    <div className="loan-installment">
      <div className="card">
        <Table
          columns={LoanInstallmentColumn}
          dataSource={loanInstallment}
          loading={loading}
          onChange={handleChange}
          pagination={{
            hideOnSinglePage: true,
            current: params?.page,
            total: loanTotalPage,
          }}
        />
      </div>
    </div>
  );
};

export default LoanInstallment;
