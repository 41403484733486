import {
  alias,
  list,
  object,
  optional,
  primitive,
  serializable,
} from "serializr";

import { Address } from "./address.model";
import { BankDetail } from "./bankDetail.model";
import { Branch } from "./branch.model";
import { CreatedBy } from "./createdBy.model";
import { Document } from "./document.model";
import { DocumentType } from "../enums/documentType.type";
import { EducationQualification } from "../enums/educationQualification.type";
import { EntityEnum } from "../enums/entityCategory.type";
import { ExistingLoan } from "./existingLoan.model";
import { FamilyDetail } from "./FamilyDetail.model";
import { FamilyMember } from "./familyMember.model";
import { Gender } from "../enums/gender.type";
import { OwnedEntity } from "./ownedEntity.model";
import { QueryParams } from "./query.params.model";
import moment from "moment";
import { CustomerCenter } from "./customerCenter";
import { ThumbnailDocs } from "./thumbnailDocs.model";

export class CustomerParams extends QueryParams {
  @serializable(alias("center.branch.id", list(primitive())))
  centerBranchId?: number[] = [];

  @serializable(alias("created_by.id", list(primitive())))
  createdById?: number[] = [];
  @serializable(alias("assigned_for_id", primitive()))
  assignedIdFor?: string;
  @serializable(alias("organisation_ids", optional(list(primitive()))))
  organisations? = [];
  @serializable(alias("from_date", primitive()))
  fromDate?: string;
  @serializable(alias("to_date", primitive()))
  toDate?: string;
  @serializable(alias("aadhaar_number", primitive()))
  aadhaarNumber?: string;
}
export class Customer {
  @serializable(alias("id", primitive()))
  id?: string;
  @serializable(alias("organisation_id", primitive()))
  organisationId?: number;

  @serializable(alias("code", primitive()))
  code?: string;

  @serializable(alias("firstname", primitive()))
  firstName?: string;

  @serializable(alias("lastname", primitive()))
  lastName?: string;

  @serializable(alias("organisation_ids", list(primitive())))
  organisationIds?: number[];

  @serializable(alias("profile_pic_url", primitive()))
  profilePicUrl?: string;
  @serializable(alias("monthly_income", primitive()))
  monthlyIncome?: number;
  @serializable(alias("business_income", primitive()))
  businessIncome?: number;

  @serializable(alias("profile_pic_id", primitive()))
  profilePicId?: string;

  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("isd_code", primitive()))
  isdCode?: string;

  @serializable(alias("phone_number", primitive()))
  phoneNumber?: string;

  @serializable(alias("aadhaar_number", primitive()))
  aadhaarNumber?: string;

  @serializable(alias('thumbnail_docs', object(ThumbnailDocs)))
  thumbnailDocs?: ThumbnailDocs

  @serializable(alias("organisation_name", primitive()))
  organizationName?: string;

  @serializable(alias("aadhaar_front_image_url", primitive()))
  aadhaarFrontImageUrl?: string;
  @serializable(alias("aadhaar_back_image_url", primitive()))
  aadhaarBackImageUrl?: string;
  @serializable(alias("cro_name", primitive()))
  croName?: string;

  @serializable(alias("gender", primitive()))
  gender?: Gender;

  @serializable(alias("center", object(CustomerCenter)))
  center?: CustomerCenter;

  @serializable(alias("created_by", object(CreatedBy)))
  createdBy?: CreatedBy;

  @serializable(alias("organisation", object(CustomerCenter)))
  organisation?: CustomerCenter;

  @serializable(alias("is_active", primitive()))
  isActive?: boolean;

  @serializable(alias("address", object(Address)))
  address = new Address();

  @serializable(alias("dob", primitive()))
  dob?: string;

  @serializable(alias("pan_number", primitive()))
  panNumber?: string;

  @serializable(alias("pan_document_id", primitive()))
  panDocumentId?: string;

  @serializable(alias("pan_document_url", primitive()))
  panDocumentUrl?: string;

  @serializable(alias("is_email_verified", primitive()))
  isEmailVerified?: boolean;

  @serializable(alias("is_mobile_verified", primitive()))
  isMobileVerified?: boolean;
  @serializable(alias("occupation", primitive()))
  occupation?: string;
  @serializable(alias("relationship", primitive()))
  relationship?: string;
  @serializable(alias("location", primitive()))
  location?: string;
  @serializable(alias("occupation_details", primitive()))
  occupationDetails?: string;
  @serializable(alias("additonal_income", primitive()))
  additonalIncome?: string;

  @serializable(alias("educational_qualification", primitive()))
  educationalQualification?: EducationQualification;

  @serializable(alias("is_aadhaar_verified", primitive()))
  isAadhaarVerified?: boolean;

  @serializable(alias("documents", list(object(Document))))
  documents: Document[] = [];

  @serializable(alias("owned_entities", list(object(OwnedEntity))))
  ownedEntities: OwnedEntity[] = [];

  @serializable(alias("bank_detail", object(BankDetail)))
  bankDetails = new BankDetail();

  @serializable(alias("family_detail", object(FamilyDetail)))
  familyDetail = new FamilyDetail();

  @serializable(alias("family_members", list(object(FamilyMember))))
  familyMembers: FamilyMember[] = [];

  @serializable(alias("existing_loans", list(object(ExistingLoan))))
  existingLoans: ExistingLoan[] = [];

  getEmail = () => this.email ?? "";

  getPhoneNumber = () => {
    const isdCode = this.isdCode ?? "+91";
    const phoneNumber = this.phoneNumber ?? "";
    return phoneNumber ? `${isdCode} ${phoneNumber}` : "NA";
  };

  getDOB = () =>
    moment(this.dob, "YYYY-MM-DD").isValid()
      ? moment(this.dob, "YYYY-MM-DD").format("D MMM YYYY")
      : "NA";

  getAge = () => {
    const age = moment().diff(moment(this.dob, "YYYY-MM-DD"), "years");
    return isNaN(age) ? "NA" : `${age} years`;
  };

  getStatus = () => (this.isActive ? "active" : "inactive");
  getName = () =>
    this.firstName ? `${this.firstName} ${this.lastName ?? ""}  ` : "";

  getEducationalQualification = () => {
    switch (this.educationalQualification) {
      case EducationQualification.HIGHER_SECONDARY:
        return "higher secondary";
      case EducationQualification.OTHER_QUALIFICATION:
        return "other educational qualification";
      default:
        return this.educationalQualification ?? "";
    }
  };

  getAadhaarNumber = () => this.aadhaarNumber;

  getPanNumber = () => this.panNumber;

  getVehicles = () =>
    this.ownedEntities?.filter(
      (entity) => entity.entityCategory === EntityEnum.VEHICLE
    );

  getAppliances = () =>
    this.ownedEntities?.filter(
      (entity) => entity.entityCategory === EntityEnum.APPLIANCE
    );

  getCattles = () =>
    this.ownedEntities?.filter(
      (entity) => entity.entityCategory === EntityEnum.CATTLE
    );

  getOtherProperties = () =>
    this.ownedEntities?.filter(
      (entity) => entity.entityCategory === EntityEnum.OTHER
    );

  getInvestments = () =>
    this.ownedEntities?.filter(
      (entity) => entity.entityCategory === EntityEnum.INVESTMENT
    );

  getNominee = () => this.familyMembers?.find((member) => member?.isNominee);

  getCoBorrower = () =>
    this.familyMembers?.find((member) => member?.isCoBorrower);

  getDrivingLicenseDoc = () =>
    this.documents?.find(
      (doc) => doc.documentType === DocumentType.DRIVING_LICENSE
    );

  getVoterIdDoc = () =>
    this.documents?.find((doc) => doc.documentType === DocumentType.VOTER_ID);

  getOtherDoc = () =>
    this.documents?.filter((doc) => doc.documentType === DocumentType.OTHER);
}
