import { LocalStorage } from "../../enums/localStorage.enum";
import { ColumnConfigProps } from "../types/columnConfiguration.type";

import { LocalStorageHelper } from "../utils/localStorageHelper";

const useTableConfig = () => {
  const columnConfig = LocalStorageHelper.getItem(
    LocalStorage.COLUMN_CONFIG
  ) as ColumnConfigProps;

  const setColumnConfig = (columnKey: string, updatedValue: string[]) => {
    LocalStorageHelper.setItem(LocalStorage.COLUMN_CONFIG, {
      ...columnConfig,
      [columnKey]: updatedValue,
    });
  };

  return { columnConfig, setColumnConfig };
};

export default useTableConfig;
