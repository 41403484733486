import { Col, Row } from "antd";
import React, { ReactElement } from "react";

import { BusinessDetail } from "../../../../../models/businessDetail.model";
import { Loan } from "../../../../../models/loan.model";
import { fillEmptyData } from "../../../../../shared/utils/fillEmptyData";
import { removeUnderscore } from "../../../../../shared/utils/removeUnderscore";

interface JobDetailsProps {
  loan: BusinessDetail;
}

const JobDetails = (props: JobDetailsProps) => {
  const { loan } = props;

  return (
    <Row gutter={[16, 16]} className="block business-nature">
      <Col className="text-capitalize block__heading" span="24">
        Applicant Job Details
      </Col>
      <Col span={6} className="block__label">
        Purpose in Details
        <div className="block__value text-capitalize">
          {fillEmptyData(loan?.purpose)}
        </div>
      </Col>

      <Col span={6} className="block__label">
        Applicant working place
        <div className="block__value text-capitalize">
          {fillEmptyData(loan?.workingPlace)}
        </div>
      </Col>

      <Col span={6} className="block__label">
        Designation
        <div className="block__value text-capitalize">
          {fillEmptyData(loan?.designation)}
        </div>
      </Col>

      <Col span={6} className="block__label">
        Monthly Salary
        <div className="block__value text-capitalize">
          {fillEmptyData(loan?.monthlySalary)}
        </div>
      </Col>

      <Col span={6} className="block__label">
        No of years
        <div className="block__value text-capitalize">
          {fillEmptyData(loan?.yearsOfExperience)}
        </div>
      </Col>
    </Row>
  );
};

export default JobDetails;
