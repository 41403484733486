import { alias, list, primitive, serializable } from "serializr";
import { QueryParams } from "./query.params.model";

export class BookDebt extends QueryParams {
    
@serializable(alias('channel_type', primitive()))
channelType?: string;

@serializable(alias('minimum_loan_amount', primitive()))
minimumLoanAmount?: number;

@serializable(alias('maximum_loan_amount', primitive()))
maximumLoanAmount?: number;

@serializable(alias('total_amount', primitive()))
totalAmount?: number;

@serializable(alias('disbursement_date', primitive()))
disbursementDate?: string;

@serializable(alias('is_hypothecator', primitive()))
isHypothecator?: boolean;

@serializable(alias('hypothecator_id', list(primitive())))
hypothecatorId? = []

@serializable(alias('security_types', list(primitive())))
securityTypes?: string[] = []

@serializable(alias('loan_type_id', list(primitive())))
loanTypeId?: number[]

@serializable(alias('branch_id', list(primitive())))
branchId?: number[]

@serializable(primitive())
tenure?: string

@serializable(alias('disbursement_from_date', primitive()))
disbursementFromDate?: string

@serializable(alias('disbursement_to_date', primitive()))
disbursementToDate?: string
@serializable(alias('state_id', list(primitive())))
stateId?: string[] = []
}

export class Hypothecator {
    @serializable(primitive())
    name?: string
}

export class HypothecatorLoans {
    @serializable(alias('loan_ids', list(primitive())))
    loanIds?: number[]

    @serializable(alias('hypothecator_id', primitive()))
    hypothecatorId?: string

    @serializable(alias('hypothecator_name', primitive()))
    hypothecatorName?: string
}