import { Col, Row } from "antd";
import React, { useState } from "react";
import { Loan } from "../../../../../models/loan.model";
import "./securityDetails.scss";
import MODUploadForm from "../../../../MODDocuments/MODUploadForm";
import { removeUnderscore } from "../../../../../shared/utils/removeUnderscore";
import { fillEmptyData } from "../../../../../shared/utils/fillEmptyData";
import { DocumentType } from "../../../../../enums/documentType.type";
import { getMODStatusLabel } from "../../../../../shared/utils/getModStatusLabel";
import { numberFormatter } from "../../../../../shared/utils/formatter";
import { LocalStorageHelper } from "../../../../../shared/utils/localStorageHelper";
import { LocalStorage } from "../../../../../enums/localStorage.enum";
import { Employee } from "../../../../../models/employee.model";
import { RoleName } from "../../../../../enums/roleName.enum";
import { renderModStatus } from "../../../../../shared/utils/renderModStatus";
import { modLoansTypes } from "../../../../../enums/modLoans.type";

interface SecurityDetailsProps {
  loan: Loan;
  initializeLoan: () => void;
  initializeCustomer?: () => void;
}

const SecurityDetails = (props: SecurityDetailsProps) => {
  const { loan, initializeCustomer, initializeLoan } = props;
  const [modalVisible, setModalVisible] = useState(false);
  const userAccess = LocalStorageHelper.getItem(LocalStorage.EMPLOYEE) as Employee;
  const hasUploadAccess = userAccess && (
    userAccess.roleName === RoleName.ADMIN ||
    userAccess.roleName === RoleName.SUPER_ADMIN ||
    userAccess.roleName === RoleName.HEAD_OFFICER
);
const refreshData = () => {
  if (initializeCustomer)
  initializeCustomer();
  initializeLoan();
}
const isModLoan = loan?.securedType === modLoansTypes.MOD_SECURED || loan?.securedType === modLoansTypes.EQUAITABLE_MORTAGE
const isPending = loan?.modStatus === modLoansTypes.PENDING

  return (
    <Row className="block security-details" gutter={[16, 16]}>
      <Col span={(hasUploadAccess && isModLoan && isPending) ? 20 : 22} className="block__heading">
        Security Details
      </Col>
      {hasUploadAccess && isModLoan && isPending &&
      <Col className="link">
        <a onClick={() => setModalVisible(!modalVisible)} className="mr-5">
          {"UPLOAD DOCUMENTS"}
        </a>
      </Col>
      }
      <Col span={4} className="block__label">
        Security Type
        <div className="block__value">
          {fillEmptyData(renderModStatus(loan?.securedType ?? ""))}
        </div>
      </Col>
      <Col span={4} className="block__label">
        Property Value
        <div className="block__value">
          {fillEmptyData(numberFormatter(loan?.propertyValue ?? 0))}
        </div>
      </Col>
      <Col span={4} className="block__label">
        Documentation Officer
        <div className="block__value">
          {fillEmptyData(removeUnderscore(loan?.documentationOffier ?? ""))}
        </div>
      </Col>
      <Col span={4} className="block__label">
        Document ID
        <div className="block__value">
          {fillEmptyData(
            removeUnderscore(
              loan?.customer?.documents.find(
                (doc) => doc.documentType === DocumentType.MOD_DOCUMENT
              )?.code ?? ""
            )
          )}
        </div>
      </Col>
      <Col span={4} className="block__label">
        Document Location
        <div className="block__value">
          {fillEmptyData(removeUnderscore(loan?.modDocumentLocation ?? ""))}
        </div>
      </Col>
      <Col span={4} className="block__label">
        MOD Status
        <div className="block__value">
          {fillEmptyData(getMODStatusLabel(removeUnderscore(loan?.modStatus)))}
        </div>
      </Col>
      <MODUploadForm
        refreshData={refreshData}
        loan={loan}
        title="Upload Security Document"
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
      />
    </Row>
  );
};

export default SecurityDetails;
