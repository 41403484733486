import "../personalDetails.scss";

import Appliances from "../Appliances";
import BankDetails from "../BankDetails";
import ExistingLoanDetails from "../ExistingLoanDetails";
import React, { useEffect } from "react";
import { Spin } from "antd";
import { Loan } from "../../../../../models/loan.model";
import LoanPersonalWrapper from "../LoanPersonalWrapper";
import { CustomerDetail } from "../../../../../models/customerDetail.model";
import CustomerFamilyDetails from "../../../../Loans/LoanIndividualDetails/CustomerFamilyDetails";

import ExistingLoanSummary from "../../../../Loans/ExistingLoanSummary";
import CustomerPersonalSummary from "../../../CustomerPersonalSummary";
import { LoanCustomerDetails } from "../../../../../models/customerDetailsNew";
import { LoanType } from "../../../../../enums/loanType.type";
import BusinessNature from "../../../../Loans/LoanIndividualDetails/LoanSummary/BusinessNature";
import PresentBusinessAsset from "../../../../Loans/LoanIndividualDetails/LoanSummary/PresentBusinessAsset";
import CustomerBankDetails from "../CustomerBankDetails";

interface CustomerPersonalDetailsProps {
  customer: CustomerDetail;
  loading: boolean;
  isPartOfLoan?: boolean;
  hasCard?: boolean;
  loan?: Loan;
  onSuccess?: () => void;
}

const CustomerPersonalDetails = (props: CustomerPersonalDetailsProps) => {
  const {
    customer,
    isPartOfLoan = false,
    hasCard = false,
    loading,
    loan,
    onSuccess,
  } = props;

  useEffect(() => {
    handleFamilyMembers();
  }, []);

  const handleFamilyMembers = () => {
    return customer?.familyMembers?.[
      customer?.familyMembers?.length - 1
    ]?.loanFamilyMembers?.find((member) => member?.isCoBorrower);
  };

  return (
    <div className={hasCard ? "card" : ""}>
      {loading ? (
        <div className="text-center">
          <Spin />
        </div>
      ) : (
        <div className="personal-details">
          <ExistingLoanSummary aadhaarNumber={customer?.aadhaarNumber} />
          <CustomerPersonalSummary
            customerDetails={
              customer?.customerDetails?.[0]?.loanCustomerDetail ??
              new LoanCustomerDetails()
            }
            loan={loan}
            customer={customer}
          />

          <ExistingLoanDetails
            familyDetail={customer?.familyDetail}
            loans={customer?.existingLoans}
          />
          {loan?.loanTypeCode === LoanType.BUSINESS && (
            <>
              <BusinessNature businessDetail={loan?.businessDetail} />

              <PresentBusinessAsset businessDetail={loan?.businessDetail} />
            </>
          )}

          <Appliances appliances={customer?.getAppliances()} />
          <LoanPersonalWrapper
            vehicles={customer?.getVehicles()}
            cattles={customer?.getCattles()}
            properties={customer?.getOtherProperties()}
            investments={customer?.getInvestments()}
          />

          <CustomerBankDetails
            customer={customer}
            loan={loan}
            onSuccess={onSuccess}
          />
        </div>
      )}
    </div>
  );
};

export default CustomerPersonalDetails;
