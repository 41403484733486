import { alias, list, object, primitive, serializable } from "serializr";
import {LoanOwnedEntity } from "./loanOwnedEntity";
import { EntityEnum } from "../enums/entityCategory.type";

export class CustomerOwnedEntity {
  @serializable(alias("id", primitive()))
  id?: number;
  
  @serializable(alias("code", primitive()))
  code?: string;
  
  @serializable(alias("loan_owned_entities", list(object(LoanOwnedEntity))))
  loanOwnedEntities: LoanOwnedEntity[] = [];

 


}
