import "../branchForm.scss";
import { Form, Formik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { Branch } from "../../../../models/branch.model";
import { Button, Col, Row, Spin } from "antd";
import { StepStatus } from "../../../../enums/stepStatus.enum";
import Maps from "../../../../shared/components/Maps";
import { branchPinLocationValidation } from "./branchPinLocationValidation";

interface BranchPinLocationFormProps {
  formValues?: Branch;
  currentStep?: number;
  toggleStep?: (status: string) => void;
  onSubmit: (branch: Branch) => void;
  onClose: () => void;
}

function BranchPinLocationForm({
  formValues,
  onClose,
  toggleStep,
  onSubmit,

  currentStep,
}: BranchPinLocationFormProps) {
  return (
    <div>
      <Formik
        initialValues={formValues ?? new Branch()}
        validationSchema={branchPinLocationValidation}
        onSubmit={(values) => onSubmit(values)}
        enableReinitialize
      >
        {({ isSubmitting, setFieldValue, values, isValid }) => {
          return (
            <>
              <div className="branch-location-form">
                <div className="branch-location-form__header">
                  <div className="branch-location-form__header__process-count">
                    3
                  </div>
                  <div className="branch-location-form__header__process-title">
                    Pin Location
                  </div>
                </div>

                <div className="branch-location-form__content">
                  <Form className="ant-form-vertical">
                    <Row>
                      <Col span={24}>
                        {" "}
                        <Maps
                          latName="address.latitude"
                          lngName="address.longitude"
                          centerValue={{
                            lat: values?.address?.latitude ?? 0,
                            lng: values?.address?.longitude ?? 0,
                          }}
                          setFieldValue={setFieldValue}
                          width="55vw"
                          height="60vh"
                        />
                      </Col>
                    </Row>
                    <div className="branch-form__content__footer drawer-footer">
                      <Button
                        className="drawer-footer__cancel"
                        htmlType="reset"
                        onClick={() =>
                          toggleStep && toggleStep(StepStatus.PREV)
                        }
                        type="link"
                      >
                        Prev
                      </Button>
                      <Button
                        // disabled={!isValid}
                        className="drawer-footer__submit"
                        htmlType="submit"
                      >
                        Save
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
}
export default BranchPinLocationForm;
