import { Button, Checkbox, Col, Form, Pagination, Radio, Row } from "antd";
import { Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { Customer } from "../../../../../models/customer.model";
import { Employee } from "../../../../../models/employee.model";
import { Meta } from "../../../../../models/meta.modal";
import { PaginationMeta } from "../../../../../models/pagination.model";
import { QueryParams } from "../../../../../models/query.params.model";
import { User } from "../../../../../models/user.model";
import { CustomersService } from "../../../../../services/Customers/customers.service";
import { EmployeeService } from "../../../../../services/Employee/employee.service";
import SearchInput from "../../../../../shared/components/SearchInput";
import "./assignUser.scss";
import { assignUserValidation } from "./assignUserValidation";
import { CheckboxValueType } from "antd/lib/checkbox/Group";

interface AssignUserProps {
  userId: string;
  onClose: () => void;
  employeeDetails: Employee;
}
const AssignUser: FC<AssignUserProps> = (props) => {
  const { onClose, userId, employeeDetails } = props;
  const { getCustomers, loading: customerLoading } = CustomersService();
  const {
    updateAssignUser,
    getEmployees,
    loading: employeesLoading,
  } = EmployeeService();
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [selectedCustomerIds, setSelectedCustomerIds] = useState<
    CheckboxValueType[]
  >([]);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<number>();
  const [customerPagination, setCustomerPagination] =
    useState<PaginationMeta>();
  const [employeePagination, setEmployeePagination] =
    useState<PaginationMeta>();
  const [employee, setEmployee] = useState<Employee[]>([]);

  const fetchCustomers = async (params?: QueryParams) => {
    const result = await getCustomers({ ...params, assignedIdFor: userId });
    result && setCustomers(result.customers);
    setCustomerPagination(result?.meta);
  };

  const fetchEmployee = async (params?: QueryParams) => {
    const branchIds = employeeDetails?.branches?.map((data) =>
      Number(data?.id)
    );

    const result = await getEmployees({
      ...params,
      currentEmployeeId: userId,
      roleIds: [Number(employeeDetails?.roleId)],
      isActive: true,
      branchesId: branchIds,
    });
    result && setEmployee(result.employees);
    setEmployeePagination(result?.meta);
  };

  useEffect(() => {
    fetchCustomers();
    fetchEmployee();
  }, []);

  const handleSubmit = async (values: User) => {
    const emp = await updateAssignUser({
      customerIds: selectedCustomerIds as [],
      employeeId: selectedEmployeeId,
    });
    emp && onClose();
  };
  const handleSelectedCustomer = (ids: CheckboxValueType[]) =>
    setSelectedCustomerIds(
      Array.from(new Set([...selectedCustomerIds, ...ids]))
    );

  return (
    <div className="assign-user">
      <Formik
        validationSchema={assignUserValidation}
        initialValues={new User()}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values, dirty, isValid }) => {
          return (
            <Form>
              <h2>Customers</h2>
              <div className="assign-user__customer">
                <div className="assign-user__search mb-5">
                  {" "}
                  <SearchInput
                    placeholder="Search Customer"
                    onSearch={(value) => {
                      fetchCustomers({ searchText: String(value) });
                    }}
                  />
                </div>
                <Checkbox.Group
                  name="customerIds"
                  className="assign-user__checkbox-group mt-5"
                  value={selectedCustomerIds}
                  onChange={handleSelectedCustomer}
                >
                  <Row gutter={[20, 10]} className="mb-5">
                    {customers.map(
                      (cust, i) =>
                        (
                          <Col span={6}>
                            <Checkbox value={cust?.id} key={cust?.id}>
                              {cust?.getName() || cust?.code}
                            </Checkbox>
                          </Col>
                        )
                    )}
                  </Row>
                </Checkbox.Group>
                <Pagination
                  className="mt-5 text-right"
                  total={customerPagination?.totalCount}
                  current={customerPagination?.currentPage}
                  onChange={(page) => {
                    fetchCustomers({ page: page });
                  }}
                />
              </div>
              <h2>Assign User</h2>
              <div>
                <div className="assign-user__search mb-5">
                  {" "}
                  <SearchInput
                    placeholder="Search User"
                    onSearch={(value) => {
                      fetchEmployee({ searchText: String(value) });
                    }}
                  />
                </div>
                <Radio.Group
                  className="mt-5"
                  name="employeeId"
                  onChange={(e) => {
                    setSelectedEmployeeId(e.target.value);
                    setFieldValue("employeeId", e.target.value);
                  }}
                >
                  <Row gutter={[20, 10]}>
                    {employee.map(
                      (emp, i) =>
                        emp?.getName() && (
                          <Col span={6}>
                            <Radio value={emp?.id} key={emp?.id}>
                              {emp?.getName()}
                            </Radio>
                          </Col>
                        )
                    )}
                  </Row>
                </Radio.Group>
                <Col span={24}>
                  <Pagination
                    className="mt-5 text-right"
                    total={employeePagination?.totalCount}
                    current={employeePagination?.currentPage}
                    onChange={(page) => {
                      fetchEmployee({ page: page });
                    }}
                  />
                </Col>
              </div>
              <div className="drawer-footer">
                <Button
                  onClick={onClose}
                  type="link"
                  className="drawer-footer__cancel"
                  htmlType="reset"
                >
                  Cancel
                </Button>
                <Button
                  className="drawer-footer__submit"
                  htmlType="submit"
                  onClick={() => handleSubmit(values)}
                  disabled={!(selectedCustomerIds && selectedEmployeeId)}
                >
                  Assign
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AssignUser;
