import { deserialize } from "serializr";
import { LocalStorage } from "../../enums/localStorage.enum";
import { NotificationTypes } from "../../enums/notificationTypes";
import axiosInstance from "../../interceptor/axiosInstance";
import { Employee } from "../../models/employee.model";
import { Token } from "../../models/token.model";
import { User } from "../../models/user.model";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import Notification from "../../shared/components/Notification";

export const TokenService = () => {
  const getRemoteAccessToken = async (
    user: User
  ): Promise<{
    token: Token;
    employee: Employee;
  }> => {
    try {
      const response = await axiosInstance.post(ApiRoutes.USER_LOGIN, {
        employee: user,
      });
      if (
        !(
          response &&
          response?.data &&
          response?.data?.employee &&
          response?.data?.token
        )
      )
        throw new Error();

      const token = deserialize(Token, response?.data?.token);
      const employee = deserialize(Employee, response.data?.["employee"]);
      localStorage.setItem(LocalStorage.USER, JSON.stringify(token));
      localStorage.setItem(
        LocalStorage.EMPLOYEE,
        JSON.stringify(employee || {})
      );
      return {
        token,
        employee,
      };
    } catch (e: any) {
      throw new Error(e);
    }
  };

  const getLocalAccessToken = (): Token => {
    let token = new Token();
    try {
      const user = localStorage.getItem(LocalStorage.USER);
      if (user) token = JSON.parse(user);
    } catch (ex: any) {
      Notification({
        message: "Token Expired",
        description: "Your Session is completed try login again",
        type: NotificationTypes.ERROR,
      });
    } finally {
      return token;
    }
  };

  const updateLocalAccessToken = async () => {
    try {
      const user = getLocalAccessToken();
      const { data } = await axiosInstance.post(ApiRoutes.USER_LOGIN, {
        employee: {
          grant_type: "refresh_token",
          refresh_token: user?.refreshToken,
        },
      });

      const token = deserialize(Token, data?.token);

      localStorage.setItem(LocalStorage.USER, JSON.stringify(token));
    } catch (ex) {
      Notification({
        message: "Token Expired",
        description: "Your Session is completed try login again",
        type: NotificationTypes.ERROR,
      });
    } finally {
    }
  };

  const removeLocalAccessToken = () => localStorage.clear();

  return {
    getRemoteAccessToken,
    getLocalAccessToken,
    updateLocalAccessToken,
    removeLocalAccessToken,
  };
};
