import React, { useEffect, useState } from 'react'
import { currencyFormatter } from '../../utils/formatter'
import { LoanParams } from '../../../models/loan.model';
import { LoanService } from '../../../services/Loan/loan.service';
import { AllLoanTypes } from '../../../enums/pipelineLoanTypes';

type Props = {
    params: LoanParams
    type: string
}

const LoanAmountDetails = (props: Props) => {

    const { params, type } = props

    const { getTotalLoanAmount } = LoanService();
    const [totalLoanAmount, setTotalLoanAmount] = useState<number>();
    const [totalLoanCount, setTotalLoanCount] = useState<number>();

    const getLoanTypeParams = (type?: string) => {

        let loanTypeParams
    
        if (type === AllLoanTypes.OFFLINE) {
          loanTypeParams = { offlineLoan: true };
        } else if (type === AllLoanTypes.REJECTED) {
          loanTypeParams = { rejected: true };
        } else if (type === AllLoanTypes.LOAN_SECTION) {
          loanTypeParams = { disbursed: true}
        } else {
          loanTypeParams = { pipeline: true };
        }
      
        return loanTypeParams;
      }
    
      useEffect(() => {
        const handleTotalLoanAmount = async (params?: LoanParams) => {
            const loanParams = getLoanTypeParams(type);
            const updatedParams = { ...params, ...loanParams };
            if (!!updatedParams['searchText'] || updatedParams['page'] && updatedParams['page'] > 1) {
                return;
            } else {
                const loanAmount = await getTotalLoanAmount(updatedParams);
                setTotalLoanAmount(loanAmount?.totalLoanAmount);
                setTotalLoanCount(loanAmount?.totalLoanCount);
            }
        };
    
        handleTotalLoanAmount(params);
    }, [params]);
    
  
  return (
    <div>
        <div className="loan-amount-container">
        <div className="total-loans-container">
          <p className="block__heading">Total Loans</p>
          <p className="block__label">{totalLoanCount}</p>
        </div>
        <div className="total-amount-container">
          <p className="block__heading">Total Amount</p>
          <p className="block__label">
            {currencyFormatter({
                  amount: totalLoanAmount,
                  hasCurrencySign: true,
                  shouldReturnNA: false
                })
              }
          </p>
        </div>
      </div>
    </div>
  )
}

export default LoanAmountDetails