import {serializable, alias, object, list, primitive} from 'serializr';

export class CreditEnquiries { 

	@serializable(alias('MEMBER-NAME', primitive()))
	memberName?: string;

	@serializable(alias('INQUIRY-DATE', primitive()))
	inquiryDate?: string;

	@serializable(alias('PURPOSE', primitive()))
	purpose?: string;

	@serializable(alias('AMOUNT', primitive()))
	amount?: string;

	@serializable(alias('REMARK', primitive()))
	remark?: string;

}
