import React, { useEffect, useState } from "react";
import { Button, Input } from "antd";
import { Form, Formik } from "formik";

import { Branch } from "../../../../models/branch.model";
import SelectInput from "../../../../shared/components/SelectInput";
import { branchTargetFormValidation } from "./branchTargetFormValidation";
import InputField from "../../../../shared/components/InputField";
import { InputType } from "../../../../enums/input.type";

import editIcon from "../../../../Assets/images/edit.png";

import "./branchTargetForm.scss";
import { Meta } from "../../../../models/meta.modal";

interface BranchTargetFormProps {
  formValues?: Branch;
  onSubmit: (branch: Branch) => void;
  onClose: () => void;
  getBranchTarget: (branch: Branch) => void;
}

const BranchTargetForm = (props: BranchTargetFormProps) => {
  const { formValues, onSubmit, onClose, getBranchTarget } = props;

  const [targetEditable, setTargetEditable] = useState<boolean>(false);
  const [formData, setFormData] = useState<Branch>(formValues ?? new Branch());

  const toggleTargetEditable = () => setTargetEditable(!targetEditable);

  const curYear = new Date().getFullYear();
  const years = Array.from(Array(100), (_, i) => curYear + i);

  const FullMonths = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div>
      <Formik
        initialValues={formValues ?? new Branch()}
        validationSchema={branchTargetFormValidation}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <div className="branch-target-form">
            <Form className="ant-form-vertical">
              <SelectInput
                options={[
                  {
                    value: formValues?.id,
                    code: formValues?.code,
                    label: formValues?.name,
                  },
                ]}
                disabled
                name="name"
                label="Branch"
              />
              <SelectInput
                options={years.map((year) => {
                  return {
                    value: year.toString(),
                    code: year.toString(),
                    label: year.toString(),
                  };
                })}
                name="year"
                label="Year"
                onChange={async (_, option) => {
                  const month = option as Meta;

                  setFieldValue("year", month.value);

                  const target = await getBranchTarget({
                    ...values,
                    year: String(month.value),
                  });
                  setFieldValue("target", target);
                }}
              />
              <SelectInput
                options={FullMonths.map((month) => {
                  return {
                    value: month,
                    code: month,
                    label: month,
                  };
                })}
                name="month"
                label="Month"
                onChange={async (_, option) => {
                  const month = option as Meta;

                  setFieldValue("month", month.value);

                  const target = await getBranchTarget({
                    ...values,
                    month: String(month.value),
                  });
                  setFieldValue("target", target);
                }}
              />
              <span className="field-icon-wrapper">
                <InputField
                  type={InputType.TEXT}
                  name="target"
                  placeholder=""
                  disabled={!targetEditable}
                />
                <img
                  src={editIcon}
                  alt=""
                  className="circular-icon"
                  onClick={toggleTargetEditable}
                />
              </span>
              <div className="branch-form__content__footer drawer-footer">
                <Button
                  className="drawer-footer__cancel"
                  htmlType="reset"
                  onClick={onClose}
                  type="link"
                >
                  Cancel
                </Button>
                <Button
                  loading={isSubmitting}
                  className="drawer-footer__submit"
                  htmlType="submit"
                >
                  Save
                </Button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default BranchTargetForm;
