import React from "react";
import { ColumnProps } from "antd/lib/table";
import { removeUnderscore } from "../../../../shared/utils/removeUnderscore";
import { Blacklists } from "../../../../models/blacklists";
import { dateTimeFormatter } from "../../../../shared/utils/formatter";
import { DateFormat } from "../../../../enums/dateFormat.type";

export const blackListCustomerColumn: ColumnProps<Blacklists>[] = [
  {
    title: "Customer ID",
    dataIndex: "code",
    render: (_, list) => list?.customer?.code ?? "",
  },
  {
    title: "Name",
    dataIndex: "name",
    render: (_, list) => list?.customer?.name ?? "",
  },
  {
    title: "Aadhar",
    dataIndex: "aadhar",
    render: (_, list) => list?.customer?.aadhaarNumber ?? "",
  },
  {
    title: "PAN",
    dataIndex: "pan",
    render: (_, list) => list?.customer?.panNumber ?? "",
  },
  {
    title: "Phone",
    dataIndex: "phone",
    render: (_, list) => list?.customer?.phoneNumber ?? "",
  },
  {
    title: "Date",
    dataIndex: "date",
    render: (date) => dateTimeFormatter(date ?? "", DateFormat.DATE),
  },
  {
    title: "Reason",
    dataIndex: "reason",
  },
  {
    title: "Blocked By",
    dataIndex: "blockedBy",
  },

  {
    title: "Status",
    dataIndex: "status",
    render: (status) => {
      const className =
        "active-status--" + status?.split("_")?.join("-").toLowerCase();
      return (
        <span className={` text-capitalize  ${className}`}>
          {removeUnderscore(status ?? "")}
        </span>
      );
    },
  },
];
